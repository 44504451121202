import {
  GET_FACULTIES_SUCCESS,
  GET_FACULTIES_FAIL,
  GET_FACULTY_SPECIALITIES_SUCCESS,
  GET_FACULTY_SPECIALITIES_FAIL,
  GET_FACULTIES_SPECIALITIES_CLIENT_SUCCESS,
  GET_FACULTIES_SPECIALITIES_CLIENT_FAIL,
  // SET_SELECTED_SPECIALITIES_FACULTIES_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
    faculties: [],
    specialties: [],
    selectedSpecialitiesFaculties: [],
  }
;

export default function compaignReducer(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_FACULTIES_SUCCESS:
      return {...state, faculties: payload};
    case GET_FACULTIES_FAIL:
      return {...state, faculties: []};
    case GET_FACULTY_SPECIALITIES_SUCCESS:
      return {...state, specialties: payload};
    case GET_FACULTY_SPECIALITIES_FAIL:
      return {...state, specialties: []};
    case GET_FACULTIES_SPECIALITIES_CLIENT_SUCCESS:
      return {...state, selectedSpecialitiesFaculties: payload};
    case GET_FACULTIES_SPECIALITIES_CLIENT_FAIL:
      return {...state, selectedSpecialitiesFaculties: []};
    // case SET_SELECTED_SPECIALITIES_FACULTIES_SUCCESS:
    //   return {...state, selectedSpecialitiesFaculties: [...state.selectedSpecialitiesFaculties, payload]};
    default:
      return state;
  }
}
