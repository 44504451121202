import {
  GET_FACULTIES_CONTACT_FAIL,
  GET_FACULTIES_CONTACT_SUCCESS,
  GET_ORGANIZATION_CONTACT_SUCCESS,
  GET_ORGANIZATION_CONTACT_FAIL,
} from "./actionTypes";
import instance from "../../customAxios";

const API_URL = "/";

export function getMyFaculties () {
  return async (dispatch) => {
    try {
      const response = await instance.get(`${API_URL}api/ext/faculties/my`);
      dispatch(getFacultiesContactSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getFacultiesContactFail());
      console.error('☠️error getFaculties actions contacts: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}

export function getOrganizationContact () {
  return async (dispatch) => {
    try {
      const response = await instance.get(`${API_URL}api/pub/organization`);
      dispatch(getOrganizationContactSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getOrganizationContactFail());
      console.error('☠️error getOrganizationContact actions contacts: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}


export function getOrganizationContactSuccess (organizationContact) {
  return {
    type: GET_ORGANIZATION_CONTACT_SUCCESS,
    payload: organizationContact
  }
}

export function getOrganizationContactFail () {
  return {
    type: GET_ORGANIZATION_CONTACT_FAIL
  }
}

export function getFacultiesContactSuccess (faculties) {
  return {
    type: GET_FACULTIES_CONTACT_SUCCESS,
    payload: faculties
  }
}

export function getFacultiesContactFail () {
  return {
    type: GET_FACULTIES_CONTACT_FAIL
  }
}
