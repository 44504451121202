import {
    Button,
    Grid,
    InputAdornment,
    TextField
} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {useForm} from "../../../../components/Customs/useForm";
import Valid from "../../../../constants/Valid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const initialFValues = {
    userId: "",
};

function useFormRemoteUserControl(propsOnSubmit, validate, values) {
    function onSubmit(e) {
        e.preventDefault();
        if (validate()) {
            const obj = {
                userId: values?.userId
            };
            propsOnSubmit && propsOnSubmit(obj);
        }
    }
    return {onSubmit};
}

export default function Form(props) {
    const classes = useStyles();
    const {onSubmit: propsOnSubmit, sending: propsSending} = props;

    const validate = (fieldValues = values) => {
        let temp = {...errors};
        if ('userId' in fieldValues) {
            temp.userId = "";
            if (temp?.userId === "" && !Valid.isIsset(fieldValues?.userId)) {
                temp.userId = "Обязательно для заполнения.";
            }
        }

        setErrors({...temp});
        if (fieldValues === values) {
            return Object.values(temp).every(x => x === '')
        }
    };

    const {
        values,
        errors,
        setErrors,
        handleInputChange: onChange
    } = useForm(initialFValues, false, validate);
    const {onSubmit} = useFormRemoteUserControl(propsOnSubmit, validate, values);

    return (
        <form className={classes.form} onSubmit={onSubmit} noValidate>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        required
                        variant="outlined"
                        fullWidth
                        name="userId"
                        label="Id пользователя"
                        id="userId"
                        placeholder="Введите Id пользователя"
                        value={values.userId}
                        onChange={onChange}
                        error={Boolean(errors.userId)}
                        helperText={errors.userId && errors.userId}
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <AccountCircleIcon color="disabled"/>
                                </InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.submit}
                        disabled={propsSending}
                    >
                        Отправить
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
    },
    formControl: {},
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

Form.propTypes = {
    onSubmit: PropTypes.func,
    sending: PropTypes.bool
};