import React, {useEffect, useState} from 'react';
import {
  Box, FormControl, FormHelperText,
  Grid,
  IconButton, InputAdornment, InputLabel, OutlinedInput,
  Paper, Tooltip,
  Typography
} from "@material-ui/core";
import KeyLang from "./keys.js";
import PropTypes from "prop-types";
import KeyboardIcon from '@material-ui/icons/Keyboard';
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide';

export function KeyBoardMini(props) {
  const {
    value, setValue, visible, defaultLanguage, name,
    xs: PropsLayoutXS, sm: PropsLayoutSM, md: PropsLayoutMD, lg: PropsLayoutLG
  } = props;

  const [language, setLanguage] = useState(null);
  const [shift, setShift] = useState(false);
  const [caps, setCaps] = useState(false);

  useEffect(() => {
    if (KeyLang.language[defaultLanguage] !== undefined) {
      setLanguage(defaultLanguage);
    } else {
      setLanguage(Object.keys(KeyLang.language)[0]);
    }
  }, []);


  function onClickButton(item = undefined) {
    if (!item) return;
    const newItem = (shift || caps ? item.toUpperCase() : item.toLowerCase());
    setValue({target: {name: name, value: (value + newItem)}});
    if (shift) setShift(!shift);
  }

  function onClickFunction(fun = undefined) {
    if (fun && fun === 'shift') {
      setShift(!shift);
    } else if (fun && fun === 'back') {
      if (value.length > 0) {
        setValue({target: {name: name, value: value.substring(0, value.length - 1)}});
      }
    } else if (fun && fun === 'caps') {
      setCaps(!caps)
    }
  }


  if (!visible) return null;
  return (
    <React.Fragment>
      <Paper>
        <Box p={1}>
          <Grid container spacing={1} alignItems="center" alignContent="center" justifyContent="center">
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center" alignContent="center" justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="caption">Клавиатура</Typography>
                  <Typography variant="caption">({KeyLang.language[language]})</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {language !== null && KeyLang.key[language] !== undefined && KeyLang.key[language].map(
                  (columItem, columIndex) => {
                    return (
                      <React.Fragment key={`row-colum-${columIndex}`}>
                        <Grid item
                              {...{xs: PropsLayoutXS ?? columItem.s ?? 3}}
                              {...(PropsLayoutSM && {sm: PropsLayoutSM ?? 2})}
                              {...(PropsLayoutSM && {md: PropsLayoutMD ?? 1})}
                              {...(PropsLayoutLG && {lg: PropsLayoutLG ?? 2})}>
                          <Tooltip
                            title={columItem.n || (shift || caps ? columItem?.t.toUpperCase() : columItem?.t.toLowerCase())}>
                            <Paper {...(
                              (shift && columItem.f === "shift") || (caps && columItem.f === "caps")
                                ? {elevation: 3, style: {opacity: '0.9'}}
                                : {variant: "outlined"})
                                   }
                                   {...(columItem.f ? {onClick: () => onClickFunction(columItem.f)} : {onClick: () => onClickButton(columItem.t)})}
                                   style={{cursor: 'pointer'}}>
                              <Box textAlign="center" display="flex"
                                   justifyContent="center"
                                   alignItems="center" style={{padding: '0.5em 0'}}>
                                {typeof columItem.t === "string"
                                  ? <Typography variant="caption">
                                    {(shift || caps ? columItem?.t.toUpperCase() : columItem.t.toLowerCase())}
                                  </Typography>
                                  : columItem.t
                                }
                              </Box>
                            </Paper>
                          </Tooltip>
                        </Grid>
                      </React.Fragment>
                    )
                  }
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

KeyBoardMini.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onVisible: PropTypes.func.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

export default function KeyBoardMiniForm(props) {
  const {
    xs: PropsLayoutXS, sm: PropsLayoutSM, md: PropsLayoutMD, lg: PropsLayoutLG,
    name, label, defaultLanguage, value, error = null, onChange, ...rest
  } = props;
  const [viewKeyBoard, setViewKeyBoard] = useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth {...(error && {
          error: true
        })}>
          <InputLabel htmlFor="outlined-adornment-password">{label ?? ''}</InputLabel>
          <OutlinedInput
            {...rest}
            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            {...(error && {error: true})}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setViewKeyBoard(!viewKeyBoard)}
                  edge="end">
                  {viewKeyBoard ? <KeyboardHideIcon/> : <KeyboardIcon/>}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
          {error ? <FormHelperText>{error}</FormHelperText> : null}
        </FormControl>
      </Grid>
      {viewKeyBoard
        ? <Grid item xs={12}>
          <KeyBoardMini name={name} visible={viewKeyBoard} onVisible={setViewKeyBoard} setValue={onChange}
                        value={value}
                        xs={PropsLayoutXS}
                        sm={PropsLayoutSM}
                        md={PropsLayoutMD}
                        lg={PropsLayoutLG}
                        defaultLanguage={defaultLanguage}/>
        </Grid>
        : null
      }
    </Grid>
  )
}
KeyBoardMiniForm.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  label: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};
