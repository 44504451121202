import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Button,
  Box
} from '@material-ui/core';
import {connect} from 'react-redux';
import {
  GET_FACULTIES_ALL,
  GET_COMBINE_FACULTIES,
  SET_CAMPAIGN_APPLICATION,
  DEL_CAMPAIGN_APPLICATION
} from '../../../../store/actions/compaign';
import LoadingSlider from '../../../../components/LoadingSlider/LoadingSlider';
import {withStyles} from '@material-ui/core/styles';
import LabelIcon from '@material-ui/icons/Label';
import FacultiesSubscribesSkeletons from '../../../../skeletons/Profile/FacultiesSubscribes';
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  }
});

class FacultiesSubscribes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsFaculties: [],
      itemsClientFaculties: [],
      preloader: true,
      loading: true,

    }
  }

  componentDidMount() {
    this.componentLoadingAllFaculties();
  }

  componentLoadingAllFaculties() {
    this.props.GET_COMBINE_FACULTIES().then(item => {
      item.all.sort(function (a, b) {
        let nameA = a.data.nameFull.toLowerCase(), nameB = b.data.nameFull.toLowerCase()
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
      this.setState(prev => {
        return {...prev, itemsFaculties: item.all, itemsClientFaculties: item.client, loading: false, preloader: false}
      });
    }).catch(()=>{
      this.setState(prev => {
        return {...prev, loading: false, preloader: false}
      });
    })
  }

  componentHandleSubscribeFaculty(faculty) {
    let {preloader} = this.state;
    if(!preloader) {
      this.setState(prev => {
        return {...prev, preloader: true}
      })
    }
    this.props.SET_CAMPAIGN_APPLICATION(faculty).then(data => {
      // console.log(data);
      this.setState(prev => {
        return {
          ...prev,
          itemsClientFaculties: [...prev.itemsClientFaculties, {...data}],
          preloader: false
        }
      });
    });
  }

  componentHandleDescribeFaculty(application) {
    let {preloader} = this.state;
    if(!preloader) {
      this.setState(prev => {
        return {...prev, preloader: true}
      })
    }
    this.props.DEL_CAMPAIGN_APPLICATION(application).then(data => {
      this.setState(prev => {
        return {
          ...prev,
          itemsClientFaculties: [...prev.itemsClientFaculties.filter(item => item.id !== data.id)],
          preloader: false
        }
      });
    });
  }

  render() {
    const {classes} = this.props;
    const {itemsFaculties, itemsClientFaculties, loading, preloader} = this.state;
    return (
        <Card>
          <LoadingSlider open={preloader}/>
          <CardContent>
            <Grid container spacing={3}>
              {
                loading
                    ? <FacultiesSubscribesSkeletons/>
                    : null
              }
              {
                (!loading && itemsFaculties.length === 0)
                  ? (
                      <Grid item lg={12} md={12} xs={12}>Список факультетов недоступен.</Grid>
                  )
                  : (
                      <Grid item lg={12} md={12} xs={12}>
                        <Box className={classes.root} p={3}>
                          {
                            itemsFaculties.map((faculty) => {
                              let facultySubscribeItem = itemsClientFaculties.find(item => item.faculty.id === faculty.id);
                              let facultySubscribe = facultySubscribeItem !== undefined;
                              let facultySubscribeApplication = (facultySubscribe ? facultySubscribeItem.id : null);
                              return (
                                  <React.Fragment key={faculty.id}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={8}>
                                        <Grid container spacing={2} alignItems="center">
                                          <Grid item xs={1}><LabelIcon color="disabled"/></Grid>
                                          <Grid item xs={11}>
                                            <Typography variant="body1">
                                              {faculty.data.nameFull}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12} sm={4}>
                                        <Grid container justifyContent="flex-end">
                                          {
                                            !facultySubscribe
                                                ? (
                                                    <Button variant="outlined"
                                                            onClick={() => this.componentHandleSubscribeFaculty(faculty.id)}
                                                            color="primary">Подписаться</Button>
                                                )
                                                : (
                                                    <Button variant="outlined"
                                                            onClick={() => this.componentHandleDescribeFaculty(facultySubscribeApplication)}
                                                            color="secondary">Отписаться</Button>
                                                )
                                          }
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                              )
                            })
                          }
                        </Box>
                      </Grid>
                  )
              }
            </Grid>
          </CardContent>
        </Card>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    GET_FACULTIES_ALL: () => dispatch(GET_FACULTIES_ALL()),
    GET_COMBINE_FACULTIES:  () => dispatch(GET_COMBINE_FACULTIES()),
    SET_CAMPAIGN_APPLICATION: (faculty) => dispatch(SET_CAMPAIGN_APPLICATION(faculty)),
    DEL_CAMPAIGN_APPLICATION: (application) => dispatch(DEL_CAMPAIGN_APPLICATION(application))
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(FacultiesSubscribes));
