import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import {Form, useForm} from "../../../../../components/Customs/useForm";
import Controls from "../../../../../components/Customs/Controls/Controls";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
} from "@material-ui/core";
import Valid from "../../../../../constants/Valid";

import {useDispatch, useSelector} from "react-redux";
import {
  ADDITIONAL_INFORMATION_EMPTY, ADDITIONAL_INFORMATION_FIO_GRSU, ADDITIONAL_INFORMATION_PPY
} from "../../../../../services/tagTypes";
import {
  deleteContractFileStatement,
  deleteTypeCompetitionStatement,
  getDataStatement,
  setDataStatement
} from "../../../../../store/Statement/actionStatement";
import {chatAction_createNotification} from "../../../../../store/Chat/ChatActions";
import FileLoadingForm from "../../../../../components/FileLoading";
import instance from "../../../../../customAxios";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const initialFValues = {
  noInfoAboutAdditionalInformation: false,
  personAttractedTo: "",
  ppy: [],
};


function AdditionalInformation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  const statementTypeCompetition = useSelector(state => state.statementReducer.typeCompetition);
  const statementContractFile = useSelector(state => state.statementReducer.contractFile);
  // const arrayPPY = useSelector(state => state.referenceBooksReducer.ppyBooksReducer);

  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [arrayPPY, setArrayPPY] = useState([]);

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])

  const setItemsPPY = (arrayPrivileges) => {
    const tmpPPY = [];
    // console.log(arrayPrivileges);
    arrayPrivileges?.forEach(el => {
      if (el?.data?.group === 'PPY') tmpPPY.push(el)
    });
    setArrayPPY(tmpPPY);
  }

  const onReloadInformationAdditionalInformation = (item) => {

    let noInfoAboutAdditionalInformation = item.find(data => data.typeTitle === ADDITIONAL_INFORMATION_EMPTY);
    if (noInfoAboutAdditionalInformation !== undefined) values.noInfoAboutAdditionalInformation = noInfoAboutAdditionalInformation.value.value;

    let personAttractedTo = item.find(data => data.typeTitle === ADDITIONAL_INFORMATION_FIO_GRSU);
    if (personAttractedTo !== undefined) values.personAttractedTo = personAttractedTo.value.value;

    let ppy = item.find(data => data.typeTitle === ADDITIONAL_INFORMATION_PPY);
    if (ppy !== undefined) values.ppy = ppy.value.value;

    setRender(!render)
  }

  useEffect(() => {
    const getDataAdditionalInformation = async () => {
      const arrayAdditionalInformationParams = [
        `type[]=${ADDITIONAL_INFORMATION_EMPTY}`,
        `type[]=${ADDITIONAL_INFORMATION_FIO_GRSU}`,
        `type[]=${ADDITIONAL_INFORMATION_PPY}`,
      ];
      const additionalInformationParams = arrayAdditionalInformationParams.join('&');
      const result = await dispatch(getDataStatement(additionalInformationParams))
      // setDataFromApi(result);
      onReloadInformationAdditionalInformation(result)
      // console.log('result ', result)
    }
    const fetchDataPrivileges = async (type) => {
      const response = await instance.get(
        `/api/v2/pub/privileges.json?type=${type}`,
      );
      // await setItemsTargetRecruitmentSpecialities(response.data);
      return response.data;
    };
    fetchDataPrivileges('ppy')
      .then((data) => {
        setItemsPPY(data);
      })

    getDataAdditionalInformation();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (validate()) {
      if (isEmptyAdditionalInformation()) {
        clearFormAdditionalInformation();
      }
      await onSaver(values)
      setIsLoading(false);
      history.push("/statement")
    } else {
      setIsLoading(false);
    }
  };

  const onSaver = async (data) => {
    const sendObject = {
      ADDITIONAL_INFORMATION_EMPTY: {value: data.noInfoAboutAdditionalInformation},
      ADDITIONAL_INFORMATION_FIO_GRSU: {value: data.personAttractedTo},
      ADDITIONAL_INFORMATION_PPY: {value: data.ppy},
      ANKETA_STATUS_FORMS_APPLICATION: {
        ...statementStatusFormsApplication,
        8: {"status": "IS_FULL", "coment": ""},
        9: {"action": "8"}
      },
      ANKETA_STATUS: {"value": "IS_WORK"}
    }

    try {
      await dispatch(setDataStatement(sendObject));
      dispatch(chatAction_createNotification({
        message: 'Данные формы сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      if (error.data.detail === "lock_application_in_asu") {
        dispatch(chatAction_createNotification({
          message: 'Форма заблокирована для изменений.',
          variant: 'error'
        }));
      } else {
        console.log(error);
        dispatch(chatAction_createNotification({
          message: 'Ошибка сохранения данных формы.',
          variant: 'error'
        }));
      }
    }
    if (statementTypeCompetition?.id) await dispatch(deleteTypeCompetitionStatement());
    if (statementContractFile?.id) await dispatch(deleteContractFileStatement());
  }

  const validate = (fieldValues = values) => {
    let temp = {...errors};

    if ('noInfoAboutAdditionalInformation' in fieldValues) {
      temp.noInfoAboutAdditionalInformation = isEmptyAdditionalInformationData()
        ? Valid.isIsset(fieldValues.noInfoAboutAdditionalInformation)
          ? ''
          : 'Это поле обязательное для заполнения, если другие поля не заполнены!'
        : '';
    }

    if (!isEmptyAdditionalInformation()) {
      if ('personAttractedTo' in fieldValues) {
        temp.personAttractedTo = Valid.regexCyrillic(fieldValues.personAttractedTo)
          ? ''
          : 'Это поле должно содержать только символы кириллицы!'
      }
    }
    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const isEmptyAdditionalInformation = () => {
    return !!values.noInfoAboutAdditionalInformation;
  }

  const isEmptyAdditionalInformationData = () => {
    return [...values.ppy].length === 0 && values.personAttractedTo === '';
  }

  const clearFormAdditionalInformation = () => {
    if (values.noInfoAboutAdditionalInformation === true) {
      values.personAttractedTo = '';
      values.ppy = [];
    }
  }

  const {
    values,
    //setValues,
    errors,
    setErrors,
    handleInputChange,
    handleSelectMultiChange
    // resetForm
  } = useForm(initialFValues, true, validate);

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} justifyContent="center" className={classes.grid_container}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader title="Дополнительные сведения"/>
            <Divider/>
            <CardContent>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Controls.Checkbox
                      name="noInfoAboutAdditionalInformation"
                      label="Дополнительные сведения отсутствуют"
                      value={values.noInfoAboutAdditionalInformation}
                      onChange={handleInputChange}
                      error={errors.noInfoAboutAdditionalInformation}
                    />
                  </Grid>
                  {
                    isEmptyAdditionalInformation()
                      ? null
                      :
                      <>
                        <Grid item xs={12}>
                          <Controls.Input
                            fullWidth
                            name="personAttractedTo"
                            label="Сотрудник ГрГУ имени Янки Купалы, повлиявший на мой выбор"
                            value={values.personAttractedTo}
                            onChange={handleInputChange}
                            error={errors.personAttractedTo}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controls.SelectPrivileges
                            fullWidth
                            name="ppy"
                            label="Дополнительно о себе сообщаю"
                            value={values.ppy}
                            options={arrayPPY}
                            onChange={handleSelectMultiChange}
                            error={errors.ppy}
                          />
                        </Grid>
                        {/*<FileLoadingForm*/}
                        {/*  tag={'ADDITIONAL_INFORMATION_FILE_SUPPORTING_DOCUMENTS'}*/}
                        {/*  labelText={'Загрузка файлов соответствующих подтверждающих документов'}*/}
                        {/*  labelForm={'Перетащите или <span class="filepond--label-action" tabindex="0">прикрепите</span> сканы (фото) соответствующих документов'}*/}
                        {/*  size={5}*/}
                        {/*/>*/}
                      </>

                  }
                  <Grid item container xs={12} justifyContent={"space-between"}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => {
                        history.push("/statement")
                      }}
                      disabled={isLoading}
                    >
                      Назад
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isLoading}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AdditionalInformation;