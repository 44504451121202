import {Avatar, Container, IconButton, InputAdornment, Paper, Typography} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import Valid from "../../../constants/Valid";
import {useForm} from "../../../components/Customs/useForm";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import {useDispatch} from "react-redux";
import {login} from "../../../store/actions/auth";
import {chatAction_createNotification} from "../../../store/Chat/ChatActions";
import {authorizationInit} from "../../../store/System/actionSystem";
import {ANKETA_STATUS, USER_GENDER, USER_TERMS} from "../../../services/tagTypes";
import {useHistory} from "react-router";
import {PROFILE_USER_DATA_GET} from "../../../store/actions/profile";
import REGULAR_EXPRESSION from "../../../constants/RegExp";
import {getDataStatement} from "../../../store/Statement/actionStatement";

const initialFValues = {
  login: "375",
  password: ""
};

function useSignIn(validate, values) {
  // console.log('render')
  const dispatch = useDispatch();
  const history = useHistory();
  const [isShowPassword, setShowPassword] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await dispatch(login(values.login, values.password));
        if (response?.status) {
          dispatch(chatAction_createNotification({
            message: 'Авторизация прошла успешно.',
            variant: 'success'
          }));
          await dispatch(authorizationInit());
          const statementStatusData = await dispatch(getDataStatement(`type[]=${ANKETA_STATUS}`,));
          const profileData = await dispatch(PROFILE_USER_DATA_GET());

          const terms = profileData.find(data => data.typeTitle === USER_TERMS);
          let redirectURL = "/profile";
          // if (profileData.find(data => data.typeTitle === USER_GENDER) !== undefined) redirectURL = "/statement";
          if (profileData.find(data => data.typeTitle === USER_GENDER) !== undefined) redirectURL = "/home";

          const findAnketaStatus = statementStatusData.find(data => data.typeTitle === ANKETA_STATUS);
          if (findAnketaStatus !== undefined && findAnketaStatus.value.value === 'IS_ASU') redirectURL = "/info";
          if (terms?.value?.value === undefined || terms?.value?.value !== true ) redirectURL = "/terms";
          history.push(redirectURL);

        }
      } catch (e) {
        dispatch(chatAction_createNotification({
          message: 'Ошибка. Проверьте правильность введенного логина или пароля.',
          variant: 'error'
        }));
        console.error(`Critical error: ${JSON.stringify(e)}`);
      }
    }
  }

  function onShowPassword() {
    setShowPassword(!isShowPassword);
  }


  // console.log({showPassword: isShowPassword})
  return {
    variable: {
      showPassword: isShowPassword
    },
    handlers: {
      submit: onSubmit,
      showPassword: onShowPassword,
    }
  }
}

export function SignIn() {
  const classes = useStyles();
  const validate = (fieldValues = values) => {
    let temp = {...errors};
    if ('login' in fieldValues) {
      temp.login = "";
      if (temp?.login === "" && !Valid.isIsset(fieldValues?.login)) {
        temp.login = "Обязательно для заполнения.";
      }
      if (temp?.login === "" && fieldValues?.login.indexOf(' ') !== -1) {
        temp.login = "Логин не должен содержать пробелы";
      }
      if (temp?.login === "" && REGULAR_EXPRESSION.EMAIL.test(fieldValues?.login) === false && REGULAR_EXPRESSION.MOBILE_PHONE.test(fieldValues?.login) === false) {
        temp.login = "Неподходящий формат логина";
      }
    }

    if ('password' in fieldValues) {
      temp.password = "";
      if (temp?.password === "" && !Valid.isIsset(fieldValues?.password)) {
        temp.password = "Обязательно для заполнения.";
      }
      if (temp?.password === "" && fieldValues?.password?.length < 8) {
        temp.password = "Пароль слишком короткий - минимум 8 символов.";
      }

    }

    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };
  const {
    values,
    errors,
    setErrors,
    handleInputChange: onChange
  } = useForm(initialFValues, false, validate)
  const {variable, handlers} = useSignIn(validate, values);
  return (
    <Container component="div" maxWidth="sm" className={classes.root}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Войти
        </Typography>
        <form className={classes.form} onSubmit={handlers.submit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                fullWidth
                name="login"
                label="Логин"
                id="login"
                placeholder="Введите логин"
                value={values.login}
                onChange={onChange}
                error={Boolean(errors.login)}
                helperText={errors.login && errors.login}
                InputProps={{
                  startAdornment:
                    <InputAdornment position="start">
                      <AccountCircleIcon color="disabled"/>
                    </InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                fullWidth
                name="password"
                label="Пароль"
                placeholder="Введите пароль"
                type={variable.showPassword ? 'text' : 'password'}
                id="password"
                value={values.password}
                onChange={onChange}
                error={Boolean(errors.password)}
                helperText={errors.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handlers.showPassword}
                        edge="end"
                      >
                        {variable.showPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>),
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon color="disabled"/>
                    </InputAdornment>)

                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Войти
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" spacing={1}>
                <Grid item>
                  <Link component={RouterLink} to="/reset-password" variant="body2">
                    Забыли пароль?
                  </Link>
                </Grid>
                <Grid item>
                  <Link component={RouterLink} to="/sign-up" variant="body2">
                    Нет учетной записи?
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  }
}));
