import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import {Form, useForm} from "../../../../../components/Customs/useForm";
import Controls from "../../../../../components/Customs/Controls/Controls";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import Valid from "../../../../../constants/Valid";

import {useDispatch, useSelector} from "react-redux";
import {
  JOB_HAVE, JOB_PLACEWORK, JOB_POSITIONWORK, JOB_EXPERIENCE_YEARS, JOB_EXPERIENCE_MONTHS, JOB_EXPERIENCE_DAYS

} from "../../../../../services/tagTypes";
import {
  deleteContractFileStatement,
  deleteTypeCompetitionStatement,
  getDataStatement,
  setDataStatement
} from "../../../../../store/Statement/actionStatement";
import FileLoadingForm from "../../../../../components/FileLoading";
import {chatAction_createNotification} from "../../../../../store/Chat/ChatActions";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const initialFValues = {
  noInfoAboutJob: false,
  placeWork: "",
  positionWork: "",
  seniorityYears: "",
  seniorityMonths: "",
  seniorityDays: "",
};

function Job() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  const statementTypeCompetition = useSelector(state => state.statementReducer.typeCompetition);
  const statementContractFile = useSelector(state => state.statementReducer.contractFile);

  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])

  const onReloadInformationJob = (item) => {

    let noInfoAboutJob = item.find(data => data.typeTitle === JOB_HAVE);
    if (noInfoAboutJob !== undefined) values.noInfoAboutJob = noInfoAboutJob.value.value;

    let placeWork = item.find(data => data.typeTitle === JOB_PLACEWORK);
    if (placeWork !== undefined) values.placeWork = placeWork.value.value;

    let positionWork = item.find(data => data.typeTitle === JOB_POSITIONWORK);
    if (positionWork !== undefined) values.positionWork = positionWork.value.value;

    let seniorityYears = item.find(data => data.typeTitle === JOB_EXPERIENCE_YEARS);
    if (seniorityYears !== undefined) values.seniorityYears = seniorityYears.value.value;

    let seniorityMonths = item.find(data => data.typeTitle === JOB_EXPERIENCE_MONTHS);
    if (seniorityMonths !== undefined) values.seniorityMonths = seniorityMonths.value.value;

    let seniorityDays = item.find(data => data.typeTitle === JOB_EXPERIENCE_DAYS);
    if (seniorityDays !== undefined) values.seniorityDays = seniorityDays.value.value;

    setRender(!render)
  }

  useEffect(() => {
    const getDataJob = async () => {
      const arrayJobParams = [
        `type[]=${JOB_HAVE}`,
        `type[]=${JOB_PLACEWORK}`,
        `type[]=${JOB_POSITIONWORK}`,
        `type[]=${JOB_EXPERIENCE_YEARS}`,
        `type[]=${JOB_EXPERIENCE_MONTHS}`,
        `type[]=${JOB_EXPERIENCE_DAYS}`,
      ];
      const jobParams = arrayJobParams.join('&');
      const result = await dispatch(getDataStatement(jobParams))
      onReloadInformationJob(result)
    }
    getDataJob();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (validate()) {
      if (isEmptyJob()) {
        clearFormJob();
      }
      await onSaver(values)
      setIsLoading(false);
      history.push("/statement")
    } else {
      setIsLoading(false);
    }
  };

  const onSaver = async (data) => {
    const sendObject = {
      JOB_HAVE: {value: data.noInfoAboutJob},
      JOB_PLACEWORK: {value: data.placeWork},
      JOB_POSITIONWORK: {value: data.positionWork},
      JOB_EXPERIENCE_YEARS: {value: data.seniorityYears},
      JOB_EXPERIENCE_MONTHS: {value: data.seniorityMonths},
      JOB_EXPERIENCE_DAYS: {value: data.seniorityDays},
      ANKETA_STATUS_FORMS_APPLICATION: {
        ...statementStatusFormsApplication,
        5: {"status": "IS_FULL", "coment": ""},
        9: {"action": "5"}
      },
      ANKETA_STATUS: {"value": "IS_WORK"}
    }
    try {
      await dispatch(setDataStatement(sendObject));
      dispatch(chatAction_createNotification({
        message: 'Данные формы сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      if (error.data.detail === "lock_application_in_asu") {
        dispatch(chatAction_createNotification({
          message: 'Форма заблокирована для изменений.',
          variant: 'error'
        }));
      } else {
        console.log(error);
        dispatch(chatAction_createNotification({
          message: 'Ошибка сохранения данных формы.',
          variant: 'error'
        }));
      }
    }
    if (statementTypeCompetition?.id) await dispatch(deleteTypeCompetitionStatement());
    if (statementContractFile?.id) await dispatch(deleteContractFileStatement());
  }

  const validate = (fieldValues = values) => {
    let temp = {...errors};
    if ('placeWork' in fieldValues) {
      temp.placeWork = !isEmptyJob()
        ? Valid.isIsset(fieldValues.placeWork)
          ? Valid.isTrim(fieldValues.placeWork)
            ? ''
            : 'Это поле не может состоять только из пробелов'
          : 'Это поле обязательное для заполнения!'
        : '';
    }
    if ('positionWork' in fieldValues) {
      temp.positionWork = !isEmptyJob()
        ? Valid.isIsset(fieldValues.positionWork)
          ? Valid.isTrim(fieldValues.positionWork)
            ? ''
            : 'Это поле не может состоять только из пробелов'
          : 'Это поле обязательное для заполнения!'
        : '';
    }
    if ('seniorityYears' in fieldValues) {
      temp.seniorityYears = !isEmptyJob()
        ? Valid.isIsset(fieldValues.seniorityYears)
          ? Valid.isNumber(fieldValues.seniorityYears)
            ? ''
            : 'Это поле может содержать только цифры!'
          : 'Это поле обязательное для заполнения!'
        : '';
    }
    if ('seniorityMonths' in fieldValues) {
      temp.seniorityMonths = !isEmptyJob()
        ? Valid.isIsset(fieldValues.seniorityMonths)
          ? Valid.isNumber(fieldValues.seniorityMonths)
            ? ''
            : 'Это поле может содержать только цифры!'
          : 'Это поле обязательное для заполнения!'
        : '';
    }
    if ('seniorityDays' in fieldValues) {
      temp.seniorityDays = !isEmptyJob()
        ? Valid.isIsset(fieldValues.seniorityDays)
          ? Valid.isNumber(fieldValues.seniorityDays)
            ? ''
            : 'Это поле может содержать только цифры!'
          : 'Это поле обязательное для заполнения!'
        : '';
    }

    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const clearFormJob = () => {
    if (values.noInfoAboutJob === true) {
      values.placeWork = '';
      values.positionWork = '';
      values.seniorityYears = '';
      values.seniorityMonths = '';
      values.seniorityDays = '';
    }
  }

  const isEmptyJob = () => {
    return !!values.noInfoAboutJob;
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFValues, true, validate);

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} justifyContent="center" className={classes.grid_container}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader title="Сведения о трудовой деятельности"/>
            <Divider/>
            <CardContent>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Controls.Checkbox
                      name="noInfoAboutJob"
                      label="Сведения о трудовой деятельности отсутствуют"
                      value={values.noInfoAboutJob}
                      onChange={handleInputChange}
                      error={errors.noInfoAboutJob}
                    />
                  </Grid>
                  {
                    isEmptyJob()
                      ? null
                      :
                      <>
                        <Grid item md={6} xs={12}>
                          <Controls.Input
                            fullWidth
                            name="placeWork"
                            label="Место работы"
                            value={values.placeWork}
                            onChange={handleInputChange}
                            error={errors.placeWork}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Controls.Input
                            fullWidth
                            name="positionWork"
                            label="Должность"
                            value={values.positionWork}
                            onChange={handleInputChange}
                            error={errors.positionWork}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Typography variant="body1" component="p">
                            Трудовой стаж по профилю избранной специальности
                          </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Controls.Input
                            fullWidth
                            name="seniorityYears"
                            label="Лет"
                            value={values.seniorityYears}
                            onChange={handleInputChange}
                            error={errors.seniorityYears}
                          />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Controls.Input
                            fullWidth
                            name="seniorityMonths"
                            label="Полных месяцев"
                            value={values.seniorityMonths}
                            onChange={handleInputChange}
                            error={errors.seniorityMonths}
                          />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Controls.Input
                            fullWidth
                            name="seniorityDays"
                            label="Дней"
                            value={values.seniorityDays}
                            onChange={handleInputChange}
                            error={errors.seniorityDays}
                          />
                        </Grid>
                        <FileLoadingForm
                          tag={'JOB_FILE_EMPLOYMENT_HISTORY'}
                          labelText={'Загрузка файлов трудовой книжки'}
                          labelForm={'Перетащите или <span class="filepond--label-action" tabindex="0">прикрепите</span> сканы (фото) трудовой книжки'}
                          size={5}
                        />
                      </>
                  }
                  <Grid item container xs={12} justifyContent={"space-between"}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => {
                        history.push("/statement")
                      }}
                      disabled={isLoading}
                    >
                      Назад
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isLoading}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Job;