import {
  FILE_LOADING_DELETE_LOADING,
  FILE_LOADING_GET_FILES, FILE_LOADING_GET_FILES_LOADING,
  FILE_LOADING_SET_FILES_LOADING
} from "./typesFileLoading";
import {getDataStatement, setDataStatement} from "../Statement/actionStatement";
import instance from "../../customAxios";
import {PROFILE_USER_MANYDATA_POST, USER_DATA_GET} from "../actions/profile";


export function FileLoadingGetFiles(types = undefined, saveType = undefined) {
  return async (dispatch) => {
    dispatch(FileLoadingGetFilesLoading(types));
    try {
      let func = getDataStatement(types ? `type[]=${types}` : '');
      if (saveType === 'user') func = USER_DATA_GET(types ? `type[]=${types}` : '');
      const response = await dispatch(func);
      dispatch(FileLoadingGetFilesSuccess(response, types));
    } catch (error) {
      dispatch(FileLoadingGetFilesFailed(types));
      return Promise.reject(error.response ? error.response : error);
    } finally {
      dispatch(FileLoadingGetFilesLoading(types));
    }
  }
}

function FileLoadingGetFilesSuccess(payload, types) {
  return {type: FILE_LOADING_GET_FILES, payload: {[types]: payload}}
}

function FileLoadingGetFilesFailed(types) {
  return {type: FILE_LOADING_GET_FILES, payload: {[types]: []}}
}

function FileLoadingGetFilesLoading(types) {
  return {type: FILE_LOADING_GET_FILES_LOADING, payload: types}
}

export function FileLoadingSetFiles(data = undefined, types = undefined) {
  return async (dispatch) => {
    dispatch(FileLoadingSetFilesLoading());
    try {
      if (data) {
        await dispatch(setDataStatement(data));
      }
      if (types) {
        await dispatch(FileLoadingGetFiles(types));
      }
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    } finally {
      dispatch(FileLoadingSetFilesLoading());
    }
  }
}

function FileLoadingSetFilesLoading() {
  return {type: FILE_LOADING_SET_FILES_LOADING}
}

export function FileLoadingDeleteFiles(application = undefined, types = undefined, saveType = undefined) {
  return async (dispatch) => {
    dispatch(FileLoadingDeleteFilesLoading());
    try {
      let url = 'application'
      if (saveType === 'user') url = 'users';
      const response = await instance.delete(
        `/api/ext/${url}/data/${application}`
      );
      if (response.data?.id && types) {
        await dispatch(FileLoadingGetFiles(types, saveType));
      }
      return response.data;
    } catch (error) {
      console.error('☠️error deleteApplication actions : ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    } finally {
      dispatch(FileLoadingDeleteFilesLoading());
    }
  };
}

function FileLoadingDeleteFilesLoading() {
  return {type: FILE_LOADING_DELETE_LOADING}
}


export function FileLoadingSetAndDeleteFiles(data = undefined, application = undefined, types = undefined, saveType = undefined) {
  return async (dispatch) => {
    dispatch(FileLoadingGetFilesLoading());
    try {
      let url = 'application'
      let func = setDataStatement(data);
      if (saveType === 'user') {
        url = 'users';
        func = PROFILE_USER_MANYDATA_POST(data);
      }
      if (data) {
        await dispatch(func);
      }
      if (application) {
        await instance.delete(`/api/ext/${url}/data/${application}`);
      }
      if (types) {
        await dispatch(FileLoadingGetFiles(types, saveType));
      }
    } catch (error) {
      console.error('☠️error deleteApplication actions : ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    } finally {
      dispatch(FileLoadingGetFilesLoading());
    }
  };
}

export function getFileStatement(hash) {
  return async () => {
    try {
      const response = await instance.get(
        `/api/ext/files/${hash}`, {responseType: 'arraybuffer'}
      );
      return response.data;
    } catch (error) {
      console.error('☠️error getFileStatement actions FileLoading: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}