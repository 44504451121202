const actionCommentTesting = ({ href_abit_testing }) => [
  {
    type: "%_HREF_ABIT_%",
    action: ({ message }) => {
      const link = `<a href="${href_abit_testing}" target="_blank" class=" pointer">по ссылке</a>`;
      return message.replace("%_HREF_ABIT_%", link);
    },
  },
];
export default actionCommentTesting;
