import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { channelsGetMessagesListInternat } from "../../store/Channels/actionChannels";
import { Typography, Link } from "@material-ui/core";

function CheckingIntoDormitory() {
  const dispatch = useDispatch();
  const [messagesList, setMessagesList] = useState(false);

  useEffect(() => {
    const getDataStatusForms = async () => {
      const result = await dispatch(channelsGetMessagesListInternat());
      setMessagesList(result);
      return result;
    };
    getDataStatusForms();
  }, []);

  return Array.isArray(messagesList) && messagesList.length !== 0
    ? messagesList.map((message, index) => (
        <Typography
          key={index}
          variant="body2"
          component="p"
          dangerouslySetInnerHTML={{ __html: message.body?.text }}
        ></Typography>
      ))
    : null;
}

export default CheckingIntoDormitory;
