import {combineReducers} from 'redux'
import authReducer from "./auth";
import profileReducer from "./profile";
import chatReducer from "../Chat/ChatReducer";
import messageReducer from "./message";
import contactsReducer from "./contacts";
import compaignReducer from "./compaign";
import statementReducer from "../Statement/reducerStatement";
import queueReducer from "../Queue/reducerQueue";
import referenceBooksReducer from "../ReferenceBooks/reducerReferenceBooks"
import systemReducer from "../System/reducerSystem"
import fileLoadingReducer from "../FileLoading/reducerFileLoading";
import adminReducer from "../Admin/reducerAdmin";
import channelsReducer from "../Channels/reducerChannels";

const rootReducer = combineReducers({
  authReducer,
  profileReducer,
  messageReducer,
  contactsReducer,
  compaignReducer,
  statementReducer,
  queueReducer,
  referenceBooksReducer,
  systemReducer,
  fileLoadingReducer,
  chatReducer,
  adminReducer,
  channelsReducer
})

export default rootReducer
