import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import {Form, useForm} from "../../../../../components/Customs/useForm";
import Controls from "../../../../../components/Customs/Controls/Controls";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
} from "@material-ui/core";
import Valid from "../../../../../constants/Valid";

import {useDispatch, useSelector} from "react-redux";
import {
  EXAMS_IS_EMPTY_EXAMS,
  EXAMS_LANGUAGE_CT_LANG,
  EXAMS_LANGUAGE_EXAMS_LANG,
  EXAMS_NEED_AHOSTEL,
  EXAMS_RESULT_CT_1,
  EXAMS_RESULT_CT_2,
  EXAMS_RESULT_CT_LANG,
  PRIVILEGES_EMPTY,
  PRIVILEGES_SOCIAL,
  PRIVILEGES_SPORT,
  PRIVILEGES_STUDY,
  TRAINING_SPECIALITY_BUDGET,
  TRAINING_SPECIALITY_PAID,
  TRAINING_TARGET_RECRUITMENT,
  TRAINING_TARGET_RECRUITMENT_SPEC,
  TRAINING_TYPE

} from "../../../../../services/tagTypes";
import {
  deleteContractFileStatement,
  deleteTypeCompetitionStatement,
  getDataStatement,
  setDataStatement
} from "../../../../../store/Statement/actionStatement";
import FileLoadingForm from "../../../../../components/FileLoading";
import {chatAction_createNotification} from "../../../../../store/Chat/ChatActions";
import instance from "../../../../../customAxios";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const initialFValues = {
  noInfoAboutPrivileges: false,
  privilegesStudy: [],
  privilegesSport: [],
  privilegesSocial: [],
};

function Privileges() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const arrayPrivileges = useSelector(state => state.referenceBooksReducer.privilegesBooksReducer);
  const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  const statementTypeCompetition = useSelector(state => state.statementReducer.typeCompetition);
  const statementContractFile = useSelector(state => state.statementReducer.contractFile);
  const selectedTypeTrainingStatement = useSelector(state => state.statementReducer.selectedTypeTrainingStatement);


  const setItemsPrivileges = (arrayPrivileges) => {
    const tmpPrivilegesStudy = [];
    const tmpPrivilegesSport = [];
    const tmpPrivilegesSocial = [];
    // console.log(arrayPrivileges);
    arrayPrivileges?.forEach(el => {
      if (el?.data?.group === 'STUDY') tmpPrivilegesStudy.push(el)
      if (el?.data?.group === 'SPORT') tmpPrivilegesSport.push(el)
      if (el?.data?.group === 'SOCIAL') tmpPrivilegesSocial.push(el)
    });
    setArrayPrivilegesStudy(tmpPrivilegesStudy);
    setArrayPrivilegesSport(tmpPrivilegesSport);
    setArrayPrivilegesSocial(tmpPrivilegesSocial);
  }

  const [arrayPrivilegesStudy, setArrayPrivilegesStudy] = useState([]);
  const [arrayPrivilegesSport, setArrayPrivilegesSport] = useState([]);
  const [arrayPrivilegesSocial, setArrayPrivilegesSocial] = useState([]);
  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])

  const onReloadInformationPrivileges = (item) => {

    for (let i = 0; i < item.length; i++) {
      if (item[i]?.typeTitle === PRIVILEGES_EMPTY) values.noInfoAboutPrivileges = item[i]?.value?.value;
      if (item[i]?.typeTitle === PRIVILEGES_STUDY) values.privilegesStudy = item[i]?.value?.value;
      if (item[i]?.typeTitle === PRIVILEGES_SPORT) values.privilegesSport = item[i]?.value?.value;
      if (item[i]?.typeTitle === PRIVILEGES_SOCIAL) values.privilegesSocial = item[i]?.value?.value;
    }
    setRender(!render)
  }

  useEffect(() => {
    const getDataPrivileges = async () => {
      const arrayPrivilegesParams = [
        `type[]=${PRIVILEGES_EMPTY}`,
        `type[]=${PRIVILEGES_STUDY}`,
        `type[]=${PRIVILEGES_SPORT}`,
        `type[]=${PRIVILEGES_SOCIAL}`,
      ];
      const privilegesParams = arrayPrivilegesParams.join('&');
      const result = await dispatch(getDataStatement(privilegesParams))
      onReloadInformationPrivileges(result)
    }
    const fetchDataPrivileges = async (type) => {
      const response = await instance.get(
        `/api/v2/pub/privileges.json?type=${type}`,
      );
      // await setItemsTargetRecruitmentSpecialities(response.data);
      return response.data;
    };

    if (selectedTypeTrainingStatement === 'targetTraining') {
      fetchDataPrivileges('target')
        .then((data) => {
          setItemsPrivileges(data);
        })
    } else if (selectedTypeTrainingStatement === 'olympiad') {
      fetchDataPrivileges('olimp')
        .then((data) => {
          setItemsPrivileges(data);
        })
    } else {
      fetchDataPrivileges('all')
        .then((data) => {
          setItemsPrivileges(data);
        })
      // setItemsPrivileges(arrayPrivileges);
    }
    getDataPrivileges();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (validate()) {
      if (isEmptyPrivileges()) {
        clearFormPrivileges()
      }
      await onSaver(values)
      setIsLoading(false);
      history.push("/statement")
    } else {
      setIsLoading(false);
    }
  };

  const onSaver = async (data) => {
    const sendObject = {
      PRIVILEGES_EMPTY: {value: data.noInfoAboutPrivileges},
      PRIVILEGES_STUDY: {value: data.privilegesStudy},
      PRIVILEGES_SPORT: {value: data.privilegesSport},
      PRIVILEGES_SOCIAL: {value: data.privilegesSocial},
      ANKETA_STATUS_FORMS_APPLICATION: {
        ...statementStatusFormsApplication,
        7: {"status": "IS_FULL", "coment": ""},
        9: {"action": "7"}
      },
      ANKETA_STATUS: {"value": "IS_WORK"}
    }
    try {
      await dispatch(setDataStatement(sendObject));
      dispatch(chatAction_createNotification({
        message: 'Данные формы сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      if (error.data.detail === "lock_application_in_asu") {
        dispatch(chatAction_createNotification({
          message: 'Форма заблокирована для изменений.',
          variant: 'error'
        }));
      } else {
        console.log(error);
        dispatch(chatAction_createNotification({
          message: 'Ошибка сохранения данных формы.',
          variant: 'error'
        }));
      }
    }
    if (statementTypeCompetition?.id) await dispatch(deleteTypeCompetitionStatement());
    if (statementContractFile?.id) await dispatch(deleteContractFileStatement());
  }

  const validate = (fieldValues = values) => {
    let temp = {...errors};

    if ('noInfoAboutPrivileges' in fieldValues) {
      temp.noInfoAboutPrivileges = isEmptyArrayPrivileges()
        ? Valid.isIsset(fieldValues.noInfoAboutPrivileges)
          ? ''
          : 'Это поле обязательное для заполнения!'
        : '';
    }

    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const clearFormPrivileges = () => {
    if (values.noInfoAboutPrivileges === true) {
      values.privilegesSocial = [];
      values.privilegesSport = [];
      values.privilegesStudy = [];
    }
  }

  const isEmptyArrayPrivileges = () => {
    return [...values.privilegesSocial, ...values.privilegesSport, ...values.privilegesStudy].length === 0;
  }

  const isEmptyPrivileges = () => {
    return !!values.noInfoAboutPrivileges;
  }

  const isAllowEmptyPrivileges = () => {
    return selectedTypeTrainingStatement === 'generalBasis' || selectedTypeTrainingStatement === 'generalBasisWithoutPrivileges' || selectedTypeTrainingStatement === 'targetTraining';
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    handleSelectMultiChange,
  } = useForm(initialFValues, true, validate);

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} justifyContent="center" className={classes.grid_container}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader title="Особые достижения и льготы"/>
            <Divider/>
            <CardContent>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controls.Checkbox
                      name="noInfoAboutPrivileges"
                      label="Особые достижения и льготы отсутствуют"
                      value={values.noInfoAboutPrivileges}
                      onChange={handleInputChange}
                      error={errors.noInfoAboutPrivileges}
                      disabled={!isAllowEmptyPrivileges()}
                    />
                  </Grid>
                  {
                    isEmptyPrivileges()
                      ? null
                      :
                      <>
                        {arrayPrivilegesStudy.length > 0
                          ?
                          <Grid item xs={12}>
                            <Controls.SelectPrivileges
                              fullWidth
                              name="privilegesStudy"
                              label="Учебные и творческие достижения"
                              value={values.privilegesStudy}
                              options={arrayPrivilegesStudy}
                              onChange={handleSelectMultiChange}
                              error={errors.privilegesStudy}
                            />
                          </Grid>
                          : null
                        }
                        {arrayPrivilegesSport.length > 0
                          ?
                          <Grid item xs={12}>
                            <Controls.SelectPrivileges
                              fullWidth
                              name="privilegesSport"
                              label="Спортивные достижения"
                              value={values.privilegesSport}
                              options={arrayPrivilegesSport}
                              onChange={handleSelectMultiChange}
                              error={errors.privilegesSport}
                            />
                          </Grid>
                          : null
                        }
                        {arrayPrivilegesSocial.length > 0
                          ?
                          <Grid item xs={12}>
                            <Controls.SelectPrivileges
                              fullWidth
                              name="privilegesSocial"
                              label="Социальные льготы"
                              value={values.privilegesSocial}
                              options={arrayPrivilegesSocial}
                              onChange={handleSelectMultiChange}
                              error={errors.privilegesSocial}
                            />
                          </Grid>
                          : null
                        }
                        <FileLoadingForm
                          tag={'PRIVILEGES_FILE_PRIVILEGES'}
                          labelText={'Загрузка файлов соответствующих документов подтверждающих право на льготы'}
                          labelForm={'Перетащите или <span class="filepond--label-action" tabindex="0">прикрепите</span> сканы (фото) соответствующих документов'}
                          size={5}/>
                      </>

                  }
                  <Grid item container xs={12} justifyContent={"space-between"}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => {
                        history.push("/statement")
                      }}
                      disabled={isLoading}
                    >
                      Назад
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isLoading}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Privileges;