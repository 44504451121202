import {
  LOGOUT,
  GET_FACULTIES_REFERENCE_BOOKS_SUCCESS,
  GET_FACULTIES_REFERENCE_BOOKS_FAIL,
  GET_CITIZENSHIP_REFERENCE_BOOKS_SUCCESS,
  GET_CITIZENSHIP_REFERENCE_BOOKS_FAIL,
  GET_PRIVILEGES_REFERENCE_BOOKS_SUCCESS,
  GET_PRIVILEGES_REFERENCE_BOOKS_FAIL,
  GET_LANGUAGE_REFERENCE_BOOKS_SUCCESS,
  GET_LANGUAGE_REFERENCE_BOOKS_FAIL,
  GET_PPY_REFERENCE_BOOKS_SUCCESS,
  GET_PPY_REFERENCE_BOOKS_FAIL,
  GET_OLYMPIADS_REFERENCE_BOOKS_SUCCESS,
  GET_OLYMPIADS_REFERENCE_BOOKS_FAIL,
} from "../actions/actionTypes";

const initialState = {
  facultiesBooksReducer: [],
  citizenshipBooksReducer: [],
  privilegesBooksReducer: [],
  languageBooksReducer: [],
  ppyBooksReducer: [],
  olympiadsBooksReducer: [],
};

export default function referenceBooksReducer(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_FACULTIES_REFERENCE_BOOKS_SUCCESS:
      return {...state, facultiesBooksReducer: payload};
    case GET_FACULTIES_REFERENCE_BOOKS_FAIL:
      return {...state, facultiesBooksReducer: []};
    case GET_CITIZENSHIP_REFERENCE_BOOKS_SUCCESS:
      return {...state, citizenshipBooksReducer: payload};
    case GET_CITIZENSHIP_REFERENCE_BOOKS_FAIL:
      return {...state, citizenshipBooksReducer: []};
    case GET_PRIVILEGES_REFERENCE_BOOKS_SUCCESS:
      return {...state, privilegesBooksReducer: payload};
    case GET_PRIVILEGES_REFERENCE_BOOKS_FAIL:
      return {...state, privilegesBooksReducer: []};
    case GET_LANGUAGE_REFERENCE_BOOKS_SUCCESS:
      return {...state, languageBooksReducer: payload};
    case GET_LANGUAGE_REFERENCE_BOOKS_FAIL:
      return {...state, languageBooksReducer: []};
    case GET_PPY_REFERENCE_BOOKS_SUCCESS:
      return {...state, ppyBooksReducer: payload};
    case GET_PPY_REFERENCE_BOOKS_FAIL:
      return {...state, ppyBooksReducer: []};
    case GET_OLYMPIADS_REFERENCE_BOOKS_SUCCESS:
      return {...state, olympiadsBooksReducer: payload};
    case GET_OLYMPIADS_REFERENCE_BOOKS_FAIL:
      return {...state, olympiadsBooksReducer: []};
    case LOGOUT:
      return {
        ...state,
        facultiesBooksReducer: [],
        citizenshipBooksReducer: [],
        privilegesBooksReducer: [],
        languageBooksReducer: [],
        ppyBooksReducer: [],
        olympiadsBooksReducer: [],
      };
    default:
      return state;
  }
}
