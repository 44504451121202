import React, {memo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    marginBottom: theme.spacing(1),
  },
  title__subtext: {textAlign: 'center', flexGrow: 2},
  title__text: {textAlign: 'center', flexGrow: 2, fontWeight: 'bold'},
  container: {padding: theme.spacing(0)},
  text__indent: {textIndent: theme.spacing(5)},
  text__blue: {color: '#3f51b5', fontWeight: 'bold'},
  text__red: {color: '#8b0000', fontWeight: 'bold'},
  text__bold: {fontWeight: 'bold'},
  list: {listStyleType: 'none', fontSize: '1rem', margin: 0},
  list__num: {listStyleType: 'decimal', fontSize: '1rem', margin: 0},
  list__line: {listStyleType: '" - "', fontSize: '1rem', margin: 0},

}));

export const TermsMessage = memo(() => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.title}>
        <Typography className={`${classes.title__subtext} ${classes.text__bold}`}>Политика обработки персональных
          данных </Typography>
        <Typography className={classes.title__subtext}>в информационной системе </Typography>
        <Typography className={classes.title__subtext}>«Цифровой кабинет абитуриента ГрГУ им. Янки Купалы» </Typography>
      </div>
      <div className={classes.container}>
        <ol className={classes.list__num}>
          <li>Информационная система «Цифровой кабинет абитуриента ГрГУ им. Янки Купалы» (далее – Цифровой кабинет
            абитуриента) функционирует с целью обеспечения оперативного взаимодействия абитуриентов учреждения
            образования «Гродненский государственный университет имени Янки Купалы» (далее – ГрГУ им. Янки Купалы,
            университет), потенциальных абитуриентов, участников Многопрофильной олимпиады, а также их законных
            представителей с уполномоченными представителями (приемной комиссией, оргкомитетом и др.) университета по
            вопросам участия в конкурсе на получение высшего образования и профориентационных мероприятиях.
          </li>
          <li>Цифровой кабинет абитуриента выполняет следующие функции:
            <ul className={classes.list}>
              <li>идентификация, аутентификация и авторизация пользователей;</li>
              <li>учет персональных данных;</li>
              <li>организация предварительной проверки документов;</li>
              <li>информирование потенциальных абитуриентов и абитуриентов;</li>
              <li>автоматизированное формирование заявления и договора до момента личной подачи абитуриентом документов
                в приемную комиссию университета;
              </li>
              <li>дистанционное получение консультаций по вопросам поступления и участия в мероприятиях университета.
              </li>
            </ul>
          </li>
          <li>Цифровой кабинет абитуриента предназначен для следующих групп пользователей:
            <ul className={classes.list}>
              <li>лица, изъявившие желание поступить в ГрГУ им. Янки Купалы для получения высшего образования (далее –
                абитуриент),
              </li>
              <li>лица, изъявившие желание участвовать в Многопрофильной олимпиаде, по результатам которой они имеют
                право на зачисление в ГрГУ им. Янки Купалы без вступительных испытаний (далее – участник Многопрофильной
                олимпиады);
              </li>
              <li>лица, изъявившие желание участвовать в профориентационных мероприятиях ГрГУ им. Янки Купалы (далее –
                потенциальный абитуриент).
              </li>
            </ul>
          </li>
          <li>До регистрации в информационной системе пользователю необходимо предоставить университету право на
            хранение и использование его персональных данных (в том числе сканированных документов), которые после
            регистрации потребуется внести в цифровую анкету.
          </li>
          <li>Персональные данные, на хранение и использование которых пользователь дает согласие университету.
            <ol className={classes.list__num}>
              <li>Абитуриент дает университету согласие на хранение и использование следующих персональных данных:</li>
              <ul className={classes.list}>
                <li>фамилия, собственное имя, отчество (на русском, белорусском и английском языках);</li>
                <li>гражданство;</li>
                <li>вид документа, удостоверяющего личность, его серия и номер, орган выдавший документ, дата выдачи,
                  срок действия;
                </li>
                <li>идентификационный номер;</li>
                <li>дата рождения;</li>
                <li>пол;</li>
                <li>контактные данные: индекс, адрес проживания, мобильный телефон, домашний телефон, е-mail;</li>
                <li>сведения о состоянии здоровья, содержащиеся в медицинской справке;</li>
                <li>образование: наименование учебного заведения, год окончания, номер аттестата, дата выдачи, средний
                  балл, обучение в профильном классе (группе), наличие документа об образовании особого образца,
                  изучаемый иностранный язык;
                </li>
                <li>сведения о законных представителях: фамилия, собственное имя, отчество законных представителей,
                  адрес их проживания, их мобильные телефоны;
                </li>
                <li>вступительные испытания: оценки по профильным предметам, оценки по централизованному экзамену /
                  централизованному тестированию;
                </li>
                <li>трудовая деятельность: место работы, должность, трудовой стаж;</li>
                <li>достижения и право на льготы;</li>
                <li>дополнительные сведения:</li>
                <li>сведения, содержащиеся в характеристике;</li>
                <li>сведения, содержащиеся рекомендацию педагогического совета;</li>
                <li>обучение на подготовительных курсах ГрГУ им. Янки Купалы;</li>
                <li>сотрудник ГрГУ имени Янки Купалы, повлиявший на выбор.</li>

                <li>Дополнительно абитуриент прикрепляет скан-копии документов:</li>
                <li>документ, удостоверяющий личность (запрашиваемые страницы, в зависимости от вида документа);</li>
                <li>медицинская справка;</li>
                <li>документ об образовании (аттестат или диплом, выписка к нему);</li>
                <li>трудовая книжка (при необходимости);</li>
                <li>документы о льготах и достижениях (дипломы, грамоты, справки) (при необходимости);</li>
                <li>договор о целевой подготовке специалиста с высшим образованием (при наличии);</li>
                <li>характеристика;</li>
                <li>рекомендация педагогического совета.</li>
              </ul>
              <li>Участник Многопрофильной олимпиады дает университету согласие на хранение и использование следующих
                персональных данных:
              </li>
              <ul className={classes.list}>
                <li>фамилия, собственное имя, отчество (при наличии);</li>
                <li>дата рождения;</li>
                <li>пол;</li>
                <li>контактные данные: индекс, адрес проживания, мобильный телефон, домашний телефон, е-mail;</li>
                <li>образование: наименование учебного заведения, год окончания, класс.</li>
                <li>Дополнительно участник прикрепляет скан-копии документов:</li>
                <li>справка о том, что гражданин является обучающимся, или иной документ, подтверждающий факт получения
                  общего среднего образования в год проведения олимпиады.
                </li>
              </ul>
              <li>Потенциальный абитуриент дает университету согласие на хранение и использование следующих персональных
                данных:
              </li>
              <ul className={classes.list}>
                <li>фамилия, собственное имя, отчество (при наличии);</li>
                <li>дата рождения;</li>
                <li>пол;</li>
                <li>контактные данные: индекс, адрес проживания, мобильный телефон, домашний телефон, е-mail;</li>
                <li>образование: наименование учебного заведения, год окончания, класс.</li>
              </ul>
            </ol>
          </li>
          <li>Цели использования предоставленной пользователем персональной информации.
            <ol className={classes.list__num}>
              <li>Предоставленная абитуриентом информация используется для:</li>
              <ul className={classes.list}>
                <li>сверки представителем приемной комиссии заполненных абитуриентом данных с данными скан-копий
                  документов;
                </li>
                <li>предоставления абитуриенту сведений о ходе проверки и соответствии представленных документов
                  требованиям законодательства, наличии замечаний к составу или содержанию документов;
                </li>
                <li>определения вида конкурса на получение высшего образования, в котором абитуриент будет принимать
                  участие;
                </li>
                <li>формирования необходимого для участия в конкурсе пакета документов, который высылается абитуриенту
                  для дальнейшего предоставления на бумажном носителе в приемную комиссию университета.
                </li>
                <li>формирования электронной очереди для личной подачи документов в приемную комиссию университета.</li>
              </ul>
              <Typography>
                Полученная информация хранится и используется в Цифровом кабинете абитуриента до 31 октября года
                поступления.
              </Typography>
              <li>Предоставленная участником Многопрофильной олимпиады информация используется для:</li>
              <ul className={classes.list}>
                <li>сверки представителем оргкомитета заполненных пользователем данных с данными скан-копий
                  документов;
                </li>
                <li>организации доступа к материалам предварительного этапа Многопрофильной олимпиады;</li>
                <li>информирования пользователей о ходе и результатов Многопрофильной олимпиады.</li>
              </ul>
              <Typography>Полученная информация хранится и используется в Цифровом кабинете абитуриента до даты
                зачисления победителей Многопрофильной олимпиады в университет.</Typography>
              <li>Предоставленная потенциальным абитуриентом информация используется для:</li>
              <ul className={classes.list}>
                <li>подбора наиболее подходящих пользователю профориентационных мероприятий;</li>
                <li>информирования пользователей о ходе и результатах профориентационных мероприятий.</li>
              </ul>
              <Typography>Полученная информация хранится и используется в Цифровом кабинете абитуриента до окончания
                текущего учебного года.</Typography>
            </ol>
          </li>
          <li>Пользователь в рамках обработки персональных данных в Цифровом кабинете абитуриента имеет право:
            <ol className={classes.list__num}>
              <li>Получить информацию, касающуюся хранения и использования его персональных данных в Цифровом кабинете
                абитуриента, содержащую:
              </li>
              <ul className={classes.list}>
                <li>сведения о наименовании и местонахождении университета;</li>
                <li>подтверждение факта хранения и использования персональных данных обратившегося лица;</li>
                <li>источник получения персональных данных;</li>
                <li>правовые основания и цели обработки персональных данных;</li>
                <li>иную информацию, предусмотренную законодательством.</li>
              </ul>
              <li>Получить от университета информацию о предоставлении персональных данных из Цифрового кабинета
                абитуриента третьим лицам (такое право может быть реализовано один раз в течение календарного год
                бесплатно).
              </li>
              <li>Требовать прекращения обработки персональных данных и (или) их удаления из Цифрового кабинета
                абитуриента.
              </li>
              <li>Обжаловать действия (бездействие) и решения университета, нарушающие его права при обработке его
                персональных данных в Цифровом кабинете абитуриента, в Национальном центре защиты персональных данных в
                порядке, установленном законодательством об обращениях граждан и юридических лиц.
              </li>
            </ol>
          </li>
          <li>
            Для реализации вышеуказанных прав абитуриент подает в университет заявление в письменной форме (нарочно,
            почтой) или в виде электронного документа посредством Государственной единой (интегрированной)
            республиканской информационной системы учета и обработки обращений граждан и юридических лиц. Такое
            заявление должно содержать:
            <ul className={classes.list}>
              <li>фамилию, собственное имя, отчество (если таковое имеется), адрес места жительства (места
                пребывания);
              </li>
              <li>дату рождения;</li>
              <li>идентификационный номер, а при его отсутствии – номер документа, удостоверяющего личность;</li>
              <li>изложение сути требований;</li>
              <li>личную подпись (для заявления в письменной форме) либо электронную цифровую подпись (для заявления в
                виде электронного документа).
              </li>
            </ul>
          </li>
          <Typography>Университет не рассматривает заявления абитуриентов, которые не
            соответствуют ранее указанным требованиям, в том числе направленные иными способами (e-mail, телефон, факс и
            т.п.).</Typography>
          <li>
            В случае отказа от предоставления университету прав хранить и использовать запрашиваемые сведения, лицо не
            сможет использовать функции Цифрового кабинета абитуриента.
          </li>

        </ol>


      </div>
    </>
  );
});