import classes from "../ChatMessages.module.css";
import {FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Tooltip} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import DialogFile from "../DialogFile";
import {useDispatch, useSelector} from "react-redux";
import {chatAction_sendMessage} from "../../../../store/Chat/ChatActions";

export default function Form(props) {
    const {newMessageSending: sendingStatus} = useSelector(state => state.chatReducer);
    const styles = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const {current: currentChat} = props;
    const submitBlocked = value.trim().length === 0;

    function onSetValue(event) {
        setValue(event.target.value);
    }

    function onKeyDownValue(event) {
        //if((e.ctrlKey && e.key === 'Enter') || (e.key === 'Enter')){
        if (event.ctrlKey && event.key === 'Enter') {
            onClickSubmit();
        }

    }

    function onClickSubmit() {
        if (submitBlocked || sendingStatus) return;
        dispatch(chatAction_sendMessage({chatId: currentChat.id, message: {only_text: value.trimStart().trimEnd()}}));
        setValue('');
    }

    return (
        <div className={classes['message__box__form']}>
            <Grid container>
                {currentChat?.status !== "ARCHIVE" ? (
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <FormControl variant="outlined" fullWidth className={styles.form}>
                            <InputLabel htmlFor="outlined-message-text">Текст сообщения</InputLabel>
                            <OutlinedInput
                                id="outlined-message-text"
                                type={'text'}
                                value={value}
                                placeholder={'Введите текст сообщения'}
                                multiline
                                labelWidth={140}
                                rows={4}
                                onChange={onSetValue}
                                onKeyDown={onKeyDownValue}
                                style={{
                                    height: '100%'
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Tooltip title="Отправить"
                                                 disableFocusListener={submitBlocked}>
                                            <IconButton
                                                color={submitBlocked ? 'default' : 'primary'}
                                                onClick={onClickSubmit}>
                                                <SendIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                }
                                startAdornment={<DialogFile chatId={currentChat?.id} />}
                            />
                        </FormControl>
                    </Grid>) : (
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <div className={classes['message__box__form__blocked_chat']}>
                            Чат доступен только для просмотра.
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}
Form.propTypes = {
    current: PropTypes.any
};
const useStyles = makeStyles(() => ({
    form: {
        height: '100%'
    }
}));
