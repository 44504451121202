import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import {Form, useForm} from "../../../../../components/Customs/useForm";
import Controls from "../../../../../components/Customs/Controls/Controls";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import Valid from "../../../../../constants/Valid";

import {useDispatch, useSelector} from "react-redux";
import {
  EXAMS_IS_EMPTY_EXAMS,
  EXAMS_RESULT_CT,
  EXAMS_RESULT_CT_1,
  EXAMS_RESULT_CT_2,
  EXAMS_RESULT_CT_LANG,
  EXAMS_LANGUAGE_CT_LANG,
  EXAMS_LANGUAGE_EXAMS_LANG,
  EXAMS_NEED_AHOSTEL,
  TRAINING_SPECIALITY_BUDGET,
  TRAINING_SPECIALITY_PAID,
  TRAINING_TARGET_RECRUITMENT,
  TRAINING_TARGET_RECRUITMENT_SPEC,
  TRAINING_TYPE,

} from "../../../../../services/tagTypes";
import {
  deleteContractFileStatement,
  deleteTypeCompetitionStatement,
  getDataStatement,
  setDataStatement
} from "../../../../../store/Statement/actionStatement";
import {chatAction_createNotification} from "../../../../../store/Chat/ChatActions";
// import FileLoadingForm from "../../../../../components/FileLoading";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const initialFValues = {
  isEmptyExams: false,
  ballCT1: "",
  ballCT2: "",
  radioGroupExamsLang: "",
  radioGroupCTLang: "",
  ctLang: "",
  needHostelToExams: false,
};

function Exams() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  const statementTypeCompetition = useSelector(state => state.statementReducer.typeCompetition);
  const statementContractFile = useSelector(state => state.statementReducer.contractFile);
  const arrayRadioGroupCTLang = [
    {title: 'русскому языку', id: "ru"},
    {title: 'белорусскому языку', id: "by"},
  ];
  const arrayRadioGroupExamsLang = [
    {title: 'на русском языке', id: "ru"},
    {title: 'на белорусском языке', id: "by"},
  ];

  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    typeTraining: '',
    targetRecruitment: false,
    targetRecruitmentSpec: {},
    specialityBudget: [],
    specialityPaid: [],
    titleSpec: '',
    titleCT1: '',
    titleCT2: '',
    typeCT1: '',
    typeCT2: '',
    titleCTLang: '',
    titleExamLang: '',
    exams: [],
  });

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render]);

  const onReloadInformationExams = (item) => {
    let findTargetRecruitment, findTargetRecruitmentSpec, findSpecialityBudget, findSpecialityPaid, typeTraining;
    for (let i = 0; i < item.length; i++) {
      if (item[i]?.typeTitle === EXAMS_IS_EMPTY_EXAMS) values.isEmptyExams = item[i]?.value?.value;
      if (item[i]?.typeTitle === EXAMS_RESULT_CT_1) values.ballCT1 = item[i]?.value?.value;
      if (item[i]?.typeTitle === EXAMS_RESULT_CT_2) values.ballCT2 = item[i]?.value?.value;
      if (item[i]?.typeTitle === EXAMS_LANGUAGE_CT_LANG) values.radioGroupCTLang = item[i]?.value?.value;
      if (item[i]?.typeTitle === EXAMS_LANGUAGE_EXAMS_LANG) values.radioGroupExamsLang = item[i]?.value?.value;
      if (item[i]?.typeTitle === EXAMS_RESULT_CT_LANG) values.ctLang = item[i]?.value?.value;
      if (item[i]?.typeTitle === EXAMS_NEED_AHOSTEL) values.needHostelToExams = item[i]?.value?.value;
      if (item[i]?.typeTitle === TRAINING_TARGET_RECRUITMENT) findTargetRecruitment = item[i]?.value?.value;
      if (item[i]?.typeTitle === TRAINING_TARGET_RECRUITMENT_SPEC) findTargetRecruitmentSpec = item[i]?.value;
      if (item[i]?.typeTitle === TRAINING_SPECIALITY_BUDGET) findSpecialityBudget = item[i]?.value;
      if (item[i]?.typeTitle === TRAINING_SPECIALITY_PAID) findSpecialityPaid = item[i]?.value;
      if (item[i]?.typeTitle === TRAINING_TYPE) typeTraining = item[i]?.value?.value;
    }

    setState({
      ...state,
      targetRecruitment: findTargetRecruitment,
      targetRecruitmentSpec: findTargetRecruitmentSpec,
      specialityBudget: findSpecialityBudget,
      specialityPaid: findSpecialityPaid,
      typeTraining: typeTraining,
    });
    setRender(!render);
  }

  useEffect(() => {
    const getDataExams = async () => {
      const arrayExamsParams = [
        `type[]=${EXAMS_IS_EMPTY_EXAMS}`,
        `type[]=${EXAMS_RESULT_CT}`,
        `type[]=${EXAMS_RESULT_CT_1}`,
        `type[]=${EXAMS_RESULT_CT_2}`,
        `type[]=${EXAMS_RESULT_CT_LANG}`,
        `type[]=${EXAMS_LANGUAGE_CT_LANG}`,
        `type[]=${EXAMS_LANGUAGE_EXAMS_LANG}`,
        `type[]=${EXAMS_NEED_AHOSTEL}`,
        `type[]=${TRAINING_SPECIALITY_BUDGET}`,
        `type[]=${TRAINING_SPECIALITY_PAID}`,
        `type[]=${TRAINING_TARGET_RECRUITMENT}`,
        `type[]=${TRAINING_TARGET_RECRUITMENT_SPEC}`,
        `type[]=${TRAINING_TYPE}`,
      ];
      const examsParams = arrayExamsParams.join('&');
      const result = await dispatch(getDataStatement(examsParams));
      onReloadInformationExams(result);
    }
    getDataExams();
  }, []);


  useEffect(() => {
    let tmpSpec;
    if (!isEmptyTargetRecruitmentSpecStatement()) {
      tmpSpec = state.targetRecruitmentSpec;
    } else if (!isEmptySpecialityBudgetStatement()) {
      tmpSpec = state.specialityBudget[0];
    } else if (!isEmptySpecialityPaidStatement()) {
      tmpSpec = state.specialityPaid[0];
    }
    // console.log(tmpSpec)
    if (tmpSpec !== undefined) {
      const prof1 = getExamOnProfile(tmpSpec?.data?.exams, 1);
      const prof2 = getExamOnProfile(tmpSpec?.data?.exams, 2);

      setState({
        ...state,
        titleSpec: tmpSpec?.speciality?.data?.name,
        titleCT1: prof1?.name,
        typeCT1: state.typeTraining === 'targetTraining' ? '' : prof1?.type,
        titleCT2: prof2?.name,
        typeCT2: state.typeTraining === 'targetTraining' ? '' : prof2?.type,
        exams: tmpSpec?.data?.exams
      })
    }
  }, [state.targetRecruitmentSpec, state.specialityBudget, state.specialityPaid])

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (validate()) {
      if (isEmptyFormExams()) {
        clearFormExams()
      }
      await onSaver(values)
      setIsLoading(false);
      history.push("/statement")
    } else {
      setIsLoading(false);
    }
  };

  const onSaver = async (data) => {
    const sendObject = {
      EXAMS_IS_EMPTY_EXAMS: {value: data.isEmptyExams},
      EXAMS_RESULT_CT_1: {value: data.ballCT1},
      EXAMS_RESULT_CT_2: {value: data.ballCT2},
      EXAMS_LANGUAGE_CT_LANG: {value: data.radioGroupCTLang},
      EXAMS_LANGUAGE_EXAMS_LANG: {value: data.radioGroupExamsLang},
      EXAMS_RESULT_CT_LANG: {value: data.ctLang},
      EXAMS_NEED_AHOSTEL: {value: data.needHostelToExams},
      ANKETA_STATUS_FORMS_APPLICATION: {
        ...statementStatusFormsApplication,
        6: {"status": "IS_FULL", "coment": ""},
        9: {"action": "6"}
      },
      ANKETA_STATUS: {"value": "IS_WORK"}
    }
    try {
      await dispatch(setDataStatement(sendObject));
      dispatch(chatAction_createNotification({
        message: 'Данные формы сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      if (error.data.detail === "lock_application_in_asu") {
        dispatch(chatAction_createNotification({
          message: 'Форма заблокирована для изменений.',
          variant: 'error'
        }));
      } else {
        console.log(error);
        dispatch(chatAction_createNotification({
          message: 'Ошибка сохранения данных формы.',
          variant: 'error'
        }));
      }
    }
    if (statementTypeCompetition?.id) await dispatch(deleteTypeCompetitionStatement());
    if (statementContractFile?.id) await dispatch(deleteContractFileStatement());
  }

  const validate = (fieldValues = values) => {
    let temp = {...errors};
    // if ('ballCT1' in fieldValues) {
    //   temp.ballCT1 = !isEmptyFormExams()
    //     ? showInputBallCT(state.typeCT1)
    //       ? Valid.isIsset(fieldValues.ballCT1)
    //         ? Valid.isTrim(fieldValues.ballCT1)
    //           ? ''
    //           : 'Это поле не может состоять только из пробелов'
    //         : 'Это поле обязательное для заполнения!'
    //       : ''
    //     : '';
    // }
    // if ('ballCT2' in fieldValues) {
    //   temp.ballCT2 = !isEmptyFormExams()
    //     ? showInputBallCT(state.typeCT2)
    //       ? Valid.isIsset(fieldValues.ballCT2)
    //         ? Valid.isTrim(fieldValues.ballCT2)
    //           ? ''
    //           : 'Это поле не может состоять только из пробелов'
    //         : 'Это поле обязательное для заполнения!'
    //       : ''
    //     : '';
    // }
    if ('radioGroupCTLang' in fieldValues) {
      temp.radioGroupCTLang = !isEmptyFormExams()
        ? showCTLang()
          ? showRadioCTLang()
            ? Valid.isIsset(fieldValues.radioGroupCTLang)
              ? ''
              : 'Это поле обязательное для заполнения!'
            : ''
          : ''
        : '';
    }
    if ('ctLang' in fieldValues) {
      temp.ctLang = !isEmptyFormExams()
        ? showCTLang()
          ? Valid.isIsset(fieldValues.ctLang)
            ? Valid.isTrim(fieldValues.ctLang)
              ? ''
              : 'Это поле не может состоять только из пробелов'
            : 'Это поле обязательное для заполнения!'
          : ''
        : '';
    }
    if ('radioGroupExamsLang' in fieldValues) {
      temp.radioGroupExamsLang = !isEmptyFormExams()
        ? showExamsLang()
          ? showRadioExamsLang()
            ? Valid.isIsset(fieldValues.radioGroupExamsLang)
              ? ''
              : 'Это поле обязательное для заполнения!'
            : ''
          : ''
        : '';
    }

    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const isEmptyFormExams = () => {
    return !!values.isEmptyExams;
  }

  const isEmptyTargetRecruitmentSpecStatement = () => {
    return !(state.targetRecruitmentSpec?.data !== undefined && state.targetRecruitmentSpec?.data?.exams !== undefined);
  }
  const isEmptySpecialityBudgetStatement = () => {
    return !(state.specialityBudget !== undefined && state.specialityBudget.length > 0 && state.specialityBudget[0]?.data?.exams !== undefined);
  }
  const isEmptySpecialityPaidStatement = () => {
    return !(state.specialityPaid !== undefined && state.specialityPaid.length > 0 && state.specialityPaid[0]?.data?.exams !== undefined);
  }
  const isEmptySpecialityStatement = () => {
    return !(!isEmptyTargetRecruitmentSpecStatement() || !isEmptySpecialityBudgetStatement() || !isEmptySpecialityPaidStatement());
  }

  const show2ProfileSubject = (typeTraining) => {
    return typeTraining !== 'targetTraining';
  }
  const showInputBallCT = (type) => {
    return type === 'ЦТ/ЦЭ';
  }
  const showRadioCTLang = () => {
    return state.titleCTLang === '' && state.typeTraining !== 'targetTraining';
  }
  const showRadioExamsLang = () => {
    return state.titleExamLang === '';
  }
  const getExamOnProfile = (exams, numProf) => {
    return exams.find((test) => test.EKZ_PROF === numProf && test.KOD_EKZ !== 0);
  }
  const getCTLang = () => {
    return state.exams.find((test) => test.EKZ_PROF === 0 && test.KOD_EKZ !== 0);
  }
  const showCTLang = () => {
    return getCTLang() !== undefined && state.typeTraining !== 'targetTraining';
  }
  const showExamsLang = () => {
    const result = state.exams.find((test) => test.type !== "ЦТ/ЦЭ" && test.KOD_EKZ !== 0)
    return result !== undefined || state.typeTraining === 'targetTraining';
  }
  // const showCTFile = () => {
  //   const result = state.exams.find((test) => test.type === "ЦТ" && test.KOD_EKZ !== 0)
  //   return result !== undefined;
  // }

  const clearFormExams = () => {
    if (values.isEmptyExams === true) {
      values.ballCT1 = '';
      values.ballCT2 = '';
      values.radioGroupExamsLang = '';
      values.radioGroupCTLang = '';
      values.ctLang = '';
      values.needHostelToExams = false;
    }
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFValues, true, validate);

  useEffect(() => {
    const philologyCTLang = () => {
      if (showCTLang()) {
        const langCT = getCTLang();
        let tmpTitleCTLang = state.titleCTLang;
        let tmpTitleExamLang = state.titleExamLang;
        if (langCT.name === 'русский язык' || state.titleSpec === 'Русская филология') {
          // tmpTitleCTLang = 'русский язык (ЦТ)';
          tmpTitleExamLang = 'русский язык';
          // values.radioGroupCTLang = 'ru';
          values.radioGroupExamsLang = 'ru';
          // console.log('русский язык')
        } else if (langCT.name === 'белорусский язык' || state.titleSpec === 'Белорусская филология') {
          // tmpTitleCTLang = 'белорусский язык (ЦТ)';
          tmpTitleExamLang = 'белорусский язык';
          // values.radioGroupCTLang = 'by';
          values.radioGroupExamsLang = 'by';
          // console.log('белорусский язык')
        }

        setState({
          ...state,
          titleCTLang: tmpTitleCTLang,
          titleExamLang: tmpTitleExamLang,
        })
      } else if (showExamsLang()) {
        const langCT = getCTLang();
        let tmpTitleExamLang = state.titleExamLang;
        if (langCT?.name === 'русский язык' || state.titleSpec === 'Русская филология') {
          tmpTitleExamLang = 'русский язык';
          values.radioGroupExamsLang = 'ru';
          // console.log('русский язык')
        } else if (langCT?.name === 'белорусский язык' || state.titleSpec === 'Белорусская филология') {
          tmpTitleExamLang = 'белорусский язык';
          values.radioGroupExamsLang = 'by';
          // console.log('белорусский язык')
        }
        setState({
          ...state,
          titleExamLang: tmpTitleExamLang,
        })
      }
    }
    philologyCTLang();
  }, [state.exams])

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} justifyContent="center" className={classes.grid_container}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader title="Вступительные испытания/ЦТ/ЦЭ"/>
            <Divider/>
            <CardContent>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {
                    isEmptySpecialityStatement()
                      ? <Grid item container xs={12} justifyContent={"space-evenly"}>
                        <Typography variant="body1" component="p">
                          Выберите специальность
                        </Typography>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          onClick={() => {
                            history.push("/training")
                          }}
                        >
                          Перейти
                        </Button>
                      </Grid>
                      : <>
                        <Grid item md={12} xs={12}>
                          <Controls.Checkbox
                            name="isEmptyExams"
                            label="Претендую на участие в конкурсе без вступительных испытаний"
                            value={values.isEmptyExams}
                            onChange={handleInputChange}
                            error={errors.isEmptyExams}
                          />
                        </Grid>
                        {isEmptyFormExams()
                          ? null
                          : <>
                            <Grid item md={12} xs={12}>
                              <Typography variant="h6" component="p" align={"center"}>
                                {state.titleSpec}
                              </Typography>
                            </Grid>

                            <Grid item sm={12} xs={12}>
                              <Typography variant="body1" component="p">
                                1 профильный предмет
                              </Typography>
                            </Grid>
                            {showInputBallCT(state.typeCT1) ?
                              <>
                                <Grid item sm={6} xs={12}>
                                  <Typography variant="body1" component="p">
                                    {`${state.titleCT1} ${state.typeCT1 !== '' ? `(${state.typeCT1})` : ''}`}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Controls.Input
                                    fullWidth
                                    name="ballCT1"
                                    label="Баллы ЦТ/ЦЭ 1 профильный предмет"
                                    value={values.ballCT1}
                                    onChange={handleInputChange}
                                    error={errors.ballCT1}
                                  />
                                </Grid>
                              </> :
                              <>
                                <Grid item sm={6} xs={12}>
                                  <Typography variant="body1" component="p">
                                    Экзамен
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Typography variant="body1" component="p">
                                    {`${state.titleCT1} ${state.typeCT1 !== '' ? `(${state.typeCT1})` : ''}`}
                                  </Typography>
                                </Grid>
                              </>
                            }
                            {show2ProfileSubject(state.typeTraining) ?
                              <>
                                <Grid item sm={12} xs={12}>
                                  <Typography variant="body1" component="p">
                                    2 профильный предмет
                                  </Typography>
                                </Grid>
                                {showInputBallCT(state.typeCT2) ?
                                  <>
                                    <Grid item sm={6} xs={12}>
                                      <Typography variant="body1" component="p">
                                        {`${state.titleCT2} (${state.typeCT2})`}
                                      </Typography>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                      <Controls.Input
                                        fullWidth
                                        name="ballCT2"
                                        label="Баллы ЦТ/ЦЭ 2 профильный предмет"
                                        value={values.ballCT2}
                                        onChange={handleInputChange}
                                        error={errors.ballCT2}
                                      />
                                    </Grid>
                                  </> :
                                  <>
                                    <Grid item sm={6} xs={12}>
                                      <Typography variant="body1" component="p">
                                        Экзамен
                                      </Typography>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                      <Typography variant="body1" component="p">
                                        {`${state.titleCT2} (${state.typeCT2})`}
                                      </Typography>
                                    </Grid>
                                  </>
                                }
                              </> :
                              null
                            }
                            {showCTLang() ?
                              <>
                                {showRadioCTLang() ?
                                  <Grid item sm={6} xs={12}>
                                    <Controls.RadioGroup
                                      fullWidth
                                      name="radioGroupCTLang"
                                      label="Сдавал ЦТ/ЦЭ по:*"
                                      value={values.radioGroupCTLang}
                                      onChange={handleInputChange}
                                      error={errors.radioGroupCTLang}
                                      items={arrayRadioGroupCTLang}
                                    />
                                  </Grid> :
                                  <Grid item sm={6} xs={12}>
                                    <Typography variant="body1" component="p">
                                      {`${state.titleCTLang}`}
                                    </Typography>
                                  </Grid>
                                }

                                <Grid item sm={6} xs={12}>
                                  <Controls.Input
                                    fullWidth
                                    name="ctLang"
                                    label="Баллы ЦТ/ЦЭ язык*"
                                    value={values.ctLang}
                                    onChange={handleInputChange}
                                    error={errors.ctLang}
                                  />
                                </Grid>
                              </> :
                              null
                            }
                            {showExamsLang() ?
                              <>
                                {showRadioExamsLang() ?
                                  <Grid item sm={12} xs={12}>
                                    <Controls.RadioGroup
                                      fullWidth
                                      name="radioGroupExamsLang"
                                      label="Вступительные испытания в университет буду сдавать:*"
                                      value={values.radioGroupExamsLang}
                                      onChange={handleInputChange}
                                      error={errors.radioGroupExamsLang}
                                      items={arrayRadioGroupExamsLang}
                                    />
                                  </Grid> :
                                  null
                                }

                                <Grid item sm={12} xs={12}>
                                  <Controls.Checkbox
                                    name="needHostelToExams"
                                    label="В период сдачи внутривузовских испытаний нуждаюсь в общежитии"
                                    value={values.needHostelToExams}
                                    onChange={handleInputChange}
                                    error={errors.needHostelToExams}
                                  />
                                </Grid>
                              </> :
                              null
                            }
                            {/*{showCTFile()*/}
                            {/*  ?*/}
                            {/*  <FileLoadingForm*/}
                            {/*    tag={'EXAMS_FILE_RESULTS_CT'}*/}
                            {/*    labelText={'Загрузка файлов результатов ЦТ'}*/}
                            {/*    labelForm={'Перетащите или <span class="filepond--label-action" tabindex="0">прикрепите</span> сканы (фото) результатов ЦТ'}*/}
                            {/*    size={5}*/}
                            {/*  />*/}
                            {/*  : null*/}
                            {/*}*/}

                          </>
                        }

                        <Grid item container xs={12} justifyContent={"space-between"}>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => {
                              history.push("/statement")
                            }}
                            disabled={isLoading}
                          >
                            Назад
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isLoading}
                          >
                            Сохранить
                          </Button>
                        </Grid>
                      </>

                  }


                </Grid>
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Exams;