import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

export default function DialogChange(props) {
    const {
        open: PropsVisible,
        onClose: PropsOnClose,
        filePond: PropsFilePond,
        files: PropsFiles
    } = props;

    function onClose() {
        PropsOnClose && PropsOnClose();
    }

    return (
        <Dialog open={PropsVisible} keepMounted
                maxWidth={"sm"} fullWidth
                onClose={onClose}>
            <DialogTitle id="alert-dialog-slide-title">Изменить файл</DialogTitle>
            <DialogContent>
                <Box p={1}>{PropsFilePond}</Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={PropsFiles.length !== 0} onClick={onClose} color="primary">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    )
}
DialogChange.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    filePond: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired
};
