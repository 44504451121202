import { useEffect } from 'react';
import {useDispatch} from "react-redux";
import {chatAction_getChats} from "../../../store/Chat/ChatActions";
import PropTypes from "prop-types";
import {clearCache} from "../../../store/System/actionSystem";

const CHAT_TIME_RELOAD = 30;

export default function Reloader(props) {
    const {isAuthenticated} = props;
    const dispatch = useDispatch();
    const reload = () => {
        dispatch(chatAction_getChats({isReloading: true}));
        dispatch(clearCache());
    }
    useEffect(() => {
        const timer = isAuthenticated && setTimeout(reload, CHAT_TIME_RELOAD * 1000);
        return () => isAuthenticated && clearTimeout(timer);
    })
    return null;
}
Reloader.propTypes ={
    isAuthenticated: PropTypes.bool
}
