import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {ListItem,Typography} from "@material-ui/core";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

function CustomListItem({ item, index }) {
  return (
    <Draggable draggableId={item.id.toString()} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        ><Typography variant="body2" >
          {(index + 1) + ') '}
          {item.speciality.data.name || item.text}
        </Typography>
        </ListItem>
      )}
    </Draggable>
  );
}

const CustomList = React.memo(function CustomList({ array }) {
  return array.map((item, index) => (
    <CustomListItem item={item} index={index} key={item.id} />
  ));
});

function ListDnD({array, setState }) {

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newArray = reorder(
      array,
      result.source.index,
      result.destination.index
    );

    setState(newArray);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <CustomList array={array} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
export default ListDnD;
