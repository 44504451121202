import {
  CREATE_QUEUE_REQUEST,
  CREATE_QUEUE_SUCCESS,
  CREATE_QUEUE_FAIL,
  GET_CURRENT_QUEUE_REQUEST,
  GET_CURRENT_QUEUE_SUCCESS,
  GET_CURRENT_QUEUE_FAIL,
  DELETE_CURRENT_QUEUE_REQUEST,
  DELETE_CURRENT_QUEUE_SUCCESS,
  DELETE_CURRENT_QUEUE_FAIL,
  FETCH_AVAILABLE_QUEUES_REQUEST,
  FETCH_AVAILABLE_QUEUES_SUCCESS,
  FETCH_AVAILABLE_QUEUES_FAILURE
} from "../actions/actionTypes";
import instance from "../../customAxios";

const API_URL = "/";

export const getAvailableQueues = (dateStart, dateEnd) => {
  return async (dispatch, getState) => {
    dispatch(fetchAvailableQueuesRequestAction());
    try {
      const { statementReducer } = getState();
      const idSelectedFacultyStatement = statementReducer?.selectedFacultyStatement?.id;
      const selectedTypeTrainingStatement = statementReducer?.selectedTypeTrainingStatement;
      let facultyId = 'null';

      if (selectedTypeTrainingStatement !== 'targetTraining' && selectedTypeTrainingStatement !== 'olympiad') {
        facultyId = idSelectedFacultyStatement ?? 'null';
      }

      if (dateStart && dateEnd) {
        const response = await instance.get(
          API_URL + `api/ext/queue/faculty/${facultyId}/start/${dateStart}/end/${dateEnd}`,
        );
        dispatch(fetchAvailableQueuesSuccessAction(response.data));
        return response.data;
      }
      throw new Error(`error idSelectedFacultyStatement: ${idSelectedFacultyStatement}, dateStart: ${dateStart}, dateEnd: ${dateEnd}`);
    } catch (error) {
      dispatch(fetchAvailableQueuesFailureAction(error));
      console.error('☠️error getFreeQueue actions Queue: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}

const fetchAvailableQueuesRequestAction = () => {
  return { type: FETCH_AVAILABLE_QUEUES_REQUEST, payload: true };
}
const fetchAvailableQueuesSuccessAction = data => {
  return { type: FETCH_AVAILABLE_QUEUES_SUCCESS, payload: data };
}
const fetchAvailableQueuesFailureAction = error => {
  return { type: FETCH_AVAILABLE_QUEUES_FAILURE, payload: true };
}

export const createQueue = queue => {
  return async (dispatch) => {
    const id = queue.id;
    dispatch(createQueueRequest());
    try {
      if (id) {
        await instance.put(
          API_URL + `api/ext/queue/${id}`,
        );

        dispatch(createQueueSuccess(null));
        dispatch(getCurrentQueue());
        return queue;
      }
      throw new Error(`error idQueue: ${id}`);
    } catch (error) {
      dispatch(createQueueFail());
      console.error('☠️error createQueue actions Queue: ', error.response ? error.response : error);
      return null;
    }
  };
}

const createQueueRequest = () => {
  return {
    type: CREATE_QUEUE_REQUEST,
    payload: true
  }
}

const createQueueSuccess = applications => {
  return {
    type: CREATE_QUEUE_SUCCESS,
    payload: applications
  }
}

const createQueueFail = () => {
  return {
    type: CREATE_QUEUE_FAIL,
    payload: true
  }
}

export const getCurrentQueue = () => {
  return async (dispatch) => {
    dispatch(getCurrentQueueRequest());
    try {
      const response = await instance.get(
        API_URL + `api/ext/queue/my`,
      );
      const data = response.data.length === 0 ? null : response.data;
      dispatch(getCurrentQueueSuccess(data));
      return response.data;
    } catch (error) {
      dispatch(getCurrentQueueFail());
      console.error('☠️error getCurrentQueue actions Queue: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}

const getCurrentQueueRequest = () => {
  return {
    type: GET_CURRENT_QUEUE_REQUEST,
    payload: true
  }
}

const getCurrentQueueSuccess = applications => {
  return {
    type: GET_CURRENT_QUEUE_SUCCESS,
    payload: applications
  }
}

const getCurrentQueueFail = () => {
  return {
    type: GET_CURRENT_QUEUE_FAIL,
    payload: true
  }
}

export const deleteCurrentQueue = idQueue => {
  return async (dispatch) => {
    dispatch(deleteCurrentQueueRequest());
    try {
      await instance.delete(
        API_URL + `api/ext/queue/${idQueue}`,
      );
      dispatch(deleteCurrentQueueSuccess());
      return null;
      throw new Error(`error idQueue: ${idQueue}`);
    } catch (error) {
      dispatch(deleteCurrentQueueFail());
      console.error('☠️error deleteCurrentQueue actions Queue: ', error.response ? error.response : error);
      return null;
    }
  };
}

const deleteCurrentQueueRequest = () => {
  return {
    type: DELETE_CURRENT_QUEUE_REQUEST,
    payload: true
  }
}

const deleteCurrentQueueSuccess = () => {
  return {
    type: DELETE_CURRENT_QUEUE_SUCCESS,
    payload: null
  }
}

const deleteCurrentQueueFail = () => {
  return {
    type: DELETE_CURRENT_QUEUE_FAIL,
    payload: true
  }
}

export const getAvailableDatesQueue = (idFaculty, param) => {
  return async (dispatch) => {
    // dispatch(getAvailableDatesQueueRequest());
    try {
      const response = await instance.get(
        API_URL + `api/ext/queue/faculty/${idFaculty}/dates${param}`,
      );
      const data = response.data.length === 0 ? null : response.data;
      // dispatch(getAvailableDatesQueueSuccess(data));
      return response.data;
    } catch (error) {
      // dispatch(getAvailableDatesQueueFail());
      console.error('☠️error getAvailableDatesQueue actions Queue: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}

const getAvailableDatesQueueRequest = () => {
  return {
    type: GET_CURRENT_QUEUE_REQUEST,
    payload: true
  }
}

const getAvailableDatesQueueSuccess = applications => {
  return {
    type: GET_CURRENT_QUEUE_SUCCESS,
    payload: applications
  }
}

const getAvailableDatesQueueFail = () => {
  return {
    type: GET_CURRENT_QUEUE_FAIL,
    payload: true
  }
}