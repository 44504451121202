import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";
import Valid from "../../../../../constants/Valid";
import {Form, useForm} from "../../../../../components/Customs/useForm";
import Controls from "../../../../../components/Customs/Controls/Controls";
import {Button, Card, CardHeader, Grid, Typography,} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {
  deleteContractFileStatement,
  deleteTypeCompetitionStatement,
  getDataStatement,
  getFacultyStatement,
  getSpecialitiesBudgetStatement,
  getSpecialitiesPaidStatement,
  getStudyFormStatement,
  getStudyPayStatement, getTypeTrainingStatement,
  setDataStatement
} from "../../../../../store/Statement/actionStatement";
import ListDnD from "../../../../../components/ListDnD"
import {
  TRAINING_TYPE,
  TRAINING_ALLOW_FOR_PAID,
  TRAINING_FACULTY,
  TRAINING_SPECIALITIES_GROUP,
  TRAINING_SPECIALITY_BUDGET,
  TRAINING_SPECIALITY_PAID,
  TRAINING_STUDY_FORM,
  TRAINING_STUDY_PAY,
  TRAINING_TARGET_RECRUITMENT,
  TRAINING_TARGET_RECRUITMENT_SPEC,
} from "../../../../../services/tagTypes";
import instance from "../../../../../customAxios";
import {GET_SELECTED_FACULTY_STATEMENT,} from "../../../../../store/actions/actionTypes";
import {chatAction_createNotification} from "../../../../../store/Chat/ChatActions";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const initialFValues = {
  studyForm: "",
  studyPay: "",
  faculty: "",
  specialityGroup: "",
  allowForPaid: "",
  targetRecruitment: false,
  targetRecruitmentSpeciality: "",
};

const PedagogicalSpecialties = ({typeTraining}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [trainingState, setTrainingState] = useState({
    itemsSpecialitiesBudget: [],
    itemsSpecialitiesPaid: [],
  });
  const [dataFromApi, setDataFromApi] = useState([]);
  const [itemsFaculty, setItemsFaculty] = useState([]);
  const [itemsSpecialityGroup, setItemsSpecialityGroup] = useState([]);
  const [render, setRender] = useState(false);
  const [dataUnloadApi, setDataUnloadApi] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])

  const itemsAllowForPaid = [
    {title: 'прошу принять документы для получения образования на платной основе', id: 'true'},
    {title: 'получать образование на платной основе не желаю', id: 'false'},
  ];
  const itemsStudyForm = [
    {title: 'Дневная', id: '1/F'},
    // {text: 'Дневная сокращенная', id: '1/S'},
    {title: 'Заочная', id: '2/F'},
    {title: 'Заочная  сокращенная', id: '2/S'},
    // {title: 'Заочная  (дистанционная)', id: '4/F'},
    // {title: 'Заочная  (дистанционная) сокращенная', id: '4/S'},
  ];
  const itemsStudyPay = [
    {title: 'За счет средств бюджета', id: "budget", disabled: true},
    {title: 'На платной основе', id: "paid"},
  ];

  const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  const statementTypeCompetition = useSelector(state => state.statementReducer.typeCompetition);
  const statementContractFile = useSelector(state => state.statementReducer.contractFile);

  const getTypeTraining = (item) => {
    const typeTraining = item.find(data => data.typeTitle === TRAINING_TYPE);
    return (typeTraining !== undefined ? typeTraining.value.value : '');
  }

  const getStudyForm = (item) => {
    const studyForm = item.find(data => data.typeTitle === TRAINING_STUDY_FORM);
    return (studyForm !== undefined ? studyForm.value : '');
  }
  const getStudyPay = (item) => {
    const studyPay = item.find(data => data.typeTitle === TRAINING_STUDY_PAY);
    return (studyPay !== undefined ? studyPay.value.value : '');
  }

  const getFacultyFromApi = (item) => {
    return item.find(data => data.typeTitle === TRAINING_FACULTY);
  }
  const getFaculty = (item, entryFaculties) => {
    // console.log(item, entryFaculties);
    const faculty = getFacultyFromApi(item);
    return (faculty !== undefined ? entryFaculties.find(data => data.id === faculty.value.id) : '');
  }
  const getSpecialityGroupFromApi = (item) => {
    return item.find(data => data.typeTitle === TRAINING_SPECIALITIES_GROUP);
  }
  const getSpecialityGroup = (item, entrySpecialityGroup) => {
    const specialityGroup = getSpecialityGroupFromApi(item);
    if (specialityGroup !== undefined) {
      const findEntrySpecialityGroup = entrySpecialityGroup.find(data => data.id === specialityGroup.value.id)
      if (findEntrySpecialityGroup !== undefined) return findEntrySpecialityGroup;
      return '';
    } else {
      return '';
    }
  }

  const getAllowForPaid = (item) => {
    const allowForPaid = item.find(data => data.typeTitle === TRAINING_ALLOW_FOR_PAID);
    return (allowForPaid !== undefined ? String(allowForPaid.value.value) : '');
  }
  const getSpecialitiesBudget = (item) => {
    const itemsSpecialitiesBudget = item.find(data => data.typeTitle === TRAINING_SPECIALITY_BUDGET);
    return (itemsSpecialitiesBudget !== undefined ? itemsSpecialitiesBudget.value : []);
  }
  const getSpecialitiesPaid = (item) => {
    const itemsSpecialitiesPaid = item.find(data => data.typeTitle === TRAINING_SPECIALITY_PAID);
    return (itemsSpecialitiesPaid !== undefined ? itemsSpecialitiesPaid.value : []);
  }

  const onSaver = async (data) => {
    let sendObject = {
      TRAINING_TYPE: {value: data.typeTraining},
      TRAINING_STUDY_FORM: data.studyForm,
      TRAINING_STUDY_PAY: {value: data.studyPay},
      TRAINING_FACULTY: data.selectedFaculty,
      TRAINING_SPECIALITIES_GROUP: data.selectedSpecialityGroup,
      TRAINING_ALLOW_FOR_PAID: {value: data.selectedAllowForPaid},
      TRAINING_SPECIALITY_BUDGET: data.itemsSpecialitiesBudget,
      TRAINING_SPECIALITY_PAID: data.itemsSpecialitiesPaid,
      TRAINING_TARGET_RECRUITMENT_SPEC: data.selectedTargetRecruitmentSpeciality,
      EXAMS_IS_EMPTY_EXAMS: {value: true},
      ANKETA_STATUS_FORMS_APPLICATION: {
        ...statementStatusFormsApplication,
        2: {"status": "IS_FULL", "coment": ""},
        6: {"status": "IS_FULL", "coment": ""},
        9: {"action": "2"}
      },
      ANKETA_STATUS: {"value": "IS_WORK"},
    }
    let isNeedClearExamsForm = clearExamsForm(dataFromApi);
    let isNeedClearPrivilegesForm = getTypeTraining(dataFromApi) !== typeTraining;
    if (isNeedClearExamsForm) {
      sendObject = {
        ...sendObject,
        EXAMS_RESULT_CT_1_CERTIFICATE: {value: ''}, // из ЦПК
        EXAMS_RESULT_CT_2_CERTIFICATE: {value: ''}, // из ЦПК
        EXAMS_RESULT_CT_LANG_CERTIFICATE: {value: ''}, // из ЦПК
        EXAMS_RESULT_CT_1: {value: ''},
        EXAMS_RESULT_CT_2: {value: ''},
        EXAMS_LANGUAGE_CT_LANG: {value: ''},
        EXAMS_LANGUAGE_EXAMS_LANG: {value: ''},
        EXAMS_RESULT_CT_LANG: {value: ''},
        EXAMS_NEED_AHOSTEL: {value: false},
        ANKETA_STATUS_FORMS_APPLICATION: {
          ...statementStatusFormsApplication,
          2: {"status": "IS_FULL", "coment": ""},
          6: {"status": "IS_FULL", "coment": ""}
        },
      }
    }
    if (isNeedClearPrivilegesForm) {
      sendObject = {
        ...sendObject,
        PRIVILEGES_EMPTY: {value: false},
        PRIVILEGES_STUDY: {value: []},
        PRIVILEGES_SPORT: {value: []},
        PRIVILEGES_SOCIAL: {value: []},
        ANKETA_STATUS_FORMS_APPLICATION: {
          ...statementStatusFormsApplication,
          2: {"status": "IS_FULL", "coment": ""},
          7: {"status": "NOT_CONFIRMED", "coment": ""}
        },
      }
    }
    if (isNeedClearExamsForm && isNeedClearPrivilegesForm) {
      sendObject = {
        ...sendObject,
        ANKETA_STATUS_FORMS_APPLICATION: {
          ...statementStatusFormsApplication,
          2: {"status": "IS_FULL", "coment": ""},
          6: {"status": "IS_FULL", "coment": ""},
          7: {"status": "NOT_CONFIRMED", "coment": ""}
        },
      }
    }
    try {
      await dispatch(setDataStatement(sendObject));
      dispatch(chatAction_createNotification({
        message: 'Данные формы сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      if (error.data.detail === "lock_application_in_asu") {
        dispatch(chatAction_createNotification({
          message: 'Форма заблокирована для изменений.',
          variant: 'error'
        }));
      } else {
        console.log(error);
        dispatch(chatAction_createNotification({
          message: 'Ошибка сохранения данных формы.',
          variant: 'error'
        }));
      }
    }
    await dispatch(getTypeTrainingStatement(data.typeTraining));
    await dispatch(getStudyFormStatement(data.studyForm));
    await dispatch(getStudyPayStatement(data.studyPay));
    await dispatch(getFacultyStatement(data.selectedFaculty));
    await dispatch(getSpecialitiesBudgetStatement(data.itemsSpecialitiesBudget));
    await dispatch(getSpecialitiesPaidStatement(data.itemsSpecialitiesPaid));
    if (statementTypeCompetition?.id) await dispatch(deleteTypeCompetitionStatement());
    if (statementContractFile?.id) await dispatch(deleteContractFileStatement());
  }

  const studyFormSubmit = () => {
    let first = values.studyForm[0];
    let last = values.studyForm[values.studyForm.length - 1];
    if (first && last) return {studyForm: +first, trainingVolume: last};
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    const dataStudyForm = studyFormSubmit();
    const boolAllowForPaid = values.allowForPaid === 'true';

    let dataSubmit = {
      typeTraining: typeTraining,
      studyForm: dataStudyForm,
      studyPay: values.studyPay,
      selectedFaculty: values.faculty,
      selectedSpecialityGroup: values.specialityGroup,
      selectedAllowForPaid: boolAllowForPaid,
      selectedTargetRecruitment: false,
      selectedTargetRecruitmentSpeciality: '',
      itemsSpecialitiesBudget: trainingState.itemsSpecialitiesBudget,
      itemsSpecialitiesPaid: trainingState.itemsSpecialitiesPaid,
    }

    if (dataStudyForm === '') {
      dataSubmit.studyForm = {value: ''};
    }
    if (values.targetRecruitmentSpeciality === '' || values.targetRecruitment === false) {
      dataSubmit.selectedTargetRecruitmentSpeciality = {value: ''};
    }
    if (values.studyPay === 'budget' && (values.allowForPaid === 'false' || values.allowForPaid === false)) {
      dataSubmit.itemsSpecialitiesPaid = [];
    }
    if (values.studyPay === 'paid') {
      dataSubmit.itemsSpecialitiesBudget = [];
      dataSubmit.selectedTargetRecruitment = false;
      dataSubmit.selectedTargetRecruitmentSpeciality = {value: ''};
      dataSubmit.selectedAllowForPaid = '';
    }

    if (validate()) {
      await onSaver(dataSubmit);
      await dispatch({
        type: GET_SELECTED_FACULTY_STATEMENT,
        payload: values.faculty
      });
      setIsSending(false);
      history.push("/statement");
    } else {
      setIsSending(false);
    }
  };


  const showItemsSpecialitiesBudgetListPriority = () => {
    return trainingState.itemsSpecialitiesBudget !== undefined && trainingState.itemsSpecialitiesBudget.length > 1 && values.studyPay === 'budget';
  }
  const showItemsSpecialitiesPaidListPriority = () => {
    return trainingState.itemsSpecialitiesPaid !== undefined && trainingState.itemsSpecialitiesPaid.length > 1 && ((values.studyPay === 'budget' && String(values.allowForPaid) === 'true') || values.studyPay === 'paid');
  }

  const validate = (fieldValues = values) => {
    let temp = {...errors};
    if ('studyForm' in fieldValues) {
      temp.studyForm = Valid.isIsset(fieldValues.studyForm)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('studyPay' in fieldValues) {
      temp.studyPay = Valid.isIsset(fieldValues.studyPay)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('faculty' in fieldValues) {
      temp.faculty = Valid.isIsset(fieldValues.faculty)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('specialityGroup' in fieldValues) {
      temp.specialityGroup = Valid.isIsset(fieldValues.specialityGroup)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('allowForPaid' in fieldValues) {
      if (showAllowForPaid()) {
        temp.allowForPaid = Valid.isIsset(String(fieldValues.allowForPaid))
          ? ''
          : 'Это поле обязательное для заполнения!';
      }
    }

    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const clearExamsForm = (item) => {
    const oldTypeTraining = getTypeTraining(item);
    const newTypeTraining = typeTraining;
    // console.log(oldTypeTraining,newTypeTraining);


    const oldObjectStudyForm = getStudyForm(item);
    const oldStudyForm = oldObjectStudyForm?.studyForm;
    const oldTrainingVolume = oldObjectStudyForm?.trainingVolume;
    const newObjectStudyForm = studyFormSubmit();
    const newStudyForm = newObjectStudyForm?.studyForm;
    const newTrainingVolume = newObjectStudyForm?.trainingVolume;
    // console.log(oldStudyForm,newStudyForm);
    // console.log(oldTrainingVolume,newTrainingVolume);

    const oldStudyPay = getStudyPay(item);
    const newStudyPay = values.studyPay;
    // console.log(oldStudyPay,newStudyPay);

    let oldFaculty = getFacultyFromApi(item);
    if (oldFaculty !== undefined) oldFaculty = oldFaculty.value;
    const newFaculty = values.faculty;
    // console.log(oldFaculty?.id,newFaculty?.id);

    let oldSpecialityGroup = getSpecialityGroupFromApi(item);
    if (oldSpecialityGroup !== undefined) oldSpecialityGroup = oldSpecialityGroup.value;
    const newSpecialityGroup = values.specialityGroup;
    // console.log(oldSpecialityGroup?.id,newSpecialityGroup?.id);

    const oldAllowForPaid = getAllowForPaid(item);
    const newAllowForPaid = values.allowForPaid;
    // console.log(String(oldAllowForPaid),String(newAllowForPaid));

    return oldTypeTraining !== newTypeTraining || oldStudyForm !== newStudyForm || oldTrainingVolume !== newTrainingVolume || oldStudyPay !== newStudyPay || oldFaculty?.id !== newFaculty?.id || oldSpecialityGroup?.id !== newSpecialityGroup?.id || String(oldAllowForPaid) !== String(newAllowForPaid);
  }
  const clearTrainingForm = () => {
    values.studyForm = '';
    values.studyPay = '';
    values.faculty = '';
    values.allowForPaid = '';
    setItemsSpecialityGroup([]);
    setItemsFaculty([]);
    clearFaculty();
  }
  const clearFaculty = () => {
    values.faculty = '';
    clearSpecialityGroupAndMore();
  }
  const clearSpecialityGroupAndMore = () => {
    values.specialityGroup = '';
    clearSpecialities();
  }
  const clearSpecialities = () => {
    setTrainingState({
      ...trainingState,
      itemsSpecialitiesBudget: [],
      itemsSpecialitiesPaid: [],
    });
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFValues, true, validate);

  const onReloadInformationToStudyTraining = (item) => {

    const studyForm = getStudyForm(item);
    const studyPay = getStudyPay(item);

    if (studyForm && studyForm.studyForm && studyForm.trainingVolume) {
      values.studyForm = `${studyForm.studyForm}/${studyForm.trainingVolume}`;
    }
    values.studyPay = studyPay;

    setRender(true);
  }

  const onReloadInformationToFacultyTraining = (item, entryFaculties) => {
    values.faculty = getFaculty(item, entryFaculties) || '';
    setRender(true);
  }

  const onReloadInformationToSpecialityGroupTraining = (item, entrySpecialityGroup) => {

    const selectedSpecialityGroup = getSpecialityGroup(item, entrySpecialityGroup) || '';
    const selectedAllowForPaid = getAllowForPaid(item);
    onReloadInformationSpecialitiesTraining(item);

    values.specialityGroup = selectedSpecialityGroup;
    values.allowForPaid = selectedAllowForPaid;

    setRender(true);
  }

  const onReloadInformationSpecialitiesTraining = (item) => {

    const itemsSpecialitiesBudget = getSpecialitiesBudget(item);
    const itemsSpecialitiesPaid = getSpecialitiesPaid(item);

    setTrainingState({
      ...trainingState,
      itemsSpecialitiesBudget: itemsSpecialitiesBudget,
      itemsSpecialitiesPaid: itemsSpecialitiesPaid,
    });
    setRender(true);
  }

  useEffect(() => {
    const getDataTraining = async () => {
      const arrayTrainingParams = [
        `type[]=${TRAINING_TYPE}`,
        `type[]=${TRAINING_STUDY_FORM}`,
        `type[]=${TRAINING_STUDY_PAY}`,
        `type[]=${TRAINING_FACULTY}`,
        `type[]=${TRAINING_SPECIALITIES_GROUP}`,
        `type[]=${TRAINING_ALLOW_FOR_PAID}`,
        `type[]=${TRAINING_SPECIALITY_BUDGET}`,
        `type[]=${TRAINING_SPECIALITY_PAID}`,
        `type[]=${TRAINING_TARGET_RECRUITMENT}`,
        `type[]=${TRAINING_TARGET_RECRUITMENT_SPEC}`,
      ];
      const trainingParams = arrayTrainingParams.join('&');
      const result = await dispatch(getDataStatement(trainingParams))
      setDataFromApi(result);
      return result
    }
    getDataTraining().then((result) => {
      if (dataUnloadApi) {
        onReloadInformationToStudyTraining(result)
      }
    })
    return () => {
      clearTrainingForm();
    };
  }, []);

// api/pub/faculties?k_form=4&full_sokr=S&is_paid=true&is_budget=true
  useEffect(() => {
    const fetchDataFaculties = async (studyForm, trainingVolume, studyPay) => {
      const response = await instance.get(
        `/api/pub/faculties?pedagogy=1&k_form=${studyForm}&full_sokr=${trainingVolume}${studyPay === 'budget' ? `&is_budget=true` : `&is_paid=true`}`,
      );
      await setItemsFaculty(response.data);
      return response.data
    };

    clearFaculty();

    if (values.studyForm && values.studyPay) {
      fetchDataFaculties(+values.studyForm[0], values.studyForm[values.studyForm.length - 1], values.studyPay)
        .then((entryFaculties) => {
          if (dataUnloadApi) {
            onReloadInformationToFacultyTraining(dataFromApi, entryFaculties);
          }
        })
    }
    return () => {
      clearFaculty()
    };
  }, [values.studyForm, values.studyPay]);

  useEffect(() => {
    const fetchDataSpecialityGroup = async (studyForm, trainingVolume, selectedFacultyId) => {
      const response = await instance.get(
        `/api/pub/faculties/${selectedFacultyId}/groups?pedagogy=1&k_form=${studyForm}&full_sokr=${trainingVolume}`,
      );
      await setItemsSpecialityGroup(response.data);
      return response.data
    };

    clearSpecialityGroupAndMore();

    if (values.studyForm && values.faculty) {
      fetchDataSpecialityGroup(+values.studyForm[0], values.studyForm[values.studyForm.length - 1], values.faculty.id)
        .then((entrySpecialityGroup) => {
          if (dataUnloadApi) {
            onReloadInformationToSpecialityGroupTraining(dataFromApi, entrySpecialityGroup);
          }
        })
    }
    return () => {
      clearSpecialityGroupAndMore()
    };
  }, [values.faculty]);

  useEffect(() => {
    const fetchDataSpeciality = async (selectedSpecialityGroupId) => {
      const response = await instance.get(
        `/api/pub/faculties/groups/${selectedSpecialityGroupId}/specialities?pedagogy=1`,
      );
      await setItemsSpecialities(response.data);
      return response.data;
    };

    clearSpecialities();

    if (values.specialityGroup?.id) {
      fetchDataSpeciality(values.specialityGroup.id)
    }
    return () => {
      clearSpecialities();
    };
  }, [values.specialityGroup, values.allowForPaid]);

  const setItemsSpecialities = (arraySpecialities) => {
    const tmpSpecialitiesBudget = [];
    const tmpSpecialitiesPaid = []

    arraySpecialities?.forEach(el => {
      if (el?.data?.PLAN_BUD > 0) tmpSpecialitiesBudget.push(el)
      if (el?.data?.PLAN_PLAT > 0) tmpSpecialitiesPaid.push(el)
    });
    // console.log(tmpSpecialitiesBudget);
    // console.log(dataFromApi.find(data => data.typeTitle === TRAINING_SPECIALITY_BUDGET));
    let isNeedClearExamsForm = clearExamsForm(dataFromApi);
    // console.log(isNeedClearExamsForm)
    if (dataUnloadApi && dataFromApi.length > 0 && (dataFromApi.find(data => data.typeTitle === TRAINING_SPECIALITY_BUDGET) || dataFromApi.find(data => data.typeTitle === TRAINING_SPECIALITY_PAID)) && !isNeedClearExamsForm) {
      // console.log('setItemsSpecialities from state');
      onReloadInformationSpecialitiesTraining(dataFromApi);
      setDataUnloadApi(false);
    } else {
      // console.log(dataUnloadApi, dataFromApi );
      // console.log('setItemsSpecialities from dictionary');
      setTrainingState({
        ...trainingState,
        itemsSpecialitiesBudget: tmpSpecialitiesBudget,
        itemsSpecialitiesPaid: tmpSpecialitiesPaid,
      });

    }
    setRender(!render);
    setIsLoading(false);
  }

  const showFaculties = () => {
    return values.studyPay && values.studyForm;
  }
  const showSpecialities = () => {
    return values.faculty;
  }
  const showAllowForPaid = () => {
    return values.studyPay === 'budget' && values.specialityGroup?.data?.isPaid === 1;
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Controls.Select
            fullWidth
            name='studyForm'
            label={"Форма обучения*"}
            value={values.studyForm}
            options={itemsStudyForm}
            onChange={(event) => {
              values.specialityGroup = '';
              handleInputChange(event);
            }}
            error={errors.studyForm}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controls.Select
            fullWidth
            name='studyPay'
            label={"Вид обучения*"}
            value={values.studyPay}
            options={itemsStudyPay}
            onChange={(event) => {
              values.specialityGroup = '';
              handleInputChange(event);
            }}
            error={errors.studyPay}
          />
        </Grid>
        {showFaculties() ?
          <Grid item md={12} xs={12}>
            <Controls.SelectFaculty
              fullWidth
              name='faculty'
              label={"Факультет*"}
              value={values.faculty}
              options={itemsFaculty}
              onChange={(event) => {
                values.specialityGroup = '';
                handleInputChange(event);
              }}
              error={errors.faculty}
            />
          </Grid>
          : null
        }
        {showSpecialities() ?
          <Grid item md={12} xs={12}>
            <Controls.SelectSpecialityGroup
              fullWidth
              name='specialityGroup'
              label={"Специальность(группа специальностей)*"}
              value={values.specialityGroup}
              options={itemsSpecialityGroup}
              onChange={handleInputChange}
              error={errors.specialityGroup}
            />
          </Grid>
          : null
        }
        {showAllowForPaid() ?
          <Grid item md={12} xs={12}>
            <Controls.RadioGroup
              fullWidth
              name="allowForPaid"
              label={<Typography color={'textPrimary'}>В случае, если не буду зачислен на обучение за счет
                средств республиканского бюджета:*</Typography>}
              value={values.allowForPaid}
              onChange={handleInputChange}
              error={errors.allowForPaid}
              items={itemsAllowForPaid}
            />
          </Grid>
          : null
        }
        {values.specialityGroup && (showItemsSpecialitiesBudgetListPriority() || showItemsSpecialitiesPaidListPriority()) ?
          <>
            <Grid item md={12} xs={12}>
              <Typography variant="body1" component="p">
                Перетаскивая элементы списка, разместите специальности в порядке приоритетности в выбранной
                группе
                специальностей (сверху наивысший приоритет).
              </Typography>
            </Grid>

            {showItemsSpecialitiesBudgetListPriority() ?
              <Grid item md={6} xs={12}>
                <Card variant="outlined">
                  <CardHeader subheader="Приоритеты: За счет средств бюджета"/>
                  <ListDnD
                    array={trainingState.itemsSpecialitiesBudget}
                    setState={(result) => {
                      setTrainingState({
                        ...trainingState,
                        itemsSpecialitiesBudget: result,
                      });
                    }}
                  />
                </Card>
              </Grid>
              : null
            }
            {showItemsSpecialitiesPaidListPriority() ?
              <Grid item md={6} xs={12}>
                <Card variant="outlined">
                  <CardHeader subheader="Приоритеты: На платной основе"/>
                  <ListDnD
                    array={trainingState.itemsSpecialitiesPaid}
                    setState={(result) => {
                      setTrainingState({
                        ...trainingState,
                        itemsSpecialitiesPaid: result,
                      });
                    }}
                  />
                </Card>
              </Grid>
              : null
            }
          </>
          : null
        }
        <Grid item container xs={12} justifyContent={"space-between"}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              history.push("/statement")
            }}
            disabled={isSending}
          >
            Назад
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading||isSending}
          >
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
};

export default PedagogicalSpecialties;