import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import {getMyFaculties, getOrganizationContact} from "../../store/actions/contacts";
import {
  Grid, Container, Link, Typography, Accordion, AccordionSummary, AccordionDetails
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  FACULTY_ABSTRACT_INFO,
  FACULTY_DECAN_CONTACT_EMAIL,
  FACULTY_DECAN_CONTACT_PHONE,
  // FACULTY_DECAN_FIO_FULL,
  // FACULTY_DECAN_FIO_SHORT,
  FACULTY_DECANAT_CONTACT_EMAIL,
  FACULTY_DECANAT_CONTACT_PHONE,
  FACULTY_FIRST_CONTACT_EMAIL,
  FACULTY_FIRST_CONTACT_FIO,
  FACULTY_FIRST_CONTACT_MOBILE,
  FACULTY_FIRST_CONTACT_PHONE,
  FACULTY_MESSENGER_SIGNAL,
  FACULTY_MESSENGER_TELEGRAM,
  FACULTY_MESSENGER_VIBER,
  FACULTY_MESSENGER_WHATSAPP,
  FACULTY_SOCIAL_FB,
  FACULTY_SOCIAL_INSTAGRAM,
  FACULTY_SOCIAL_TWITTER,
  FACULTY_SOCIAL_VK,
  FACULTY_WEBSITE
} from "../../services/tagTypes";

const useStyles = makeStyles(() => ({}));

function Contacts() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const faculties = useSelector(state => state.contactsReducer.faculties);
  const organization = useSelector(state => state.contactsReducer.organization);
  React.useEffect(() => {
    dispatch(getMyFaculties());
    dispatch(getOrganizationContact());
  }, [dispatch]);

  const findData = (item, tag) => {
    let entry = item.find(data => data?.typeTitle === tag.trim());
    if (entry) {
      return entry.value
    }
    return null
  }

  const setDataTable = (row) => {
    // let fio_fullDecan = findData(row, FACULTY_DECAN_FIO_FULL)
    let phoneDecan = findData(row, FACULTY_DECAN_CONTACT_PHONE)
    let emailDecan = findData(row, FACULTY_DECAN_CONTACT_EMAIL)
    let phoneDecanat = findData(row, FACULTY_DECANAT_CONTACT_PHONE)
    let emailDecanat = findData(row, FACULTY_DECANAT_CONTACT_EMAIL)
    let website = findData(row, FACULTY_WEBSITE)
    let abstractInfo = findData(row, FACULTY_ABSTRACT_INFO)
    let mobileFirstContact = findData(row, FACULTY_FIRST_CONTACT_MOBILE)
    let phoneFirstContact = findData(row, FACULTY_FIRST_CONTACT_PHONE)
    let emailFirstContact = findData(row, FACULTY_FIRST_CONTACT_EMAIL)
    let fioFirstContact = findData(row, FACULTY_FIRST_CONTACT_FIO)
    let messengerWhatsApp = findData(row, FACULTY_MESSENGER_WHATSAPP)
    let messengerTelegram = findData(row, FACULTY_MESSENGER_TELEGRAM)
    let messengerViber = findData(row, FACULTY_MESSENGER_VIBER)
    let messengerSignal = findData(row, FACULTY_MESSENGER_SIGNAL)
    let socialVK = findData(row, FACULTY_SOCIAL_VK)
    let socialFB = findData(row, FACULTY_SOCIAL_FB)
    let socialInstagram = findData(row, FACULTY_SOCIAL_INSTAGRAM)
    let socialTwitter = findData(row, FACULTY_SOCIAL_TWITTER)

    return {
      // fio_fullDecan: {
      //   label: 'ФИО декана:',
      //   value: fio_fullDecan?.value,
      //   visible: fio_fullDecan?.visible
      // },
      phoneDecan: {
        label: 'Телефон:',
        array: phoneDecan?.value.split(/\s*;\s*/),
        value: phoneDecan?.value,
        visible: phoneDecan?.visible
      },
      emailDecan: {
        label: 'Почта:',
        array: emailDecan?.value.split(/\s*;\s*/),
        value: emailDecan?.value,
        visible: emailDecan?.visible
      },
      phoneDecanat: {
        label: 'Телефон деканата:',
        array: phoneDecanat?.value.split(/\s*;\s*/),
        value: phoneDecanat?.value,
        visible: phoneDecanat?.visible
      },
      emailDecanat: {
        label: 'Почта деканата:',
        array: emailDecanat?.value.split(/\s*;\s*/),
        value: emailDecanat?.value,
        visible: emailDecanat?.visible
      },
      abstractInfo: {
        label: 'Общая информация:',
        array: abstractInfo?.value.split(/\s*;\s*/),
        value: abstractInfo?.value,
        visible: abstractInfo?.visible
      },
      fioFirstContact: {
        label: 'ФИО:',
        value: fioFirstContact?.value,
        visible: fioFirstContact?.visible
      },
      mobileFirstContact: {
        label: 'Мобильный:',
        array: mobileFirstContact?.value.split(/\s*;\s*/),
        value: mobileFirstContact?.value,
        visible: mobileFirstContact?.visible
      },
      phoneFirstContact: {
        label: 'Телефон:',
        array: phoneFirstContact?.value.split(/\s*;\s*/),
        value: phoneFirstContact?.value,
        visible: phoneFirstContact?.visible
      },
      emailFirstContact: {
        label: 'Email:',
        array: emailFirstContact?.value.split(/\s*;\s*/),
        value: emailFirstContact?.value,
        visible: emailFirstContact?.visible
      },
      website: {
        link: website?.value,
        label: 'Сайт:',
        array: website?.value.split(/\s*;\s*/),
        value: website?.value,
        visible: website?.visible
      },
      messengerWhatsApp: {
        label: 'WhatsApp:',
        array: messengerWhatsApp?.value.split(/\s*;\s*/),
        value: messengerWhatsApp?.value,
        visible: messengerWhatsApp?.visible
      },
      messengerTelegram: {
        label: 'Telegram:',
        array: messengerTelegram?.value.split(/\s*;\s*/),
        value: messengerTelegram?.value,
        visible: messengerTelegram?.visible
      },
      messengerViber: {
        label: 'Viber:',
        array: messengerViber?.value.split(/\s*;\s*/),
        value: messengerViber?.value,
        visible: messengerViber?.visible
      },
      messengerSignal: {
        label: 'Signal:',
        array: messengerSignal?.value.split(/\s*;\s*/),
        value: messengerSignal?.value,
        visible: messengerSignal?.visible
      },
      socialVK: {
        link: socialVK?.value,
        label: 'VK:',
        array: socialVK?.value.split(/\s*;\s*/),
        value: socialVK?.value,
        visible: socialVK?.visible
      },
      socialFB: {
        link: socialFB?.value,
        label: 'Facebook:',
        array: socialFB?.value.split(/\s*;\s*/),
        value: socialFB?.value,
        visible: socialFB?.visible
      },
      socialInstagram: {
        link: socialInstagram?.value,
        label: 'Instagram:',
        array: socialInstagram?.value.split(/\s*;\s*/),
        value: socialInstagram?.value,
        visible: socialInstagram?.visible
      },
      socialTwitter: {
        link: socialTwitter?.value,
        label: 'Twitter:',
        array: socialTwitter?.value.split(/\s*;\s*/),
        value: socialTwitter?.value,
        visible: socialTwitter?.visible
      },
    }
  }

  // const MyTableRow = (index, {label, value, link, array}) => {
  //   return (
  //     <TableRow key={index}>
  //       <TableCell>
  //         {label}
  //       </TableCell>
  //       <TableCell>
  //         {
  //           link !== undefined
  //             ?
  //             <Link color="inherit" target="_blank" rel="noopener" href={link}>
  //               {link}
  //             </Link>
  //             : array !== undefined
  //             ?
  //             array.map((item, index) =>
  //               <Typography key={index} variant={"body2"}>{item}</Typography>
  //             )
  //             : value
  //         }
  //       </TableCell>
  //     </TableRow>
  //   )
  // }

  const MyTableRow = (index, {label, value, link, array}) => {
    return (
      <Grid container spacing={1} key={index}>
        <Grid item xs={12} sm={4}>
          <strong>{label}</strong>
        </Grid>
        <Grid item xs={12} sm={8}>
          {
            link !== undefined
              ?
              <Link color="inherit" target="_blank" rel="noopener" href={link}>
                {link}
              </Link>
              : array !== undefined
              ?
              array.map((item, index) =>
                <Typography key={index} variant={"body2"}>{item}</Typography>
              )
              : value
          }
        </Grid>
      </Grid>
    )
  }


  // const MyTable = ({entry}) => (
  //   <Table className={classes.table} size="small" aria-label="a dense table">
  //     <TableBody>
  //       {
  //         Object.values(entry).map((row, index) => {
  //           if (row.value && (row.visible === true)) {
  //             return MyTableRow(index, {...row})
  //           }
  //           return null
  //         })
  //       }
  //     </TableBody>
  //   </Table>
  // )
  const MyTable = ({entry}) => (
    <Container  className={classes.table} >
        {
          Object.values(entry).map((row, index) => {
            if (row.value && (row.visible === true)) {
              return MyTableRow(index, {...row})
            }
            return null
          })
        }
    </Container >
  )

  let entryTableOrganization = setDataTable(organization);
  // console.log('entryTableOrganization', entryTableOrganization)

  return (
    <Container component="div" maxWidth="md">
      <Grid container spacing={3} style={{marginTop: '8px'}}>
        {/*{console.log('faculties', faculties)}*/}
        {/*{console.log('organization', organization)}*/}
        {
          organization.length !== 0 && Object.values(entryTableOrganization).find(data => data.visible === true)
            ? <Grid item xs={12} >
              <Accordion defaultExpanded >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Приемная комиссия</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MyTable entry={entryTableOrganization}/>
                </AccordionDetails>
              </Accordion>
            </Grid>
            : null
        }
        {
          faculties.map((row) => {
              let entryTable = setDataTable(row.facultyData)
              return (
                row.facultyData.length !== 0 && Object.values(entryTable).find(data => data.visible === true)
                  ?
                  <Grid key={row.faculty.id} item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>{row.faculty?.data?.nameFull}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MyTable entry={entryTable}/>
                      </AccordionDetails>
                    </Accordion>
                    {/*<Card>*/}
                    {/*  <CardHeader title={row.data?.nameFull}/>*/}
                    {/*  <CardContent>*/}
                    {/*    <MyTable entry={entryTable}/>*/}
                    {/*  </CardContent>*/}
                    {/*</Card>*/}
                  </Grid>
                  : null
              )
            }
          )
        }
      </Grid>
    </Container>
  );
}

export default Contacts;
