import {GET_PROFILE_INFO_SUCCESS, GET_PROFILE_INFO_FAIL} from "./actionTypes";
import instance from "../../customAxios";

const API_URL = "/";

export function USER_DATA_GET(params) {
  return async () => {
    try {
      // console.log(data)
      const response = await instance.get(
        API_URL + `api/ext/users/data?${params}`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      console.error('☠️error USER_DATA_GET actions profile: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function PROFILE_USER_DATA_GET(){
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL +
        "api/ext/users/data?" +
        "type[]=USER_SURNAME&" +
        "type[]=USER_FIRST_NAME&" +
        "type[]=USER_PATRONYMIC&" +
        "type[]=USER_GENDER&" +
        "type[]=USER_BORNDATE&" +
        "type[]=USER_EMAIL&" +
        "type[]=USER_PHONE_MOBILE&"+
        "type[]=USER_EDUCATION_INSTITUTIONS_NAME&" +
        "type[]=USER_EDUCATION_INSTITUTIONS_ROOM&" +
        "type[]=USER_EDUCATION_INSTITUTIONS_DATE&" +
        "type[]=USER_FULL_ADDRESS&" +
        "type[]=USER_TERMS"
      );
      dispatch(getProfileInfoSuccess(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response);
    }

  }
}
export function PROFILE_USER_MANYDATA_POST(data){
  return async (dispatch) => {
    try {
      const response = await instance.post(
        API_URL +
        "api/ext/users/data",
        data
      );
      dispatch(getProfileInfoSuccess(response.data));
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}

export function PROFILE_USER_DATA_DELETE(id){
  return async () => {
    try {
      const response = await instance.delete(
        API_URL +
        `api/ext/users/data/${id}`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}

export function getProfileInfoSuccess (payload) {
  return {
    type: GET_PROFILE_INFO_SUCCESS,
    payload
  }
}

export function getProfileInfoFail () {
  return {
    type: GET_PROFILE_INFO_FAIL
  }
}
