import {
    CHAT_GET_CHAT_LIST_FAILED,
    CHAT_GET_CHAT_LIST_LOADING,
    CHAT_GET_CHAT_LIST_RELOADING,
    CHAT_GET_CHAT_LIST_SUCCESSFUL,
    CHAT_GET_MESSAGE_LIST_FAILED,
    CHAT_GET_MESSAGE_LIST_LOADING,
    CHAT_GET_MESSAGE_LIST_RELOADING,
    CHAT_GET_MESSAGE_LIST_SUCCESSFUL,
    CHAT_SET_NEW_MESSAGE_SENDING, CHAT_STATUS_LAST_EVENT, CHAT_STATUS_VISIBLE

} from "./typesChat";

const initialState = {
    chatList: [],
    chatListLoading: false,
    chatListReloading: false,
    messageList: [],
    messageListLoading: false,
    messageListReloading: false,
    newMessageSending: false,

    visibleChat: false,
    newMessages: 0,
    statusLastEvent: "NOT_STATUS"
};


export default function chatReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case CHAT_GET_CHAT_LIST_SUCCESSFUL:
            return {...state, chatList: payload, newMessages: payload.reduce((sum = 0, item) => { return (sum?.newMessages !== undefined ? sum?.newMessages : sum) + item.newMessages; })};
        case CHAT_GET_CHAT_LIST_FAILED:
            return {...state, chatList: [], newMessages: 0};
        case CHAT_GET_CHAT_LIST_LOADING:
            return {...state, chatListLoading: !(state.chatListLoading)};
        case CHAT_GET_CHAT_LIST_RELOADING:
            return {...state, chatListReloading: !(state.chatListReloading)};
        case CHAT_GET_MESSAGE_LIST_SUCCESSFUL:
            return {...state, messageList: payload};
        case CHAT_GET_MESSAGE_LIST_FAILED:
            return {...state, messageList: []};
        case CHAT_GET_MESSAGE_LIST_LOADING:
            return {...state, messageListLoading: !(state.messageListLoading)};
        case CHAT_GET_MESSAGE_LIST_RELOADING:
            return {...state, messageListReloading: !(state.messageListReloading)};
        case CHAT_SET_NEW_MESSAGE_SENDING:
            return {...state, newMessageSending: !(state.newMessageSending)};
        case CHAT_STATUS_VISIBLE:
            return {...state, visibleChat: !(state.visibleChat)};
        case CHAT_STATUS_LAST_EVENT:
            return {...state, statusLastEvent: payload};
        default:
            return state;
    }
}
