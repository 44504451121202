import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";

export default function DialogView(props) {
    const {files} = useSelector(state => state.fileLoadingReducer);
    const classes = useStyles();
    const {
        open: PropsOpen,
        onClose: PropsOnCloseDialog,
        item: PropsItem,
        tag: TagName
    } = props;

    let currentFiles = [];
    Object.keys(files).filter(item => {
        if(item === TagName){
            currentFiles = files[item] ?? [];
        }
    });

    function onClose() {
        PropsOnCloseDialog && PropsOnCloseDialog()
    }

    return (
        <Dialog
            open={PropsOpen}
            keepMounted
            maxWidth={"sm"}
            fullWidth
            onClose={onClose}
        >
            <DialogTitle>Просмотр файла</DialogTitle>
            <DialogContent>
                <Box p={1}>
                    <img className={classes.fileComponentViewImg}
                         src={`/api/ext/files/${currentFiles.find(item => item.id === PropsItem)['value']['value']}`}
                         alt="file"/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    )
}
DialogView.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    item: PropTypes.any,
    tag: PropTypes.string.isRequired
};
const useStyles = makeStyles(() => ({
    fileComponentViewImg: {
        width: '100%',
        height: 'auto',
        borderRadius: '0.5em'
    }
}));
