import {
  LOGOUT,
  GET_STATEMENT_STATUS_FORMS_APPLICATION_SUCCESS,
  GET_STATEMENT_STATUS_FORMS_APPLICATION_FAIL,
  GET_APPLICATIONS_STATEMENT_ACTIVE_SUCCESS,
  GET_APPLICATIONS_STATEMENT_ACTIVE_FAIL,
  CREATE_APPLICATION_STATEMENT_ACTIVE_SUCCESS,
  CREATE_APPLICATION_STATEMENT_ACTIVE_FAIL,
  GET_SELECTED_FACULTY_STATEMENT,
  GET_SELECTED_SPECIALITY_GROUP_STATEMENT,
  GET_SELECTED_STUDY_FORM_STATEMENT,
  SET_STATUS_FORMS_APPLICATION_STATEMENT,
  SET_TYPE_COMPETITION_STATEMENT,
  SET_CONTRACT_FILE_STATEMENT,
  SET_STATEMENT_STATUS,
  GET_SELECTED_STUDY_PAY_STATEMENT,
  GET_SELECTED_SPECIALITY_BUDGET_STATEMENT,
  GET_SELECTED_SPECIALITY_PAID_STATEMENT,
  SET_DATA_STATUS_MESSAGES_ASU,
  SET_TEMPLATE_STATUS_MESSAGES_ASU, GET_SELECTED_TYPE_TRAINING_STATEMENT
} from "../actions/actionTypes";

const initialState = {
  applicationStatementActive: {},
  typeCompetition: {},
  contractFile: {},
  statementStatus: 'NOT_CONFIRMED',
  statementStatusFormsApplication: {
    1: {"status": "NOT_CONFIRMED","coment":""},
    2: {"status": "NOT_CONFIRMED","coment":""},
    3: {"status": "NOT_CONFIRMED","coment":""},
    4: {"status": "NOT_CONFIRMED","coment":""},
    5: {"status": "NOT_CONFIRMED","coment":""},
    6: {"status": "NOT_CONFIRMED","coment":""},
    7: {"status": "NOT_CONFIRMED","coment":""},
    8: {"status": "NOT_CONFIRMED","coment":""}
  },
  selectedStudyFormStatement: {},
  selectedStudyPayStatement: {},
  selectedFacultyStatement: {},
  selectedSpecialityGroupStatement: {},
  selectedArraySpecialitiesBudgetStatement: [],
  selectedArraySpecialitiesPaidStatement: [],
  selectedSpecialityTargetRecruitmentStatement: {},
  templateStatusMessagesASU: {},
  dataStatusMessagesASU: {},
};

export default function statementReducer(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case CREATE_APPLICATION_STATEMENT_ACTIVE_SUCCESS:
      return {...state, applicationStatementActive: payload};
    case CREATE_APPLICATION_STATEMENT_ACTIVE_FAIL:
      return {...state, applicationStatementActive: {}};
    case GET_APPLICATIONS_STATEMENT_ACTIVE_SUCCESS:
      return {...state, applicationStatementActive: payload};
    case GET_APPLICATIONS_STATEMENT_ACTIVE_FAIL:
      return {...state, applicationStatementActive: {}};
    case GET_STATEMENT_STATUS_FORMS_APPLICATION_SUCCESS:
      return {...state, statementStatusFormsApplication: payload};
    case GET_STATEMENT_STATUS_FORMS_APPLICATION_FAIL:
      return {...state, statementStatusFormsApplication: {}};
    case GET_SELECTED_TYPE_TRAINING_STATEMENT:
      return {...state, selectedTypeTrainingStatement: payload};
    case GET_SELECTED_STUDY_FORM_STATEMENT:
      return {...state, selectedStudyFormStatement: payload};
    case GET_SELECTED_STUDY_PAY_STATEMENT:
      return {...state, selectedStudyPayStatement: payload};
    case GET_SELECTED_SPECIALITY_BUDGET_STATEMENT:
      return {...state, selectedArraySpecialitiesBudgetStatement: payload};
    case GET_SELECTED_SPECIALITY_PAID_STATEMENT:
      return {...state, selectedArraySpecialitiesPaidStatement: payload};
    case GET_SELECTED_FACULTY_STATEMENT:
      return {...state, selectedFacultyStatement: payload};
    case GET_SELECTED_SPECIALITY_GROUP_STATEMENT:
      return {...state, selectedSpecialityGroupStatement: payload};
    case SET_STATUS_FORMS_APPLICATION_STATEMENT:
      return {...state, statementStatusFormsApplication: payload};
    case SET_STATEMENT_STATUS:
      return {...state, statementStatus: payload};
    case SET_TYPE_COMPETITION_STATEMENT:
      return {...state, typeCompetition: payload};
    case SET_CONTRACT_FILE_STATEMENT:
      return {...state, contractFile: payload};
    case SET_DATA_STATUS_MESSAGES_ASU:
      return {...state, dataStatusMessagesASU: payload};
    case SET_TEMPLATE_STATUS_MESSAGES_ASU:
      return {...state, templateStatusMessagesASU: payload};
    case LOGOUT:
      return {
        ...state,
        applicationStatementActive: {},
        typeCompetition: {},
        contractFile: {},
        selectedStudyFormStatement: {},
        selectedStudyPayStatement: {},
        selectedFacultyStatement: {},
        selectedSpecialityGroupStatement: {},
        selectedArraySpecialitiesBudgetStatement: [],
        selectedArraySpecialitiesPaidStatement: [],
        selectedSpecialityTargetRecruitmentStatement: {},
        statementStatusFormsApplication: {
          1: {"status": "NOT_CONFIRMED","coment":""},
          2: {"status": "NOT_CONFIRMED","coment":""},
          3: {"status": "NOT_CONFIRMED","coment":""},
          4: {"status": "NOT_CONFIRMED","coment":""},
          5: {"status": "NOT_CONFIRMED","coment":""},
          6: {"status": "NOT_CONFIRMED","coment":""},
          7: {"status": "NOT_CONFIRMED","coment":""},
          8: {"status": "NOT_CONFIRMED","coment":""}
        },
        statementStatus: 'NOT_CONFIRMED',
        templateStatusMessagesASU: {},
        dataStatusMessagesASU: {},
      };
    default:
      return state;
  }
}
