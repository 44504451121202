import axios from "axios";
import {logout, refreshTokens} from "./store/actions/auth";

const instance = axios.create();


const AUTH_TOKEN = `Bearer ` + localStorage.getItem('access')|| null;
instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export function installInterceptors (store) {
  // console.log('start interceptors');
  instance.interceptors.response.use(
    resp => resp,

    async error => {
      /* refresh token and retry request once more on 401
         else log user out
      */
      const {config: originalReq, response} = error
      // console.log('start interceptors error');
      // console.dir(error);
      // skip refresh token request, retry attempts to avoid infinite loops
      if (originalReq.url !== 'api/refresh' && !originalReq.isRetryAttempt && response && response.status === 401) {
        try {
          // console.log('start interceptors error prev dispatch refreshTokens in IF true');
          await  store.dispatch(refreshTokens())
          // console.log('start interceptors error after dispatch refreshTokens');
          originalReq.isRetryAttempt = true
          originalReq.headers['Authorization'] = instance.defaults.headers.common['Authorization']
          return await instance.request(originalReq)
        } catch (e) {
          // log user out if fail to refresh (due to expired or missing token) or persistent 401 errors from original requests
          if (e.message === 'user has not logged in' || (e.response && e.response.status === 401)) {
            console.log('Catch interceptors error in IF true: ', e.response? e.response: e.name + ': ' + e.message );
            store.dispatch(logout());
          }
          // suppress original error to throw the new one to get new information
          console.log('Catch interceptors error in IF false: ', e)
          store.dispatch(logout());
          // eslint-disable-next-line no-throw-literal
          throw e
        }
      } else {
        console.log('ELSE interceptors error in IF false: ', error)
        // store.dispatch(logout());
        throw error
      }
    }
  )
}

export default instance;
