import {
  Avatar,
  Container, Hidden, Paper, Step, StepContent, StepLabel, Stepper,
  Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Form from "./Form";
import Confirm from "./Confirm";
import {Alert} from "@material-ui/lab";
import {login, registerConfirmNew, registerNew} from "../../../store/actions/auth";
import {useDispatch} from "react-redux";
import {chatAction_createNotification} from "../../../store/Chat/ChatActions";
import LoadingSlider from "../../../components/LoadingSlider/LoadingSlider";
import {useHistory} from "react-router";
import {registrationInit} from "../../../store/System/actionSystem";

let isMount = true;


function useSingUp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState("REGISTER_FORM");
  const [isSending, setSending] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [userToken, setUserToken] = useState(null);

  function onSending() {
    if (!isMount) return;
    if (!isSending) {
      setSending(true);
      setTimeout(() => {
        setSending(false)
      }, 2000);
    }
  }

  function onChangeActiveStep() {
    if (!isMount) return;
    if (activeStep === 'REGISTER_FORM') {
      setActiveStep('CONFIRM_FORM');
    } else if (activeStep === 'CONFIRM_FORM') {
      setActiveStep('CONFIRM_SUCCESSFUL');
    } else {
      setActiveStep('REGISTER_FORM');
    }
  }

  async function onSubmitRegister(data) {
    if (!isMount) return;
    setLoading(true);
    const useData = {
      ...(data?.type && {type: data.type}),
      ...(data?.phone && {phone: data.phone}),
      ...(data?.email && {email: data.email}),
      ...(data?.password && {password: data.password}),
      ...(data?.optionalUserData && {optionalUserData: data.optionalUserData}),
    };
    try {
      const response = await dispatch(registerNew(useData));
      setUserToken(useData);
      if (response?.user.status === "NOT_CONFIRMED") {
        dispatch(chatAction_createNotification({
          message: `
                        ${data?.phone ? `На указанный номер телефона отправлен проверочный код.` : ``}
                        ${data?.email ? `На указанную электронную почту отправлен проверочный код.` : ``}
                    `,
          variant: 'success',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
        onChangeActiveStep();
      } else {
        dispatch(chatAction_createNotification({
          message: `Произошла ошибка при обработке данных.`,
          variant: 'error',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
        console.error(`Critical error: ${JSON.stringify(response)}`);
      }
      setLoading(false);
      onSending();
    } catch (e) {

      if (e?.data?.message === "Credential already use.") {
        dispatch(chatAction_createNotification({
          message: `
                        ${data?.phone ? `Ошибка. Указанный номер уже зарегистрирован в системе.` : ``}
                        ${data?.email ? `Ошибка. Указанная электронная почта уже зарегистрирована в системе.` : ``}
                    `,
          variant: 'error',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
      } else {
        dispatch(chatAction_createNotification({
          message: `Произошла ошибка при обработке данных.`,
          variant: 'error',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
        console.error(`Critical error: ${JSON.stringify(e)}`);
      }
      setLoading(false);
      onSending();
    }
  }

  async function onReloadRegister() {
    if (!isMount) return;
    if (userToken === null) return;
    setLoading(true);
    try {
      const response = await dispatch(registerNew(userToken));
      if (response?.user.status === "NOT_CONFIRMED") {
        dispatch(chatAction_createNotification({
          message: `
                        ${userToken?.phone ? `На указанный номер телефона отправлен проверочный код.` : ``}
                        ${userToken?.email ? `На указанную электронную почту отправлен проверочный код.` : ``}
                    `,
          variant: 'success',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
      } else {
        dispatch(chatAction_createNotification({
          message: `Произошла ошибка при обработке данных.`,
          variant: 'error',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
        console.error(`Critical error: ${JSON.stringify(response)}`);
      }
      setLoading(false);
      onSending();
    } catch (e) {
      if (e?.data?.message === "Credential already use.") {
        dispatch(chatAction_createNotification({
          message: `
                        ${userToken?.phone ? `Ошибка. Указанный номер уже зарегистрирован в системе.` : ``}
                        ${userToken?.email ? `Ошибка. Указанная электронная почта уже зарегистрирована в системе.` : ``}
                    `,
          variant: 'error',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
      } else {
        dispatch(chatAction_createNotification({
          message: `Произошла ошибка при обработке данных.`,
          variant: 'error',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
        console.error(`Critical error: ${JSON.stringify(e.response)}`);
      }
      setLoading(false);
      onSending();
    }
  }

  async function onSubmitConfirm(data) {
    if (!isMount) return;
    setLoading(true);
    const useData = {
      ...(userToken?.type && {type: userToken.type}),
      ...(userToken?.phone && {phone: userToken.phone}),
      ...(userToken?.email && {email: userToken.email}),
      ...(data?.tokenConfirm && {token: data.tokenConfirm}),
    };
    try {
      const response = await dispatch(registerConfirmNew(useData));
      if (response?.error?.type === "Token not found") {
        dispatch(chatAction_createNotification({
          message: `Ошибка. Указанный неверный проверочный код.`,
          variant: 'error',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
      } else if (response?.message === "SUCCESS") {
        dispatch(chatAction_createNotification({
          message: `Регистрация прошла успешно.`,
          variant: 'success',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
        onChangeActiveStep();
        await onLogin();
      } else {
        dispatch(chatAction_createNotification({
          message: `Произошла ошибка при обработке данных.`,
          variant: 'error',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
        console.error(`Critical error: ${JSON.stringify(response)}`);
      }
      if (isMount) setLoading(false);
      if (isMount) onSending();
    } catch (e) {
      console.log(e)
      if (e?.data?.message === "Token not found") {
        dispatch(chatAction_createNotification({
          message: `${userToken?.phone ? `Ошибка. Указанный неверный проверочный код.` : ``}`,
          variant: 'error',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
      } else {
        dispatch(chatAction_createNotification({
          message: `Произошла ошибка при обработке данных.`,
          variant: 'error',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
        console.error(`Critical error: ${JSON.stringify(e.response)}`);
      }
      setLoading(false);
      onSending();
    }

  }

  async function onLogin() {
    if (!isMount) return;
    setLoading(true);
    try {
      const response = await dispatch(login(userToken?.phone ? userToken.phone : userToken?.email, userToken?.password));
      if (response?.status === true) {
        await dispatch(registrationInit());
        history.push("/profile");
      }
      if (isMount) setLoading(false);
    } catch (e) {
      console.error(`Critical error: ${JSON.stringify(e.response)}`);
      if (isMount) setLoading(false);
    }
  }

  useEffect(() => {
    return () => {
      isMount = false;
    }
  }, [])
  return {
    status: {sending: isSending, loading: isLoading},
    activeStep,
    userToken,
    onChangeActiveStep,
    onSubmitRegister,
    onSubmitConfirm,
    onReloadRegister
  };

}


export function SignUp() {
  const classes = useStyles();
  const {
    status,
    activeStep,
    userToken,
    onSubmitRegister,
    onSubmitConfirm,
    onReloadRegister
  } = useSingUp();

  return (
    <Container component="div" maxWidth="sm" className={classes.root}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Зарегистрироваться
        </Typography>
        <Typography component="p" variant="body2">
          (в том числе для участия в Многопрофильной университетской олимпиаде)
        </Typography>
        <Hidden xsDown>
          <Stepper className={classes.stepperRoot}>
            <Step active={activeStep === 'REGISTER_FORM'} completed={activeStep !== 'REGISTER_FORM'}>
              <StepLabel>Регистрация</StepLabel>
            </Step>
            <Step active={activeStep === 'CONFIRM_FORM'}
                  completed={activeStep !== 'CONFIRM_FORM' && activeStep !== 'REGISTER_FORM'}>>
              <StepLabel>Подтверждение</StepLabel>
            </Step>
          </Stepper>
        </Hidden>
        <Hidden smUp>
          <Stepper orientation="vertical" className={classes.stepperRoot}>
            <Step active={activeStep === 'REGISTER_FORM'} completed={activeStep !== 'REGISTER_FORM'}>
              <StepLabel>Форма регистрации</StepLabel>
              <StepContent/>
            </Step>
            <Step active={activeStep === 'CONFIRM_FORM'}
                  completed={activeStep !== 'CONFIRM_FORM' && activeStep !== 'REGISTER_FORM'}>
              <StepLabel>Подтверждение регистрации</StepLabel>
              <StepContent/>
            </Step>
          </Stepper>
        </Hidden>
        {activeStep === "REGISTER_FORM" ?
          <Form onSubmit={onSubmitRegister} sending={status?.sending || status.loading}/> : null}
        {activeStep === "CONFIRM_FORM" ?
          <Confirm data={userToken} onReloadMessage={onReloadRegister} onSubmit={onSubmitConfirm}
                   sending={status?.sending || status.loading}/> : null}
        {activeStep === "CONFIRM_SUCCESSFUL" ? <Alert severity="success">Регистрация завершена</Alert> : null}
        <LoadingSlider open={status?.loading}/>
      </Paper>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  stepperRoot: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  formControl: {},
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

