import KeyboardCapslockIcon from "@material-ui/icons/KeyboardCapslock";

const iconFontSize = {fontSize: '1.4em'};
const keysBoard = {
    "language": {
        "BEL": "БЕЛ",
    },
    "key": {
        "BEL": [
            {
                "s": 3,
                "t": "ў"
            },
            {
                "s": 3,
                "t": "і"
            },
            {
                "s": 3,
                "t": "'"
            },
            {
                "s": 3,
                "n": "Caps Lock",
                "t": <KeyboardCapslockIcon style={iconFontSize}/>,
                "f": "caps"
            }
        ]
    }
};
export default keysBoard;
