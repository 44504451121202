import classes from './ChatMessages.module.css';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {chatAction_getMessages} from "../../../store/Chat/ChatActions";
import Undefined from "./Undefined";
import Loading from "./Loading";
import Messages from "./Messages";
import Form from "./Form";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {IconButton, Tooltip} from "@material-ui/core";

function useChatMessages({current = undefined}) {
  const [updated, setUpdated] = useState(false);
  const [lastCurrent, setLastCurrent] = useState(undefined);

  useEffect(() => {
    if (current !== lastCurrent) {
      setLastCurrent(current);
      setUpdated(true);
    } else {
      setUpdated(false);
    }
  }, [current]);

  return {updated, setUpdated};

}

function useChatMessagesDescription() {
  //const dispatch = useDispatch();
  const [view, setView] = useState(false);
  const [current, setCurrent] = useState(null);

  //const {chatList: chats} = useSelector(state => state.chatReducer);
  //const currentChat = chats?.find(item => item.channelId === current);

  useEffect(() => {
    if (view === false) {
      setView(true);
    }
    //onView();
  }, [current]);
  /*
      function onView() {
          if (view && current !== null) {
              if (currentChat?.title?.description !== undefined) {
                  dispatch(chatAction_createNotification({
                      message: <Grid container justifyContent={"center"}>
                          <Grid item xs={12}>
                              <Typography variant="body1">{currentChat?.title?.value}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                              <Typography variant="caption">{currentChat?.title?.description}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                              <Box display="flex" flexDirection={"row"} alignItems={"flex-end"} justifyContent="flex-end">
                                  <Button variant="text">Больше не показывать</Button>
                              </Box>
                          </Grid>
                      </Grid>,
                      variant: 'info',
                      autoHide: false
                  }));
                  setView(false);
              }
          }
      }

   */

  return {setCurrent};

}

export default function ChatMessages(props) {
  const dispatch = useDispatch();
  const {current: PropsCurrent} = props;
  const {
    chatList: chats,
    messageList: messages,
    messageListLoading: messages_loading,
    //statusLastEvent: chats_last_event_status
  } = useSelector(state => state.chatReducer);
  const currentChat = chats?.find(item => item.id === PropsCurrent);
  const {updated, setUpdated} = useChatMessages({current: PropsCurrent});

  useEffect(() => {
    let isMount = true;

    if (isMount) {
      if (currentChat !== undefined && PropsCurrent !== null) {
        if (updated) {
          dispatch(chatAction_getMessages({
            chatId: currentChat?.id,
            isReloading: false,
            isRead: currentChat.newMessages !== 0,
            limit: {
              offset: 0,
              limit: 50
            }
          }));
          setUpdated(false);
        } else {
          if (currentChat.newMessages !== 0) {
            dispatch(chatAction_getMessages({
              chatId: currentChat?.id,
              isReloading: true,
              isRead: true,
              limit: {
                offset: 0,
                limit: 50
              }
            }));
          }

        }
      }
    }
    return () => {
      isMount = false;
    }
  }, [currentChat, PropsCurrent, updated]);

  const {setCurrent} = useChatMessagesDescription({current: PropsCurrent})
  useEffect(() => {
    setCurrent(PropsCurrent);
  }, [PropsCurrent])

  if (messages_loading) return <Loading/>;
  if (PropsCurrent === null) return <Undefined/>;

  return (
    <div className={classes['message__box']}>
      <div className={`${classes['message__box__title']}`}>
        <div className={classes['message__box__title_text']}>
          {currentChat?.title?.value}
          {currentChat?.title?.description ? <Tooltip title={currentChat?.title?.description}>
            <IconButton color="secondary" size={"small"} onClick={() => console.log(1)}>
              <InfoOutlinedIcon fontSize={"small"} style={{height: '14px', width: '14px'}}/>
            </IconButton>
          </Tooltip> : null}
        </div>
      </div>
      <Messages messages={messages} current={PropsCurrent}/>
      <Form current={currentChat}/>
    </div>
  )
}
