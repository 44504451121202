import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import ChatList from "./chatList";
import ChatMessages from "./chatMessages";
import {AppBar, Dialog, IconButton, Toolbar, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {chatAction_getChats, chatAction_toggleChatVisible} from "../../store/Chat/ChatActions";

let isMount = true;

export function useChatForm() {
    const dispatch = useDispatch();

    function onVisibleChat() {
        dispatch(chatAction_toggleChatVisible());
        dispatch(chatAction_getChats({isReloading: false}));
    }

    return {onVisibleChat};
}

export default function ChatForm() {
    const styles = useStyles();
    const dispatch = useDispatch();
    const {visibleChat: open} = useSelector(state => state.chatReducer);
    const [current, setCurrent] = useState(null);

    function onClose() {
        dispatch(chatAction_toggleChatVisible());
    }

    function onMenu() {
        setCurrent(null);
    }

    function setCurrentHandler(current = null) {
        setCurrent(current)
    }

    useEffect(() => {
        if (isMount) {
            async function getData() {
                await dispatch(chatAction_getChats({isReloading: false}));
            }

            getData();
        }
        return () => {
            isMount = false;
        }
    }, [])
    if (!isMount || !open) return null;

    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <AppBar className={styles.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onMenu}
                                className={`${styles.menuChatList} ${current === null ? styles.arrowHide : styles.arrowShow} `}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <Typography variant="h6" className={styles.title}>
                        Чат
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={styles.chatBox}>
                <div className={`${styles.chatBoxList}  ${current !== null ? styles.hide : ''}`}>
                    <ChatList onClick={setCurrentHandler} current={current}/>
                </div>
                <div className={`${styles.chatBoxMessages} ${current === null ? styles.hide : ''}`}>
                    <ChatMessages onClick={setCurrentHandler} current={current}/>
                </div>
            </div>
        </Dialog>

    )
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    chatBox: {
        display: 'flex',
        maxHeight: `calc(100vh - 64px)`,
        height: `calc(100vh - 64px)`,
        width: '100vw',
        maxWidth: '100vw',
        flexDirection: 'row',
        flexWrap: 'wrap',
        minWidth: '250px'
    },
    chatBoxList: {
        display: 'flex',
        maxHeight: `calc(100vh - 64px)`,
        height: `calc(100vh - 64px)`,
        width: `30%`,
        maxWidth: `30%`,
        borderRight: '1px solid rgba(23,23,23, 0.1)',
        [theme.breakpoints.between('xs', 'sm')]: {
            width: `100%`,
            maxWidth: `100%`,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: `100%`,
            maxWidth: `100%`,
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: `30%`,
            maxWidth: `30%`,
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            width: `30%`,
            maxWidth: `30%`,
        },
        [theme.breakpoints.up('xl')]: {
            width: `30%`,
            maxWidth: `30%`,
        }
    },
    chatBoxMessages: {
        display: 'flex',
        maxHeight: `calc(100vh - 64px)`,
        height: `calc(100vh - 64px)`,
        width: `70%`,
        maxWidth: `70%`,
        [theme.breakpoints.between('xs', 'sm')]: {
            width: `100%`,
            maxWidth: `100%`,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: `100%`,
            maxWidth: `100%`,
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: `70%`,
            maxWidth: `70%`,
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            width: `70%`,
            maxWidth: `70%`,
        },
        [theme.breakpoints.up('xl')]: {
            width: `70%`,
            maxWidth: `70%`,
        }
    },
    menuChatList: {
        [theme.breakpoints.between('xs', 'sm')]: {
            display: 'inherit'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            display: 'inherit'
        },
        [theme.breakpoints.between('md', 'lg')]: {
            display: 'none'
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
    hide: {
        [theme.breakpoints.between('xs', 'sm')]: {
            display: 'none'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            display: 'none'
        },
        [theme.breakpoints.between('md', 'lg')]: {
            display: 'inherit'
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            display: 'inherit'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'inherit'
        }
    },
    arrowHide: {
        display: 'none'
    },
    arrowShow: {
        display: 'inherit'
    }

}));
