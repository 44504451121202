import instance from "../../customAxios";

export function GetOlympiadAuthURL(id){
  return async () => {
    try {
      const response = await instance.get(
        `/api/ext/olympics/${id}/auth`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}