import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import Valid from "../../../../../constants/Valid";
import {Form, useForm} from "../../../../../components/Customs/useForm";
import Controls from "../../../../../components/Customs/Controls/Controls";
import {
  Avatar, Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid, Link,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteContractFileStatement,
  deleteTypeCompetitionStatement,
  getDataStatement,
  setDataStatement, setStatementStatus, setStatusFormsApplicationStatement
} from "../../../../../store/Statement/actionStatement";
import {
  GENERAL_SURNAME_RU,
  GENERAL_FIRST_NAME_RU,
  GENERAL_PATRONYMIC_RU,
  GENERAL_SURNAME_BY,
  GENERAL_FIRST_NAME_BY,
  GENERAL_PATRONYMIC_BY,
  GENERAL_SURNAME_EN,
  GENERAL_FIRST_NAME_EN,
  GENERAL_DOCUMENT_TYPE,
  GENERAL_DOCUMENT_SERIES,
  GENERAL_DOCUMENT_NUMBER,
  GENERAL_IDENTIFICATION_NUMBER,
  GENERAL_DOCUMENT_STATE_AGENCY,
  GENERAL_DOCUMENT_DATE_ISSUE,
  GENERAL_DOCUMENT_DATEISSUE_END,
  GENERAL_BORN_DATE,
  GENERAL_GENDER,
  GENERAL_CITIZENSHIP,
  GENERAL_RESIDENCE_INBY,
  GENERAL_POST_INDEX,
  GENERAL_FULL_ADDRESS,
  GENERAL_PHONE_MOBILE,
  GENERAL_PHONE_HOME,
  GENERAL_NEED_A_HOSTEL,
  GENERAL_EMAIL,
  ANKETA_STATUS_FORMS_APPLICATION,
  ANKETA_STATUS,
  USER_SURNAME,
  USER_FIRST_NAME,
  USER_PATRONYMIC, USER_GENDER, USER_BORNDATE, USER_EMAIL, USER_PHONE_MOBILE, USER_FULL_ADDRESS,
} from "../../../../../services/tagTypes";
import NumberFormat from "react-number-format";
import FileLoadingForm from "../../../../../components/FileLoading";
import KeyBoardMiniForm from "../../../../../components/KeyBoardMini";
import {DialogCustom, useDialog} from "../../../../../components/Customs/useDialog";
import examplePhoto from '../../../../../image/example_photo.jpg';
import {chatAction_createNotification} from "../../../../../store/Chat/ChatActions";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const initialFValues = {
  surnameRu: "",
  nameRu: "",
  patronymicRu: "",
  surnameBy: "",
  nameBy: "",
  patronymicBy: "",
  surnameEn: "",
  nameEn: "",
  documentType: "PASSPORT_RB",
  passportSeries: "",
  passportNumber: "",
  passportNumberPlaceholder: "",
  identificationNumber: "",
  stateAgency: "",
  dateIssue: null,
  validity: null,
  dateBorn: null,
  gender: "",
  citizenship: "",
  residence: false,
  postIndex: "",
  email: "",
  fullAddress: "",
  phoneMobile: "",
  phoneHome: "",
  needAHostel: false,
};

function General() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {profileInfo: userData} = useSelector(state => state.profileReducer);
  const arrayCitizenship = useSelector(state => state.referenceBooksReducer.citizenshipBooksReducer);
  const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  const statementTypeCompetition = useSelector(state => state.statementReducer.typeCompetition);
  const statementContractFile = useSelector(state => state.statementReducer.contractFile);
  const arrayTypeIdentityDocument = [
    {title: 'Паспорт Республики Беларусь', id: "PASSPORT_RB"},
    {title: 'Идентификационная карта гражданина Республики Беларусь', id: "ID_CARD_RB"},
    {title: 'Идентификационная карта Вида на жительство в Республике Беларусь', id: "ID_CARD_RESIDENCE_PERMIT"},
    {title: 'Вид на жительство в Республике Беларусь', id: "RESIDENCE_PERMIT"},
    {
      title: 'Паспорт иностранного государства (Российская Федерация, Республика Казахстан, Кыргызская Республика, Республика Таджикистан)',
      id: "PASSPORT_OTHER_COUNTRY"
    }
  ];
  const arrayGender = [
    {title: 'мужской', id: "male"},
    {title: 'женский', id: "female"},
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])

  const onReloadInformationGeneral = (item) => {
    if (item.length > 0) {
      for (let i = 0; i < item.length; i++) {
        if (item[i]?.typeTitle === GENERAL_SURNAME_RU) values.surnameRu = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_FIRST_NAME_RU) values.nameRu = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_PATRONYMIC_RU) values.patronymicRu = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_SURNAME_BY) values.surnameBy = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_FIRST_NAME_BY) values.nameBy = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_PATRONYMIC_BY) values.patronymicBy = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_SURNAME_EN) values.surnameEn = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_FIRST_NAME_EN) values.nameEn = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_DOCUMENT_TYPE) values.documentType = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_DOCUMENT_SERIES) values.passportSeries = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_DOCUMENT_NUMBER) values.passportNumber = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_IDENTIFICATION_NUMBER) values.identificationNumber = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_DOCUMENT_STATE_AGENCY) values.stateAgency = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_DOCUMENT_DATE_ISSUE) values.dateIssue = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_DOCUMENT_DATEISSUE_END) values.validity = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_BORN_DATE) values.dateBorn = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_GENDER) values.gender = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_CITIZENSHIP && arrayCitizenship.length > 0) values.citizenship = arrayCitizenship.find(data => data.id === item[i]?.value?.id);
        if (item[i]?.typeTitle === GENERAL_RESIDENCE_INBY) values.residence = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_POST_INDEX) values.postIndex = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_EMAIL) values.email = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_FULL_ADDRESS) values.fullAddress = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_PHONE_MOBILE) values.phoneMobile = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_PHONE_HOME) values.phoneHome = item[i]?.value?.value;
        if (item[i]?.typeTitle === GENERAL_NEED_A_HOSTEL) values.needAHostel = item[i]?.value?.value;
      }
    } else {
      for (let i = 0; i < userData.length; i++) {
        if (userData[i]?.typeTitle === USER_SURNAME) values.surnameRu = userData[i]?.value?.value;
        if (userData[i]?.typeTitle === USER_FIRST_NAME) values.nameRu = userData[i]?.value?.value;
        if (userData[i]?.typeTitle === USER_PATRONYMIC) values.patronymicRu = userData[i]?.value?.value;

        if (userData[i]?.typeTitle === USER_BORNDATE) values.dateBorn = userData[i]?.value?.value;
        if (userData[i]?.typeTitle === USER_GENDER) values.gender = userData[i]?.value?.value;

        if (userData[i]?.typeTitle === USER_EMAIL) values.email = userData[i]?.value?.value;
        if (userData[i]?.typeTitle === USER_FULL_ADDRESS) values.fullAddress = userData[i]?.value?.value;
        if (userData[i]?.typeTitle === USER_PHONE_MOBILE) values.phoneMobile = userData[i]?.value?.value;
      }
    }

    setRender(!render)
  }

  useEffect(() => {
    const getDataGeneral = async () => {
      const arrayGeneralParams = [
        `type[]=${GENERAL_SURNAME_RU}`,
        `type[]=${GENERAL_FIRST_NAME_RU}`,
        `type[]=${GENERAL_PATRONYMIC_RU}`,
        `type[]=${GENERAL_SURNAME_BY}`,
        `type[]=${GENERAL_FIRST_NAME_BY}`,
        `type[]=${GENERAL_PATRONYMIC_BY}`,
        `type[]=${GENERAL_SURNAME_EN}`,
        `type[]=${GENERAL_FIRST_NAME_EN}`,
        `type[]=${GENERAL_DOCUMENT_TYPE}`,
        `type[]=${GENERAL_DOCUMENT_SERIES}`,
        `type[]=${GENERAL_DOCUMENT_NUMBER}`,
        `type[]=${GENERAL_IDENTIFICATION_NUMBER}`,
        `type[]=${GENERAL_DOCUMENT_STATE_AGENCY}`,
        `type[]=${GENERAL_DOCUMENT_DATE_ISSUE}`,
        `type[]=${GENERAL_DOCUMENT_DATEISSUE_END}`,
        `type[]=${GENERAL_BORN_DATE}`,
        `type[]=${GENERAL_GENDER}`,
        `type[]=${GENERAL_CITIZENSHIP}`,
        `type[]=${GENERAL_RESIDENCE_INBY}`,
        `type[]=${GENERAL_POST_INDEX}`,
        `type[]=${GENERAL_EMAIL}`,
        `type[]=${GENERAL_FULL_ADDRESS}`,
        `type[]=${GENERAL_PHONE_MOBILE}`,
        `type[]=${GENERAL_PHONE_HOME}`,
        `type[]=${GENERAL_NEED_A_HOSTEL}`,
      ];
      const generalParams = arrayGeneralParams.join('&');
      const result = await dispatch(getDataStatement(generalParams));
      onReloadInformationGeneral(result);
    }
    getDataGeneral();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (validate()) {
      await onSaver(values);
      setIsLoading(false);
      history.push("/statement");
    } else {
      setIsLoading(false);
    }
  };
  const onSaver = async (data) => {
    const sendObject = {
      GENERAL_SURNAME_RU: {value: data.surnameRu.trim()},
      GENERAL_FIRST_NAME_RU: {value: data.nameRu.trim()},
      GENERAL_PATRONYMIC_RU: {value: data.patronymicRu.trim()},
      GENERAL_SURNAME_BY: {value: data.surnameBy.trim()},
      GENERAL_FIRST_NAME_BY: {value: data.nameBy.trim()},
      GENERAL_PATRONYMIC_BY: {value: data.patronymicBy.trim()},
      GENERAL_SURNAME_EN: {value: data.surnameEn.trim()},
      GENERAL_FIRST_NAME_EN: {value: data.nameEn.trim()},
      GENERAL_DOCUMENT_TYPE: {value: data.documentType},
      GENERAL_DOCUMENT_SERIES: {value: data.passportSeries.trim()},
      GENERAL_DOCUMENT_NUMBER: {value: data.passportNumber.trim()},
      GENERAL_IDENTIFICATION_NUMBER: {value: data.identificationNumber.trim()},
      GENERAL_DOCUMENT_STATEAGENCY: {value: data.stateAgency.trim()},
      GENERAL_DOCUMENT_DATEISSUE: {value: data.dateIssue},
      GENERAL_DOCUMENT_DATEISSUE_END: {value: data.validity},
      GENERAL_BORNDATE: {value: data.dateBorn},
      GENERAL_GENDER: {value: data.gender},
      GENERAL_CITIZENSHIP: data.citizenship,
      GENERAL_RESIDENCE_INBY: {value: data.residence},
      GENERAL_POSTINDEX: {value: data.postIndex.trim()},
      GENERAL_EMAIL: {value: data.email.trim()},
      GENERAL_FULL_ADDRESS: {value: data.fullAddress.trim()},
      GENERAL_PHONE_MOBILE: {value: data.phoneMobile.trim()},
      GENERAL_PHONE_HOME: {value: data.phoneHome.trim()},
      GENERAL_NEED_AHOSTEL: {value: data.needAHostel},
      ANKETA_STATUS_FORMS_APPLICATION: {
        ...statementStatusFormsApplication,
        1: {"status": "IS_FULL", "coment": ""},
        9: {"action": "1"}
      },
      ANKETA_STATUS: {"value": "IS_WORK"}
    }
    try {
      await dispatch(setDataStatement(sendObject));
      dispatch(chatAction_createNotification({
        message: 'Данные формы сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      if (error.data.detail === "lock_application_in_asu") {
        dispatch(chatAction_createNotification({
          message: 'Форма заблокирована для изменений.',
          variant: 'error'
        }));
      } else {
        console.log(error);
        dispatch(chatAction_createNotification({
          message: 'Ошибка сохранения данных формы.',
          variant: 'error'
        }));
      }
    }
    if (statementTypeCompetition?.id) await dispatch(deleteTypeCompetitionStatement());
    if (statementContractFile?.id) await dispatch(deleteContractFileStatement());
  }

  const validate = (fieldValues = values) => {
    let temp = {...errors};
    if ('surnameRu' in fieldValues) {
      temp.surnameRu = Valid.isIsset(fieldValues.surnameRu)
        ? Valid.regexCyrillic(fieldValues.surnameRu)
          ? Valid.isTrim(fieldValues.surnameRu)
            ? ''
            : 'Это поле не может состоять только из пробелов'
          : 'Это поле должно содержать только символы кириллицы!'
        : 'Это поле обязательное для заполнения!';
    }
    if ('nameRu' in fieldValues) {
      temp.nameRu = Valid.isIsset(fieldValues.nameRu)
        ? Valid.regexCyrillic(fieldValues.nameRu)
          ? Valid.isTrim(fieldValues.nameRu)
            ? ''
            : 'Это поле не может состоять только из пробелов'
          : 'Это поле должно содержать только символы кириллицы!'
        : 'Это поле обязательное для заполнения!';
    }
    if ('patronymicRu' in fieldValues) {
      temp.patronymicRu = Valid.regexCyrillic(fieldValues.patronymicRu)
        ? ''
        : 'Это поле должно содержать только символы кириллицы!';
    }
    if ('surnameBy' in fieldValues) {
      temp.surnameBy = Valid.isIsset(fieldValues.surnameBy)
        ? Valid.regexBy(fieldValues.surnameBy)
          ? Valid.isTrim(fieldValues.surnameBy)
            ? ''
            : 'Это поле не может состоять только из пробелов'
          : 'Это поле должно содержать только символы белорусского алфавита!'
        : 'Это поле обязательное для заполнения!';
    }
    if ('nameBy' in fieldValues) {
      temp.nameBy = Valid.isIsset(fieldValues.nameBy)
        ? Valid.regexBy(fieldValues.nameBy)
          ? Valid.isTrim(fieldValues.nameBy)
            ? ''
            : 'Это поле не может состоять только из пробелов'
          : 'Это поле должно содержать только символы белорусского алфавита!'
        : 'Это поле обязательное для заполнения!';
    }
    if ('patronymicBy' in fieldValues) {
      temp.patronymicBy = Valid.regexBy(fieldValues.patronymicBy)
        ? ''
        : 'Это поле должно содержать только символы белорусского алфавита!';
    }
    if ('surnameEn' in fieldValues) {
      temp.surnameEn = Valid.isIsset(fieldValues.surnameEn)
        ? Valid.regexLatin(fieldValues.surnameEn)
          ? Valid.isTrim(fieldValues.surnameEn)
            ? ''
            : 'Это поле не может состоять только из пробелов'
          : 'Это поле должно содержать только латинские символы!'
        : 'Это поле обязательное для заполнения!';
    }
    if ('nameEn' in fieldValues) {
      temp.nameEn = Valid.isIsset(fieldValues.nameEn)
        ? Valid.regexLatin(fieldValues.nameEn)
          ? Valid.isTrim(fieldValues.nameEn)
            ? ''
            : 'Это поле не может состоять только из пробелов'
          : 'Это поле должно содержать только латинские символы!'
        : 'Это поле обязательное для заполнения!';
    }
    if ('documentType' in fieldValues) {
      temp.documentType = Valid.isIsset(fieldValues.documentType)
        ? ''
        : 'Это поле обязательное для выбора!';
    }
    if (showPassportSeries()) {
      if ('passportSeries' in fieldValues) {
        temp.passportSeries = Valid.isIsset(fieldValues.passportSeries)
          ? ''
          : 'Это поле обязательное для заполнения!';
      }
    }
    if ('passportNumber' in fieldValues) {
      temp.passportNumber = Valid.isIsset(fieldValues.passportNumber)
        ? Valid.isTrim(fieldValues.passportNumber)
          ? ''
          : 'Это поле не может состоять только из пробелов'
        : 'Это поле обязательное для заполнения!';
    }
    if (showIdentificationNumber()) {
      if ('identificationNumber' in fieldValues) {
        temp.identificationNumber = Valid.isIsset(fieldValues.identificationNumber)
          ? ''
          : 'Это поле обязательное для заполнения!';
      }
    }
    if ('stateAgency' in fieldValues) {
      temp.stateAgency = Valid.isIsset(fieldValues.stateAgency)
        ? Valid.isTrim(fieldValues.stateAgency)
          ? ''
          : 'Это поле не может состоять только из пробелов'
        : 'Это поле обязательное для заполнения!';
    }
    if ('dateIssue' in fieldValues) {
      temp.dateIssue = Valid.isIsset(fieldValues.dateIssue)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('validity' in fieldValues) {
      temp.validity = Valid.isIsset(fieldValues.validity)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('dateBorn' in fieldValues) {
      temp.dateBorn = "";
      if (temp?.dateBorn === "" && !Valid.isIsset(fieldValues?.dateBorn)) {
        temp.dateBorn = "Обязательно для выбора.";
      }
      if (temp?.dateBorn === "" && !Valid.isMoreThan(fieldValues?.dateBorn, '2008-01-01')) {
        temp.dateBorn = "Дата рождения не может быть больше 31.12.2007.";
      }
    }
    if ('gender' in fieldValues) {
      temp.gender = Valid.isIsset(fieldValues.gender)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('citizenship' in fieldValues) {
      temp.citizenship = Valid.isIsset(fieldValues.citizenship)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('postIndex' in fieldValues) {
      temp.postIndex = Valid.isIsset(fieldValues.postIndex)
        ? Valid.isTrim(fieldValues.postIndex)
          ? ''
          : 'Это поле не может состоять только из пробелов'
        : 'Это поле обязательное для заполнения!';
    }
    if ('email' in fieldValues) {
      temp.email = Valid.isIsset(fieldValues.email)
        ? Valid.isEmail(fieldValues.email)
          ? Valid.isTrim(fieldValues.email)
            ? ''
            : 'Это поле не может состоять только из пробелов'
          : 'Не корректный email!'
        : 'Это поле обязательное для заполнения!';
    }
    if ('fullAddress' in fieldValues) {
      temp.fullAddress = Valid.isIsset(fieldValues.fullAddress)
        ? Valid.isTrim(fieldValues.fullAddress)
          ? ''
          : 'Это поле не может состоять только из пробелов'
        : 'Это поле обязательное для заполнения!';
    }
    if ('phoneMobile' in fieldValues) {
      temp.phoneMobile = Valid.isIsset(fieldValues.phoneMobile)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFValues, true, validate);

  useEffect(() => {
    // if (firstStart) {
    if (values.documentType === 'PASSPORT_RB' || values.documentType === 'ID_CARD_RB') {
      values.citizenship = (arrayCitizenship.length > 0 ? arrayCitizenship.find(data => data.id === 9) : '');
      values.passportSeries = '';
      values.residence = false;
      values.passportNumberPlaceholder = "КН2221133";
    } else if (values.documentType === 'RESIDENCE_PERMIT' || values.documentType === 'ID_CARD_RESIDENCE_PERMIT') {
      values.residence = true;
      values.citizenship = '';
      values.passportSeries = '';
      values.passportNumberPlaceholder = "КН2221133";
    } else if (values.documentType === 'PASSPORT_OTHER_COUNTRY') {
      values.citizenship = '';
      values.residence = false;
      values.passportNumberPlaceholder = "";
    }
    // }
    setRender(!render)
  }, [values.documentType])

  const showPassportSeries = () => {
    return values.documentType === 'PASSPORT_OTHER_COUNTRY';
  }
  const showResidence = () => {
    return values.documentType === 'PASSPORT_OTHER_COUNTRY';
  }
  const showIdentificationNumber = () => {
    return values.documentType !== 'PASSPORT_OTHER_COUNTRY';
  }
  const stateAgencyLabel = () => {
    if (values.documentType === 'ID_CARD_RB' || values.documentType === 'ID_CARD_RESIDENCE_PERMIT') {
      return 'Код органа выдавшего документ*';
    }
    return 'Государственный орган, выдавший документ*';
  }

  const {
    open,
    scroll,
    handleClickOpen,
    handleClose,
  } = useDialog();

  const subTitlePassport = () => {
    if (values.documentType === 'PASSPORT_RB') {
      return <Typography variant="subtitle1" component="h6" align="center">
        30-31 страницы паспорта, 32-33 страницы паспорта
      </Typography>
    } else if (values.documentType === 'RESIDENCE_PERMIT') {
      return <Typography variant="subtitle1" component="h6" align="center">
        14-15 страницы вида на жительство, 16-17 страницы вида на жительство
      </Typography>
    } else if (values.documentType === 'ID_CARD_RB' || values.documentType === 'ID_CARD_RESIDENCE_PERMIT') {
      return <Typography variant="subtitle1" component="h6" align="center">
        Фотографии карточки с обеих сторон
      </Typography>
    }
    return '';
  }

  const dialogContent =
    <Grid container spacing={2} justifyContent="center">
      <Grid item md={12} xs={12}>
        <Typography variant="body2" component="p">Фотография будет помещена на студенческом билете и использоваться
          системами
          автоматического распознавания лица при входе в здания университета.</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography variant="body2" component="p">Фотографируясь для подачи документов в приемную комиссию, попросите в
          фотомастерской
          переписать Вам файл с фотографией и прикрепите его в Цифровом кабинете абитуриента.</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography variant="body2" component="p">
          Фотография на документы. Изображение анфас, открытое лицо, без головного убора, маски, солнцезащитных очков и
          иных закрывающих лицо предметов. Допускаются очки, если абитуриент их носит постоянно. Одежда делового стиля.
        </Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography variant="body2" component="p">
          Соотношение сторон 3:4 (вертикальная). Разрешение цифровой версии не хуже, чем 600 dpi х 800 dpi.
        </Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography variant="body2" component="p">
          Изображение лица должно на светлом (белом) фоне и занимать не менее 50% площади фотографии.
        </Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography variant="body2" component="p">
          Не допускается художественная обработка изображения, применение искажающих фильтров.
        </Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography variant="body2" component="p">
          В случае сканирования бумажной фотографии, оригинал должен быть хорошего качества, без изломов царапин и без
          искажения пропорций изображения.
        </Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography variant="body2" component="p">
          Формат файла фотографии: JPG, JPEG.
        </Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Box align={'center'}>
          <Avatar
            src={examplePhoto}
            variant="square"
            style={{height: '214px', width: '160px', border: '1px solid black'}}/>
          <Typography variant="caption" component="p">
            Пример фотографии
          </Typography>
        </Box>
      </Grid>
    </Grid>;

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} justifyContent="center" className={classes.grid_container}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader title="Общие сведения"/>
            <Divider/>
            <CardContent>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Typography variant="body1" component="p">
                      Фамилия, имя, отчество на русском языке
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Controls.Input
                      fullWidth
                      name="surnameRu"
                      label="Фамилия*"
                      value={values.surnameRu}
                      onChange={handleInputChange}
                      error={errors.surnameRu}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Controls.Input
                      fullWidth
                      name="nameRu"
                      label="Имя*"
                      value={values.nameRu}
                      onChange={handleInputChange}
                      error={errors.nameRu}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Controls.Input
                      fullWidth
                      name="patronymicRu"
                      label="Отчество"
                      value={values.patronymicRu}
                      onChange={handleInputChange}
                      error={errors.patronymicRu}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography variant="body1" component="p">
                      Фамилия, имя, отчество на белорусском языке
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <KeyBoardMiniForm
                      fullWidth
                      name="surnameBy"
                      label="Прозвішча*"
                      value={values.surnameBy}
                      onChange={handleInputChange}
                      error={errors.surnameBy}
                      defaultLanguage={'BEL'}
                      xs={3}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <KeyBoardMiniForm
                      fullWidth
                      name="nameBy"
                      label="Імя*"
                      value={values.nameBy}
                      onChange={handleInputChange}
                      error={errors.nameBy}
                      defaultLanguage={'BEL'}
                      xs={3}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <KeyBoardMiniForm
                      fullWidth
                      name="patronymicBy"
                      label="Імя па бацьку"
                      value={values.patronymicBy}
                      onChange={handleInputChange}
                      error={errors.patronymicBy}
                      defaultLanguage={'BEL'}
                      xs={3}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography variant="body1" component="p">
                      Фамилия, имя на английском языке(как в паспорте)
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.Input
                      fullWidth
                      name="surnameEn"
                      label="Surname*"
                      value={values.surnameEn}
                      onChange={handleInputChange}
                      error={errors.surnameEn}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.Input
                      fullWidth
                      name="nameEn"
                      label="Name*"
                      value={values.nameEn}
                      onChange={handleInputChange}
                      error={errors.nameEn}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={12}>
                    <Controls.Select
                      fullWidth
                      name="documentType"
                      label="Вид документа, удостоверяющего личность*"
                      value={values.documentType}
                      options={arrayTypeIdentityDocument}
                      onChange={handleInputChange}
                      error={errors.documentType}
                    />
                  </Grid>
                  {showPassportSeries()
                    ? <Grid item md={6} xs={12}>
                      <Controls.Input
                        fullWidth
                        name="passportSeries"
                        label="Серия документа"
                        value={values.passportSeries.toUpperCase()}
                        onChange={handleInputChange}
                        error={errors.passportSeries}
                      />
                    </Grid>
                    : null
                  }
                  <Grid item md={6} xs={12}>
                    <Controls.Input
                      fullWidth
                      name="passportNumber"
                      label="Номер документа*"
                      placeholder={values.passportNumberPlaceholder}
                      value={values.passportNumber.toUpperCase()}
                      onChange={handleInputChange}
                      error={errors.passportNumber}
                    />
                  </Grid>
                  {showIdentificationNumber()
                    ? <Grid item xs={12}>
                      <Controls.Input
                        fullWidth
                        name="identificationNumber"
                        label="Идентификационный номер*"
                        value={values.identificationNumber.toUpperCase()}
                        onChange={handleInputChange}
                        error={errors.identificationNumber}
                      />
                    </Grid>
                    : null
                  }

                  <Grid item md={6} xs={12}>
                    <Controls.Input
                      fullWidth
                      name="stateAgency"
                      label={stateAgencyLabel()}
                      value={values.stateAgency}
                      onChange={handleInputChange}
                      error={errors.stateAgency}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.DatePicker
                      name="dateIssue"
                      label="Дата выдачи*"
                      value={values.dateIssue}
                      onChange={handleInputChange}
                      error={errors.dateIssue}
                      openTo="year"
                      views={["year", "month", "date"]}
                      maxDate={new Date()}
                      minDate={new Date('2000-01-01')}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.DatePicker
                      name="validity"
                      label="Срок действия*"
                      value={values.validity}
                      onChange={handleInputChange}
                      error={errors.validity}
                      openTo="year"
                      views={["year", "month", "date"]}
                      minDate={new Date()}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.DatePicker
                      name="dateBorn"
                      label="Дата рождения*"
                      value={values.dateBorn}
                      onChange={handleInputChange}
                      error={errors.dateBorn}
                      disableFuture
                      openTo="year"
                      views={["year", "month", "date"]}
                      minDate={new Date('1950-01-01')}
                      maxDate={new Date('2007-12-31')}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.RadioGroup
                      fullWidth
                      row
                      name="gender"
                      label="Пол*"
                      value={values.gender}
                      onChange={handleInputChange}
                      error={errors.gender}
                      items={arrayGender}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controls.SelectCitizenship
                      fullWidth
                      name="citizenship"
                      label="Гражданство*"
                      value={values.citizenship}
                      options={arrayCitizenship}
                      onChange={handleInputChange}
                      error={errors.citizenship}
                      // disabled
                    />
                  </Grid>
                  {showResidence()
                    ? <Grid item md={12} xs={12}>
                      <Controls.Checkbox
                        name="residence"
                        label="С видом на жительство в РБ"
                        value={values.residence}
                        onChange={handleInputChange}
                        error={errors.residence}
                      />
                    </Grid>
                    : null
                  }
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Controls.Input
                      fullWidth
                      name="postIndex"
                      label="Индекс*"
                      value={values.postIndex}
                      onChange={handleInputChange}
                      error={errors.postIndex}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.Input
                      fullWidth
                      name="email"
                      label="Электронная почта*"
                      value={values.email}
                      onChange={handleInputChange}
                      error={errors.email}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Controls.Input
                      fullWidth
                      name="fullAddress"
                      label="Адрес*"
                      tooltipTitle={'Беларусь, Гродненская область, г.Гродно, ул. Ожешко, д.22, кв. 120'}
                      value={values.fullAddress}
                      onChange={handleInputChange}
                      error={errors.fullAddress}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <NumberFormat
                      customInput={Controls.Input}
                      format="375#########"
                      allowEmptyFormatting
                      mask="_"
                      variant="outlined"
                      fullWidth
                      name="phoneMobile"
                      label="Телефон (мобильный)*"
                      value={values.phoneMobile}
                      onChange={handleInputChange}
                      error={errors.phoneMobile}
                    />
                    {/*<Controls.Input*/}
                    {/*  fullWidth*/}
                    {/*  name="phoneMobile"*/}
                    {/*  label="Телефон (мобильный)"*/}
                    {/*  value={values.phoneMobile}*/}
                    {/*  onChange={handleInputChange}*/}
                    {/*  {...(errors.phoneMobile && {error: true, helperText: errors.phoneMobile})}*/}
                    {/*/>*/}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <NumberFormat
                      customInput={Controls.Input}
                      format="375#########"
                      allowEmptyFormatting
                      mask="_"
                      variant="outlined"
                      fullWidth
                      name="phoneHome"
                      label="Телефон (домашний с кодом)"
                      value={values.phoneHome}
                      onChange={handleInputChange}
                      error={errors.phoneHome}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Controls.Checkbox
                      name="needAHostel"
                      label="В период обучения в университете нуждаюсь в общежитии"
                      value={values.needAHostel}
                      onChange={handleInputChange}
                      error={errors.needAHostel}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography variant="subtitle1" component="h6" align="center">
                      Действия с прикрепленным файлом доступны после клика левой кнопкой мыши по самому изображению
                    </Typography>
                  </Grid>
                  <FileLoadingForm
                    tag={'GENERAL_FILE_PASSPORT'}
                    labelText={
                      <>
                        <Typography variant="h6" component="h6" align="center">
                          Загрузка файлов документа, удостоверяющего личность
                        </Typography>
                        {subTitlePassport()}
                      </>
                    }
                    labelForm={'Перетащите или <span class="filepond--label-action" tabindex="0">прикрепите</span> сканы (фото) документа удостоверяющего личность'}
                    size={5}

                  />


                  <FileLoadingForm
                    tag={'GENERAL_FILE_MEDICAL_CERTIFICATE'}
                    labelText={'Загрузка файлов медицинской справки'}
                    labelForm={'Перетащите или <span class="filepond--label-action" tabindex="0">прикрепите</span> сканы (фото) медицинской справки'}
                    size={5}
                  />

                  <Grid item container xs={12} justifyContent={"space-between"}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => {
                        history.push("/statement")
                      }}
                      disabled={isLoading}
                    >
                      Назад
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isLoading}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              <DialogCustom
                open={open}
                scroll={scroll}
                handleClose={handleClose}
                dialogTitle={'Требования к фотографии'}
                dialogContextText={dialogContent}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default General;
