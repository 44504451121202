import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  message: {
    marginTop: theme.spacing(1),
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "stretch",
    marginBottom: theme.spacing(1),
  },
  title__text: { textAlign: "center", flexGrow: 2, fontWeight: "bold" },
  container: { padding: theme.spacing(0) },
  text__blue: { color: "#3f51b5", fontWeight: "bold" },
  text__red: { color: "#8b0000", fontWeight: "bold" },
  list: { listStyleType: "none" },
  container__text: { textAlign: "center" },
}));

export const InterviewMessage = memo(() => {
  const classes = useStyles();
  const text = (
    <>
      <div className={classes.title}>
        <Typography className={classes.title__text}>
          Уважаемый абитуриент!
        </Typography>
      </div>
      <div className={classes.container}>
        <Typography className={classes.container__text}>
          Гродненский государственный университет имени Янки Купалы
          заинтересован в повышении качества работы приемной кампании. Просим
          Вас и Ваших родителей принять участие в опросе. Ваше мнение очень
          важно для нас. Обращаем внимание: опрос анонимный, все ответы будут
          использованы только в обобщенном виде.{" "}
        </Typography>
        <Typography className={classes.container__text}>
          Заполнение анкеты займет не более 10 минут.
        </Typography>
        <Typography className={classes.container__text}>
          Просим Вас ответить на вопросы анкеты, для этого необходимо перейти по
          ссылке:
        </Typography>
        <ul className={classes.list}>
          <li>
            <a
              href="https://forms.gle/NmXuqtebP5AyW8CM6"
              target="_blank"
              rel="noreferrer"
            >
              анкета для абитуриентов
            </a>
          </li>
          <li>
            <a
              href="https://forms.gle/eTXGDECGJQsXjMs1A"
              target="_blank"
              rel="noreferrer"
            >
              анкета для родителей абитуриентов
            </a>
          </li>
        </ul>
        <Typography className={classes.title__text}>
          Благодарим за участие в опросе!
        </Typography>
        <Typography className={classes.title__text}>
          Мы будем рады видеть Вас в числе наших студентов!
        </Typography>

        <Typography className={classes.container__text}>
          При возникновении вопросов по заполнению анкет обращаться в отдел
          менеджмента качества ГрГУ им. Янки Купалы на адрес электронной почты{" "}
          <span className={classes.text__blue}>osmi@grsu.by</span> или{" "}
          <span className={classes.text__blue}>umc@grsu.by</span>, или по
          телефонам:
          <span className={classes.text__blue}>
            (+375152) 39-34-09, (+375152) 73-19-94.
          </span>
        </Typography>
      </div>
    </>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      className={classes.message}
    >
      <Grid item xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{"Информация об опросе"}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                {text}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
});
