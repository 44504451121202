const Valid = {};

Valid.isIsset = (value) => {
    return (!!value );
};
Valid.isTrim = (value) => {
    return (!!value.trim() );
};
Valid.isUndefined = (value) => {
    return (typeof value === 'undefined');
};
Valid.isNumber = (value) => {
    return new RegExp('^[0-9]+$').test(value);
};
Valid.isString = (value) => {
    return new RegExp('^[a-zA-ZА-Яа-я]+$').test(value);
};
Valid.isText = (value) => {
    return new RegExp('^[a-zA-ZА-Яа-я ]+$').test(value);
};
Valid.isEmail = (value) => {
    return new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$').test(value);
};
Valid.regexCyrillic = (value) => {
    return new RegExp('^([\u0410-\u044F\u0401\u0451\\s\\-]*)$').test(value);
};
Valid.regexBy = (value) => {
    return new RegExp(`^([ёа-зй-шы-яЁА-ЗЙ-ШЫ-ЯІіЎў'\\s\\-]*)$`).test(value);
};
Valid.regexLatin = (value) => {
    return new RegExp('^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\\s\\-]*)$').test(value);
};
Valid.isPhoneNumber = (value) => {
    return new RegExp('^[0-9_+]+$').test(value);
};
Valid.regexAveragePoint = (value) => {
    return new RegExp('^\\d+\\.?[0-9]?$').test(value);
};
Valid.isValueFloat = (value) => {
    value = ((`${value}`.indexOf(',') !== -1)
        ? value.replace(",", ".")
        : value
    );
    let parseNewStr = parseFloat(value);
    // console.log(parseNewStr)
    return (!isNaN(parseNewStr));
}
Valid.isMoreThan = (value, date) => {
    let date1 = new Date(value);
    let date2 = new Date(date);
    return date1 < date2;
};
export default Valid;
