import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import rootReducer from './store/reducers/rootReducer';
import thunk from 'redux-thunk';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";
import {installInterceptors} from "./customAxios";
import { SnackbarProvider } from 'notistack';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);


installInterceptors(store);

const app = (
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale} >
        <SnackbarProvider>
          <App/>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

ReactDOM.render(
  app,
  document.getElementById('root')
);