import {
  GET_FACULTIES_CONTACT_FAIL,
  GET_FACULTIES_CONTACT_SUCCESS,
  GET_ORGANIZATION_CONTACT_SUCCESS,
  GET_ORGANIZATION_CONTACT_FAIL,
} from "../actions/actionTypes";

const initialState = {faculties: [], organization: []};

export default function contactsReducer(state = initialState, action) {

  switch (action.type) {
    case GET_FACULTIES_CONTACT_SUCCESS:
      return {
        ...state,
        faculties: action.payload,
      };
    case GET_FACULTIES_CONTACT_FAIL:
      return {
        ...state,
      };
    case GET_ORGANIZATION_CONTACT_SUCCESS:
      return {
        ...state,
        organization: action.payload,
      };
    case GET_ORGANIZATION_CONTACT_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
