
import instance from "../../customAxios";

const API_URL = "/";

export function getRemoteUserControl (userId) {
  return async () => {
    try {
      const response = await instance.get(
        API_URL + `api/remote_user/${userId}`,
      );
      localStorage.setItem('access', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      return Promise.resolve(response);
    } catch (error) {
      console.error('☠️error getRemoteUserControl actions Admin: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}