import React from 'react'
import {TextField as TextFieldMiU} from '@material-ui/core';
import {Autocomplete as AutocompleteMiU} from '@material-ui/lab';
import Typography from "@material-ui/core/Typography";

export default function AutocompleteInput(props) {

    const { name, label, value,error=null, onChange, options, ...rest } = props;
    return (
        <AutocompleteMiU
            options={options}
            autoHighlight
            getOptionLabel={(option) => option.title}
            renderOption={(option) => (
                <React.Fragment>
                    <Typography>
                        {option.title}
                    </Typography>
                </React.Fragment>
            )}
            name={name}
            getOptionSelected={(option, value) => {
                console.log(value);
                if(value.id === undefined) return false;
                return (option.item.id === value.id);
            }}
            onChange={(event, newValue) => {
                event.target.value = newValue;
                event.target.name = name;
                onChange(event);
            }}
            value={value}
            renderInput={(params) => <TextFieldMiU
                {...params}
                fullWidth = {rest.fullWidth ? rest.fullWidth : null}
                variant="outlined"
                label={label}
                {...(error && {error:true,helperText:error})}
            />}
        />
    )
}
