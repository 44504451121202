import {InputAdornment, Tooltip} from "@material-ui/core";
import React, {useRef, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {AttachFileOutlined} from "@material-ui/icons";
import {FilePond} from "react-filepond";
import {useDispatch, useSelector} from "react-redux";
import {chatAction_createNotification, chatAction_sendMessage} from "../../../../store/Chat/ChatActions";
import PropTypes from "prop-types";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function useDialogFile(chatId = undefined) {
    const {newMessageSending: sendingStatus} = useSelector(state => state.chatReducer);
    const dispatch = useDispatch();
    const {accessToken} = useSelector(state => state.authReducer);
    const [open, setOpen] = React.useState(false);
    const [submit, setSubmit] = useState(false);
    const [process, setProcess] = useState(null);

    const [file, setFile] = useState([]);
    const [tokenFile, setTokenFile] = useState(null);

    let pond = useRef();

    const filepondOptions = {
        ref: (ref) => (pond = ref),
        files: file,
        allowMultiple: false,
        maxFiles: 1,
        labelIdle: '<span class="filepond--label-action" tabIndex="0">Выберите файл</span>',
        allowRemove: false,
        allowRevert: false,
        allowProcess: false,
        allowReorder: false,
        onupdatefiles: handleUpdate,
        onaddfile: handleAddFile,
        onprocessfile: handleProcess,
        onprocessfilestart: onProcessFileStart,
        credits: false,
        server: {
            process: {
                url: '/api/ext/files',
                method: 'POST',
                headers: {Authorization: accessToken},
                onload: onLoadFiles,
                onerror: onErrorFiles
            }
        }
    };

    return {
        open, setOpen, submit, setSubmit, process, setProcess, file, setFile, filepondOptions, onAbortProcess, onSubmit
    }

    function onSubmit(){
        if(!submit || chatId === undefined || sendingStatus) return;
        const fileLoaded = file[0];
        const fileType = fileLoaded?.type?.split('/')[0];
        const url = `/api/ext/files/${tokenFile}`;
        const message = {...(fileType === "image" ? {img: url} : {file: url})};
        dispatch(chatAction_sendMessage({chatId: chatId, message: message}));
        if(open) {
            setOpen(false);
        }
    }
    function onAbortProcess(){
        if(process !== null){
            process.abortProcessing();
            setProcess(null);
        }
        setFile([]);
    }

    function onProcessFileStart(e){
        setProcess(e);
    }


    async function onLoadFiles(response) {
        setProcess(null);
        const token = JSON.parse(response)['filepond'];
        if(token){
            setTokenFile(token);
            setSubmit(true);
        }else{
            if(tokenFile !== null){
                setTokenFile(null);
            }
        }
    }

    async function onErrorFiles(error) {
        console.log(`Error: ${JSON.stringify(error)}`);
    }

    async function handleProcess(error) {
        if(error !== null){
            dispatch(chatAction_createNotification({message: 'Ошибка при загрузке файла.', autoHide: true, variant: 'error'}));
        }else{
            dispatch(chatAction_createNotification({message: 'Файл успешно загружен.', autoHide: true, variant: 'success'}));
        }
    }
    function handleAddFile(error, fileItem) {
        let errorText = undefined;
        if (fileItem.file.size > 1024 * 1024 * 5) {
            fileItem.abortProcessing();
            setTimeout(() => fileItem.abortLoad(), 1000);
            errorText = `Допустимый размер файла ${fileItem.file.name} превышен.`;
        }
        if (errorText) {
            dispatch(chatAction_createNotification({message: errorText, autoHide: true, variant: 'error'}));
        }
    }

    function handleUpdate(fileItems) {
        setFile(fileItems.map((fileItem) => fileItem.file));
    }
}

export default function DialogFile(props) {
    const {chatId} = props;

    const {open, setOpen, submit: SubmitDisabled, filepondOptions, onAbortProcess, onSubmit} = useDialogFile(chatId);
    const handleClickOpen = () => {
        if (!open) {
            setOpen(true);
        }
    };
    const handleClose = () => {
        if (open) {
            onAbortProcess();
            setOpen(false);
        }
    };

    return (
        <InputAdornment position="start">
            <Tooltip title="Прикрепить файл">
                <IconButton onClick={handleClickOpen}>
                    <AttachFileOutlined/>
                </IconButton>
            </Tooltip>
            {open ? (
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullScreen>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Загрузить файл
                    </DialogTitle>

                    <DialogContent dividers>
                        <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center',  flexDirection: 'column'}}>
                            <FilePond {...filepondOptions}/>
                            <Typography gutterBottom>
                                Размер файла не более 5 МБ.
                            </Typography>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={SubmitDisabled === false} autoFocus onClick={onSubmit} variant="contained" size="large" color="primary">
                            Отправить
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : null}
        </InputAdornment>
    );
}
DialogFile.propTypes = {
    chatId: PropTypes.any.isRequired
};
