import {GET_PROFILE_INFO_SUCCESS, GET_PROFILE_INFO_FAIL, LOGOUT} from "../actions/actionTypes";

const initialState = {profileInfo: []};


export default function profileReducer (state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_PROFILE_INFO_SUCCESS:
      return {...state, profileInfo: payload};
    case GET_PROFILE_INFO_FAIL:
      return {...state, profileInfo: {}};
    case LOGOUT:
      return {
        ...state,
        profileInfo: []
      };
    default:
      return state;
  }
}
