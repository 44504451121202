import { Container, Paper, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Form from "./Form";
import LoadingSlider from "../../../components/LoadingSlider/LoadingSlider";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {chatAction_createNotification} from "../../../store/Chat/ChatActions";
import {getRemoteUserControl} from "../../../store/Admin/actionAdmin";

let isMount = true;

function useRemoteUserControl() {
  const dispatch = useDispatch();
  const [isSending, setSending] = useState(false);
  const [isLoading, setLoading] = useState(false);

  function onSending() {
    if (!isMount) return;
    if (!isSending) {
      setSending(true);
      setTimeout(() => {
        setSending(false)
      }, 2000);
    }
  }

  async function onSubmitNotification(data) {
    if (!isMount) return;
    setLoading(true);
    try {
      const response = await dispatch(getRemoteUserControl(data.userId));
      if (response?.statusText === "OK") {
        dispatch(chatAction_createNotification({
          message: 'Успешное получение токенов пользователя.',
          variant: 'success',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
        await onLogin();
      } else {
        dispatch(chatAction_createNotification({
          message: 'Ошибка при обработке данных.',
          variant: 'error',
          autoHide: true,
          position: {vertical: 'left', horizontal: 'bottom'}
        }));
      }
      setLoading(false);
      onSending();
    } catch (e) {
      dispatch(chatAction_createNotification({
        message: 'Ошибка. Проверьте правильность вводимых данных.',
        variant: 'error',
        autoHide: true,
        position: {vertical: 'left', horizontal: 'bottom'}
      }));
      console.error(`Critical error: ${JSON.stringify(e.response)}`);
      setLoading(false);
      onSending();
    }
  }

  async function onLogin() {
    if (!isMount) return;
    document.location.reload();
  }

  useEffect(() => {
    return () => {
      isMount = false;
    }
  }, [])

  return {
    status: {sending: isSending, loading: isLoading},
    onSubmitNotification,
  };

}

export function RemoteUserControl() {
  const classes = useStyles();
  const {
    status,
    onSubmitNotification,
  } = useRemoteUserControl();
  return (<Container component="div" maxWidth="sm" className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.title}>
          Удаленное управление пользователем
        </Typography>
        <Form onSubmit={onSubmitNotification} sending={status?.sending || status.loading}/>
        <LoadingSlider open={status?.loading}/>
      </Paper>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  title: {
    margin: theme.spacing(1)
  }
}));
