
const actionCommentResult = ({status}) => [
  {
    type: "%_COMMENT_RESULT_DATA_%",
    action: ({message}) => {
      const star = (status._COMMENT_ !== null && status._COMMENT_ !== '' ) ? `* ` : '';
      return message.replace("%_COMMENT_RESULT_DATA_%", star + status._COMMENT_ )
    }
  },
]

export default actionCommentResult;