import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";
import Valid from "../../../../../constants/Valid";
import {useForm} from "../../../../../components/Customs/useForm";
import Controls from "../../../../../components/Customs/Controls/Controls";
import {Card, CardContent, CardHeader, Container, Divider, Grid,} from '@material-ui/core';
import {useDispatch} from "react-redux";
import {getDataStatement} from "../../../../../store/Statement/actionStatement";
import {
  TRAINING_ALLOW_FOR_PAID,
  TRAINING_FACULTY,
  TRAINING_SPECIALITIES_GROUP,
  TRAINING_SPECIALITY_BUDGET,
  TRAINING_SPECIALITY_PAID,
  TRAINING_STUDY_FORM,
  TRAINING_STUDY_PAY,
  TRAINING_TARGET_RECRUITMENT,
  TRAINING_TARGET_RECRUITMENT_SPEC,
  TRAINING_TYPE,
} from "../../../../../services/tagTypes";
import GeneralBasis from "./generalBasis";
import TargetTraining from "./targetTraining";
import Olympiad from "./olympiad";
import PedagogicalSpecialties from "./pedagogicalSpecialties";
import SpecialtiesByTheEconomy from "./specialtiesByTheEconomy";
import Gold from './gold';

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const initialFValues = {
  typeTraining: "",
};

const Training = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [dataFromApi, setDataFromApi] = useState([]);
  const [render, setRender] = useState(false);
  const [dataUnloadApi, setDataUnloadApi] = useState(true);

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])

  const itemsTypeTraining = [
    {title: 'на условиях целевой подготовки (с 27.06 по 02.07)', id: 'targetTraining', disabled: true},
    {title: 'как победитель университетской олимпиады (с 27.06 по 01.07)', id: 'olympiad', disabled: true},
    {title: 'на общих основаниях', id: 'generalBasisWithoutPrivileges', disabled: false},
    {title: 'без экзаменов на педагогические специальности', id: 'pedagogicalSpecialties', disabled: false},
    // {title: 'без экзаменов на специальности, востребованные экономикой', id: 'specialtiesByTheEconomy', disabled: false},
    {title: 'без экзаменов как обладатель аттестата особого образца или диплома с отличием', id: 'gold', disabled: false},
    {title: 'на иных условиях', id: 'generalBasis', disabled: false},
  ];

  const getTypeTraining = (item) => {
    const typeTraining = item.find(data => data.typeTitle === TRAINING_TYPE);
    return (typeTraining !== undefined ? typeTraining.value.value : '');
  }

  const validate = (fieldValues = values) => {
    let temp = {...errors};
    if ('typeTraining' in fieldValues) {
      temp.typeTraining = Valid.isIsset(fieldValues.typeTraining)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFValues, true, validate);

  const onReloadInformationToStudyTraining = (item) => {
    values.typeTraining = getTypeTraining(item);
    setRender(true);
    setDataUnloadApi(false);
  }

  useEffect(() => {
    const getDataTraining = async () => {
      const arrayTrainingParams = [
        `type[]=${TRAINING_TYPE}`,
        `type[]=${TRAINING_STUDY_FORM}`,
        `type[]=${TRAINING_STUDY_PAY}`,
        `type[]=${TRAINING_FACULTY}`,
        `type[]=${TRAINING_SPECIALITIES_GROUP}`,
        `type[]=${TRAINING_ALLOW_FOR_PAID}`,
        `type[]=${TRAINING_SPECIALITY_BUDGET}`,
        `type[]=${TRAINING_SPECIALITY_PAID}`,
        `type[]=${TRAINING_TARGET_RECRUITMENT}`,
        `type[]=${TRAINING_TARGET_RECRUITMENT_SPEC}`,
      ];
      const trainingParams = arrayTrainingParams.join('&');
      const result = await dispatch(getDataStatement(trainingParams))
      setDataFromApi(result);
      return result
    }
    getDataTraining().then((result) => {
      if (dataUnloadApi) {
        onReloadInformationToStudyTraining(result);
      }
    })
    return () => {
      setDataUnloadApi(true);
      values.typeTraining = '';
    };
  }, []);

  const showStudyField = () => {
    return values.typeTraining;
  }
  const showTargetTraining = () => {
    return values.typeTraining === 'targetTraining';
  }
  const showOlympiad = () => {
    return values.typeTraining === 'olympiad';
  }
  const showSpecialtiesByTheEconomy = () => {
    return values.typeTraining === 'specialtiesByTheEconomy';
  }
  const showPedagogicalSpecialties = () => {
    return values.typeTraining === 'pedagogicalSpecialties';
  }
  const showGold = () => {
    return values.typeTraining === 'gold';
  }
  const showGeneralBasis = () => {
    return values.typeTraining === 'generalBasis' || values.typeTraining === 'generalBasisWithoutPrivileges';
  }

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} justifyContent="center" className={classes.grid_container}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader title="Выбор специальности"/>
            <Divider/>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controls.Select
                    fullWidth
                    name='typeTraining'
                    label={"Я буду подавать документы*"}
                    value={values.typeTraining}
                    options={itemsTypeTraining}
                    onChange={handleInputChange}
                    error={errors.typeTraining}
                  />
                </Grid>
                {showStudyField() ?
                  <Grid item xs={12}>
                    {showGeneralBasis() ?
                      <GeneralBasis typeTraining={values.typeTraining} dataFromApi={dataFromApi}/>
                      : null
                    }
                    {showTargetTraining() ?
                      <TargetTraining typeTraining={values.typeTraining} dataFromApi={dataFromApi}/>
                      : null
                    }
                    {showOlympiad() ?
                      <Olympiad typeTraining={values.typeTraining} dataFromApi={dataFromApi}/>
                      : null
                    }
                    {showSpecialtiesByTheEconomy() ?
                      <SpecialtiesByTheEconomy typeTraining={values.typeTraining} dataFromApi={dataFromApi}/>
                      : null
                    }
                    {showPedagogicalSpecialties() ?
                      <PedagogicalSpecialties typeTraining={values.typeTraining} dataFromApi={dataFromApi}/>
                      : null
                    }
                    {showGold() ?
                      <Gold typeTraining={values.typeTraining} dataFromApi={dataFromApi}/>
                      : null
                    }
                  </Grid>
                  : null
                }
              </Grid>
            </CardContent>
          </Card>

        </Grid>
      </Grid>
    </Container>
  )
};

export default Training;