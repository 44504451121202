import {Menu, MenuItem} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

export default function TargetMenu(props) {
    const {
        onClose: PropsOnClose,
        onClick: PropsOnClick,
        element: PropsElement,
        maxFiles: PropsMaxFiles,
        item: PropsItem
    } = props;

    function onCloseMenu() {
        PropsOnClose && PropsOnClose();
    }

    function onClickMenu(window,item) {
        PropsOnClick && PropsOnClick(window,item);
    }
    return (
        <Menu anchorEl={PropsElement}
              keepMounted
              open={Boolean(PropsElement)}
              onClose={onCloseMenu}>
            <MenuItem onClick={() => onClickMenu('view', PropsItem)}>
                Просмотреть
            </MenuItem>
            <MenuItem onClick={() => onClickMenu('change', PropsItem)}>
                Изменить файл
            </MenuItem>
            {(PropsMaxFiles && PropsMaxFiles > 1) || !PropsMaxFiles
                ? <MenuItem onClick={() => onClickMenu('delete', PropsItem)}>
                    Удалить
            </MenuItem>
                : null}
        </Menu>
    )
}
TargetMenu.propTypes = {
    onClose: PropTypes.func.isRequired,
    element: PropTypes.any,
    item: PropTypes.number,
    maxFiles: PropTypes.any,
    onClick: PropTypes.func.isRequired
};
