import classes from "./ChatList.module.css";
import React from "react";
import {useSelector} from "react-redux";

export default function ChatList(props) {
    const {chatList: chats, chatListLoading: chatsLoading, messageListLoading: messageLoading} = useSelector(state => state.chatReducer);
    const {onClick: PropsOnClick, current: PropsCurrent} = props;

    function onClick(current) {
        if(chatsLoading || messageLoading) return;
        PropsOnClick && PropsOnClick(current);
    }

    chats.sort((a, b) => {
        if (a.status !== "ARCHIVE" && b.status !== "ARCHIVE") {
            if (b.newMessages !== a.newMessages) {
                return b.newMessages - a.newMessages;
            } else {
                return new Date(b.lastVisit) - new Date(a.lastVisit);
            }
        } else {
            if (a.status !== "ARCHIVE" && b.status === "ARCHIVE") {
                return -1;
            } else {
                return 1;
            }
        }
    });

    return (
        <div className={classes['list__box']}>
            <div className={classes['list__box__title']}>
                Список чатов
            </div>
            {chats?.length ?
                <div className={classes['list__box_items']}>
                    {chats?.map((chat, index) => {
                        let classesItem = classes['list__box_item'];
                        if (PropsCurrent !== null && PropsCurrent === chat?.id) {
                            classesItem += ` ${classes['list__box_item_active']}`;
                        }
                        if (chat?.status === "ARCHIVE") {
                            classesItem += ` ${classes['list__box_item_archive']}`;
                        }
                        if (chat?.newMessages !== 0) {
                            classesItem += ` ${classes['list__box__status__new_message']}`;
                        }
                        return (
                            <div className={`${classesItem}`}
                                 key={`${chat?.id}-${index}`} onClick={() => onClick(chat?.id)}>
                                <div className={classes['list__box_item_title']}>
                                    {chat?.title?.title}
                                </div>
                            </div>
                        )
                    })}
                </div>
                : null
            }
        </div>
    )
}
