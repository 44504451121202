import {Button, Grid, TextField, Typography} from "@material-ui/core";
import Countdown from "../../../../components/Countdown";
import React, {useState} from "react";
import {useForm} from "../../../../components/Customs/useForm";
import Valid from "../../../../constants/Valid";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {Alert} from "@material-ui/lab";

const initialFValues = {
    tokenConfirm: ""
};

function useConfirm(propsOnSubmit, PropsOnReloadMessage, validate, values) {
    const [loading, setLoading] = useState(true);


    function onSubmit(e) {
        e.preventDefault();
        if (validate()) {
            propsOnSubmit && propsOnSubmit(values)
        }
    }

    async function onResetSMS() {
        if (loading) return;
        PropsOnReloadMessage && await PropsOnReloadMessage();
        setLoading(true);
    }

    function onTimeOut() {
        setLoading(false);
    }

    return {loading, onSubmit, onResetSMS, onTimeOut};
}

const _DefaultProps = {
    timeCount: 60
}

export default function Confirm(props) {
    const {
        onSubmit: propsOnSubmit,
        onReloadMessage: PropsOnReloadMessage,
        sending: propsSending,
        data: PropsData
    } = props;
    const validate = (fieldValues = values) => {
        let temp = {...errors};
        if ('tokenConfirm' in fieldValues) {
            temp.tokenConfirm = "";
            if (temp?.tokenConfirm === "" && !Valid.isIsset(fieldValues?.tokenConfirm)) {
                temp.tokenConfirm = "Обязательно для заполнения.";
            }
        }

        setErrors({...temp});
        if (fieldValues === values) {
            return Object.values(temp).every(x => x === '')
        }
    };


    const {
        values,
        errors,
        setErrors,
        handleInputChange: onChange
    } = useForm(initialFValues, true, validate);
    const classes = useStyles();
    const {
        loading,
        onSubmit,
        onResetSMS,
        onTimeOut
    } = useConfirm(propsOnSubmit, PropsOnReloadMessage, validate, values);
    return (
        <form className={classes.form} onSubmit={onSubmit} noValidate>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        {props.children}
                    </Typography>

                    <Grid container spacing={2} direction="column"
                          alignItems="center">
                        {PropsData !== null && (PropsData?.phone || PropsData?.email) ?
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    {PropsData?.phone ? <React.Fragment>Код подтверждения отправлен на
                                            телефон <strong>{PropsData.phone}</strong></React.Fragment>
                                        : null}
                                    {PropsData?.email ? <React.Fragment>Код подтверждения отправлен на электронную
                                            почту <strong>{PropsData.email}</strong></React.Fragment>
                                        : null}
                                </Alert>
                            </Grid>
                            : null}
                        <Grid item xs={12}>
                            <Typography variant="body2" align="center">
                                Если Вам не пришло сообщение, сделайте повторвый запрос
                            </Typography>
                        </Grid>
                        <Grid item>
                            {loading
                                ? <Countdown initSec={_DefaultProps.timeCount} handleTimeout={onTimeOut}/>
                                : <Button type="button" variant="contained"
                                          color="primary"
                                          className={classes.submit}
                                          onClick={onResetSMS}>
                                    Сообщение не получено
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        variant="outlined"
                        margin="normal"
                        id="tokenConfirm"
                        name="tokenConfirm"
                        label="Код подтверждения"
                        type="text"
                        fullWidth
                        autoFocus
                        value={values.tokenConfirm}
                        onChange={onChange}
                        error={Boolean(errors.tokenConfirm)}
                        helperText={errors.tokenConfirm && errors.tokenConfirm}
                    />
                </Grid>
            </Grid>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className={classes.submit}
                disabled={propsSending}
            >
                Отправить
            </Button>

        </form>
    )
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
Confirm.propTypes = {
    onSubmit: PropTypes.func,
    onReload: PropTypes.func,
    sending: PropTypes.bool,
    data: PropTypes.any
};
