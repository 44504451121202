import resolver from "./resolver";
import actionSpecPriority from "./actionSpecPriority";
import actionTesting from "./actionTesting";
import actionResultTesting from "./actionResultTesting";
import actionCommentTesting from "./actionCommentTesting";
import actionFinalResult from "./actionFinalResult";
import actionCommentResult from "./actionCommentResult";
import actionSchoolResult from "./actionSchoolResult";

const actionEnrollResult = ({status}) => [
  {
    type: "%_ENROLL_DOCUMENT_%",
    action: ({message}) => {
      return message.replace("%_ENROLL_DOCUMENT_%", status._ENROLL_DOCUMENT_)
    }
  },
  {
    type: "%_FORM_ENROLL_%",
    action: ({message}) => {
      const result =`<span class="font-weight-medium ">` + status._ENROLL_FORM_ + `</span>`;
      return message.replace("%_FORM_ENROLL_%", result);
    }
  },
  {
    type: "%_ENROLL_TYPE_%",
    action: ({message}) => {
      const result =`<span class="font-weight-medium ">` + status._ENROLL_TYPE_ + `</span>`;
      return message.replace("%_ENROLL_TYPE_%", result);
    }
  },
  {
    type: "%_FACULTY_%",
    action: ({message}) => {
      const result =`<span class="font-weight-medium">` + status._FACULTY_ + `</span>`;
      return  message.replace("%_FACULTY_%", result );
    }
  },
  {
    type: "%_ENROLL_SPECIALITY_%",
    action: ({message}) => {
      const result =`<span class="font-weight-medium">` + status._ENROLL_SPECIALITY_ + `</span>`;
      return  message.replace("%_ENROLL_SPECIALITY_%", result );
    }
  },
  {
    type: "%_YOU_CRASAVCHEG_%",
    action: ({message, vars}) => {
      const enrollVar = vars.find(item => item.name === "YOU_CRASAVCHEG");
      const result = enrollVar.messages.map(entry => {
        return `<div class="text-center ma-0 text-uppercase font-weight-bold" style="font-size: 1rem; line-height: 1.75rem">` + entry + `</div>`;
      }).join('')

      return  message.replace("%_YOU_CRASAVCHEG_%", result );
    }
  },
  {
    type: "%_WELCOME_%",
    action: ({message, vars}) => {
      const enrollVar = vars.find(item => item.name === "WELCOME");
      const result = enrollVar.messages.map(entry => {
        return `<div class="text-center ma-0 ">` + entry + `</div>`;
      }).join('')

      return  message.replace("%_WELCOME_%", result );
    }
  },
  {
    type: "%_ALL_BEST_%",
    action: ({message, vars}) => {
      const enrollVar = vars.find(item => item.name === "ALL_BEST");
      const result =`<div class="text-center ma-0 text-uppercase font-weight-bold">` + enrollVar.messages.join('') + `</div>`;
      return  message.replace("%_ALL_BEST_%", result );
    }
  },
  {
    type: "%_SPEC_PRIORITY_%",
    action: ({vars, status}) => {

      if (status._PRIORITY_.length > 1) {

        const disciplineVar = vars.find(item => item.name === "SPEC_PRIORITY_ANY")

        return resolver({
          messages: disciplineVar.messages,
          actions: actionSpecPriority({arraySpec: status._PRIORITY_}),
          vars: vars,
          status: status
        }).join('')

      } else if (status._PRIORITY_.length === 1) {
        return status._PRIORITY_
          .map(spec => {

            const disciplineVar = vars.find(item => item.name === "SPEC_PRIORITY_SINGLE")

            return resolver({
              messages: disciplineVar.messages,
              actions: actionSpecPriority({spec: spec}),
              vars: vars,
              status: status
            })

          })
          .join('')
      }

      return ''

    }
  },
  {
    type: "%_CARYSEL_%",
    action: ({message, status}) => message.replace("%_CARYSEL_%", status._CARYSEL_)
  },
  {
    type: "%_PAY_FORM_%",
    action: ({message, status}) => message.replace("%_PAY_FORM_%", status._TYPE_)
  },
  {
    type: "%_SPECIALITY_TITLE_%",
    action: ({message, status}) => message.replace("%_SPECIALITY_TITLE_%", status._SPECIALITY_TITLE_.trim())
  },
  {
    type: "%_STUDY_FORM_%",
    action: ({message, status}) => message.replace("%_STUDY_FORM_%", status._FORM_)
  },
  {
    type: "%_TYPE_TYPE_%",
    action: ({message, status}) => message.replace("%_TYPE_TYPE_%", status._TYPE_TYPE_)
  },
  {
    type: "%_TRAINING_HREF_FACULTY_%",
    action: ({message, vars}) => {
      const value_training_faculcy_var = vars.find(item => item.name === "TRAINING_FACULTY_VALUE");
      const href_training_faculcy_var = vars.find(item => item.name === "TRAINING_HREF_FACULTY");
      const link = `<a href="${href_training_faculcy_var.messages.join('')}" target="_blank" class=" pointer">${value_training_faculcy_var.messages.join('')}</a>`;
      return message.replace("%_TRAINING_HREF_FACULTY_%", link)
    }
  },
  {
    type: "%_TRAINING_HREF_%",
    action: ({message, vars}) => {
      const value_training_faculcy_var = vars.find(item => item.name === "TRAINING_VALUE");
      const href_training_faculcy_var = vars.find(item => item.name === "TRAINING_HREF");
      const link = `<a href="${href_training_faculcy_var.messages.join('')}" target="_blank" class=" pointer">${value_training_faculcy_var.messages.join('')}</a>`;
      return message.replace("%_TRAINING_HREF_%", link)
    }
  },
  {
    type: "%_TESTING_%",
    action: ({vars, status}) => {
      return status._TESTING_
        .sort((a, b) => {
          if (a.__NUM_EX > b.__NUM_EX) {
            return 1;
          }
          if (a.__NUM_EX < b.__NUM_EX) {
            return -1;
          }
          return 0
        })
        .map(test => {
          const disciplineVar = vars.find(item => item.name === "TESTING")

          return resolver({
            messages: disciplineVar.messages,
            actions: actionTesting({test: test}),
            vars: vars,
            status: status
          })

        })
        .map(messages => {

          return `<ol style="list-style-type: none" class="mb-2">`
            + messages.map(e => `<li>${e}</li>`).join('')
            + `</ol>`
        })
        .join('')

    }
  },
  {
    type: "%_RESULT_TESTING_%",
    action: ({vars, status}) => {
      return status._RESULT_TESTING_
        .sort((a, b) => {
          if (a.__NUM_EX > b.__NUM_EX) {
            return 1;
          }
          if (a.__NUM_EX < b.__NUM_EX) {
            return -1;
          }
          return 0
        })
        .map(result => {
          const disciplineVar = vars.find(item => item.name === "RESULT_TESTING")

          return resolver({
            messages: disciplineVar.messages,
            actions: actionResultTesting({result: result}),
            vars: vars,
            status: status
          })

        })
        .map(messages => {

          return `<ol style="list-style-type: none" class="mb-2">`
            + messages.map(e => `<li>${e}</li>`).join('')
            + `</ol>`
        })
        .join('')

    }
  },

  // todo: change hard href to href from api
  {
    type: "%_COMMENT_TESTING_%",
    action: ({vars, status}) => {

      if (status._TESTING_.length > 0) {
        const href_abit_testing = 'https://abit.grsu.by/view/373-raspisanie-vstupitel-nykh-ispytanij-2020-goda.html';

        const disciplineVar = vars.find(item => item.name === "COMMENT_TESTING")

        return resolver({
          messages: disciplineVar.messages,
          actions: actionCommentTesting({href_abit_testing: href_abit_testing}),
          vars: vars,
          status: status
        }).join('')
      }

      return ''

    }
  },
  {
    type: "%_DOC_REQUEST_APPLICATION_%",
    action: ({message, vars}) => {
      const entryVar = vars.find(item => item.name === "DOC_REQUEST_APPLICATION")
      const href = entryVar.ref;
      const text = entryVar.text;
      const link = `<a href="${href}" target="_blank" class="pointer">${text}</a>`;
      return message.replace("%_DOC_REQUEST_APPLICATION_%", link);
    }
  },
  {
    type: "%_FINAL_RESULT_%",
    action: ({vars, status}) => {

      const disciplineVar = vars.find(item => item.name === "FINAL_RESULT")

      return resolver({
        messages: disciplineVar.messages,
        actions: actionFinalResult({status: status}),
        vars: vars,
        status: status
      }).join('')

    }
  },
  {
    type: "%_COMMENT_RESULT_%",
    action: ({vars, status}) => {

      const disciplineVar = vars.find(item => item.name === "COMMENT_RESULT")

      return resolver({
        messages: disciplineVar.messages,
        actions: actionCommentResult({status: status}),
        vars: vars,
        status: status
      }).join('')

    }
  },
  {
    type: "%_SCHOOL_RESULT_%",
    action: ({vars, status}) => {

      const disciplineVar = vars.find(item => item.name === "SCHOOL_RESULT")

      return resolver({
        messages: disciplineVar.messages,
        actions: actionSchoolResult({status: status}),
        vars: vars,
        status: status
      }).join('')

    }
  },

]

export default actionEnrollResult;