import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider, FormControl, FormHelperText,
  Grid,
  InputLabel,
  MenuItem, Select,
  TextField, Tooltip
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useForm} from "../../../../components/Customs/useForm";
import Valid from "../../../../constants/Valid";
import {DatePicker} from "@material-ui/pickers";
import {useDispatch, useSelector} from "react-redux";
import {PROFILE_USER_DATA_GET, PROFILE_USER_MANYDATA_POST} from "../../../../store/actions/profile";
import {
  USER_BORNDATE,
  USER_EDUCATION_INSTITUTIONS_DATE,
  USER_EDUCATION_INSTITUTIONS_NAME,
  USER_EDUCATION_INSTITUTIONS_ROOM,
  USER_EMAIL,
  USER_FIRST_NAME, USER_FULL_ADDRESS,
  USER_GENDER,
  USER_PATRONYMIC,
  USER_PHONE_MOBILE,
  USER_SURNAME,
  USER_TERMS
} from "../../../../services/tagTypes";
import REGULAR_EXPRESSION from "../../../../constants/RegExp";
import {chatAction_createNotification} from "../../../../store/Chat/ChatActions";
import LoadingSlider from "../../../../components/LoadingSlider/LoadingSlider";
import DeleteIcon from '@material-ui/icons/Delete';
import {DialogCustom, useDialog} from "../../../../components/Customs/useDialog";
import DeleteConfirm from "../../../../components/DeleteConfirm";
import {deleteAccount, deleteAccountConfirm} from "../../../../store/System/actionSystem";
import {logout} from "../../../../store/actions/auth";
import InputForObj from "../../../../components/componentsMUI/InputForObj";
import Controls from "../../../../components/Customs/Controls/Controls";
import {useHistory} from "react-router";

const initialFValues = {
  surName: "",
  firstName: "",
  patronymic: "",
  sex: "",
  dateOfBirth: null,
  email: "",
  phone: "",
  graduatedEducationalInstitution: "",
  classInEducationalInstitution: "",
  yearOfEnding: null,
  cityAddress: "",
  address: "",
  terms: true,
};

function useDeleteAccount() {
  let isMount = true;
  const dispatch = useDispatch();

  async function onDeleteAccount() {

    try {
      const response = await dispatch(deleteAccount());
      dispatch(chatAction_createNotification({
        message: 'Введите код подтверждения',
        variant: 'success'
      }))
    } catch (e) {
      dispatch(chatAction_createNotification({
        message: 'Ошибка удаления аккаунта',
        variant: 'error'
      }));
      console.error(`Critical error: ${JSON.stringify(e)}`);
    }

  }

  async function onDeleteAccountConfirm(data) {
    try {
      const tokenObj = {"token": data?.tokenConfirm};
      const response = await dispatch(deleteAccountConfirm(tokenObj));
      dispatch(chatAction_createNotification({
        message: 'Аккаунт успешно удален',
        variant: 'success'
      }));
      await dispatch(logout());
    } catch (e) {
      if (e?.data?.message === 'Token invalid or not permission') {
        dispatch(chatAction_createNotification({
          message: 'Ошибка. Указан неверный проверочный код.',
          variant: 'error'
        }));
      } else {
        dispatch(chatAction_createNotification({
          message: 'Ошибка удаления аккаунта',
          variant: 'error'
        }));
      }

      // console.error(`Critical error: ${JSON.stringify(e.response)}`);
    }
  }


  useEffect(() => {
    return () => {
      isMount = false;
    }
  }, [])

  return {
    onDeleteAccount,
    onDeleteAccountConfirm,
  };

}

function useProfileForm(validate, values) {
  let isMount = true;
  const history = useHistory();
  const dispatch = useDispatch();
  const {user: userMe} = useSelector(state => state.authReducer);
  const {profileInfo: userData} = useSelector(state => state.profileReducer);
  const [isEmailBlocked, setEmailBlocked] = useState(false);
  const [isPhoneBlocked, setPhoneBlocked] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    function onChangeUserMe() {
      if (userMe?.credential?.length > 0) {
        if (userMe.credential.find(item => item.status === "ACTIVE" && item.type === "PHONE")) {
          if (!isPhoneBlocked) {
            setPhoneBlocked(true)
          }
        } else {
          if (isPhoneBlocked) {
            setPhoneBlocked(false)
          }
        }
        if (userMe.credential.find(item => item.status === "ACTIVE" && item.type === "EMAIL")) {
          if (!isEmailBlocked) {
            setEmailBlocked(true)
          }
        } else {
          if (isEmailBlocked) {
            setEmailBlocked(false)
          }
        }
      }
    }

    onChangeUserMe();
  }, [userMe]);

  useEffect(() => {
    function onChangeUserData() {
      if (userData?.length > 0) {
        const item = userData;
        let surName = item?.find(data => data?.typeTitle === USER_SURNAME);
        if (surName !== undefined) {
          values.surName = surName?.value?.value;
        }
        let firstName = item?.find(data => data?.typeTitle === USER_FIRST_NAME);
        if (firstName !== undefined) {
          values.firstName = firstName?.value?.value;
        }
        let patronymic = item?.find(data => data?.typeTitle === USER_PATRONYMIC);
        if (patronymic !== undefined) {
          values.patronymic = patronymic?.value?.value;
        }
        let sex = item?.find(data => data?.typeTitle === USER_GENDER);
        if (sex !== undefined) {
          values.sex = sex?.value?.value.toLowerCase();
        }
        let dateOfBirth = item?.find(data => data?.typeTitle === USER_BORNDATE);
        if (dateOfBirth !== undefined) {
          values.dateOfBirth = dateOfBirth?.value?.value;
        }

        let email = item?.find(data => data?.typeTitle === USER_EMAIL);
        if (email !== undefined) {
          values.email = email?.value?.value;
        }

        let phone = item?.find(data => data?.typeTitle === USER_PHONE_MOBILE);
        if (phone !== undefined) {
          values.phone = phone?.value?.value;
        }

        let graduatedEducationalInstitution = item?.find(data => data?.typeTitle === USER_EDUCATION_INSTITUTIONS_NAME);
        if (graduatedEducationalInstitution !== undefined) {
          values.graduatedEducationalInstitution = graduatedEducationalInstitution?.value?.value;
        }

        let classInEducationalInstitution = item?.find(data => data?.typeTitle === USER_EDUCATION_INSTITUTIONS_ROOM);
        if (classInEducationalInstitution !== undefined) {
          values.classInEducationalInstitution = classInEducationalInstitution?.value?.value;
        }

        let yearOfEnding = item?.find(data => data?.typeTitle === USER_EDUCATION_INSTITUTIONS_DATE);
        if (yearOfEnding !== undefined) {
          values.yearOfEnding = yearOfEnding?.value?.value;
        }

        let address = item?.find(data => data?.typeTitle === USER_FULL_ADDRESS);
        if (address !== undefined) {
          values.address = address?.value?.value;
        }
      }
    }

    onChangeUserData();
  }, [userData]);

  useEffect(async () => {
    async function onLoadingData() {
      setLoading(true);
      try {
        await dispatch(PROFILE_USER_DATA_GET());
        setLoading(false);
      } catch (e) {
        console.error(`Critical error: ${JSON.stringify(e.response)}`);
        setLoading(false);
      }

    }

    if (isMount) {
      await onLoadingData();
    }
    return () => {
      isMount = false;
    };
  }, []);

  async function onSubmit(e) {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const data = {
        ...(values.surName && {USER_SURNAME: {value: values.surName}}),
        ...(values.firstName && {USER_FIRST_NAME: {value: values.firstName}}),
        ...(values.patronymic && {USER_PATRONYMIC: {value: values.patronymic}}),
        ...(values.sex && {USER_GENDER: {value: values.sex}}),
        ...(values.dateOfBirth && {USER_BORNDATE: {value: values.dateOfBirth}}),
        ...(values.email && !isEmailBlocked && {USER_EMAIL: {value: values.email}}),
        ...(values.phone && !isPhoneBlocked && {USER_PHONE_MOBILE: {value: values.phone}}),
        ...(values.graduatedEducationalInstitution && {USER_EDUCATION_INSTITUTIONS_NAME: {value: values.graduatedEducationalInstitution}}),
        ...(values.classInEducationalInstitution && {USER_EDUCATION_INSTITUTIONS_ROOM: {value: values.classInEducationalInstitution}}),
        ...(values.yearOfEnding && {USER_EDUCATION_INSTITUTIONS_DATE: {value: values.yearOfEnding}}),
        ...(values.address && {USER_FULL_ADDRESS: {value: values.address}}),
        ...(values.terms && {USER_TERMS: {value: values.terms}})
      };
      try {
        const response = await dispatch(PROFILE_USER_MANYDATA_POST(data));
        if (Object.values(data)?.length === Object.values(response)?.length) {
          dispatch(chatAction_createNotification({
            message: 'Данные успешно сохранены',
            variant: 'success'
          }))

        }
        setLoading(false);
        // history.push("/statement");
        history.push("/home")
      } catch (e) {
        dispatch(chatAction_createNotification({
          message: 'Ошибка сохранения данных',
          variant: 'error'
        }));
        console.error(`Critical error: ${JSON.stringify(e.response)}`);
        setLoading(false);
      }

    }
  }

  return {
    handlers: {onSubmit},
    options: {isEmailBlocked, isPhoneBlocked, isLoading, setLoading}
  }
}

export default function ProfileForm() {
  const history = useHistory();
  const statementStatus = useSelector(state => state.statementReducer.statementStatus);
  const {profileInfo} = useSelector(state => state.profileReducer);
// todo иногда переносит на terms даже если они уже выбраны
  useEffect(() => {
    options.setLoading(true);
    const terms = profileInfo.find(data => data.typeTitle === USER_TERMS);
    if (terms?.value?.value === undefined || terms?.value?.value !== true) {
      history.push("/terms");
    } else {
      options.setLoading(false);
    }
    return () => {
      options.setLoading(false);
    };
  }, []);

  const validate = (fieldValues = values) => {
    let temp = {...errors};

    if ('firstName' in fieldValues) {
      temp.firstName = "";
      if (/^[а-яА-ЯёЁ\s-]*$/.test(fieldValues?.firstName) === false) {
        temp.firstName = "Только символы русского языка.";
      }
      if (temp?.firstName === "" && !Valid.isIsset(fieldValues?.firstName)) {
        temp.firstName = "Обязательно для заполнения.";
      }
    }
    if ('surName' in fieldValues) {
      temp.surName = "";
      if (/^[а-яА-ЯёЁ\s-]*$/.test(fieldValues?.surName) === false) {
        temp.surName = "Только символы русского языка.";
      }
      if (temp?.surName === "" && !Valid.isIsset(fieldValues?.surName)) {
        temp.surName = "Обязательно для заполнения.";
      }
    }
    if ('patronymic' in fieldValues) {
      temp.patronymic = "";
      if (/^[а-яА-ЯёЁ\s-]*$/.test(fieldValues?.patronymic) === false) {
        temp.patronymic = "Только символы русского языка.";
      }
    }
    if ('sex' in fieldValues) {
      temp.sex = "";
      if (temp?.sex === "" && !Valid.isIsset(fieldValues?.sex)) {
        temp.sex = "Обязательно для выбора.";
      }
    }
    if ('dateOfBirth' in fieldValues) {
      temp.dateOfBirth = "";
      if (temp?.dateOfBirth === "" && !Valid.isIsset(fieldValues?.dateOfBirth)) {
        temp.dateOfBirth = "Обязательно для выбора.";
      }
      if (temp?.dateOfBirth === "" && !Valid.isMoreThan(fieldValues?.dateOfBirth, '2008-01-01')) {
        temp.dateOfBirth = "Дата рождения не может быть больше 31.12.2007.";
      }
    }
    if ('phone' in fieldValues) {
      temp.phone = "";
      if (temp?.phone === "" && !Valid.isIsset(fieldValues?.phone)) {
        temp.phone = "Обязательно для заполнения.";
      }
      if (temp?.phone === "" && REGULAR_EXPRESSION.MOBILE_PHONE.test(fieldValues?.phone) === false) {
        temp.phone = "Неподходящий формат номера телефона";
      }

    }
    if ('email' in fieldValues) {
      temp.email = "";
      if (temp?.email === "" && !Valid.isIsset(fieldValues?.email)) {
        temp.email = "Обязательно для заполнения.";
      }
      if (temp?.email === "" && REGULAR_EXPRESSION.EMAIL.test(fieldValues?.email) === false) {
        temp.email = "Неподходящий формат электронной почты";
      }
    }
    // if ('graduatedEducationalInstitution' in fieldValues) {
    //   temp.graduatedEducationalInstitution = "";
    //   if (temp?.graduatedEducationalInstitution === "" && !Valid.isIsset(fieldValues?.graduatedEducationalInstitution)) {
    //     temp.graduatedEducationalInstitution = "Обязательно для заполнения.";
    //   }
    // }
    // if ('classInEducationalInstitution' in fieldValues) {
    //   temp.classInEducationalInstitution = "";
    //   if (temp?.classInEducationalInstitution === "" && !Valid.isIsset(fieldValues?.classInEducationalInstitution)) {
    //     temp.classInEducationalInstitution = "Обязательно для заполнения.";
    //   }
    // }
    // if ('yearOfEnding' in fieldValues) {
    //   temp.yearOfEnding = "";
    //   if (temp?.yearOfEnding === "" && !Valid.isIsset(fieldValues?.yearOfEnding)) {
    //     temp.yearOfEnding = "Обязательно для заполнения.";
    //   }
    // }
    // if ('address' in fieldValues) {
    //   temp.address = "";
    //   if (temp?.address === "" && !Valid.isIsset(fieldValues?.address)) {
    //     temp.address = "Обязательно для заполнения.";
    //   }
    // }
    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const {
    values,
    errors,
    setErrors,
    handleInputChange: onChange
  } = useForm(initialFValues, true, validate);
  const {
    open,
    scroll,
    handleClickOpen,
    handleClose,
  } = useDialog();
  const {
    onDeleteAccount,
    onDeleteAccountConfirm
  } = useDeleteAccount();

  const {handlers, options} = useProfileForm(validate, values);

  const showDeleteAccount = () => {
    return statementStatus !== 'IS_ASU';
  }

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12}>
        <LoadingSlider open={options.isLoading}/>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Card>
              <CardHeader title="Информация"/>
              <Divider/>
              <CardContent>
                <form onSubmit={handlers.onSubmit} noValidate>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        variant="outlined"
                        fullWidth
                        id="surName"
                        label="Фамилия"
                        name="surName"
                        type="text"
                        value={values.surName}
                        onChange={onChange}
                        error={Boolean(errors.surName)}
                        helperText={errors.surName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        variant="outlined"
                        fullWidth
                        id="firstName"
                        label="Имя"
                        name="firstName"
                        type="text"
                        value={values.firstName}
                        onChange={onChange}
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="patronymic"
                        label="Отчество"
                        name="patronymic"
                        type="text"
                        value={values.patronymic}
                        onChange={onChange}
                        error={Boolean(errors.patronymic)}
                        helperText={errors.patronymic}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl variant="outlined" error={Boolean(errors.sex)}
                                   fullWidth>
                        <InputLabel id="profile-sex-form">Пол</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="profile-sex-form"
                          value={values.sex}
                          onChange={onChange}
                          label="Пол"
                          name="sex"
                        >
                          <MenuItem value="">
                            <em>Выберите</em>
                          </MenuItem>
                          <MenuItem value={"male"}>Мужской</MenuItem>
                          <MenuItem value={"female"}>Женский</MenuItem>
                        </Select>
                        {Boolean(errors.sex) ?
                          <FormHelperText>{errors.sex}</FormHelperText> : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Controls.DatePicker
                        name="dateOfBirth"
                        label="Дата рождения *"
                        value={values.dateOfBirth}
                        onChange={onChange}
                        error={errors.dateOfBirth}
                        disableFuture
                        openTo="year"
                        views={["year", "month", "date"]}
                        minDate={new Date('1950-01-01')}
                        maxDate={new Date(new Date().getFullYear() - 14, 0, 1)}
                        id="dateOfBirth"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        required
                        disabled={options.isEmailBlocked}
                        variant="outlined"
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        type="text"
                        value={values.email}
                        onChange={onChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        disabled={options.isPhoneBlocked}
                        required
                        variant="outlined"
                        fullWidth
                        id="phone"
                        label="Контактный телефон"
                        name="phone"
                        type="text"
                        value={values.phone}
                        onChange={onChange}
                        error={Boolean(errors.phone)}
                        helperText={errors.phone}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        // required
                        variant="outlined"
                        fullWidth
                        id="graduatedEducationalInstitution"
                        label="Учебное заведение"
                        name="graduatedEducationalInstitution"
                        type="text"
                        value={values.graduatedEducationalInstitution}
                        onChange={onChange}
                        error={Boolean(errors.graduatedEducationalInstitution)}
                        helperText={errors.graduatedEducationalInstitution}
                      />
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <TextField
                        // required
                        variant="outlined"
                        fullWidth
                        id="classInEducationalInstitution"
                        label="Класс"
                        name="classInEducationalInstitution"
                        type="text"
                        value={values.classInEducationalInstitution}
                        onChange={onChange}
                        error={Boolean(errors.classInEducationalInstitution)}
                        helperText={errors.classInEducationalInstitution}
                      />

                    </Grid>
                    <Grid item md={3} xs={6}>
                      <Controls.DatePicker
                        name="yearOfEnding"
                        label="Год окончания"
                        value={values.yearOfEnding}
                        onChange={onChange}
                        error={errors.yearOfEnding}
                        openTo="year"
                        views={["year"]}
                        minDate={new Date('1960-01-01')}
                        maxDate={new Date(new Date().getFullYear() + 1, 0, 1)}
                        id="yearOfEnding"
                        // required
                        format="yyyy"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Tooltip title={'Беларусь, Гродненская область, г.Гродно, ул. Ожешко, д.22, кв.1' || ''} arrow>
                        <TextField
                          // required
                          variant="outlined"
                          fullWidth
                          id="address"
                          label="Адрес проживания"
                          name="address"
                          type="text"
                          value={values.address}
                          onChange={onChange}
                          // tooltip={'Беларусь, Гродненская область, г.Гродно, ул. Ожешко, д.22'}
                          error={Boolean(errors.address)}
                          helperText={errors.address}
                        />
                      </Tooltip>

                    </Grid>
                    {/*<Grid item md={12} xs={12}>*/}
                    {/*  <InputForObj*/}
                    {/*    required={true}*/}
                    {/*    variant="outlined"*/}
                    {/*    fullWidth*/}
                    {/*    name="cityAddress"*/}
                    {/*    label="Адрес"*/}
                    {/*    id="cityAddress"*/}
                    {/*    value={values.cityAddress}*/}
                    {/*    onChangeMyInput={(e) => onChange({*/}
                    {/*      target: {*/}
                    {/*        value: e,*/}
                    {/*        name: 'cityAddress'*/}
                    {/*      }*/}
                    {/*    })}*/}
                    {/*    error={Boolean(errors.cityAddress) ? true : undefined}*/}
                    {/*  />*/}
                    {/*</Grid>*/}
                    <Grid item container xs={12} justifyContent={"space-between"} spacing={1}>
                      <Grid item>
                        {showDeleteAccount()
                          ?
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled={options.isLoading}
                            startIcon={<DeleteIcon/>}
                            onClick={async () => {
                              if (window.confirm('Вы уверены, что хотите удалить свой аккаунт? В случае удаления Вы сможете зарегистрироваться заново.')) {
                                await onDeleteAccount();
                                handleClickOpen();
                              }
                            }}
                          >
                            Удалить аккаунт
                          </Button>
                          : null
                        }
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={options.isLoading}
                        >
                          Сохранить
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
                <DialogCustom
                  open={open}
                  scroll={scroll}
                  handleClose={handleClose}
                  dialogTitle={'Удалить аккаунт'}
                  dialogContextText={
                    <DeleteConfirm
                      onSubmit={onDeleteAccountConfirm}
                    />
                  }
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
