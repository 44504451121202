import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" target="_blank" rel="noopener" href="https://abit.grsu.by/">
        ГрГУ имени Янки Купалы,
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default Copyright;
