import {
  CREATE_APPLICATION_STATEMENT_ACTIVE_FAIL,
  CREATE_APPLICATION_STATEMENT_ACTIVE_SUCCESS,
  GET_APPLICATIONS_STATEMENT_ACTIVE_SUCCESS,
  GET_APPLICATIONS_STATEMENT_ACTIVE_FAIL,
  GET_SELECTED_FACULTY_STATEMENT,
  GET_SELECTED_SPECIALITY_BUDGET_STATEMENT,
  GET_SELECTED_SPECIALITY_GROUP_STATEMENT,
  GET_SELECTED_SPECIALITY_PAID_STATEMENT,
  GET_SELECTED_STUDY_FORM_STATEMENT,
  GET_SELECTED_STUDY_PAY_STATEMENT,
  SET_CONTRACT_FILE_STATEMENT,
  SET_DATA_STATUS_MESSAGES_ASU,
  SET_STATEMENT_STATUS,
  SET_STATUS_FORMS_APPLICATION_STATEMENT, SET_TEMPLATE_STATUS_MESSAGES_ASU,
  SET_TYPE_COMPETITION_STATEMENT, GET_SELECTED_TYPE_TRAINING_STATEMENT,
} from "../actions/actionTypes";
import instance from "../../customAxios";
import {deleteUserData} from "../System/actionSystem";

const API_URL = "/";

export function createApplicationsStatementWithoutFaculty() {
  return async (dispatch) => {
    try {
      const response = await instance.put(
        API_URL + "api/ext/campaigns/active/applications/active",
      );
      dispatch(createApplicationsStatementWithoutFacultySuccess(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(createApplicationsStatementWithoutFacultyFail());
      console.error('☠️error  createApplicationsStatementWithoutFaculty actions Statement: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function createApplicationsStatementWithoutFacultySuccess(applications) {
  return {
    type: CREATE_APPLICATION_STATEMENT_ACTIVE_SUCCESS,
    payload: applications
  }
}

export function createApplicationsStatementWithoutFacultyFail() {
  return {
    type: CREATE_APPLICATION_STATEMENT_ACTIVE_FAIL
  }
}

export function getApplicationsStatement() {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + "api/ext/campaigns/active/applications/active",
      );
      dispatch(getApplicationsStatementSuccess(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(getApplicationsStatementFail());
      console.error('☠️error getApplicationsStatement actions Statement: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function getApplicationsStatementSuccess(applications) {
  return {
    type: GET_APPLICATIONS_STATEMENT_ACTIVE_SUCCESS,
    payload: applications
  }
}

export function getApplicationsStatementFail() {
  return {
    type: GET_APPLICATIONS_STATEMENT_ACTIVE_FAIL
  }
}

export function getSelectedStudyFormStatement(studyForm) {
  return {
    type: GET_SELECTED_STUDY_FORM_STATEMENT,
    payload: studyForm
  }
}

export function getSelectedFacultyStatement(faculty) {
  return {
    type: GET_SELECTED_FACULTY_STATEMENT,
    payload: faculty
  }
}

export function getSelectedSpecialityGroupStatement(specialityGroup) {
  return {
    type: GET_SELECTED_SPECIALITY_GROUP_STATEMENT,
    payload: specialityGroup
  }
}

export function getSpecialityGroupStatement(studyForm, trainingVolume, selectedFacultyId) {
  return async () => {
    try {
      const response = await instance.get(
        API_URL + `api/pub/faculties/${selectedFacultyId}/groups?k_form=${studyForm}&full_sokr=${trainingVolume}`,
      );
      return response.data;
    } catch (error) {
      console.error('☠️error getSpecialityGroupStatement actions Statement: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function setDataStatement(data) {
  return async () => {
    try {
      // console.log(data)
      const response = await instance.post(
        API_URL + `api/ext/campaigns/active/applications/active/data`, data
      );
      return response.data;
    } catch (error) {
      console.error('☠️error setDataStatement actions Statement: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function getDataStatement(params = '') {
  return async () => {
    try {
      const response = await instance.get(
        API_URL + `api/ext/campaigns/active/applications/active/data?${params}`, //type[]=EDUCATION_INSTITUTIONS_NAME
      );
      return response.data;
    } catch (error) {
      console.error('☠️error getDataStatement actions Statement: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function setStatusFormsApplicationStatement(statusForms) {
  return {
    type: SET_STATUS_FORMS_APPLICATION_STATEMENT,
    payload: statusForms
  }
}

export function setStatementStatus(statusStatement) {
  return {
    type: SET_STATEMENT_STATUS,
    payload: statusStatement
  }
}

export function setTypeCompetitionStatement(typeCompetition) {
  return {
    type: SET_TYPE_COMPETITION_STATEMENT,
    payload: typeCompetition
  }
}

export function setContractStatement(document) {
  return {
    type: SET_CONTRACT_FILE_STATEMENT,
    payload: document
  }
}

export function getTypeTrainingStatement(typeTraining) {
  return {
    type: GET_SELECTED_TYPE_TRAINING_STATEMENT,
    payload: typeTraining
  }
}

export function getStudyFormStatement(studyForm) {
  return {
    type: GET_SELECTED_STUDY_FORM_STATEMENT,
    payload: studyForm
  }
}

export function getStudyPayStatement(studyPay) {
  return {
    type: GET_SELECTED_STUDY_PAY_STATEMENT,
    payload: studyPay
  }
}

export function getFacultyStatement(faculty) {
  return {
    type: GET_SELECTED_FACULTY_STATEMENT,
    payload: faculty
  }
}

export function getSpecialitiesBudgetStatement(itemsSpecialitiesBudget) {
  return {
    type: GET_SELECTED_SPECIALITY_BUDGET_STATEMENT,
    payload: itemsSpecialitiesBudget
  }
}

export function getSpecialitiesPaidStatement(itemsSpecialitiesPaid) {
  return {
    type: GET_SELECTED_SPECIALITY_PAID_STATEMENT,
    payload: itemsSpecialitiesPaid
  }
}

export function deleteTypeCompetitionStatement() {
  return async (dispatch, getState) => {
    try {
      const {statementReducer} = getState();
      const idTypeCompetitionStatement = statementReducer.typeCompetition.id
      if (idTypeCompetitionStatement) {
        const response = await dispatch(deleteUserData(idTypeCompetitionStatement));
        await dispatch(setTypeCompetitionStatement({}));
        return response.data;
      }
      throw new Error(`error idTypeCompetitionStatement: ${idTypeCompetitionStatement}`);
    } catch (error) {
      console.error('☠️error deleteTypeCompetitionStatement actions Statement: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function deleteContractFileStatement() {
  return async (dispatch, getState) => {
    try {
      const {statementReducer} = getState();
      const idContractFileStatement = statementReducer.contractFile.id
      if (idContractFileStatement) {
        const response = await dispatch(deleteUserData(idContractFileStatement));
        await dispatch(setContractStatement({}));
        return response.data;
      }
      throw new Error(`error idContractFileStatement: ${idContractFileStatement}`);
    } catch (error) {
      console.error('☠️error deleteContractFileStatement actions Statement: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function getDataStatusMessagesASU() {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + `api/ext/campaigns/active/applications/active/admission_status`,
      );
      dispatch(setDataStatusMessagesASU(response.data?.byArea));
      return response.data?.byArea;
    } catch (error) {
      console.error('☠️error getDataStatusMessagesASU actions Statement: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function getTemplateStatusMessagesASU() {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + `api/pub/search-directories/types=form`,
      );
      dispatch(setTemplateStatusMessagesASU(response.data?.[0].value));
      return response.data?.[0].value;
    } catch (error) {
      console.error('☠️error getTemplateStatusMessagesASU actions Statement: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function getDataStatusMessagesASUStatement() {
  return async (dispatch) => {
    try {
      dispatch(getDataStatusMessagesASU());
      dispatch(getTemplateStatusMessagesASU());
    } catch (error) {
      console.error('☠️error getDataStatusMessagesASUStatement actions Statement: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function setDataStatusMessagesASU(data) {
  return {
    type: SET_DATA_STATUS_MESSAGES_ASU,
    payload: data
  }
}

export function setTemplateStatusMessagesASU(templates) {
  return {
    type: SET_TEMPLATE_STATUS_MESSAGES_ASU,
    payload: templates
  }
}