import React, {useEffect, useState} from 'react';
import {resolver, actions} from '../../services/statusMessages/index';
import {useDispatch, useSelector} from "react-redux";
import {getDataStatement, getDataStatusMessagesASUStatement} from "../../store/Statement/actionStatement";
import {Grid, Typography} from "@material-ui/core";
import {MessageDates} from "../MessageDates";
import {
  GENERAL_FIRST_NAME_RU,
  GENERAL_GENDER,
  GENERAL_PATRONYMIC_RU,
  GENERAL_SURNAME_RU
} from "../../services/tagTypes";

export const StatusMessages = () => {
  const dispatch = useDispatch();
  const selectedTypeTrainingStatement = useSelector(state => state.statementReducer.selectedTypeTrainingStatement);
  const idApplicationStatementActive = useSelector(state => state.statementReducer.applicationStatementActive.id);
  const statusMessagesASU = useSelector(state => state.statementReducer.dataStatusMessagesASU);
  const templateStatusMessagesASU = useSelector(state => state.statementReducer.templateStatusMessagesASU);
  const currentQueue = useSelector(state => state.queueReducer.currentQueue);
  const currentDatetime = currentQueue !== null && Array.isArray(currentQueue) && currentQueue.length > 0 ? currentQueue[0] : null;
  const gender = useSelector(state => state.profileReducer.profileInfo.find(item => item.typeTitle === 'USER_GENDER'));
  const patronymic = useSelector(state => state.profileReducer.profileInfo.find(item => item.typeTitle === 'USER_PATRONYMIC'));
  const name = useSelector(state => state.profileReducer.profileInfo.find(item => item.typeTitle === 'USER_FIRST_NAME'));
  const surname = useSelector(state => state.profileReducer.profileInfo.find(item => item.typeTitle === 'USER_SURNAME'));
  const [genderFromAdditional, setGenderFromAdditional] = useState(gender?.value?.value);
  const [nameFromAdditional, setNameFromAdditional] = useState(name?.value?.value);
  const [surnameFromAdditional, setSurnameFromAdditional] = useState(surname?.value?.value);
  const [patronymicFromAdditional, setPatronymicFromAdditional] = useState(patronymic?.value?.value);

  const TIME_RELOAD = 60;

  const reload = () => {
    dispatch(getDataStatusMessagesASUStatement());
  }
  useEffect(() => {
    const timerStatus = setTimeout(reload, TIME_RELOAD * 1000);
    return () => clearTimeout(timerStatus);
  })

  const title = () => {
    if (template() !== null) {
      return resolver({
        messages: [template().title],
        actions: actions(),
      }, {
        gender: genderFromAdditional,
        name: nameFromAdditional,
        surname: surnameFromAdditional,
        patronymic: patronymicFromAdditional,
      }).join();
    } else if (
      genderFromAdditional !== null &&
      nameFromAdditional !== null &&
      surnameFromAdditional !== null &&
      genderFromAdditional !== '' &&
      nameFromAdditional !== '' &&
      surnameFromAdditional !== ''
    ) {
      return resolver({
        messages: ["Уважаем%_GENDER_% %_USER_NAME_% %_USER_PATRONYMIC_SURNAME_%!"],
        actions: actions(),
      }, {
        gender: genderFromAdditional,
        name: nameFromAdditional,
        surname: surnameFromAdditional,
        patronymic: patronymicFromAdditional,
      }).join();
    }
    return 'Уважаемый абитуриент!'
  }

  const messages = () => {
    if (template() !== null) {
      return resolver({
        messages: template().messages,
        actions: actions(),
        vars: template().vars,
        status: statusMessagesASU,
      }, {currentDatetime: getQueueDatetime(), typeTraining: selectedTypeTrainingStatement});
    }
    return null;
  }

  // const warns = () => {
  //   if (this.template !== null) {
  //     return resolver({
  //       messages: this.template.other.warns,
  //       actions: actions(),
  //       vars: this.template.other.vars,
  //       status: this.statusMessagesASU,
  //     });
  //   }
  //   return resolver({
  //     messages: ["Информируем Вас, что в университете действует режим использования масок. Просим Вас на территории университета использовать маску и соблюдать социальную дистанцию."],
  //     actions: actions(),
  //   });
  // }
  //
  const template = () => {
    let tmp = selectTemplateMessage();
    if (tmp !== undefined && tmp !== null) {
      return tmp
    }
    return null
  }

  const selectTemplateMessage = () => {
    const templateStatusMessages = templateStatusMessagesASU;
    if (statusMessagesASU.length || Object.entries(statusMessagesASU).length) {

      if (statusMessagesASU._ENROLL_RESULT_ > 0 && templateStatusMessages["RESULT"]) {
        return templateStatusMessages["RESULT"];
      }

      if (statusMessagesASU._IS_EXIST_TESTS_ && templateStatusMessages["TEST_ENABLE"]) {
        return templateStatusMessages["TEST_ENABLE"];
      }
      return templateStatusMessages["TEST_NONE"];

    }

    if (currentDatetime !== null && templateStatusMessages["QUEUE"]) {
      return templateStatusMessages["QUEUE"];
    }

    return null;
  }

  const getQueueDatetime = () => {
    if (currentDatetime !== null) {
      if (currentDatetime?.item !== undefined) {
        return `<strong>${setSuccessMessage(currentDatetime.item)}</strong>`
      }
    }
    return ''
  }

  const setSuccessMessage = (response) => {
    let day = response.time.substr(8, 2);
    let month = response.time.substr(5, 2);
    let year = response.time.substr(0, 4);
    return `${day}.${month}.${year} в ${response.text}`;
  }

  const initializationStatusMessages = () => {
    dispatch(getDataStatusMessagesASUStatement());
  }

  useEffect(() => {
    const getGender = async () => {
      const arrayGeneralParams = [
        `type[]=${GENERAL_GENDER}`,
        `type[]=${GENERAL_SURNAME_RU}`,
        `type[]=${GENERAL_FIRST_NAME_RU}`,
        `type[]=${GENERAL_PATRONYMIC_RU}`,
      ];
      const generalParams = arrayGeneralParams.join('&');
      const result = await dispatch(getDataStatement(generalParams));
      for (let i = 0; i < result.length; i++) {
        if (result[i]?.typeTitle === GENERAL_GENDER && result[i]?.value?.value) setGenderFromAdditional(result[i]?.value?.value);
        if (result[i]?.typeTitle === GENERAL_SURNAME_RU && result[i]?.value?.value) setSurnameFromAdditional(result[i]?.value?.value);
        if (result[i]?.typeTitle === GENERAL_FIRST_NAME_RU && result[i]?.value?.value) setNameFromAdditional(result[i]?.value?.value);
        if (result[i]?.typeTitle === GENERAL_PATRONYMIC_RU && result[i]?.value?.value) setPatronymicFromAdditional(result[i]?.value?.value);
      }
    }
    getGender();
    if (idApplicationStatementActive) initializationStatusMessages();
  }, []);

  return (
    idApplicationStatementActive && templateStatusMessagesASU?.QUEUE
      ?

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Typography align="center" variant="body1" component="p" dangerouslySetInnerHTML={{__html: title()}}/>
        </Grid>
        <Grid item xs={12}>
          {
            messages() !== null
              ? messages().map((message, index) => <Typography align="justify" variant="body1" component="p" key={index}
                                                               dangerouslySetInnerHTML={{__html: message}}/>)
              : <MessageDates/>
            // : null
          }
        </Grid>
      </Grid>
      : null
  );
}