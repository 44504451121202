import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,} from "@material-ui/core";

//import { makeStyles } from "@material-ui/core";

export function useDialog() {

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    open,
    setOpen,
    scroll,
    setScroll,
    handleClickOpen,
    handleClose,
  }
}

/*
const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1)
        }
    }
}));
 */

export function DialogCustom(props) {

  //const classes = useStyles();
  const {open, handleClose, scroll, dialogTitle, dialogContextText, ...rest} = props;
  return (
    <Dialog
      {...rest}
      open={open}
      onClose={handleClose}
      scroll={scroll}
    >
      {dialogTitle?
        <DialogTitle id="scroll-dialog-title">{dialogTitle}</DialogTitle>:
        null
      }
      <DialogContent dividers={scroll === 'paper'}>
        {dialogContextText}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}
