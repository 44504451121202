import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, StepButton} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  getDataStatement, getDataStatusMessagesASUStatement,
  setDataStatement, setStatementStatus,
  setStatusFormsApplicationStatement
} from "../../store/Statement/actionStatement";
import {
  ANKETA_STATUS,
  ANKETA_STATUS_FORMS_APPLICATION,
} from "../../services/tagTypes";
import {useForm} from "../Customs/useForm";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2.5),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  confirmed: {
    color: "#4caf50 !important"
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(1, 0),
  },
}));

const initialFValues = {
  statementCondition: false,
};

export default function VerticalLinearStepper() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [steps, setSteps] = useState([
    {
      name: 'general',
      id: '1',
      title: 'Общие сведения',
      url: {name: 'general'},
      active: true,
      status: 'NOT_CONFIRMED',
      coment: '',
    },
    {
      name: 'training',
      id: '2',
      title: 'Выбор специальности',
      url: {name: 'training'},
      active: true,
      status: 'NOT_CONFIRMED',
      coment: '',
    },
    {
      name: 'education',
      id: '3',
      title: 'Образование',
      url: {name: 'education'},
      active: true,
      status: 'NOT_CONFIRMED',
      coment: '',
    },
    {
      name: 'parents',
      id: '4',
      title: 'Родители',
      url: {name: 'parents'},
      active: true,
      status: 'NOT_CONFIRMED',
      coment: '',
    },
    {
      name: 'job',
      id: '5',
      title: 'Сведения о трудовой деятельности',
      url: {name: 'job'},
      active: true,
      status: 'NOT_CONFIRMED',
      coment: '',
    },
    {
      name: 'exams',
      id: '6',
      title: 'Вступительные испытания/ЦТ/ЦЭ',
      url: {name: 'exams'},
      active: true,
      status: 'NOT_CONFIRMED',
      coment: '',
    },
    {
      name: 'privileges',
      id: '7',
      title: 'Особые достижения и льготы',
      url: {name: 'privileges'},
      active: true,
      status: 'NOT_CONFIRMED',
      coment: '',
    },
    {
      name: 'additionalInformation',
      id: '8',
      title: 'Дополнительные сведения',
      url: {name: 'additionalInformation'},
      active: true,
      status: 'NOT_CONFIRMED',
      coment: '',
    },
  ])
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  const statementStatus = useSelector(state => state.statementReducer.statementStatus);

  const [render, setRender] = useState(false);

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])

  const TIME_RELOAD = 30;

  const reload = () => {
    getDataStatusForms();
  }
  useEffect(() => {
    const timerStatus = setTimeout(reload, TIME_RELOAD * 1000);
    return () => clearTimeout(timerStatus);
  })

  const getDataStatusForms = async () => {
    const arrayStatusFormsParams = [
      `type[]=${ANKETA_STATUS_FORMS_APPLICATION}`,
      `type[]=${ANKETA_STATUS}`,
    ];
    const statusFormsParams = arrayStatusFormsParams.join('&');
    const result = await dispatch(getDataStatement(statusFormsParams))
    // setDataFromApi(result);
    onReloadInformationStatusFormsParams(result)
    // console.log('result ', result)
  }

  useEffect(() => {
    getDataStatusForms();
    return () => {
      setRender(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let newSteps = [...steps];
  const newCompleted = completed;

  const updateAnketaStatus = async () => {
    await dispatch(setDataStatement({ANKETA_STATUS: {"value": "IS_FULL"}}));
    await dispatch(setStatementStatus("IS_FULL"));
  }

  useEffect(() => {
    const setNewStepComment = (step, comment) => {
      return {...step, coment: comment}
    }
    newSteps.forEach((step, index) => {
      step.status = statementStatusFormsApplication[step.id].status
      step.coment = statementStatusFormsApplication[step.id].coment
      if (step.status === 'IS_CONFIRMED') {
        newCompleted[index] = true;
        newSteps[index] = setNewStepComment(step, step.coment !== '' ? step.coment : 'Информация заполнена и проверена');
        if (statementStatus === 'IS_ASU' || statementStatus === 'IS_CONFIRMED') newSteps[index].active = false;
        if (statementStatus === 'IS_WORK') newSteps[index].active = true;
      }
      if (step.status === 'IS_FULL') {
        newCompleted[index] = true;
        newSteps[index] = setNewStepComment(step, step.coment !== '' ? step.coment : 'Информация заполнена');
        if (statementStatus === 'IS_WORK') newSteps[index].active = true;
      }
      if (step.status === 'IS_WORK') {
        newCompleted[index] = false;
        newSteps[index] = setNewStepComment(step, step.coment !== '' ? step.coment : 'Необходимо внести исправления');
        if (statementStatus === 'IS_WORK') newSteps[index].active = true;
      }
      if (step.status === 'NOT_CONFIRMED') {
        newCompleted[index] = false;
        if (statementStatus === 'IS_WORK') newSteps[index].active = true;
      }
    })

    setSteps(newSteps)
    setCompleted(newCompleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCompleted, statementStatusFormsApplication, statementStatus])


  const onReloadInformationStatusFormsParams = (item) => {

    const anketaStatusForm = item.find(data => data.typeTitle === ANKETA_STATUS_FORMS_APPLICATION);
    if (anketaStatusForm !== undefined) dispatch(setStatusFormsApplicationStatement(anketaStatusForm.value));

    const findAnketaStatus = item.find(data => data.typeTitle === ANKETA_STATUS);
    if (findAnketaStatus !== undefined) dispatch(setStatementStatus(findAnketaStatus.value.value));

    setRender(true)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isDisabledSubmitApplication()) {
        updateAnketaStatus();
    }
  }

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleFill = () => {
    // console.log('handleFill ', activeStep);
  };

  const getButton = ({label, onClick, show, ...otherProps}) => {
    return (
      show
        ?
        <Button
          onClick={onClick}
          {...otherProps}
        >
          {label}
        </Button>
        : null
    )
  }

  const showStatementCondition = () => {
    if (statementStatus === 'IS_WORK') {
      const lengthNewCompleted = Object.values(newCompleted);
      if (!lengthNewCompleted.includes(false)) {
        return true;
      }
    }
    return false;
  }
  const showAlertStatementIsFull = () => {
    return statementStatus === 'IS_FULL';
  }

  const isDisabledSubmitApplication = () => {
    return !(statementStatus === 'IS_WORK' && values.statementCondition);

  }

  const validate = (fieldValues = values) => {
    let temp = {...errors};
    if (showStatementCondition()) {
      if ('statementCondition' in fieldValues) {
        temp.statementCondition = "";
        if (temp?.statementCondition === "" && Boolean(fieldValues?.statementCondition) === false) {
          temp.statementCondition = "Условия отправки заявления должны быть приняты.";
        }
      }
    }

    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const {
    values,
    errors,
    setErrors,
    handleInputChange: onChange
  } = useForm(initialFValues, true, validate);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
        {steps.map((step, index) => {
            const buttonProps = {};
            const labelProps = {};
            const comment = <Typography>{step.coment}</Typography>;
            let buttonFill = getButton(
              {
                show: step.active,
                label: 'Заполнить',
                onClick: handleFill,
                component: RouterLink,
                to: step.url.name,
                variant: 'contained',
                color: 'primary',
                className: classes.button,
                disabled: !step.active
              })
            if (step.status === 'IS_CONFIRMED') {
              buttonProps.completed = true;
              // buttonProps.optional = <Typography variant="caption">Группа заполнена и проверена</Typography>;
              labelProps.StepIconProps = {classes: {root: classes.confirmed}};
              buttonFill = getButton(
                {
                  show: step.active,
                  label: 'Изменить',
                  onClick: handleFill,
                  component: RouterLink,
                  to: step.url.name,
                  variant: 'contained',
                  color: 'primary',
                  className: classes.button,
                  disabled: !step.active
                })
            }
            if (step.status === 'IS_FULL') {
              buttonProps.completed = true;
              // buttonProps.optional = <Typography variant="caption">Группа заполнена и отправлена на проверку</Typography>;
              buttonFill = getButton(
                {
                  show: step.active,
                  label: 'Изменить',
                  onClick: handleFill,
                  component: RouterLink,
                  to: step.url.name,
                  variant: 'contained',
                  color: 'primary',
                  className: classes.button,
                  disabled: !step.active
                })
            }
            if (step.status === 'IS_WORK') {
              buttonProps.optional = <Typography variant="caption">Требуется доработка</Typography>;
              labelProps.error = true;
              buttonFill = getButton(
                {
                  show: step.active,
                  label: 'Исправить',
                  onClick: handleFill,
                  component: RouterLink,
                  to: step.url.name,
                  variant: 'contained',
                  color: 'primary',
                  className: classes.button,
                  disabled: !step.active
                })
            }
            return (
              <Step key={step.name}>

                <StepButton onClick={handleStep(index)} completed={completed[index]} {...buttonProps}>
                  <StepLabel {...labelProps}>{step.title}</StepLabel>
                </StepButton>
                <StepContent>
                  {comment}
                  {step.status !== 'IS_CONFIRMED' ? buttonFill : null}
                  {/*{buttonFill}*/}
                </StepContent>
              </Step>
            )
          }
        )}
      </Stepper>
      {showStatementCondition() ?
        <form className={classes.form} onSubmit={onSubmit} noValidate>
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item>
              <FormControl
                error={Boolean(errors.statementCondition)}
                component="fieldset"
                className={classes.formControl}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        checked={values.statementCondition}
                        onChange={(e) => onChange({
                          target: {
                            name: e.target.name,
                            value: e.target.checked
                          }
                        })}
                        name="statementCondition"
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Я подтверждаю достоверность предоставленных данных.*
                      </Typography>
                    }
                  />
                </FormGroup>
                {
                  Boolean(errors.statementCondition)
                    ? <FormHelperText>{errors.statementCondition}</FormHelperText>
                    : null
                }

              </FormControl>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className={classes.submit}
                disabled={isDisabledSubmitApplication()}
              >
                Отправить заявку
              </Button>
            </Grid>
          </Grid>
        </form>
        : showAlertStatementIsFull() ?
            <Grid item xs={12} md={10}>
                <Alert variant="outlined" severity="success">
                    Заявление отправлено на проверку.
                </Alert>
            </Grid>
          :null
      }
    </div>
  );
}