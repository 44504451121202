import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import AutocompleteInput from "./Autocomplete";
import SelectCitizenship from "./SelectCitizenship";
import SelectPrivileges from "./SelectPrivileges";
import SelectFaculty from "./SelectFaculty";
import SelectSpecialityGroup from "./SelectSpecialityGroup";
import SelectTargetRecruitmentSpecialities from "./SelectTargetRecruitmentSpecialities";

const Controls = {
    Input,
    RadioGroup,
    Select,
    SelectCitizenship,
    Checkbox,
    DatePicker,
    Button,
    AutocompleteInput,
    SelectPrivileges,
    SelectFaculty,
    SelectSpecialityGroup,
    SelectTargetRecruitmentSpecialities,
};

export default Controls;
