import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {makeStyles} from "@material-ui/core/styles";
import {Container, Grid} from "@material-ui/core";

import {
  channelsGetMessagesList,
} from "../../store/Channels/actionChannels";
import CardNews from "../../components/CardNews";
import {MessageDates} from "../../components/MessageDates";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
    flexDirection: "column",
    gap: 10,
  },
}));

function Channel() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const messagesList = useSelector(state => state.channelsReducer.messagesList);

  useEffect(() => {
      dispatch(channelsGetMessagesList())
  }, []);

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} justifyContent="space-around" className={classes.grid_container}>
        {
          Array.isArray(messagesList) && messagesList.length !== 0 ?
            messagesList.map((message, index) => (
            <CardNews message={message} key={index} />
          )) :
            <MessageDates/>
        }
      </Grid>
    </Container>
  );
}

export default Channel;