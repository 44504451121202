export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_CONFIRM_SUCCESS = "REGISTER_CONFIRM_SUCCESS";
export const REGISTER_CONFIRM_FAIL = "REGISTER_CONFIRM_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_CONFIRM_SUCCESS = "RESET_PASSWORD_CONFIRM_SUCCESS";
export const RESET_PASSWORD_CONFIRM_FAIL = "RESET_PASSWORD_CONFIRM_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_TOKENS = "SET_TOKENS";
export const GET_TOKENS = "GET_TOKENS";
export const GET_USER_ROLES_SUCCESS = "GET_USER_ROLES_SUCCESS";
export const GET_USER_ROLES_FAIL = "GET_USER_ROLES_FAIL";
export const REFRESH_TOKENS_SUCCESS = "REFRESH_TOKENS_SUCCESS";
export const REFRESH_TOKENS_FAIL = "REFRESH_TOKENS_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "CLEAR_MESSAGE";

export const GET_PROFILE_INFO_SUCCESS = "GET_PROFILE_INFO_SUCCESS";
export const GET_PROFILE_INFO_FAIL = "GET_PROFILE_INFO_FAIL";

export const GET_FACULTIES_CONTACT_SUCCESS = "GET_FACULTIES_CONTACT_SUCCESS";
export const GET_FACULTIES_CONTACT_FAIL = "GET_FACULTIES_CONTACT_FAIL";
export const GET_ORGANIZATION_CONTACT_SUCCESS = "GET_ORGANIZATION_CONTACT_SUCCESS";
export const GET_ORGANIZATION_CONTACT_FAIL = "GET_ORGANIZATION_CONTACT_FAIL";

export const GET_FACULTIES_SUCCESS = "GET_FACULTIES_SUCCESS";
export const GET_FACULTIES_FAIL = "GET_FACULTIES_FAIL";
export const GET_FACULTY_SPECIALITIES_SUCCESS = "GET_FACULTY_SPECIALITIES_SUCCESS";
export const GET_FACULTY_SPECIALITIES_FAIL = "GET_FACULTY_SPECIALITIES_FAIL";
export const GET_FACULTY_SPECIALITIES_CLIENT_SUCCESS = "GET_FACULTY_SPECIALITIES_CLIENT_SUCCESS";
export const GET_FACULTY_SPECIALITIES_CLIENT_FAIL = "GET_FACULTY_SPECIALITIES_CLIENT_FAIL";
export const GET_FACULTIES_SPECIALITIES_CLIENT_SUCCESS = "GET_FACULTIES_SPECIALITIES_CLIENT_SUCCESS";
export const GET_FACULTIES_SPECIALITIES_CLIENT_FAIL = "GET_FACULTIES_SPECIALITIES_CLIENT_FAIL";
export const SET_SELECTED_SPECIALITIES_FACULTIES_SUCCESS = "SET_SELECTED_SPECIALITIES_FACULTIES_SUCCESS";

export const GET_APPLICATIONS_STATEMENT_ACTIVE_SUCCESS = "GET_APPLICATIONS_STATEMENT_ACTIVE_SUCCESS";
export const GET_APPLICATIONS_STATEMENT_ACTIVE_FAIL = "GET_APPLICATIONS_STATEMENT_ACTIVE_FAIL";
export const CREATE_APPLICATION_STATEMENT_ACTIVE_SUCCESS = "CREATE_APPLICATION_STATEMENT_ACTIVE_SUCCESS";
export const CREATE_APPLICATION_STATEMENT_ACTIVE_FAIL = "CREATE_APPLICATION_STATEMENT_ACTIVE_FAIL";
export const GET_STATEMENT_STATUS_FORMS_APPLICATION_SUCCESS = "GET_STATEMENT_STATUS_FORMS_APPLICATION_SUCCESS";
export const GET_STATEMENT_STATUS_FORMS_APPLICATION_FAIL = "GET_STATEMENT_STATUS_FORMS_APPLICATION_FAIL";

export const SET_STATUS_FORMS_APPLICATION_STATEMENT = "SET_STATUS_FORMS_APPLICATION_STATEMENT";
export const SET_TYPE_COMPETITION_STATEMENT = "SET_TYPE_COMPETITION_STATEMENT";
export const SET_CONTRACT_FILE_STATEMENT = "SET_CONTRACT_FILE_STATEMENT";
export const SET_STATEMENT_STATUS = "SET_STATEMENT_STATUS";

export const GET_SELECTED_TYPE_TRAINING_STATEMENT = "GET_SELECTED_TYPE_TRAINING_STATEMENT";
export const GET_SELECTED_STUDY_FORM_STATEMENT = "GET_SELECTED_STUDY_FORM_STATEMENT";
export const GET_SELECTED_FACULTY_STATEMENT = "GET_SELECTED_FACULTY_STATEMENT";
export const GET_SELECTED_STUDY_PAY_STATEMENT = "GET_SELECTED_STUDY_PAY_STATEMENT";
export const GET_SELECTED_SPECIALITY_BUDGET_STATEMENT = "GET_SELECTED_SPECIALITY_BUDGET_STATEMENT";
export const GET_SELECTED_SPECIALITY_PAID_STATEMENT = "GET_SELECTED_SPECIALITY_PAID_STATEMENT";
export const GET_SELECTED_SPECIALITY_GROUP_STATEMENT = "GET_SELECTED_SPECIALITY_GROUP_STATEMENT";
export const SET_DATA_STATUS_MESSAGES_ASU = "SET_DATA_STATUS_MESSAGES_ASU";
export const SET_TEMPLATE_STATUS_MESSAGES_ASU = "SET_TEMPLATE_STATUS_MESSAGES_ASU";

export const GET_FACULTIES_REFERENCE_BOOKS_SUCCESS = "GET_FACULTIES_REFERENCE_BOOKS_SUCCESS";
export const GET_FACULTIES_REFERENCE_BOOKS_FAIL = "GET_FACULTIES_REFERENCE_BOOKS_FAIL";
export const GET_CITIZENSHIP_REFERENCE_BOOKS_SUCCESS = "GET_CITIZENSHIP_REFERENCE_BOOKS_SUCCESS";
export const GET_CITIZENSHIP_REFERENCE_BOOKS_FAIL = "GET_CITIZENSHIP_REFERENCE_BOOKS_FAIL";
export const GET_PRIVILEGES_REFERENCE_BOOKS_SUCCESS = "GET_PRIVILEGES_REFERENCE_BOOKS_SUCCESS";
export const GET_PRIVILEGES_REFERENCE_BOOKS_FAIL = "GET_PRIVILEGES_REFERENCE_BOOKS_FAIL";
export const GET_LANGUAGE_REFERENCE_BOOKS_SUCCESS = "GET_LANGUAGE_REFERENCE_BOOKS_SUCCESS";
export const GET_LANGUAGE_REFERENCE_BOOKS_FAIL = "GET_LANGUAGE_REFERENCE_BOOKS_FAIL";
export const GET_PPY_REFERENCE_BOOKS_SUCCESS = "GET_PPY_REFERENCE_BOOKS_SUCCESS";
export const GET_PPY_REFERENCE_BOOKS_FAIL = "GET_PPY_REFERENCE_BOOKS_FAIL";
export const GET_OLYMPIADS_REFERENCE_BOOKS_SUCCESS = "GET_OLYMPIADS_REFERENCE_BOOKS_SUCCESS";
export const GET_OLYMPIADS_REFERENCE_BOOKS_FAIL = "GET_OLYMPIADS_REFERENCE_BOOKS_FAIL";

export const CREATE_QUEUE_SUCCESS = "CREATE_QUEUE_SUCCESS";
export const CREATE_QUEUE_FAIL = "CREATE_QUEUE_FAIL";
export const DELETE_CURRENT_QUEUE_REQUEST = "DELETE_CURRENT_QUEUE_REQUEST";
export const GET_CURRENT_QUEUE_REQUEST = "GET_CURRENT_QUEUE_REQUEST";
export const GET_CURRENT_QUEUE_SUCCESS = "GET_CURRENT_QUEUE_SUCCESS";
export const GET_CURRENT_QUEUE_FAIL = "GET_CURRENT_QUEUE_FAIL";
export const DELETE_CURRENT_QUEUE_SUCCESS = "DELETE_CURRENT_QUEUE_SUCCESS";
export const DELETE_CURRENT_QUEUE_FAIL = "DELETE_CURRENT_QUEUE_FAIL";

export const CREATE_QUEUE_REQUEST = "CREATE_QUEUE_REQUEST";
export const FETCH_AVAILABLE_QUEUES_REQUEST = "FETCH_AVAILABLE_QUEUES_REQUEST";
export const FETCH_AVAILABLE_QUEUES_SUCCESS = "FETCH_AVAILABLE_QUEUES_SUCCESS";
export const FETCH_AVAILABLE_QUEUES_FAILURE = "FETCH_AVAILABLE_QUEUES_FAILURE";