import React, { useState, useEffect } from 'react';
import { Paper, Typography, Grid, Box, Button } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import { format, add } from 'date-fns';
import {
  getAvailableQueues,
  createQueue,
  getCurrentQueue,
  deleteCurrentQueue,
  getAvailableDatesQueue
} from '../../store/Queue/actionQueue';
import QueueFrameComponent from './QueueFrameComponent';
import UserMessage from './UserMessage';
import './Queue.css'
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { MessageDates } from "../MessageDates";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': { transform: "scale(1)" },
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const Queue = props => {
  const classes = useStyles();
  const history = useHistory();
  const [date, setDate] = useState({});
  const [availableDates, setAvailableDates] = useState([]);
  const queueState = useSelector(state => state.queueReducer);
  const selectedTypeTrainingStatement = useSelector(state => state.statementReducer.selectedTypeTrainingStatement);
  const selectedFacultyStatement = useSelector(state => state.statementReducer.selectedFacultyStatement);
  // const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  // const selectedStudyPayStatement = useSelector(state => state.statementReducer.selectedStudyPayStatement);
  // const selectedArraySpecialitiesPaidStatement = useSelector(state => state.statementReducer.selectedArraySpecialitiesPaidStatement);
  const statementStatus = useSelector(state => state.statementReducer.statementStatus);
  const dispatch = useDispatch();

  const chooseDate = choosedDate => {
    if (format(new Date(choosedDate), 'yyyy-LL-dd') >= format(new Date(minDate), 'yyyy-LL-dd') && format(new Date(choosedDate), 'yyyy-LL-dd') <= format(new Date(maxDate), 'yyyy-LL-dd')) {
      if (!queueState.load && queueState.currentQueue === null) {
        const dateObj = {
          enteredDate: choosedDate,
          dateOut: format(choosedDate, 'dd.LL.yyyy'),
          dateStart: format(choosedDate, 'yyyy-LL-dd'),
          dateEnd: format(add(new Date(choosedDate), { days: 1 }), 'yyyy-LL-dd')
        };

        setDate(dateObj);
        dispatch(getAvailableQueues(dateObj.dateStart, dateObj.dateEnd));
      }
    }
  }

  const chooseQueue = queue => {
    if (window.confirm(`Записаться на ${date.dateOut} в ${queue.text}?`)) {
      dispatch(createQueue(queue));
    }
  }

  const deleteQueue = async queueId => {
    if (window.confirm('Удалить очередь?')) {
      await dispatch(deleteCurrentQueue(queueId));
      // await dispatch(getAvailableQueues(date.dateStart, date.dateEnd));
    }
  }

  const showCalendarQueue = () => {
    return availableDates.length !== 0;
  }

  const showQueue = () => {
    // return selectedFacultyStatement?.id !== undefined  && (statementStatusFormsApplication[1].status !== "NOT_CONFIRMED" && statementStatusFormsApplication[2].status !== "NOT_CONFIRMED")
    // return (statementStatusFormsApplication[1].status !== "NOT_CONFIRMED" && statementStatusFormsApplication[2].status !== "NOT_CONFIRMED")
    return (statementStatus === 'IS_ASU' || statementStatus === 'IS_CONFIRMED' || statementStatus === 'IS_FULL' || statementStatus === 'IS_CHECKED')
  }

  const finishDate = () => {
    // if (selectedStudyPayStatement === 'paid') {
    //   const result = selectedArraySpecialitiesPaidStatement[0].data.exams.find((test) => test.type !== "ЦТ" && test.KOD_EKZ !== 0)
    //   if (result === undefined) return availableDates[availableDates.length - 1];
    // }
    return availableDates[availableDates.length - 1];
  }

  const minDate = new Date() >= new Date(availableDates[0]) ? new Date() : new Date(availableDates[0]);
  const maxDate = new Date(finishDate());

  useEffect(() => {
    dispatch(getCurrentQueue());
    if (selectedTypeTrainingStatement === 'targetTraining' || selectedTypeTrainingStatement === 'olympiad') {
      let paramQuery = '';
      if (selectedTypeTrainingStatement === 'targetTraining') paramQuery = '?type=LIM ';
      if (selectedTypeTrainingStatement === 'olympiad') paramQuery = '?type=LIMIT';
      dispatch(getAvailableDatesQueue(null, paramQuery))
        .then(items => {
          const array = items.map(item => item.date);
          setAvailableDates(array);
        });
    }
    else if (selectedFacultyStatement?.id && (selectedTypeTrainingStatement === 'generalBasis' || selectedTypeTrainingStatement === 'generalBasisWithoutPrivileges' || selectedTypeTrainingStatement === 'pedagogicalSpecialties' || selectedTypeTrainingStatement === 'specialtiesByTheEconomy' || selectedTypeTrainingStatement === 'gold')) {
      dispatch(getAvailableDatesQueue(selectedFacultyStatement?.id, ''))
        .then(items => {
          const array = items.map(item => item.date);
          setAvailableDates(array);
        });
    }
  }, []);

  return (
    <div>
      <Paper elevation={3} className="MainPaper">
        <Typography variant="h6">Электронная очередь</Typography>
        <Paper elevation={3} className="Paper">
          <MessageDates />
        </Paper>
        <Paper elevation={3} className="Paper">
          <UserMessage />
        </Paper>
        <Grid container>
          {
            showQueue()
              ?

              showCalendarQueue()
                ?
                <>
                  <Grid item md={3} xs={12}>
                    <Box style={{ maxWidth: 325 }}>
                      <DatePicker
                        autoOk
                        variant="static"
                        value={typeof (date.enteredDate) === 'undefined' ? new Date() : date.enteredDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        openTo="date"
                        onChange={e => chooseDate(e)}
                        orientation='portrait'
                      />
                    </Box>
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <QueueFrameComponent date={date} chooseQueue={chooseQueue} deleteQueue={deleteQueue} />
                  </Grid>
                </>
                :
                <Grid item container xs={12} justifyContent={"space-evenly"}>
                  <Typography variant="body1" style={{ color: '#8b0000', fontWeight: 'bold', textTransform: 'uppercase' }}>Нет доступных дат для электронной записи на выбранный
                    факультет.</Typography>
                </Grid>


              :
              <Grid item container xs={12} justifyContent={"space-evenly"}>
                {/* <Typography variant="body1">Электронная очередь будет доступна после заполнения данных в разделах 1 (Общие сведения) и 2 (Выбор специальности)</Typography> */}
                <Typography variant="body1">Электронная очередь будет доступна после заполнения данных во всех разделах и отправки заявления на проверку</Typography>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => {
                    // history.push("/statement")
                    history.push("/home")
                  }}
                >
                  Перейти
                </Button>
              </Grid>
          }

        </Grid>
      </Paper>
    </div>
  );
}

export default Queue;