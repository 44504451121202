import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_CONFIRM_SUCCESS,
  REGISTER_CONFIRM_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_TOKENS,
  // SET_TOKENS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_CONFIRM_SUCCESS,
  RESET_PASSWORD_CONFIRM_FAIL,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAIL,
  REFRESH_TOKENS_SUCCESS,
  REFRESH_TOKENS_FAIL,
} from "./actionTypes";
import VanillaAxios from "axios";
import instance from "../../customAxios";
import {clearCache} from "../System/actionSystem";

const API_URL = "/";

export function register (phone, password, optionalUserData) {
  return async dispatch => {

    try {
      const response = await VanillaAxios.post(
        API_URL + "api/auth/registration/start",
        {phone, password, optionalUserData}
      );
      dispatch(registerSuccess());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(registerFail());
      console.error('error register actions auth: ', error.response);
      return Promise.reject(error.response);
    }

  }
}
export function registerNew (data = {}) {
  return async dispatch => {

    try {
      const response = await VanillaAxios.post(API_URL + "api/auth/registration/start", data);
      dispatch(registerSuccess());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(registerFail());
      console.error('error register actions auth: ', error.response);
      return Promise.reject(error.response);
    }

  }
}

export function registerConfirm (phone, token) {
  return async dispatch => {

    try {
      const response = await VanillaAxios.post(
        API_URL + "api/auth/registration/confirm",
        {phone, token}
      );
      dispatch(registerConfirmSuccess());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(registerConfirmFail());
      console.error('error registerConfirm actions auth: ', error.response);
      return Promise.reject(error.response);
    }

  }
}
export function registerConfirmNew (data) {
  return async dispatch => {

    try {
      const response = await VanillaAxios.post(API_URL + "api/auth/registration/confirm", data);
      dispatch(registerConfirmSuccess());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(registerConfirmFail());
      console.error('error registerConfirm actions auth: ', error.response);
      return Promise.reject(error.response);
    }

  }
}

export function login (credential, password) {
  return async dispatch => {
    try {
      const response = await VanillaAxios.post(
        API_URL + "api/auth/login",
        {credential, password}
      );
      // console.log('login actions auth: ', response.data);
      localStorage.setItem('access', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      instance.defaults.headers.common['Authorization'] = `Bearer ` + response.data.access;
      dispatch(loginSuccess(response.data));
      dispatch(clearCache());
      return Promise.resolve({status: response?.data?.access !== undefined && response?.data?.refresh !== undefined});
    } catch (error) {
      dispatch(loginFail());
      console.error('error login actions auth: ', error.response);
      return Promise.reject(error.response);
    }
  };
}

export function resetPassword (phone) {
  return async dispatch => {
    try {
      const response = await VanillaAxios.post(
        API_URL + "api/auth/passwords/reset-start",
        {phone}
      );
      dispatch(resetPasswordSuccess());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(resetPasswordFail());
      console.error('error reset Password actions auth: ', error.response);
      return Promise.reject(error.response);
    }

  }
}
export function resetPasswordNew(data) {
  return async dispatch => {
    try {
      const response = await VanillaAxios.post(
        API_URL + "api/auth/passwords/reset-start",
        data
      );
      dispatch(resetPasswordSuccess());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(resetPasswordFail());
      console.error('error reset Password actions auth: ', error.response);
      return Promise.reject(error.response);
    }

  }
}

export function resetPasswordConfirm (phone, token, password) {
  return async dispatch => {

    try {
      const response = await VanillaAxios.post(
        API_URL + "api/auth/passwords/reset-confirm",
        {phone, token, password}
      );
      dispatch(resetPasswordConfirmSuccess());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(resetPasswordConfirmFail());
      console.error('error resetPasswordConfirm actions auth: ', error.response);
      return Promise.reject(error.response);
    }

  }
}
export function resetPasswordConfirmNew (data) {
  return async dispatch => {

    try {
      const response = await VanillaAxios.post(
        API_URL + "api/auth/passwords/reset-confirm",
        data
      );
      dispatch(resetPasswordConfirmSuccess());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(resetPasswordConfirmFail());
      console.error('error resetPasswordConfirm actions auth: ', error.response);
      return Promise.reject(error.response);
    }

  }
}

export function getUserRoles () {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.access;
      const refreshToken = localStorage.refresh;
      const response = await instance.get(
        API_URL + "api/ext/users/me",
      );
      // console.log('getUserRoles actions auth: ', response.data);
      dispatch(getUserRolesSuccess(response.data));
      dispatch(loginSuccess({access: accessToken, refresh: refreshToken}));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(getUserRolesFail());
      console.error('☠️error getUserRoles actions auth: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}

// export function refreshTokens () {
//   return async dispatch => {
//     try {
//       const refreshToken = localStorage.refresh;
//       const response = await axios.post(
//         API_URL + "api/refresh",
//         {refresh: refreshToken}
//       );
//       console.log('refreshTokens actions auth: ', response.data);
//       dispatch(refreshTokensSuccess(response.data));
//       localStorage.setItem('access', response.data.access);
//       localStorage.setItem('refresh', response.data.refresh);
//     } catch (error) {
//       dispatch(refreshTokensFail());
//       console.error('error refreshTokens actions auth: ', error);
//       return Promise.reject(error.response);
//     }
//   };
// }

export function refreshTokens () {
  return async dispatch => {
    // console.log('start refreshTokens ');
    let newTokenObj = {data: null};
    const refreshToken = localStorage.refresh;
    // console.log('get old refreshToken');
    if (!refreshToken) {
      // console.log('get old refreshToken is empty ');
      throw new Error('user has not logged in')
    }
    // console.log('start refreshPromise, refreshToken: not empty');
    // use private variable to keep 1 active JWT refresh request at any time.
    let refreshPromise;
    refreshPromise = refreshPromise || VanillaAxios.post(
      API_URL + "api/auth/refresh",
      {refresh: refreshToken}
    );
    // console.log('after refreshPromise');
    // get new access token
    try {
      newTokenObj = await refreshPromise;
    } catch (error) {
      dispatch(refreshTokensFail());
      dispatch(logout());
      console.error('error refreshTokens actions auth: ', error.response);
      return Promise.reject(error.response);
    } finally {
      refreshPromise = null;
    }

    // dispatch(setTokens(newTokenObj));

    // console.log('refreshTokens actions auth: ', newTokenObj);
    dispatch(refreshTokensSuccess(newTokenObj.data));
    localStorage.setItem('access', newTokenObj.data.access);
    localStorage.setItem('refresh', newTokenObj.data.refresh);
  };
}

//
// export function setTokens (newTokenObj) {
//   const accessToken = localStorage.setItem('access', newTokenObj.data.access);
//   const refreshToken = localStorage.setItem('refresh', newTokenObj.data.refresh);
//   console.log('setTokens actions auth: ', {accessToken,refreshToken} )
//   return {
//     type: SET_TOKENS,
//     payload: {
//       access: accessToken,
//       refresh: refreshToken
//     }
//   }
// }

export function getTokens () {
  const accessToken = localStorage.access;
  const refreshToken = localStorage.refresh;
  return {
    type: GET_TOKENS,
    payload: {
      access: accessToken,
      refresh: refreshToken
    }
  }
}

export function logout () {
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  instance.defaults.headers.common['Authorization'] = null;
  return {
    type: LOGOUT
  }
}

export function loginSuccess (tokens) {
  return {
    type: LOGIN_SUCCESS,
    payload: tokens
  }
}

export function loginFail () {
  return {
    type: LOGIN_FAIL
  }
}

export function registerSuccess () {
  return {
    type: REGISTER_SUCCESS
  }
}

export function registerFail () {
  return {
    type: REGISTER_FAIL
  }
}

export function registerConfirmSuccess () {
  return {
    type: REGISTER_CONFIRM_SUCCESS
  }
}

export function registerConfirmFail () {
  return {
    type: REGISTER_CONFIRM_FAIL
  }
}

export function resetPasswordSuccess () {
  return {
    type: RESET_PASSWORD_SUCCESS
  }
}

export function resetPasswordFail () {
  return {
    type: RESET_PASSWORD_FAIL
  }
}

export function resetPasswordConfirmSuccess () {
  return {
    type: RESET_PASSWORD_CONFIRM_SUCCESS
  }
}

export function resetPasswordConfirmFail () {
  return {
    type: RESET_PASSWORD_CONFIRM_FAIL
  }
}

export function getUserRolesSuccess (payload) {
  return {
    type: GET_USER_ROLES_SUCCESS,
    payload
  }
}

export function getUserRolesFail () {
  return {
    type: GET_USER_ROLES_FAIL
  }
}

export function refreshTokensSuccess (tokens) {
  instance.defaults.headers.common['Authorization'] = tokens.access;
  return {
    type: REFRESH_TOKENS_SUCCESS,
    payload: tokens
  }
}

export function refreshTokensFail () {
  return {
    type: REFRESH_TOKENS_FAIL
  }
}
