import axios from "../../customAxios";
import {
  CHAT_GET_CHAT_LIST_FAILED,
  CHAT_GET_CHAT_LIST_LOADING,
  CHAT_GET_CHAT_LIST_RELOADING,
  CHAT_GET_CHAT_LIST_SUCCESSFUL,
  CHAT_GET_MESSAGE_LIST_FAILED,
  CHAT_GET_MESSAGE_LIST_LOADING,
  CHAT_GET_MESSAGE_LIST_RELOADING,
  CHAT_GET_MESSAGE_LIST_SUCCESSFUL,
  CHAT_SET_NEW_MESSAGE_SENDING,
  CHAT_STATUS_LAST_EVENT,
  CHAT_STATUS_VISIBLE
} from "./typesChat";
import {closeSnackbar, enqueueSnackbar} from "../actions/message";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import {IconButton} from "@material-ui/core";

const API_URL = "/";

/*
Список чатов
 */

async function chatApi_getChats() {
  try {
    const request = await axios.get(`${API_URL}api/ext/chats`);
    return Promise.resolve(request.data);
  } catch (error) {
    return Promise.reject(error.response ? error.response : error);
  }
}

export function chatAction_getChats({isReloading = false}) {
  return async (dispatch) => {
    if (isReloading) {
      dispatch(chatReducer_getChatsReloading());
    } else {
      dispatch(chatReducer_getChatsLoading());
    }
    try {
      const response = await chatApi_getChats();
      dispatch(chatReducer_getChatsSuccessful(response));
      if (isReloading) {
        dispatch(chatReducer_setStatusLastEvent("RELOADING_CHATS"));
      } else {
        dispatch(chatReducer_setStatusLastEvent("LOADING_CHATS"));
      }
      return Promise.resolve(response);
    } catch (error) {
      dispatch(chatReducer_getChatsFailed());
      return Promise.reject(error.response ? error.response : error);
    } finally {
      if (isReloading) {
        dispatch(chatReducer_getChatsReloading());
      } else {
        dispatch(chatReducer_getChatsLoading());
      }
    }
  }
}

function chatReducer_getChatsSuccessful(payload) {
  return {type: CHAT_GET_CHAT_LIST_SUCCESSFUL, payload}
}

function chatReducer_getChatsFailed() {
  return {type: CHAT_GET_CHAT_LIST_FAILED}
}

function chatReducer_getChatsLoading() {
  return {type: CHAT_GET_CHAT_LIST_LOADING}
}

function chatReducer_getChatsReloading() {
  return {type: CHAT_GET_CHAT_LIST_RELOADING}
}

/*
Список сообщений
 */

async function chatApi_getMessages({chatId, isRead = false, limit = undefined}) {
  try {
    const request = await axios.get(`${API_URL}api/ext/chats/${chatId}/messages${isRead ? `?set_visible` : ``}${ limit !== undefined && "limit" in limit && "offset" in limit ? `${isRead ? `&` : `?`}limit=${limit.limit}&offset=${limit.offset}` : ``}`);
    return Promise.resolve(request.data);
  } catch (error) {
    return Promise.reject(error.response ? error.response : error);
  }
}

export function chatAction_getMessages({chatId, isReloading = false, isRead = false, limit = undefined}) {
  return async (dispatch) => {
    if (isReloading) {
      dispatch(chatReducer_getMessagesReloading());
    } else {
      dispatch(chatReducer_getMessagesLoading());
    }
    try {
      const response = await chatApi_getMessages({chatId, isRead, limit});
      dispatch(chatReducer_getMessagesSuccessful(response));
      if (isReloading) {
        dispatch(chatReducer_setStatusLastEvent("RELOADING_MESSAGES"));
      } else {
        dispatch(chatReducer_setStatusLastEvent("LOADING_MESSAGES"));
      }
      dispatch(chatAction_getChats({isReloading: true}));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(chatReducer_getMessagesFailed());
      return Promise.reject(error.response ? error.response : error);
    } finally {
      if (isReloading) {
        dispatch(chatReducer_getMessagesReloading());
      } else {
        dispatch(chatReducer_getMessagesLoading());
      }
    }
  }
}

function chatReducer_getMessagesSuccessful(payload) {
  return {type: CHAT_GET_MESSAGE_LIST_SUCCESSFUL, payload};
}

function chatReducer_getMessagesFailed() {
  return {type: CHAT_GET_MESSAGE_LIST_FAILED};
}

function chatReducer_getMessagesLoading() {
  return {type: CHAT_GET_MESSAGE_LIST_LOADING};
}

function chatReducer_getMessagesReloading() {
  return {type: CHAT_GET_MESSAGE_LIST_RELOADING};
}

/*
Отправка нового сообщения
 */

async function chatApi_sendMessage({chatId, message}) {
  try {
    const request = await axios.post(`${API_URL}api/ext/chats/${chatId}/messages`, message);
    return Promise.resolve(request.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export function chatAction_sendMessage({chatId, message}) {
  return async (dispatch) => {
    dispatch(chatReducer_sendMessageSending());
    try {
      const response = await chatApi_sendMessage({chatId, message});
      if (response?.id !== undefined) {
        dispatch(chatReducer_createNotification({
          message: 'Сообщение отправлено.',
          autoHide: true,
          variant: 'success'
        }));
        dispatch(chatAction_getChats({isReloading: true}));
        dispatch(chatAction_getMessages({chatId: chatId, isRead: false, isReloading: true}));
      } else {
        console.log({error: JSON.stringify(response)});
      }
      return Promise.resolve(response);
    } catch (e) {
      dispatch(chatReducer_createNotification({
        message: 'Ошибка отправки сообщения.',
        autoHide: true,
        variant: 'error'
      }));
      return Promise.reject(e.response);
    } finally {
      dispatch(chatReducer_sendMessageSending());
    }
  }
}

function chatReducer_sendMessageSending() {
  return {type: CHAT_SET_NEW_MESSAGE_SENDING}
}

/*
Изменение последнего статуса события
 */

export function chatAction_setStatusLastEvent({status}) {
  return async (dispatch) => {
    dispatch(chatReducer_setStatusLastEvent(status));
  }
}

function chatReducer_setStatusLastEvent(payload = "NOT_STATUS") {
  return {type: CHAT_STATUS_LAST_EVENT, payload}
}

/*
Статус отображения чата у пользователя
 */


export function chatAction_toggleChatVisible() {
  return async (dispatch) => {
    dispatch(chatReducer_toggleChatVisible())
  }
}

export function chatReducer_toggleChatVisible() {
  return {type: CHAT_STATUS_VISIBLE}
}

/*
Создание сообщения у пользователя
 */
export function chatAction_createNotification({message, autoHide = false, variant = '',  position = undefined}) {
  return async (dispatch) => {
    dispatch(chatReducer_createNotification({message, autoHide, variant, ...(position && position)}));
  }
}

function chatReducer_createNotification({message, autoHide = false, variant = '', position = {}}) {
  return async (dispatch) => {
    dispatch(enqueueSnackbar({
      message: message,
      options: {
        ...(autoHide === true ? {autoHideDuration: 5000} : {}),
        anchorOrigin: {
          ...(position?.vertical ? {vertical: position.vertical}: {vertical: 'bottom'}),
          ...(position?.horizontal ? {horizontal: position.horizontal} : {horizontal: 'left'}),
        },
        key: new Date().getTime() + Math.random(),
        variant: variant,
        action: key => (
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => dispatch(closeSnackbar(key))}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
        ),
      },
    }));

  }
}
