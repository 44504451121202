import React, {memo} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import HomeIcon from '@material-ui/icons/Home';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChatIcon from '@material-ui/icons/Chat';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import QueueIcon from '@material-ui/icons/Queue';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import InfoIcon from '@material-ui/icons/Info';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import GavelIcon from '@material-ui/icons/Gavel';
import DvrIcon from '@material-ui/icons/Dvr';
import {Link as RouterLink} from "react-router-dom";
import {useChatForm} from "../../containers/Chat";
import {useSelector} from "react-redux";


export const DrawerMenu = memo((props) => {
  const user = useSelector(state => state.authReducer.user);
  const {onVisibleChat: chatFormOpen} = useChatForm();

  let links = [
    {
      path: "/",
      text: "Главная",
      icon: <HomeIcon/>
    },
    {
      path: "/sign-up",
      text: "Зарегистрироваться",
      icon: <PersonAddIcon/>
    },
    {
      path: "/sign-in",
      text: "Войти",
      icon: <ExitToAppIcon/>
    },
    {
      path: "/reset-password",
      text: "Сброс пароля",
      icon: <VpnKeyIcon/>
    },
    {
      path: "/terms",
      text: "Политика",
      icon: <GavelIcon/>
    },
    // {
    //   path: "/404",
    //   text: "404",
    //   icon: ""
    // },
  ];
  if (props.isAuthenticated) {

    links = [
      {
        path: "/",
        text: "Главная",
        icon: <HomeIcon/>
      },
      {
        path: "/profile",
        text: "Профиль",
        icon: <AccountCircleIcon/>
      },
      // {
      //   path: "/olympiads",
      //   text: "Многопрофильная олимпиада",
      //   icon: <DvrIcon/>
      // },
      // {
      //   path: "/statement",
      //   text: "Заявление на поступление",
      //   icon: <AssignmentIcon/>
      // },
      {
        path: "/info",
        text: "Информация",
        icon: <InfoIcon/>
      },
      {
        onClick: chatFormOpen,
        text: "Чат",
        icon: <QuestionAnswerIcon/>
      },
      {
        path: "/channel",
        text: "Новости",
        icon: <ChatIcon/>
      },
      {
        path: "/contacts",
        text: "Контакты",
        icon: <PermContactCalendarIcon/>
      },
      // {
      //   path: "/queue",
      //   text: "Электронная очередь",
      //   icon: <QueueIcon/>
      // },
      {
        path: "/terms",
        text: "Политика",
        icon: <GavelIcon/>
      },

      {
        path: "/reset-password",
        text: "Сброс пароля",
        icon: <VpnKeyIcon/>
      },
      {
        path: "/logout",
        text: "Выйти",
        icon: <InboxIcon/>
      },
    ]

    if (user?.roles !== undefined && user.roles.includes('ROLE_ADMIN')) {
      links.unshift(
        {
          path: "/remote_user_control",
          text: "Админ права",
          icon: <VideogameAssetIcon/>
        })
    }
  }

  return (
    <List>
      {links.map((link, index) => (
        <ListItem
          button
          key={index}
          {...(link.path ? {component: RouterLink, to: link.path, onClick: props.handleDrawerToggle} : (link?.onClick ? {onClick: () => {link?.onClick(); props.handleDrawerToggle()}} : {}))}
          variant="body2"
          // selected={}
        >
          <ListItemIcon>{link.icon}</ListItemIcon>
          <ListItemText primary={link.text}/>
        </ListItem>
      ))}
    </List>
  );
}
)