export const arrayByFormsOfLearning = (array, keyTag) => {
  if (array && Array.isArray(array) && array.length !== 0) {
    // console.log('start arrayByFormsOfLearning', array)
    const filterArray = array.filter((speciality) => {
      return speciality.data.educationForm === keyTag
    })
    // console.log('arrayByFormsOfLearning filterArray ', filterArray)
    const resultArray = filterArray.filter((speciality, index, self) =>
      index === self.findIndex((t) => (
        t.speciality.data.name_original === speciality.speciality.data.name_original && t.data.educationForm === speciality.data.educationForm
      ))
    )
    // console.log('arrayByFormsOfLearning resultArray ',resultArray)
    return resultArray
  }
  return []
}