import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Paper } from "@material-ui/core";
import { StatusMessages } from "../../components/StatusMessages";
import { InterviewMessage } from "../../components/InterviewMessage";
import FirstYearStudents from "../../components/FirstYearStudents";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

export const Info = () => {
  const classes = useStyles();
  const status = useSelector(
    (state) => state.statementReducer.dataStatusMessagesASU
  );
  return (
    <Container maxWidth="xl">
      <Paper className={classes.paper}>
        <StatusMessages />
      </Paper>
      {status._SHOW_PROFORG_FORT_ === true ? (
        <>
          <FirstYearStudents />
        </>
      ) : null}
      <InterviewMessage />
    </Container>
  );
};
