export const ANKETA_STATUS_FORMS_APPLICATION = "ANKETA_STATUS_FORMS_APPLICATION";
export const ANKETA_STATUS = "ANKETA_STATUS";
export const ANKETA_USER_TYPE_COMPETITION = "ANKETA_USER_TYPE_COMPETITION";
export const ANKETA_PDF_CREATE = "ANKETA_PDF_CREATE";

export const FACULTY_DECAN_FIO_FULL = "FACULTY_DECAN_FIO_FULL";
export const FACULTY_DECAN_FIO_SHORT = "FACULTY_DECAN_FIO_SHORT";
export const FACULTY_DECAN_CONTACT_PHONE = "FACULTY_DECAN_CONTACT_PHONE";
export const FACULTY_DECAN_CONTACT_EMAIL = "FACULTY_DECAN_CONTACT_EMAIL";
export const FACULTY_DECANAT_CONTACT_PHONE = "FACULTY_DECANAT_CONTACT_PHONE";
export const FACULTY_DECANAT_CONTACT_EMAIL = "FACULTY_DECANAT_CONTACT_EMAIL";
export const FACULTY_WEBSITE = "FACULTY_WEBSITE";
export const FACULTY_ABSTRACT_INFO = "FACULTY_ABSTRACT_INFO";
export const FACULTY_FIRST_CONTACT_MOBILE = "FACULTY_FIRST_CONTACT_MOBILE";
export const FACULTY_FIRST_CONTACT_PHONE = "FACULTY_FIRST_CONTACT_PHONE";
export const FACULTY_FIRST_CONTACT_EMAIL = "FACULTY_FIRST_CONTACT_EMAIL";
export const FACULTY_FIRST_CONTACT_FIO = "FACULTY_FIRST_CONTACT_FIO";
export const FACULTY_MESSENGER_WHATSAPP = "FACULTY_MESSAGER_WHATSAPP";
export const FACULTY_MESSENGER_TELEGRAM = "FACULTY_MESSAGER_TELEGRAM";
export const FACULTY_MESSENGER_VIBER = "FACULTY_MESSAGER_VIBER";
export const FACULTY_MESSENGER_SIGNAL = "FACULTY_MESSAGER_SIGNAL";
export const FACULTY_SOCIAL_VK = "FACULTY_SOLIAL_VK";
export const FACULTY_SOCIAL_FB = "FACULTY_SOLIAL_FB";
export const FACULTY_SOCIAL_INSTAGRAM = "FACULTY_SOCIAL_INSTAGRAM";
export const FACULTY_SOCIAL_TWITTER = "FACULTY_SOCIAL_TWITTER";

export const USER_PREFERENCE = "USER_PREFERENCE";
export const USER_SURNAME = "USER_SURNAME";
export const USER_FIRST_NAME = "USER_FIRST_NAME";
export const USER_PATRONYMIC = "USER_PATRONYMIC";
export const USER_GENDER = "USER_GENDER";
export const USER_BORNDATE = "USER_BORNDATE";
export const USER_EMAIL = "USER_EMAIL";
export const USER_PHONE_MOBILE = "USER_PHONE_MOBILE";
export const USER_EDUCATION_INSTITUTIONS_NAME = "USER_EDUCATION_INSTITUTIONS_NAME";
export const USER_EDUCATION_INSTITUTIONS_ROOM = "USER_EDUCATION_INSTITUTIONS_ROOM";
export const USER_EDUCATION_INSTITUTIONS_DATE = "USER_EDUCATION_INSTITUTIONS_DATE";
export const USER_FULL_ADDRESS = "USER_FULL_ADDRESS";
export const USER_TERMS = "USER_TERMS";

export const GENERAL_SURNAME_RU = "GENERAL_SURNAME_RU";
export const GENERAL_FIRST_NAME_RU = "GENERAL_FIRST_NAME_RU";
export const GENERAL_PATRONYMIC_RU = "GENERAL_PATRONYMIC_RU";
export const GENERAL_SURNAME_BY = "GENERAL_SURNAME_BY";
export const GENERAL_FIRST_NAME_BY = "GENERAL_FIRST_NAME_BY";
export const GENERAL_PATRONYMIC_BY = "GENERAL_PATRONYMIC_BY";
export const GENERAL_SURNAME_EN = "GENERAL_SURNAME_EN";
export const GENERAL_FIRST_NAME_EN = "GENERAL_FIRST_NAME_EN";
export const GENERAL_DOCUMENT_TYPE = "GENERAL_DOCUMENT_TYPE";
export const GENERAL_DOCUMENT_SERIES = "GENERAL_DOCUMENT_SERIES";
export const GENERAL_DOCUMENT_NUMBER = "GENERAL_DOCUMENT_NUMBER";
export const GENERAL_IDENTIFICATION_NUMBER = "GENERAL_IDENTIFICATION_NUMBER";
export const GENERAL_DOCUMENT_STATE_AGENCY = "GENERAL_DOCUMENT_STATEAGENCY";
export const GENERAL_DOCUMENT_DATE_ISSUE = "GENERAL_DOCUMENT_DATEISSUE";
export const GENERAL_DOCUMENT_DATEISSUE_END = "GENERAL_DOCUMENT_DATEISSUE_END";
export const GENERAL_BORN_DATE = "GENERAL_BORNDATE";
export const GENERAL_GENDER = "GENERAL_GENDER";
export const GENERAL_CITIZENSHIP = "GENERAL_CITIZENSHIP";
export const GENERAL_RESIDENCE_INBY = "GENERAL_RESIDENCE_INBY";
export const GENERAL_POST_INDEX = "GENERAL_POSTINDEX";
export const GENERAL_EMAIL = "GENERAL_EMAIL";
export const GENERAL_FULL_ADDRESS = "GENERAL_FULL_ADDRESS";
export const GENERAL_PHONE_MOBILE = "GENERAL_PHONE_MOBILE";
export const GENERAL_PHONE_HOME = "GENERAL_PHONE_HOME";
export const GENERAL_NEED_A_HOSTEL = "GENERAL_NEED_AHOSTEL";

export const TRAINING_TYPE = "TRAINING_TYPE";
export const TRAINING_STUDY_FORM = "TRAINING_STUDY_FORM";
export const TRAINING_STUDY_PAY = "TRAINING_STUDY_PAY";
export const TRAINING_FACULTY = "TRAINING_FACULTY";
export const TRAINING_SPECIALITIES_GROUP = "TRAINING_SPECIALITIES_GROUP";
export const TRAINING_ALLOW_FOR_PAID = "TRAINING_ALLOW_FOR_PAID";
export const TRAINING_SPECIALITY_BUDGET = "TRAINING_SPECIALITY_BUDGET";
export const TRAINING_SPECIALITY_PAID = "TRAINING_SPECIALITY_PAID";
export const TRAINING_TARGET_RECRUITMENT = "TRAINING_TARGET_RECRUITMENT";
export const TRAINING_TARGET_RECRUITMENT_SPEC = "TRAINING_TARGET_RECRUITMENT_SPEC";

export const EDUCATION_INSTITUTIONS_TYPE = "EDUCATION_INSTITUTIONS_TYPE";
export const EDUCATION_INSTITUTIONS_NAME = "EDUCATION_INSTITUTIONS_NAME";
export const EDUCATION_INSTITUTIONS_BALL_TYPE = "EDUCATION_INSTITUTIONS_BALL_TYPE";
export const EDUCATION_INSTITUTIONS_POINT = "EDUCATION_INSTITUTIONS_POINT";
export const EDUCATION_INSTITUTIONS_DATE = "EDUCATION_INSTITUTIONS_DATE";
export const EDUCATION_INSTITUTIONS_LANG = "EDUCATION_INSTITUTIONS_LANG";
export const EDUCATION_INSTITUTIONS_TRAINING = "EDUCATION_INSTITUTIONS_TRAINING";
export const EDUCATION_INSTITUTIONS_TYPE_DOCUMENT = "EDUCATION_INSTITUTIONS_TYPE_DOCUMENT";

export const PARENTS_MOTHER_HAVE = "PARENTS_MOTHER_HAVE";
export const PARENTS_MOTHER_SURNAME = "PARENTS_MOTHER_SURNAME";
export const PARENTS_MOTHER_FIRSTNAME = "PARENTS_MOTHER_FIRSTNAME";
export const PARENTS_MOTHER_PATRONYMIC = "PARENTS_MOTHER_PATRONYMIC";
export const PARENTS_MOTHER_PHONE_MOBILE = "PARENTS_MOTHER_PHONE_MOBILE";
export const PARENTS_MOTHER_LIVINGTOGETHER = "PARENTS_MOTHER_LIVINGTOGETHER";
export const PARENTS_MOTHER_ADDRESS = "PARENTS_MOTHER_ADDRESS";
export const PARENTS_FATHER_HAVE = "PARENTS_FATHER_HAVE";
export const PARENTS_FATHER_SURNAME = "PARENTS_FATHER_SURNAME";
export const PARENTS_FATHER_FIRSTNAME = "PARENTS_FATHER_FIRSTNAME";
export const PARENTS_FATHER_PATRONYMIC = "PARENTS_FATHER_PATRONYMIC";
export const PARENTS_FATHER_PHONE_MOBILE = "PARENTS_FATHER_PHONE_MOBILE";
export const PARENTS_FATHER_LIVINGTOGETHER = "PARENTS_FATHER_LIVINGTOGETHER";
export const PARENTS_FATHER_ADDRESS = "PARENTS_FATHER_ADDRESS";

export const JOB_HAVE = "JOB_HAVE";
export const JOB_PLACEWORK = "JOB_PLACEWORK";
export const JOB_POSITIONWORK = "JOB_POSITIONWORK";
export const JOB_EXPERIENCE_YEARS = "JOB_EXPERIENCE_YEARS";
export const JOB_EXPERIENCE_MONTHS = "JOB_EXPERIENCE_MONTHS";
export const JOB_EXPERIENCE_DAYS = "JOB_EXPERIENCE_DAYS";

export const EXAMS_IS_EMPTY_EXAMS = "EXAMS_IS_EMPTY_EXAMS";
export const EXAMS_RESULT_CT = "EXAMS_RESULT_CT";
export const EXAMS_RESULT_CT_1 = "EXAMS_RESULT_CT_1";
export const EXAMS_RESULT_CT_2 = "EXAMS_RESULT_CT_2";
export const EXAMS_RESULT_CT_LANG = "EXAMS_RESULT_CT_LANG";
export const EXAMS_LANGUAGE_CT_LANG = "EXAMS_LANGUAGE_CT_LANG";
export const EXAMS_LANGUAGE_EXAMS_LANG = "EXAMS_LANGUAGE_EXAMS_LANG";
export const EXAMS_NEED_AHOSTEL = "EXAMS_NEED_AHOSTEL";

export const PRIVILEGES_EMPTY = "PRIVILEGES_EMPTY";
export const PRIVILEGES_STUDY = "PRIVILEGES_STUDY";
export const PRIVILEGES_SPORT = "PRIVILEGES_SPORT";
export const PRIVILEGES_SOCIAL = "PRIVILEGES_SOCIAL";

export const ADDITIONAL_INFORMATION_EMPTY = "ADDITIONAL_INFORMATION_EMPTY";
export const ADDITIONAL_INFORMATION_CLASSROOM_TEACHER = "ADDITIONAL_INFORMATION_CLASSROOM_TEACHER";
export const ADDITIONAL_INFORMATION_CLASSNAME = "ADDITIONAL_INFORMATION_CLASSNAME";
export const ADDITIONAL_INFORMATION_FIO_GRSU = "ADDITIONAL_INFORMATION_FIO_GRSU";
export const ADDITIONAL_INFORMATION_PPY = "ADDITIONAL_INFORMATION_PPY";

export const OLYMPIADS_SELECTED = "OLYMPIADS_SELECTED";



