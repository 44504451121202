import resolver from "./resolver";
import actionTesting from "./actionTesting";
import actionResultTesting from "./actionResultTesting";
import actionCommentTesting from "./actionCommentTesting";
import actionFinalResult from "./actionFinalResult";
import actionCommentResult from "./actionCommentResult";
import actionSchoolResult from "./actionSchoolResult";
import actionSpecPriority from "./actionSpecPriority";
import actionEnrollResult from "./actionEnrollResult";

const actions = () => [
  {
    type: "%_ENROLL_RESULT_FORM_%",
    action: ({vars, status}) => {

      let disciplineVar = {messages: [], name: ''};
      if (status._ENROLL_RESULT_ === 1) {
        disciplineVar = vars.find(item => item.name === "ENROLL_YOU_CRASAVCHEG")
      } else if (status._ENROLL_RESULT_ === 2) {
        disciplineVar = vars.find(item => item.name === "ENROLL_PAY")
      } else if (status._ENROLL_RESULT_ === 3) {
        disciplineVar = vars.find(item => item.name === "ENROLL_BUDGET_FAIL")
      } else if (status._ENROLL_RESULT_ === 4) {
        disciplineVar = vars.find(item => item.name === "ENROLL_PAY_FAIL")
      } else if (status._ENROLL_RESULT_ === 5) {
        disciplineVar = vars.find(item => item.name === "PROF_FAIL")
      }

      return resolver({
        messages: disciplineVar.messages,
        actions: actionEnrollResult({vars: vars, status: status}),
        vars: vars,
        status: status
      }).map(message => {

        return `<div class="mb-2">`
          + message
          + `</div>`
      }).join('')
    }
  },
  {
    type: "%_TESTING_%",
    action: ({vars, status}) => {
      return status._TESTING_
        .sort((a, b) => {
          if (a.__NUM_EX > b.__NUM_EX) {
            return 1;
          }
          if (a.__NUM_EX < b.__NUM_EX) {
            return -1;
          }
          return 0
        })
        .map(test => {
          const disciplineVar = vars.find(item => item.name === "TESTING")

          return resolver({
            messages: disciplineVar.messages,
            actions: actionTesting({test: test}),
            vars: vars,
            status: status
          })

        })
        .map(messages => {

          return `<ol style="list-style-type: none" class="mb-2">`
            + messages.map(e => `<li>${e}</li>`).join('')
            + `</ol>`
        })
        .join('')

    }
  },
  {
    type: "%_RESULT_TESTING_%",
    action: ({vars, status}) => {
      return status._RESULT_TESTING_
        .sort((a, b) => {
          if (a.__NUM_EX > b.__NUM_EX) {
            return 1;
          }
          if (a.__NUM_EX < b.__NUM_EX) {
            return -1;
          }
          return 0
        })
        .map(result => {
          const disciplineVar = vars.find(item => item.name === "RESULT_TESTING")

          return resolver({
            messages: disciplineVar.messages,
            actions: actionResultTesting({result: result}),
            vars: vars,
            status: status
          })

        })
        .map(messages => {

          return `<ol style="list-style-type: none" >`
            + messages.map(e => `<li>${e}</li>`).join('')
            + `</ol>`
        })
        .join('')

    }
  },
  {
    type: "%_SPEC_PRIORITY_%",
    action: ({vars, status}) => {

      if (status._PRIORITY_.length > 1) {

        const disciplineVar = vars.find(item => item.name === "SPEC_PRIORITY_ANY")

        return resolver({
          messages: disciplineVar.messages,
          actions: actionSpecPriority({arraySpec: status._PRIORITY_}),
          vars: vars,
          status: status
        }).join('')

      } else if (status._PRIORITY_.length === 1) {
        return status._PRIORITY_
          .map(spec => {

            const disciplineVar = vars.find(item => item.name === "SPEC_PRIORITY_SINGLE")

            return resolver({
              messages: disciplineVar.messages,
              actions: actionSpecPriority({spec: spec}),
              vars: vars,
              status: status
            })

          })
          .join('')
      }

      return ''
    }
  },

  // todo: change hard href to href from api
  {
    type: "%_COMMENT_TESTING_%",
    action: ({vars, status}) => {

      if (status._TESTING_.length > 0) {
        const href_abit_testing = 'https://abit.grsu.by/view/373-raspisanie-vstupitel-nykh-ispytanij-2020-goda.html';

        const disciplineVar = vars.find(item => item.name === "COMMENT_TESTING")

        return resolver({
          messages: disciplineVar.messages,
          actions: actionCommentTesting({href_abit_testing: href_abit_testing}),
          vars: vars,
          status: status
        }).join('')
      }

      return ''

    }
  },
  {
    type: "%_FINAL_RESULT_%",
    action: ({vars, status}) => {

      const disciplineVar = vars.find(item => item.name === "FINAL_RESULT")

      return resolver({
        messages: disciplineVar.messages,
        actions: actionFinalResult({status: status}),
        vars: vars,
        status: status
      }).join('')

    }
  },
  {
    type: "%_COMMENT_RESULT_%",
    action: ({vars, status}) => {

      const disciplineVar = vars.find(item => item.name === "COMMENT_RESULT")

      return resolver({
        messages: disciplineVar.messages,
        actions: actionCommentResult({status: status}),
        vars: vars,
        status: status
      }).join('')

    }
  },
  {
    type: "%_SCHOOL_RESULT_%",
    action: ({vars, status}) => {

      const disciplineVar = vars.find(item => item.name === "SCHOOL_RESULT")

      return resolver({
        messages: disciplineVar.messages,
        actions: actionSchoolResult({status: status}),
        vars: vars,
        status: status
      }).join('')

    }
  },
  {
    type: "%_COMMENT2_%",
    action: ({message, status}) => message.replace("%_COMMENT2_%", status._COMMENT2_)
  },
  {
    type: "%_INFO_LINK_%",
    action: ({message, vars, status}) => {
      if (Array.isArray(status._TESTING_) && status._TESTING_.length === 0) return '';
      const entryVar = vars.find(item => item.name === "INFO_LINK")
      const href = entryVar.ref;
      const text = entryVar.text;
      const link = `<a href="${href}" target="_blank" class="pointer">${text}</a>`;
      return message.replace("%_INFO_LINK_%", link);
    }
  },
  {
    type: "%_CARYSEL_%",
    action: ({message, status}) => message.replace("%_CARYSEL_%", status._CARYSEL_)
  },
  {
    type: "%_PAY_FORM_%",
    action: ({message, status}) => message.replace("%_PAY_FORM_%", status._TYPE_)
  },
  {
    type: "%_SPECIALITY_TITLE_%",
    action: ({message, status}) => message.replace("%_SPECIALITY_TITLE_%", status._SPECIALITY_TITLE_.trim())
  },
  {
    type: "%_STUDY_FORM_%",
    action: ({message, status}) => message.replace("%_STUDY_FORM_%", status._FORM_)
  },
  {
    type: "%_GENDER_%",
    action: ({message}, store) => message.replace("%_GENDER_%", (store.gender === 'female' ? 'ая' : 'ый'))
  },
  {
    type: "%_USER_NAME_%",
    action: ({message}, store) => message.replace("%_USER_NAME_%", (store.name === '' || store.name === undefined ? 'Абитуриент' : store.name))
  },
  {
    type: "%_USER_PATRONYMIC_SURNAME_%",
    action: ({message}, store) => {
      const surname = (store.surname === '' || store.surname === undefined ? '' : store.surname)
      const patronymic = (store.patronymic === '' || store.patronymic === undefined ? '' : store.patronymic + ' ')

      return message.replace("%_USER_PATRONYMIC_SURNAME_%", `${patronymic}${surname}`);
    }
  },
  {
    type: "%_QUEUE_DATETIME_%",
    action: ({message}, store) => message.replace("%_QUEUE_DATETIME_%", (store.currentDatetime === '' || store.currentDatetime === undefined ? '' : store.currentDatetime))
  },
// todo: change hard href to href from api
  {
    type: "%_HREF_TO_DOC_%",
    action: ({message}, store) => {
      let href_abit_to_doc = 'https://abit.grsu.by/view/18-vysshee/kak-postupit/1041-dokumenty-predstavlyaemye-v-priemnuyu-komissiyu.html';
      if (store.typeTraining === 'targetTraining') href_abit_to_doc = 'https://abit.grsu.by/kak-postupit/18-kategorii-ru/vysshee/kak-postupit/1659-perechen-dokumentov-dlya-tselevikov.html';
      if (store.typeTraining === 'olympiad') href_abit_to_doc = 'https://abit.grsu.by/kak-postupit/18-kategorii-ru/vysshee/kak-postupit/1658-perechen-dokumentov-dlya-olimpiadnikov.html';
      const link = `<a href="${href_abit_to_doc}" target="_blank" class="v-chip--link pointer">по ссылке</a>`;
      return message.replace("%_HREF_TO_DOC_%", link);
    }
  },

  {
    type: "%_DEFAULT_MESSAGES_%",
    action: ({message}, store) => {
      return message.replace("%_DEFAULT_MESSAGES_%", store.defaultMessage);
    }
  },

  {
    type: "%_YEAR_%",
    action: ({message}, store) => {
      return message.replace("%_YEAR_%", new Date().getFullYear());
    }
  },

  {
    type: "%_FACULTY_GENITIV_%",
    action: ({message, status}) => {
      return message.replace("%_FACULTY_GENITIV_%",  status?._PROFORG_INFO_?.facultyData?.genitiv.trim());
    }
  },

  {
    type: "%_FACULTY_INSTUM_%",
    action: ({message, status}) => {
      return message.replace("%_FACULTY_INSTUM_%",  status?._PROFORG_INFO_?.facultyData?.locativ.trim());
    }
  },

  {
    type: "%_MEETING_DATETIME_%",
    action: ({message, status}) => {
      const prof_meeting = status?._PROFORG_INFO_.fs_info.prof_meeting;
      return message.replace("%_MEETING_DATETIME_%",  prof_meeting?.date.trim()+ " " + prof_meeting?.time.trim());
    }
  },

  {
    type: "%_MEETENG_PLACE_%",
    action: ({message, status}) => {
      const prof_meeting = status?._PROFORG_INFO_.fs_info.prof_meeting;
      return message.replace("%_MEETENG_PLACE_%",  prof_meeting?.place.trim() + ", ауд.  " + prof_meeting?.room.trim());
    }
  },

  {
    type: "%_INTERNAT_LINK_%",
    action: ({message, status}) => {
      const prof_meeting = status?._PROFORG_INFO_.fs_info.prof_meeting;
      return message.replace("%_INTERNAT_LINK_%",  '<a href="#internal">(указаны ниже)</a>');
    }
  },

  {
    type: "%_DEANERY_OFFICE_PHONE_%",
    action: ({message, status}) => {
      const phones = status?._PROFORG_INFO_?.facultyData?.phones;
      return message.replace("%_DEANERY_OFFICE_PHONE_%",  phones);
    }
  },

  {
    type: "%_INTERNAT_INFO_%",
    action: ({message, status}) => {
      const {internat, medical} = status?._PROFORG_INFO_.fs_info;
      const table = `<table border="1" style="border-collapse: collapse;border-color: #000;border-spacing: 0;width: 100%;text-align: center;">
        <thead>
      <tr>
        <th colspan="2" style="width: 50%;">Выдача направлений на заселение в общежитие</th>
        <th colspan="2" style="width: 50%;">Медицинский осмотр</th>
      </tr>
      <tr>
        <th style="width: 25%;">Дата и время</th>
        <th style="width: 25%;">Адрес</th>
        <th style="width: 25%;">Дата и время</th>
        <th style="width: 25%;">Адрес</th>
      </tr>
      </thead>
    <tbody>
      
      <tr>
        <td>${internat.date + ' ' + internat.time}</td>
        <td>${internat.place}</td>
        <td>${medical.date + ' ' + medical.time}</td>
        <td>${medical.place}</td>
      </tr>
    </tbody>
    </table>`;

      return message.replace("%_INTERNAT_INFO_%",  table.trim())
    }
  },
]
export default actions;