import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import {Form, useForm} from "../../../../../components/Customs/useForm";
import Controls from "../../../../../components/Customs/Controls/Controls";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import Valid from "../../../../../constants/Valid";

import {useDispatch, useSelector} from "react-redux";
import {
  PARENTS_MOTHER_HAVE, PARENTS_MOTHER_SURNAME, PARENTS_MOTHER_FIRSTNAME, PARENTS_MOTHER_PATRONYMIC,
  PARENTS_MOTHER_PHONE_MOBILE, PARENTS_MOTHER_LIVINGTOGETHER, PARENTS_MOTHER_ADDRESS,
  PARENTS_FATHER_HAVE, PARENTS_FATHER_SURNAME, PARENTS_FATHER_FIRSTNAME, PARENTS_FATHER_PATRONYMIC,
  PARENTS_FATHER_PHONE_MOBILE, PARENTS_FATHER_LIVINGTOGETHER, PARENTS_FATHER_ADDRESS,
} from "../../../../../services/tagTypes";
import {
  deleteContractFileStatement,
  deleteTypeCompetitionStatement,
  getDataStatement,
  setDataStatement
} from "../../../../../store/Statement/actionStatement";
import NumberFormat from "react-number-format";
import {chatAction_createNotification} from "../../../../../store/Chat/ChatActions";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const initialFValues = {
  noInfoAboutMother: false,
  surnameMother: "",
  nameMother: "",
  patronymicMother: "",
  phoneMobileMother: '',
  livingTogetherMother: false,
  addressMother: "",
  noInfoAboutFather: false,
  surnameFather: "",
  nameFather: "",
  patronymicFather: "",
  phoneMobileFather: '',
  livingTogetherFather: false,
  addressFather: "",

};

function Parents() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  const statementTypeCompetition = useSelector(state => state.statementReducer.typeCompetition);
  const statementContractFile = useSelector(state => state.statementReducer.contractFile);

  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])

  const onReloadInformationParents = (item) => {

    let noInfoAboutMother = item.find(data => data.typeTitle === PARENTS_MOTHER_HAVE);
    if (noInfoAboutMother !== undefined) values.noInfoAboutMother = noInfoAboutMother.value.value;

    let surnameMother = item.find(data => data.typeTitle === PARENTS_MOTHER_SURNAME);
    if (surnameMother !== undefined) values.surnameMother = surnameMother.value.value;

    let nameMother = item.find(data => data.typeTitle === PARENTS_MOTHER_FIRSTNAME);
    if (nameMother !== undefined) values.nameMother = nameMother.value.value;

    let patronymicMother = item.find(data => data.typeTitle === PARENTS_MOTHER_PATRONYMIC);
    if (patronymicMother !== undefined) values.patronymicMother = patronymicMother.value.value;

    let phoneMobileMother = item.find(data => data.typeTitle === PARENTS_MOTHER_PHONE_MOBILE);
    if (phoneMobileMother !== undefined) values.phoneMobileMother = phoneMobileMother.value.value;

    let livingTogetherMother = item.find(data => data.typeTitle === PARENTS_MOTHER_LIVINGTOGETHER);
    if (livingTogetherMother !== undefined) values.livingTogetherMother = livingTogetherMother.value.value;

    let addressMother = item.find(data => data.typeTitle === PARENTS_MOTHER_ADDRESS);
    if (addressMother !== undefined) values.addressMother = addressMother.value.value;

    let noInfoAboutFather = item.find(data => data.typeTitle === PARENTS_FATHER_HAVE);
    if (noInfoAboutFather !== undefined) values.noInfoAboutFather = noInfoAboutFather.value.value;

    let surnameFather = item.find(data => data.typeTitle === PARENTS_FATHER_SURNAME);
    if (surnameFather !== undefined) values.surnameFather = surnameFather.value.value;

    let nameFather = item.find(data => data.typeTitle === PARENTS_FATHER_FIRSTNAME);
    if (nameFather !== undefined) values.nameFather = nameFather.value.value;

    let patronymicFather = item.find(data => data.typeTitle === PARENTS_FATHER_PATRONYMIC);
    if (patronymicFather !== undefined) values.patronymicFather = patronymicFather.value.value;

    let phoneMobileFather = item.find(data => data.typeTitle === PARENTS_FATHER_PHONE_MOBILE);
    if (phoneMobileFather !== undefined) values.phoneMobileFather = phoneMobileFather.value.value;

    let livingTogetherFather = item.find(data => data.typeTitle === PARENTS_FATHER_LIVINGTOGETHER);
    if (livingTogetherFather !== undefined) values.livingTogetherFather = livingTogetherFather.value.value;

    let addressFather = item.find(data => data.typeTitle === PARENTS_FATHER_ADDRESS);
    if (addressFather !== undefined) values.addressFather = addressFather.value.value;

    setRender(!render)
  }

  useEffect(() => {
    const getDataParents = async () => {
      const arrayParentsParams = [
        `type[]=${PARENTS_MOTHER_HAVE}`,
        `type[]=${PARENTS_MOTHER_SURNAME}`,
        `type[]=${PARENTS_MOTHER_FIRSTNAME}`,
        `type[]=${PARENTS_MOTHER_PATRONYMIC}`,
        `type[]=${PARENTS_MOTHER_PHONE_MOBILE}`,
        `type[]=${PARENTS_MOTHER_LIVINGTOGETHER}`,
        `type[]=${PARENTS_MOTHER_ADDRESS}`,
        `type[]=${PARENTS_FATHER_HAVE}`,
        `type[]=${PARENTS_FATHER_SURNAME}`,
        `type[]=${PARENTS_FATHER_FIRSTNAME}`,
        `type[]=${PARENTS_FATHER_PATRONYMIC}`,
        `type[]=${PARENTS_FATHER_PHONE_MOBILE}`,
        `type[]=${PARENTS_FATHER_LIVINGTOGETHER}`,
        `type[]=${PARENTS_FATHER_ADDRESS}`,
      ];
      const parentsParams = arrayParentsParams.join('&');
      const result = await dispatch(getDataStatement(parentsParams))
      onReloadInformationParents(result)
    }
    getDataParents();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (validate()) {
      if (isEmptyInfoAboutMother()) {
        clearFormInfoAboutMother();
      }
      if (isEmptyInfoAboutFather()) {
        clearFormInfoAboutFather();
      }
      if (isLivingTogetherMother()) {
        clearFormInfoAboutMotherAddress();
      }
      if (isLivingTogetherFather()) {
        clearFormInfoAboutFatherAddress();
      }
      await onSaver(values)
      setIsLoading(false);
      history.push("/statement")
    } else {
      setIsLoading(false);
    }
  };

  const onSaver = async (data) => {
    const sendObject = {
      PARENTS_MOTHER_HAVE: {value: data.noInfoAboutMother},
      PARENTS_MOTHER_SURNAME: {value: data.surnameMother},
      PARENTS_MOTHER_FIRSTNAME: {value: data.nameMother},
      PARENTS_MOTHER_PATRONYMIC: {value: data.patronymicMother},
      PARENTS_MOTHER_PHONE_MOBILE: {value: data.phoneMobileMother},
      PARENTS_MOTHER_LIVINGTOGETHER: {value: data.livingTogetherMother},
      PARENTS_MOTHER_ADDRESS: {value: data.addressMother},
      PARENTS_FATHER_HAVE: {value: data.noInfoAboutFather},
      PARENTS_FATHER_SURNAME: {value: data.surnameFather},
      PARENTS_FATHER_FIRSTNAME: {value: data.nameFather},
      PARENTS_FATHER_PATRONYMIC: {value: data.patronymicFather},
      PARENTS_FATHER_PHONE_MOBILE: {value: data.phoneMobileFather},
      PARENTS_FATHER_LIVINGTOGETHER: {value: data.livingTogetherFather},
      PARENTS_FATHER_ADDRESS: {value: data.addressFather},
      ANKETA_STATUS_FORMS_APPLICATION: {
        ...statementStatusFormsApplication,
        4: {"status": "IS_FULL", "coment": ""},
        9: {"action": "4"}
      },
      ANKETA_STATUS: {"value": "IS_WORK"}
    }

    try {
      await dispatch(setDataStatement(sendObject));
      dispatch(chatAction_createNotification({
        message: 'Данные формы сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      if (error.data.detail === "lock_application_in_asu") {
        dispatch(chatAction_createNotification({
          message: 'Форма заблокирована для изменений.',
          variant: 'error'
        }));
      } else {
        console.log(error);
        dispatch(chatAction_createNotification({
          message: 'Ошибка сохранения данных формы.',
          variant: 'error'
        }));
      }
    }
    if (statementTypeCompetition?.id) await dispatch(deleteTypeCompetitionStatement());
    if (statementContractFile?.id) await dispatch(deleteContractFileStatement());
  }

  const validate = (fieldValues = values) => {
    let temp = {...errors};

    if ('surnameMother' in fieldValues) {
      temp.surnameMother = !isEmptyInfoAboutMother()
        ? Valid.isIsset(fieldValues.surnameMother)
          ? Valid.regexCyrillic(fieldValues.surnameMother)
            ? Valid.isTrim(fieldValues.surnameMother)
              ? ''
              : 'Это поле не может состоять только из пробелов'
            : 'Это поле должно содержать только символы кириллицы!'
          : 'Это поле обязательное для заполнения!'
        : '';
    }
    if ('nameMother' in fieldValues) {
      temp.nameMother = !isEmptyInfoAboutMother()
        ? Valid.isIsset(fieldValues.nameMother)
          ? Valid.regexCyrillic(fieldValues.nameMother)
            ? Valid.isTrim(fieldValues.nameMother)
              ? ''
              : 'Это поле не может состоять только из пробелов'
            : 'Это поле должно содержать только символы кириллицы!'
          : 'Это поле обязательное для заполнения!'
        : '';
    }
    if ('patronymicMother' in fieldValues) {
      temp.patronymicMother = !isEmptyInfoAboutMother()
        ? Valid.regexCyrillic(fieldValues.patronymicMother)
          ? ''
          : 'Это поле должно содержать только символы кириллицы!'
        : '';
    }
    if ('phoneMobileMother' in fieldValues) {
      temp.phoneMobileMother = !isEmptyInfoAboutMother()
        ? Valid.isIsset(fieldValues.phoneMobileMother)
          ? ''
          : 'Это поле обязательное для заполнения!'
        : '';
    }
    if ('addressMother' in fieldValues) {
      temp.addressMother = !isEmptyInfoAboutMother()
        ? !isLivingTogetherMother()
          ? Valid.isIsset(fieldValues.addressMother)
            ? ''
            : 'Это поле обязательное для заполнения!'
          : ''
        : '';
    }

    if ('surnameFather' in fieldValues) {
      temp.surnameFather = !isEmptyInfoAboutFather()
        ? Valid.isIsset(fieldValues.surnameFather)
          ? Valid.regexCyrillic(fieldValues.surnameFather)
            ? Valid.isTrim(fieldValues.surnameFather)
              ? ''
              : 'Это поле не может состоять только из пробелов'
            : 'Это поле должно содержать только символы кириллицы!'
          : 'Это поле обязательное для заполнения!'
        : '';
    }
    if ('nameFather' in fieldValues) {
      temp.nameFather = !isEmptyInfoAboutFather()
        ? Valid.isIsset(fieldValues.nameFather)
          ? Valid.regexCyrillic(fieldValues.nameFather)
            ? Valid.isTrim(fieldValues.nameFather)
              ? ''
              : 'Это поле не может состоять только из пробелов'
            : 'Это поле должно содержать только символы кириллицы!'
          : 'Это поле обязательное для заполнения!'
        : '';
    }
    if ('patronymicFather' in fieldValues) {
      temp.patronymicFather = !isEmptyInfoAboutFather()
        ? Valid.regexCyrillic(fieldValues.patronymicFather)
          ? ''
          : 'Это поле должно содержать только символы кириллицы!'
        : '';
    }

    if ('phoneMobileFather' in fieldValues) {
      temp.phoneMobileFather = !isEmptyInfoAboutFather()
        ? Valid.isIsset(fieldValues.phoneMobileFather)
          ? ''
          : 'Это поле обязательное для заполнения!'
        : '';
    }
    if ('addressFather' in fieldValues) {
      temp.addressFather = !isEmptyInfoAboutFather()
        ? !isLivingTogetherFather()
          ? Valid.isIsset(fieldValues.addressFather)
            ? ''
            : 'Это поле обязательное для заполнения!'
          : ''
        : '';
    }
    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const isEmptyInfoAboutMother = () => {
    return !!values.noInfoAboutMother;
  }

  const clearFormInfoAboutMother = () => {
    if (values.noInfoAboutMother === true) {
      values.surnameMother = '';
      values.nameMother = '';
      values.patronymicMother = '';
      values.phoneMobileMother = '';
      values.livingTogetherMother = false;
      values.addressMother = '';
    }
  }

  const isLivingTogetherMother = () => {
    return !!values.livingTogetherMother;
  }

  const clearFormInfoAboutMotherAddress = () => {
    if (values.livingTogetherMother === true) {
      values.addressMother = '';
    }
  }

  const isEmptyInfoAboutFather = () => {
    return !!values.noInfoAboutFather;
  }
  const clearFormInfoAboutFather = () => {
    if (values.noInfoAboutFather === true) {
      values.surnameFather = '';
      values.nameFather = '';
      values.patronymicFather = '';
      values.phoneMobileFather = '';
      values.livingTogetherFather = false;
      values.addressFather = '';
    }
  }

  const isLivingTogetherFather = () => {
    return !!values.livingTogetherFather;
  }

  const clearFormInfoAboutFatherAddress = () => {
    if (values.livingTogetherFather === true) {
      values.addressFather = '';
    }
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFValues, true, validate);

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} justifyContent="center" className={classes.grid_container}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader title="Родители"/>
            <Divider/>
            <CardContent>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Controls.Checkbox
                      name="noInfoAboutMother"
                      label="Сведения о матери отсутствуют"
                      value={values.noInfoAboutMother}
                      onChange={handleInputChange}
                      error={errors.noInfoAboutMother}
                    />
                  </Grid>
                  {
                    isEmptyInfoAboutMother()
                      ? null
                      :
                      <>
                        <Grid item md={12} xs={12}>
                          <Typography variant="body1" component="p">
                            Сведения о матери
                          </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Controls.Input
                            fullWidth
                            name="surnameMother"
                            label="Фамилия*"
                            value={values.surnameMother}
                            onChange={handleInputChange}
                            error={errors.surnameMother}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Controls.Input
                            fullWidth
                            name="nameMother"
                            label="Имя*"
                            value={values.nameMother}
                            onChange={handleInputChange}
                            error={errors.nameMother}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Controls.Input
                            fullWidth
                            name="patronymicMother"
                            label="Отчество"
                            value={values.patronymicMother}
                            onChange={handleInputChange}
                            error={errors.patronymicMother}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <NumberFormat
                            customInput={Controls.Input}
                            format="375#########"
                            allowEmptyFormatting
                            mask="_"
                            variant="outlined"
                            fullWidth
                            name="phoneMobileMother"
                            label="Телефон (мобильный)*"
                            value={values.phoneMobileMother}
                            onChange={handleInputChange}
                            error={errors.phoneMobileMother}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Controls.Checkbox
                            name="livingTogetherMother"
                            label="Проживает с абитуриентом"
                            value={values.livingTogetherMother}
                            onChange={handleInputChange}
                            error={errors.livingTogetherMother}
                          />
                        </Grid>
                        {
                          isLivingTogetherMother()
                            ? null
                            :
                            <Grid item xs={12}>
                              <Controls.Input
                                fullWidth
                                name="addressMother"
                                label="Почтовый индекс, адрес места жительства в соответствии со штампом о регистрации*"
                                value={values.addressMother}
                                onChange={handleInputChange}
                                error={errors.addressMother}
                              />
                            </Grid>
                        }

                      </>

                  }
                  <Grid item md={12} xs={12}>
                    <Controls.Checkbox
                      name="noInfoAboutFather"
                      label="Сведения об отце отсутствуют"
                      value={values.noInfoAboutFather}
                      onChange={handleInputChange}
                      error={errors.noInfoAboutFather}
                    />
                  </Grid>
                  {
                    isEmptyInfoAboutFather()
                      ? null
                      :
                      <>
                        <Grid item md={12} xs={12}>
                          <Typography variant="body1" component="p">
                            Сведения об отце
                          </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Controls.Input
                            fullWidth
                            name="surnameFather"
                            label="Фамилия*"
                            value={values.surnameFather}
                            onChange={handleInputChange}
                            error={errors.surnameFather}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Controls.Input
                            fullWidth
                            name="nameFather"
                            label="Имя*"
                            value={values.nameFather}
                            onChange={handleInputChange}
                            error={errors.nameFather}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Controls.Input
                            fullWidth
                            name="patronymicFather"
                            label="Отчество"
                            value={values.patronymicFather}
                            onChange={handleInputChange}
                            error={errors.patronymicFather}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <NumberFormat
                            customInput={Controls.Input}
                            format="375#########"
                            allowEmptyFormatting
                            mask="_"
                            variant="outlined"
                            fullWidth
                            name="phoneMobileFather"
                            label="Телефон (мобильный)*"
                            value={values.phoneMobileFather}
                            onChange={handleInputChange}
                            error={errors.phoneMobileFather}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Controls.Checkbox
                            name="livingTogetherFather"
                            label="Проживает с абитуриентом"
                            value={values.livingTogetherFather}
                            onChange={handleInputChange}
                            error={errors.livingTogetherFather}
                          />
                        </Grid>
                        {
                          isLivingTogetherFather()
                            ? null
                            :
                            <Grid item xs={12}>
                              <Controls.Input
                                fullWidth
                                name="addressFather"
                                label="Почтовый индекс, адрес места жительства в соответствии со штампом о регистрации*"
                                value={values.addressFather}
                                onChange={handleInputChange}
                                error={errors.addressFather}
                              />
                            </Grid>
                        }

                      </>

                  }

                  <Grid item container xs={12} justifyContent={"space-between"}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => {
                        history.push("/statement")
                      }}
                      disabled={isLoading}
                    >
                      Назад
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isLoading}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Parents;