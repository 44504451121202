import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup, FormHelperText,
  Grid,
  IconButton,
  InputAdornment, Link,
  TextField, Typography
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {Link as RouterLink} from "react-router-dom";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {useForm} from "../../../../components/Customs/useForm";
import Valid from "../../../../constants/Valid";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import REGULAR_EXPRESSION from "../../../../constants/RegExp";
import {DialogCustom, useDialog} from "../../../../components/Customs/useDialog";
import {TermsMessage} from "../../../../components/Terms/TermsMessage";

const initialFValues = {
  phone: "",
  email: "",
  firstName: "",
  surName: "",
  password: "",
  terms: false,
};

function useFormSingUp(propsOnSubmit, validate, values) {

  const [isEmailAuth, setEmailAuth] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);

  function onSubmit(e) {
    e.preventDefault();
    if (validate()) {
      const obj = {
        ...(isEmailAuth && {email: values?.email}),
        ...(isEmailAuth && {type: "EMAIL"}),
        ...(!isEmailAuth && {phone: values?.phone}),
        password: values?.password,
        optionalUserData: {
          USER_TERMS: {value: values?.terms},
          USER_FIRST_NAME: {value: values?.firstName},
          USER_SURNAME: {value: values?.surName},
          ...(isEmailAuth && {USER_EMAIL: {value: values?.email}}),
          ...(!isEmailAuth && {USER_PHONE_MOBILE: {value: values?.phone}}),
        }
      };
      propsOnSubmit && propsOnSubmit(obj);
    }
  }

  function onShowPassword() {
    setShowPassword(!isShowPassword);
  }

  function onEmailAuth(e) {
    if (e.altKey && e.ctrlKey && e.key === 'Enter') {
      setEmailAuth(!isEmailAuth);
    }
  }

  return {
    onSubmit,
    onShowPassword,
    onEmailAuth,
    options: {emailAuth: isEmailAuth, showPassword: isShowPassword}
  };
}

export default function Form(props) {
  const classes = useStyles();
  const {onSubmit: propsOnSubmit, sending: propsSending} = props;
  const validate = (fieldValues = values) => {
    let temp = {...errors};
    if (!options.emailAuth) {
      if ('phone' in fieldValues) {
        temp.phone = "";
        if (temp?.phone === "" && !Valid.isIsset(fieldValues?.phone)) {
          temp.phone = "Обязательно для заполнения.";
        }
        if (temp?.phone === "" && REGULAR_EXPRESSION.MOBILE_PHONE.test(fieldValues?.phone) === false) {
          temp.phone = "Неподходящий формат номера телефона";
        }
      }
      if ('email' in fieldValues) {
        temp.email = "";
      }
    } else {
      if ('phone' in fieldValues) {
        temp.phone = "";
      }
      if ('email' in fieldValues) {
        temp.email = "";
        if (temp?.email === "" && !Valid.isIsset(fieldValues?.email)) {
          temp.email = "Обязательно для заполнения.";
        }
        if (temp?.email === "" && REGULAR_EXPRESSION.EMAIL.test(fieldValues?.email) === false) {
          temp.email = "Неподходящий формат электронной почты";
        }
      }
    }
    if ('firstName' in fieldValues) {
      temp.firstName = "";
      if (!Valid.isIsset(fieldValues?.firstName)) {
        temp.firstName = "Обязательно для заполнения.";
      }
      if (temp?.firstName === "" && /^[а-яА-ЯёЁ\s-]*$/.test(fieldValues?.firstName) === false) {
        temp.firstName = "Только символы русского языка.";
      }
    }
    if ('surName' in fieldValues) {
      temp.surName = "";
      if (temp?.surName === "" && !Valid.isIsset(fieldValues?.surName)) {
        temp.surName = "Обязательно для заполнения.";
      }
      if (temp?.surName === "" && /^[а-яА-ЯёЁ\s-]*$/.test(fieldValues?.surName) === false) {
        temp.surName = "Только символы русского языка.";
      }
    }
    if ('password' in fieldValues) {
      temp.password = "";
      if (temp?.password === "" && !Valid.isIsset(fieldValues?.password)) {
        temp.password = "Обязательно для заполнения.";
      }
      if (temp?.password === "" && fieldValues?.password?.length < 8) {
        temp.password = "Пароль слишком короткий - минимум 8 символов.";
      }
    }
    if ('terms' in fieldValues) {
      temp.terms = "";
      if (temp?.terms === "" && Boolean(fieldValues?.terms) === false) {
        temp.terms = "Условия использования должны быть приняты.";
      }
    }

    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const {
    open,
    scroll,
    handleClickOpen,
    handleClose,
  } = useDialog();
  const {
    values,
    errors,
    setErrors,
    handleInputChange: onChange
  } = useForm(initialFValues, true, validate);
  const {
    onSubmit,
    onShowPassword,
    onEmailAuth,
    options
  } = useFormSingUp(propsOnSubmit, validate, values);

  return (
    <form className={classes.form} onSubmit={onSubmit} noValidate>
      <Grid container spacing={2}>
        {
          Boolean(options?.emailAuth) === false ?
            <Grid item xs={12}>
              <NumberFormat
                autoFocus
                required
                customInput={TextField}
                format="375#########"
                allowEmptyFormatting
                mask="_"
                variant="outlined"
                fullWidth
                id="phone"
                label="Номер телефона"
                name="phone"
                placeholder="Введите номер телефона"
                onKeyDown={onEmailAuth}
                value={values.phone}
                onChange={onChange}
                error={Boolean(errors.phone)}
                helperText={errors.phone && errors.phone}
                InputProps={
                  {
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneAndroidIcon color="disabled"/>
                      </InputAdornment>
                    ),
                  }
                }
              />
            </Grid>
            : null
        }
        {
          Boolean(options?.emailAuth) === true ?
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                variant="outlined"
                onKeyDown={onEmailAuth}
                fullWidth
                id="email"
                label="Электронная почта"
                placeholder="Введите электронную почту"
                name="email"
                type="email"
                value={values.email}
                onChange={onChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
                InputProps={
                  {
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailIcon color="disabled"/>
                      </InputAdornment>
                    ),
                  }
                }
              />
            </Grid>
            : null
        }

        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            fullWidth
            name="firstName"
            label="Имя"
            placeholder="Введите имя"
            id="firstName"
            value={values.firstName}
            onChange={onChange}
            error={Boolean(errors.firstName)}
            helperText={errors.firstName}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            fullWidth
            name="surName"
            label="Фамилия"
            placeholder="Введите фамилию"
            id="surName"
            value={values.surName}
            onChange={onChange}
            error={Boolean(errors.surName)}
            helperText={errors.surName}
          />
        </Grid>


        <Grid item xs={12}>
          <TextField
            required
            variant="outlined"
            fullWidth
            name="password"
            label="Пароль"
            type={options.showPassword ? 'text' : 'password'}
            placeholder="Введите пароль"
            id="password"
            value={values.password}
            onChange={onChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onShowPassword}
                    onMouseDown={onShowPassword}
                    edge="end"
                  >
                    {options.showPassword ? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            error={Boolean(errors.terms)}
            component="fieldset"
            className={classes.formControl}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={values.terms}
                    onChange={(e) => onChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked
                      }
                    })}
                    name="terms"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    Даю согласие учреждению образования «Гродненский государственный университет имени Янки Купалы» на обработку моих персональных данных в соответствии с <Link onClick={handleClickOpen} component={RouterLink} to={'/sign-up'} variant="body2">Политикой обработки персональных данных в информационной системе «Цифровой кабинет абитуриента ГрГУ им. Янки Купалы»</Link>. С условиями <Link onClick={handleClickOpen} component={RouterLink} to={'/sign-up'} variant="body2">Политики</Link>  ознакомлен (ознакомлена) и согласен (согласна).
                  </Typography>
                }
              />
            </FormGroup>
            {
              Boolean(errors.terms)
                ? <FormHelperText>{errors.terms}</FormHelperText>
                : null
            }

          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
            disabled={propsSending}
          >
            Зарегистрироваться
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Link component={RouterLink} to="/sign-in" variant="body2">
            Уже есть аккаунт? Войти в систему
          </Link>
        </Grid>
      </Grid>
      <DialogCustom open={open} handleClose={handleClose} scroll={scroll} dialogContextText={<TermsMessage/>} fullScreen/>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  formControl: {},
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

Form.propTypes = {
  onSubmit: PropTypes.func,
  sending: PropTypes.bool
};
