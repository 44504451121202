import {
  LOGOUT,
} from "../actions/actionTypes";
import {
  CHANNELS_GET_USER_CHANNEL_LIST_SUCCESS,
  CHANNELS_GET_USER_CHANNEL_LIST_FAILED,
  CHANNELS_GET_MESSAGES_LIST_SUCCESS,
  CHANNELS_GET_MESSAGES_LIST_FAILED,
} from "./typesChannels";

const initialState = {
  channelList: [],
  messagesList: [],
};

export default function channelsReducer(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case CHANNELS_GET_USER_CHANNEL_LIST_SUCCESS:
      return {...state, channelList: payload };
    case CHANNELS_GET_USER_CHANNEL_LIST_FAILED:
      return {...state };
    case CHANNELS_GET_MESSAGES_LIST_SUCCESS:
      return {...state, messagesList: payload };
    case CHANNELS_GET_MESSAGES_LIST_FAILED:
      return {...state };
    case LOGOUT:
      return {
        ...state,
        channelList: [],
        messagesList: []
      };
    default:
      return state;
  }
}
