import React from 'react'
import {FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText} from '@material-ui/core';

export default function SelectTargetRecruitmentSpecialities(props) {
  const {name, label, value, error = null, onChange, options, disabled, ...rest} = props;
  return (
    <FormControl variant="outlined"
                 disabled={disabled}
                 fullWidth={rest.fullWidth ? rest.fullWidth : false}
                 {...(error && {error: true})}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        autoWidth={true}
        label={label}
        name={name}
        value={value}
        onChange={onChange}>
        {
          Array.isArray(options) && options.length > 0 ?
              options.map((item) => (
                  <MenuItem key={item.id}
                            value={item}
                            style={{whiteSpace: "normal"}}>
                    {item.speciality.data.name}
                  </MenuItem>
                ))
            :
            <MenuItem disabled={true} value={''}>
              Данные отсутствуют
            </MenuItem>
        }
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}