import {Avatar, Container, Hidden, Paper, Step, StepContent, StepLabel, Stepper, Typography} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, {useEffect, useState} from "react";
import Form from "./Form";
import Confirm from "./Confirm";
import {Alert} from "@material-ui/lab";
import LoadingSlider from "../../../components/LoadingSlider/LoadingSlider";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {
    resetPasswordConfirmNew,
    resetPasswordNew
} from "../../../store/actions/auth";
import {chatAction_createNotification} from "../../../store/Chat/ChatActions";



function useResetPassword() {
    let isMount = true;
    const dispatch = useDispatch();
    const history = useHistory();
    const [activeStep, setActiveStep] = useState("REGISTER_FORM");
    const [isSending, setSending] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [userToken, setUserToken] = useState(null);

    function onSending() {
        if (!isMount) return;
        if (!isSending) {
            setSending(true);
            setTimeout(() => {
                setSending(false)
            }, 2000);
        }
    }

    function onChangeActiveStep() {
        if (!isMount) return;
        if (activeStep === 'REGISTER_FORM') {
            setActiveStep('CONFIRM_FORM');
        } else if (activeStep === 'CONFIRM_FORM') {
            setActiveStep('CONFIRM_SUCCESSFUL');
        } else {
            setActiveStep('REGISTER_FORM');
        }
    }

    async function onSubmitNotification(data, isReload = false) {
        if (!isMount) return;
        setLoading(true);
        const useData = {
            ...(data?.type && {type: data.type}),
            ...(data?.phone && {phone: data.phone}),
            ...(data?.email && {email: data.email}),
        };
        try {
            const response = await dispatch(resetPasswordNew(useData));
            if (response?.message === "SUCCESS") {
                setUserToken(useData);
                !isReload && onChangeActiveStep();
                dispatch(chatAction_createNotification({
                    message: `
                        ${useData?.phone ? `На указанный номер телефона отправлен проверочный код.` : ``}
                        ${useData?.email ? `На указанную электронную почту отправлен проверочный код.` : ``}
                    `,
                    variant: 'success',
                    autoHide: true,
                    position: {vertical: 'left', horizontal: 'bottom'}
                }));
            } else {
                dispatch(chatAction_createNotification({
                    message: 'Ошибка при обработке данных.',
                    variant: 'error',
                    autoHide: true,
                    position: {vertical: 'left', horizontal: 'bottom'}
                }));
            }
            setLoading(false);
            onSending();
        } catch (e) {
            dispatch(chatAction_createNotification({
                message: 'Ошибка. Проверьте правильность вводимых данных.',
                variant: 'error',
                autoHide: true,
                position: {vertical: 'left', horizontal: 'bottom'}
            }));
            console.error(`Critical error: ${JSON.stringify(e.response)}`);
            setLoading(false);
            onSending();
        }
    }

    async function onReloadRegister() {
        if (!isMount) return;
        if (userToken === null) return;
        await onSubmitNotification(userToken, true);
    }

    async function onSubmitConfirm(data) {
        if (!isMount) return;
        setLoading(true);
        const useData = {
            ...(userToken?.type && {type: userToken.type}),
            ...(userToken?.phone && {phone: userToken.phone}),
            ...(userToken?.email && {email: userToken.email}),
            ...(data?.tokenConfirm && {token: data.tokenConfirm}),
            ...(data?.password && {password: data.password}),
        };
        try {
            const response = await dispatch(resetPasswordConfirmNew(useData));
            if (response?.message === "SUCCESS") {
                dispatch(chatAction_createNotification({
                    message: `Пароль успешно восстановлен.`,
                    variant: 'success',
                    autoHide: true,
                    position: {vertical: 'left', horizontal: 'bottom'}
                }));
                await onChangeActiveStep();
                await onLogin();
            } else {
                dispatch(chatAction_createNotification({
                    message: `Произошла ошибка при обработке данных.`,
                    variant: 'error',
                    autoHide: true,
                    position: {vertical: 'left', horizontal: 'bottom'}
                }));
                console.error(`Critical error: ${JSON.stringify(response)}`);
            }
            if (isMount) setLoading(false);
            if (isMount) onSending();
        } catch (e) {
            dispatch(chatAction_createNotification({
                message: `Ошибка. Указан неверный проверочный код.`,
                variant: 'error',
                autoHide: true,
                position: {vertical: 'left', horizontal: 'bottom'}
            }));
            console.error(`Critical error: ${JSON.stringify(e.response)}`);
            if (isMount) setLoading(false);
            if (isMount) onSending();
        }
    }

    async function onLogin() {
        if (!isMount) return;
        history.push("/profile");
    }

    useEffect(() => {
        return () => {
            isMount = false;
        }
    }, [])

    return {
        status: {sending: isSending, loading: isLoading},
        activeStep,
        userToken,
        onChangeActiveStep,
        onSubmitNotification,
        onSubmitConfirm,
        onReloadRegister
    };

}

export function ResetPassword() {
    const classes = useStyles();
    const {
        status,
        activeStep,
        userToken,
        onSubmitNotification,
        onSubmitConfirm,
        onReloadRegister
    } = useResetPassword();

    return (<Container component="div" maxWidth="sm" className={classes.root}>
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Сбросить пароль
                </Typography>
                <Hidden xsDown>
                    <Stepper className={classes.stepperRoot}>
                        <Step active={activeStep === 'REGISTER_FORM'} completed={activeStep !== 'REGISTER_FORM'}>
                            <StepLabel>Сброс пароля</StepLabel>
                        </Step>
                        <Step active={activeStep === 'CONFIRM_FORM'}
                              completed={activeStep !== 'CONFIRM_FORM' && activeStep !== 'REGISTER_FORM'}>>
                            <StepLabel>Подтверждение</StepLabel>
                        </Step>
                    </Stepper>
                </Hidden>
                <Hidden smUp>
                    <Stepper orientation="vertical" className={classes.stepperRoot}>
                        <Step active={activeStep === 'REGISTER_FORM'} completed={activeStep !== 'REGISTER_FORM'}>
                            <StepLabel>Сброс пароля</StepLabel>
                            <StepContent/>
                        </Step>
                        <Step active={activeStep === 'CONFIRM_FORM'}
                              completed={activeStep !== 'CONFIRM_FORM' && activeStep !== 'REGISTER_FORM'}>
                            <StepLabel>Подтверждение</StepLabel>
                            <StepContent/>
                        </Step>
                    </Stepper>
                </Hidden>
                {activeStep === "REGISTER_FORM" ?
                    <Form onSubmit={onSubmitNotification} sending={status?.sending || status.loading}/> : null}
                {activeStep === "CONFIRM_FORM" ?
                    <Confirm data={userToken} onReloadMessage={onReloadRegister} onSubmit={onSubmitConfirm}
                             sending={status?.sending || status.loading}/> : null}
                {activeStep === "CONFIRM_SUCCESSFUL" ?
                    <Alert severity="success">Восстановление завершено</Alert> : null}
                <LoadingSlider open={status?.loading}/>
            </Paper>
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    stepperRoot: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    formControl: {},
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
