import {getUserRoles} from "../actions/auth";
import {
  createApplicationsStatementWithoutFaculty,
  getApplicationsStatement,
  getDataStatement,
  getFacultyStatement,
  getSpecialitiesBudgetStatement,
  getSpecialitiesPaidStatement,
  getStudyFormStatement,
  getStudyPayStatement,
  setDataStatement, setContractStatement,
  setStatementStatus,
  setStatusFormsApplicationStatement, setTypeCompetitionStatement, getTypeTrainingStatement
} from "../Statement/actionStatement";
import {
  getCitizenshipReferenceBooks,
  getFacultiesReferenceBooks,
  getLanguageReferenceBooks, getOlympiadsReferenceBooks, getPPYReferenceBooks,
  getPrivilegesReferenceBooks
} from "../ReferenceBooks/actionReferenceBooks";
import {
  ANKETA_PDF_CREATE, ANKETA_STATUS, ANKETA_STATUS_FORMS_APPLICATION,
  ANKETA_USER_TYPE_COMPETITION,
  TRAINING_FACULTY,
  TRAINING_SPECIALITIES_GROUP, TRAINING_SPECIALITY_BUDGET, TRAINING_SPECIALITY_PAID,
  TRAINING_STUDY_FORM,
  TRAINING_STUDY_PAY, TRAINING_TARGET_RECRUITMENT_SPEC, TRAINING_TYPE, USER_TERMS
} from "../../services/tagTypes";
import {PROFILE_USER_DATA_GET} from "../actions/profile";
import instance from "../../customAxios";
import {getCurrentQueue} from "../Queue/actionQueue";
import axios from "axios";

export function authorizationInit() {
  return async (dispatch) => {
    try {
      await dispatch(getApplicationsStatement());
      await dispatch(getUserRoles());
      await dispatch(PROFILE_USER_DATA_GET());
      await dispatch(authInitData());
      dispatch(referenceBooksInit());
      return Promise.resolve({status: true});
    } catch (error) {
      console.error('☠️error authorizationInit actions System: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function authInitData() {
  return async (dispatch) => {
    try {
      const arrayTrainingParams = [
        `type[]=${TRAINING_TYPE}`,
        `type[]=${TRAINING_STUDY_FORM}`,
        `type[]=${TRAINING_STUDY_PAY}`,
        `type[]=${TRAINING_FACULTY}`,
        `type[]=${TRAINING_SPECIALITIES_GROUP}`,
        `type[]=${TRAINING_SPECIALITY_BUDGET}`,
        `type[]=${TRAINING_SPECIALITY_PAID}`,
        `type[]=${TRAINING_TARGET_RECRUITMENT_SPEC}`,
        `type[]=${ANKETA_STATUS_FORMS_APPLICATION}`,
        `type[]=${ANKETA_STATUS}`,
        `type[]=${ANKETA_USER_TYPE_COMPETITION}`,
        `type[]=${ANKETA_PDF_CREATE}`,
      ];
      const trainingParams = arrayTrainingParams.join('&');
      const result = await dispatch(getDataStatement(trainingParams))

      let typeTraining = result.find(data => data.typeTitle === TRAINING_TYPE);
      typeTraining = (typeTraining !== undefined ? typeTraining.value.value : '');

      let studyForm = result.find(data => data.typeTitle === TRAINING_STUDY_FORM);
      studyForm = (studyForm !== undefined ? studyForm.value : '');
      let studyPay = result.find(data => data.typeTitle === TRAINING_STUDY_PAY);
      studyPay = (studyPay !== undefined ? studyPay.value.value : '');
      let faculty = result.find(data => data.typeTitle === TRAINING_FACULTY);
      faculty = (faculty !== undefined ? faculty.value : {});

      let itemsSpecialitiesBudget = result.find(data => data.typeTitle === TRAINING_SPECIALITY_BUDGET);
      itemsSpecialitiesBudget = (itemsSpecialitiesBudget !== undefined ? itemsSpecialitiesBudget.value : []);

      let itemsSpecialitiesPaid = result.find(data => data.typeTitle === TRAINING_SPECIALITY_PAID);
      itemsSpecialitiesPaid = (itemsSpecialitiesPaid !== undefined ? itemsSpecialitiesPaid.value : []);

      let typeCompetition = result.find(data => data.typeTitle === ANKETA_USER_TYPE_COMPETITION);
      typeCompetition = (typeCompetition !== undefined ? typeCompetition : {});
      let document = result.find(data => data.typeTitle === ANKETA_PDF_CREATE);
      document = (document !== undefined ? document : {});
      const anketaStatusForm = result.find(data => data.typeTitle === ANKETA_STATUS_FORMS_APPLICATION);
      if (anketaStatusForm !== undefined) await dispatch(setStatusFormsApplicationStatement(anketaStatusForm.value));
      const findAnketaStatus = result.find(data => data.typeTitle === ANKETA_STATUS);
      if (findAnketaStatus !== undefined) await dispatch(setStatementStatus(findAnketaStatus.value.value));

      await dispatch(getTypeTrainingStatement(typeTraining));
      await dispatch(getStudyFormStatement(studyForm));
      await dispatch(getStudyPayStatement(studyPay));
      await dispatch(getFacultyStatement(faculty));
      await dispatch(getSpecialitiesBudgetStatement(itemsSpecialitiesBudget));
      await dispatch(getSpecialitiesPaidStatement(itemsSpecialitiesPaid));
      await dispatch(setTypeCompetitionStatement(typeCompetition));
      await dispatch(setContractStatement(document));
      await dispatch(getCurrentQueue());
    } catch (error) {
      console.error('☠️error authInitData actions System: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function registrationInit() {
  return async (dispatch) => {
    try {
      await dispatch(createApplicationsStatementWithoutFaculty());
      await dispatch(getUserRoles());
      await dispatch(PROFILE_USER_DATA_GET());
      await dispatch(setDataStatement({ANKETA_STATUS: {"value": "NOT_CONFIRMED"}}));
      dispatch(referenceBooksInit());

    } catch (error) {
      console.error('☠️error registrationInit actions System: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function referenceBooksInit() {
  return async (dispatch) => {
    try {
      dispatch(getFacultiesReferenceBooks());
      dispatch(getCitizenshipReferenceBooks());
      // dispatch(getPrivilegesReferenceBooks());
      dispatch(getLanguageReferenceBooks());
      // dispatch(getPPYReferenceBooks());
       dispatch(getOlympiadsReferenceBooks());

    } catch (error) {
      console.error('☠️error referenceBooksInit actions System: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function deleteUserData(dataId) {
  return async () => {
    try {
        const response = await instance.delete(
          `/api/ext/application/data/${dataId}`
        );
        return Promise.resolve(response);
    } catch (error) {
      console.error('☠️error deleteUserData actions System: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}


export function deleteAccount() {
  return async () => {
    try {
      const response = await instance.get(
        `/api/ext/users/delete_request`
      );
      return Promise.resolve(response);
    } catch (error) {
      console.error('☠️error deleteAccount actions System: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function deleteAccountConfirm(data) {
  return async () => {
    try {
      const response = await instance.post(
        `/api/ext/users/delete_confirm`,
        data
      );
      return Promise.resolve(response);
    } catch (error) {
      console.error('☠️error deleteAccount actions System: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

export function clearCache() {
  return async () => {
    try {
      const refreshCacheAndReload = () => {
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then((names) => {
            for (const name of names) {
              caches.delete(name);
            }
          });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
      };
      axios.get("/meta.json", {headers:{
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }})
        .then((response) => response.data)
        .then((meta) => {
          const latestVersionDate = meta.buildDate.toString();
          const currentVersionDate = localStorage?.buildDate;

          const shouldForceRefresh = latestVersionDate !== currentVersionDate
          // console.log(`shouldForceRefresh: ${shouldForceRefresh}, latestVersionDate: ${typeof latestVersionDate}, currentVersionDate: ${ typeof currentVersionDate},`);
          if (shouldForceRefresh) {
            localStorage.setItem('buildDate', meta.buildDate);
            refreshCacheAndReload();
          }
        });
    } catch (error) {
      console.error('☠️error clearCache actions System: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}