import React from "react";
import { Container, Tabs, Tab, Box } from "@material-ui/core";
import Specialties from "./SpecialtiesV2";
import FacultiesSubscribes from "./FacultiesSubscribes";
import ProfileForm from "./Form";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabNumber: 0,
    };
  }

  tabChanger(event, number) {
    this.setState({ tabNumber: number });
  }

  render() {
    const { tabNumber } = this.state;
    const TabContainer = ({ item }) => {
      switch (item) {
        case 0:
          return <ProfileForm />;
        case 1:
          return <FacultiesSubscribes />;
        case 2:
          return <Specialties />;
        default:
          return null;
      }
    };
    return (
      <Container maxWidth="md">
        <Tabs
          value={tabNumber}
          onChange={this.tabChanger.bind(this)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label="Профиль" />
          <Tab label="Факультеты" />
          <Tab label="Специальности" />
        </Tabs>
        <Box mt={2}>
          <TabContainer item={tabNumber} />
        </Box>
      </Container>
    );
  }
}

export default Profile;
