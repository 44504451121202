import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import Valid from "../../../constants/Valid";
import {
  CircularProgress, Grid,
} from "@material-ui/core";
import instance from "../../../customAxios";
import useDebounce from "../../../hooks/useDebounce";

const filter = createFilterOptions();

const initialValues = {
  region: '',
  district: '',
  localityType: '',
  localityName: '',
  locality: '',
  type_city_location: '',
  title_type_city_location: '',
  name_city_location: '',
  num_house: '',
  num_housing: '',
  num_apartment: '',
};

export default function InputForObj({...props}) {

  const [open, toggleOpen] = useState(false);
  const [valueToTextField, setValueToTextField] = useState("");
  const [valueToAutocompleteLocation, setValueToAutocompleteLocation] = useState("");
  const [inputValueToAutocompleteLocation, setInputValueToAutocompleteLocation] = useState("");
  const [openAutocompleteLocation, setOpenAutocompleteLocation] = React.useState(false);
  const [optionsLocation, setOptionsLocation] = React.useState([]);
  const loading = openAutocompleteLocation && optionsLocation.length === 0;
  const [arrayType_city_location, setArrayType_city_location] = React.useState([]);
  const [valueToAutocompleteTypeCityLocation, setValueToAutocompleteTypeCityLocation] = useState("");
  const [dialogValue, setDialogValue] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    setDialogValue({
      ...initialValues
    });
    toggleOpen(false);
  };

  const handleSubmitDialog = (event) => {
    if (validate()) {
      event.preventDefault();
      onChangeMyInput(dialogValue);
      handleClose();
    }
  };

  const debouncedLocation = useDebounce(inputValueToAutocompleteLocation, 700);

  async function fetchOptionsLocation(name_locality) {
    try {
      const name_locality_trim = name_locality && name_locality.trim();
      const response = await instance.get(
        `/api/pub/directories/type=locality/hash=${name_locality_trim || '0'}`
      );
      setOptionsLocation(response.data);
    } catch (error) {
      console.error('☠️error directories/type=locality: ', error.response ? error.response : error);
    }
  }

  useEffect(
    () => {
      if (debouncedLocation) {
        fetchOptionsLocation(debouncedLocation);
      }
    },
    [debouncedLocation]
  );

  const handleInputChange = e => {
    const {name, value} = e.target;
    setDialogValue({
      ...dialogValue,
      [name]: value
    });
    validate({[name]: value})
  };

  const validate = (fieldValues = dialogValue) => {
    let temp = {...errors};

    if ('localityName' in fieldValues) {
      temp.localityName = "";
      if (temp?.localityName === "" && !Valid.isIsset(fieldValues?.localityName)) {
        temp.localityName = "Обязательно для заполнения.";
      }
    }
    setErrors({...temp});
    if (fieldValues === dialogValue) {
      return Object.values(temp).every(x => x === '')
    }
  };

  let {value, name, onChangeMyInput, ...otherProps} = props;

  const addressToString = (value) => {
    const locality = value?.locality?.value?.fullTitle || '';
    const type_city_location = value?.type_city_location?.value?.short || value?.type_city_location?.value?.title || '';
    const name_city_location = value?.name_city_location || '';
    const num_house = value?.num_house || '';
    const num_housing = value?.num_housing || '';
    const num_apartment = value?.num_apartment || '';

    const localityText = locality && locality;
    const type_city_locationText = ', ' + type_city_location && type_city_location;
    const name_city_locationText =  ' ' + name_city_location && name_city_location;
    const num_houseText = num_house && ', д. ' + num_house;
    const num_housingText = num_housing && ', кор. ' + num_housing;
    const num_apartmentText = num_apartment && ', кв. ' + num_apartment;

    return `${localityText}${type_city_locationText}${name_city_locationText}${num_houseText}${num_housingText}${num_apartmentText}`
  }

  useEffect(() => {
    setValueToTextField(addressToString(value));
    setDialogValue({
      ...dialogValue,
      ...value
    });
  }, [value]);


  useEffect(() => {
    const getCityLocation = async () => {
      try {
        const response = await instance.get(
          `/api/pub/search-directories/types=city_location`
        );
        setArrayType_city_location(response.data);
      } catch (error) {
        console.error('☠️error search-directories/types=city_location: ', error.response ? error.response : error);
      }
    };
    getCityLocation();
  }, []);


  useEffect(() => {
    if (!openAutocompleteLocation) {
      setOptionsLocation([]);
    }
  }, [openAutocompleteLocation]);

  return (
    <>
      <TextField
        InputLabelProps={{shrink: true}}
        value={valueToTextField || ''}
        {...otherProps}
        onClick={() => {
          toggleOpen(!open);
        }}
        onChange={() => {
          toggleOpen(!open);
        }}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen>
        <DialogTitle id="form-dialog-title">Ввести полный адрес</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DialogContentText>
                Выберите подходящий населенный пункт и тип улицы из предложенных вариантов, если подходящих вариантов
                нет - введите самостоятельно.<br/>
                Например: Гродненская область, г.Гродно, ул. Ожешко, д.22, кв.1
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="locality"
                name="locality"
                open={openAutocompleteLocation}
                onOpen={() => {
                  setOpenAutocompleteLocation(true);
                }}
                onClose={() => {
                  setOpenAutocompleteLocation(false);
                }}
                options={optionsLocation}
                getOptionLabel={(option) => {
                  // e.g value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.value.fullTitle;
                }}
                noOptionsText={'В справочнике нет такого населенного пункта, введите его вручную'}
                // loading={loading}
                // loadingText={'Загрузка...'}
                selectOnFocus
                // clearOnBlur
                handleHomeEndKeys
                renderOption={(option) => option.value.fullTitle}
                freeSolo
                value={valueToAutocompleteLocation}
                onChange={(event, newValue) => {
                  setValueToAutocompleteLocation(newValue);
                  setDialogValue({
                    ...dialogValue,
                    locality: newValue,
                    region: newValue?.value?.region,
                    district: newValue?.value?.district,
                    localityType: newValue?.value?.localityType,
                    localityName: newValue?.value?.locality,
                  });
                }}
                inputValue={inputValueToAutocompleteLocation}
                onInputChange={(event, newInputValue) => {
                  setInputValueToAutocompleteLocation(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Населенный пункт"
                    fullWidth
                    variant={'outlined'}
                    margin="dense"
                    error={Boolean(errors.localityName)}
                    helperText={errors.localityName}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20}/> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                variant="outlined"
                margin="dense"
                value={valueToAutocompleteTypeCityLocation}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setValueToAutocompleteTypeCityLocation({
                      value: {
                        title: newValue,
                      }
                    });
                    setDialogValue({
                      ...dialogValue,
                      type_city_location: {
                        value: {
                          title: newValue,
                        }
                      }
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValueToAutocompleteTypeCityLocation({
                        value: {
                          title: newValue.inputValue,
                        }
                      }
                    );
                    setDialogValue({
                      ...dialogValue,
                      type_city_location: {
                        value: {
                          title: newValue.inputValue,
                        }
                      }
                    });
                  } else {
                    setValueToAutocompleteTypeCityLocation(newValue);
                    setDialogValue({
                      ...dialogValue,
                      type_city_location: newValue
                    });
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== '') {
                    console.log(params)
                    filtered.push({
                      type: "city_location",
                      value: {
                        inputValue: params.inputValue,
                        title: `Добавить "${params.inputValue}"`,
                      }
                    })
                    ;
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="type_city_location"
                options={arrayType_city_location}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    console.log('Value selected with enter, right from the input: ', option);
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option?.value?.inputValue) {
                    console.log('Add "xxx" option created dynamically: ', option);
                    return option?.value?.inputValue;
                  }
                  // Regular option
                  return option?.value?.title;
                }}
                renderOption={(option) => option?.value?.title}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params}
                             label="Тип улицы"
                             fullWidth
                             variant={'outlined'}
                             margin="dense"
                             error={Boolean(errors.type_city_location)}
                             helperText={errors.type_city_location}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant={'outlined'}
                margin="dense"
                id="name_city_location"
                name="name_city_location"
                value={dialogValue.name_city_location || ''}
                onChange={handleInputChange}
                label="Название улицы"
              />

            </Grid>
            <Grid item container xs={12} justifyContent={"space-between"}>
              <Grid item>
                <TextField
                  variant={'outlined'}
                  margin="dense"
                  id="num_house"
                  name="num_house"
                  value={dialogValue.num_house || ''}
                  onChange={handleInputChange}
                  label="Номер дома"
                />
              </Grid>
              <Grid item>
                <TextField
                  variant={'outlined'}
                  margin="dense"
                  id="num_housing"
                  name="num_housing"
                  value={dialogValue.num_housing || ''}
                  onChange={handleInputChange}
                  label="Номер корпуса"
                />
              </Grid>
              <Grid item>
                <TextField
                  variant={'outlined'}
                  margin="dense"
                  id="num_apartment"
                  name="num_apartment"
                  value={dialogValue.num_apartment || ''}
                  onChange={handleInputChange}
                  label="Номер квартиры"
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item container xs={12} justifyContent={"space-between"} spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleClose}
              >
                Закрыть
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                type="button"
                color="primary"
                onClick={handleSubmitDialog}
              >
                Подтвердить
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}