import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import {connect} from "react-redux";
import {
  DELETE_CAMPAIGN_APPLICATION_DATA,
  GET_CAMPAIGN_APPLICATION_DATA,
  GET_FACULTIES_CLIENT, GET_FACULTIES_SPECIALTIES_CLIENT,
  GET_FACULTY_SPECIALTIES_ALL,
  SET_CAMPAIGN_APPLICATION_DATA,
  // setSelectedSpecialitiesFacultiesSuccess
} from '../../../store/actions/compaign';
import LoadingSlider from '../../../components/LoadingSlider/LoadingSlider';
import {arrayByFormsOfLearning} from "../../../services/SpecialtiesPotencial/arrayByFormsOfLearning";

const MySelectMulti = ({value, arrayOptions, label, labelId, ...props}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
        label={label}
        labelId={labelId}
        value={value}
        fullWidth
        multiple
        renderValue={(selected) => (
          <div>
            {selected.map((value, index) => (
              <Chip key={value.id + ' ' + index} label={value.speciality.data.name + (value.data.version === "ABBREVIATED" ? " (Сокращенный срок)" : '')}/>
            ))}
          </div>
        )}
      >
        {
          arrayOptions && arrayOptions.length > 0
            ?
            arrayOptions.map((speciality) => (
              <MenuItem key={speciality.id} value={speciality}>
                <Checkbox checked={value.indexOf(speciality) > -1}/>
                {/*<Checkbox checked={this.myChecked(value, speciality)}/>*/}
                <ListItemText primary={speciality.speciality.data.name + (speciality.data.version === "ABBREVIATED" ? " (Сокращенный срок)" : '')}
                              style={{whiteSpace: "normal"}}/>
              </MenuItem>
            ))
            :
            <MenuItem disabled value={''}>
              <ListItemText
                primary={'Выбраны все специальности'}
                style={{whiteSpace: "normal"}}
              />
            </MenuItem>
        }
      </Select>
    </FormControl>
  );
};

class Specialties extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      // facultiesUser: [],
      facultySelect: "",
      specialitiesAll: [],
      specialitiesDaytime: [],
      specialitiesExtramural: [],
      specialitiesEvening: [],
      specialitiesExtramuralRemote: [],
      specialitiesDaytimeSelect: [],
      specialitiesExtramuralSelect: [],
      specialitiesEveningSelect: [],
      specialitiesExtramuralRemoteSelect: [],
      specialitiesSelect: [], // [specialitiesDaytimeSelect + ... + specialitiesExtramuralRemoteSelect]
      // selectedSpecialitiesFaculties: [], // [{facultySelect + specialitiesSelect}, ... ,{facultySelect + specialitiesSelect}]
      specialitiesSelectArray: [], // selectedSpecialitiesFaculties[facultySelect.id].specialitiesSelect

      loading: true
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.props.getFacultySpecialtiesClient();
      this.setState({
        loading: false
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadingSpecialitiesAll() {
    // console.log('loadingSpecialitiesAll start',)
    if (this.state.facultySelect !== '' && this.state.facultySelect !== undefined && this.state.facultySelect !== null) {
      // console.log('loadingSpecialitiesAll if true',)

      let itemSpecialitiesSelect = await this.props.getFacultySpecialtiesAll(this.state.facultySelect.faculty.id)
      try {
        let specialitiesSelectArray = [];
        if (this.props.selectedSpecialitiesFaculties && this.props.selectedSpecialitiesFaculties.length !== 0) {
          const filterArray = this.props.selectedSpecialitiesFaculties.filter((faculty) => {
            // console.log('faculty ', faculty)
            // console.log('facultySelect ', this.state.facultySelect)
            return faculty.faculty.id === this.state.facultySelect.faculty.id
          })
          // console.log("filterArray selectedSpecialitiesFaculties ", filterArray)
          if (filterArray && filterArray.length > 0) {
            specialitiesSelectArray = filterArray[0].specialitiesSelect.map((spec) => {
              return spec.value
            });
          }
        }
        // console.log("specialitiesSelectArray ", specialitiesSelectArray)
        this.setState({
          specialitiesAll: itemSpecialitiesSelect,
          loading: false,
          specialitiesSelectArray: specialitiesSelectArray,
          specialitiesDaytime: arrayByFormsOfLearning(itemSpecialitiesSelect, 'DAYTIME'),
          specialitiesExtramural: arrayByFormsOfLearning(itemSpecialitiesSelect, 'EXTRAMURAL'),
          specialitiesEvening: arrayByFormsOfLearning(itemSpecialitiesSelect, 'EVENING'),
          specialitiesExtramuralRemote: arrayByFormsOfLearning(itemSpecialitiesSelect, 'DISTANT'),
        });
      } catch (e) {
        console.error('getFacultySpecialtiesAll catch', e)
        this.setState({specialitiesAll: [], loading: false});
      }
    } else {
      this.setState({
        specialitiesAll: [],
        specialitiesDaytime: [],
        specialitiesExtramural: [],
        specialitiesEvening: [],
        specialitiesExtramuralRemote: [],
        loading: false
      });
    }

  }

  buttonClickHandler = async () => {
    const specialitiesSelect = [
      ...this.state.specialitiesDaytimeSelect,
      ...this.state.specialitiesExtramuralSelect,
      ...this.state.specialitiesEveningSelect,
      ...this.state.specialitiesExtramuralRemoteSelect,
    ];
    const promises = specialitiesSelect.map(async elem => {
      try {
        const setResult = await this.props.setCampaignApplicationData(
          this.state.facultySelect.id,
          {
            USER_PREFERENCE: elem
          }
        )
        // console.log('setResult ',setResult);
        if (setResult && setResult.length > 0) {
          this.props.getFacultySpecialtiesClient();
        }
      } catch (e) {
        console.error('setSelectedSpecialitiesFaculties error', e)
      }

    });
    await Promise.all(promises);

    this.setState({
      specialitiesSelect: specialitiesSelect,
      // selectedSpecialitiesFaculties: my,
      facultySelect: '',
      specialitiesAll: [],
      specialitiesDaytime: [],
      specialitiesExtramural: [],
      specialitiesEvening: [],
      specialitiesExtramuralRemote: [],
      specialitiesDaytimeSelect: [],
      specialitiesExtramuralSelect: [],
      specialitiesEveningSelect: [],
      specialitiesExtramuralRemoteSelect: [],
      loading: false
    })
  }
  handleChangeFaculty = (event) => {
    this.setState({
      facultySelect: event.target.value,
      specialitiesDaytimeSelect: [],
      specialitiesExtramuralSelect: [],
      specialitiesEveningSelect: [],
      specialitiesExtramuralRemoteSelect: []
    }, this.loadingSpecialitiesAll)
  }
  handleChangeMultiSelect = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  excludingItems = (arrayFrom, excludeArray) => {
    // console.log('excludingItems arrayFrom ', arrayFrom);
    // console.log('excludingItems excludeArray ', excludeArray);

    let newArray = [...arrayFrom];

    if (excludeArray && excludeArray.length > 0) {
      newArray = [];
      arrayFrom.map((elem) => {
        if (excludeArray.find(element => element.id === elem.id) === undefined) {
          newArray.push(elem)
        }
        return false
      })
    }

    // console.log('excludingItems newArray ', newArray);
    return newArray;
  }
  replaceEducationForm = (text) => {
    switch (text) {
      case 'DAYTIME':
        return 'Дневная форма'
      case 'EXTRAMURAL':
        return 'Заочная форма'
      case 'EVENING':
        return 'Вечерняя форма'
      case 'DISTANT':
        return 'Заочная (дистанционная) форма'
      default:
        return '';
    }
  }

  render() {
    let {
      facultySelect,
      specialitiesDaytime,
      specialitiesExtramural,
      specialitiesEvening,
      specialitiesExtramuralRemote,
      specialitiesDaytimeSelect,
      specialitiesExtramuralSelect,
      specialitiesEveningSelect,
      specialitiesExtramuralRemoteSelect,
      loading
    } = this.state;
    let {
      facultiesUser,
      selectedSpecialitiesFaculties,
    } = this.props;

    let newSpecialitiesDaytime = this.excludingItems(specialitiesDaytime, this.state.specialitiesSelectArray);
    let newSpecialitiesExtramural = this.excludingItems(specialitiesExtramural, this.state.specialitiesSelectArray);
    let newSpecialitiesEvening = this.excludingItems(specialitiesEvening, this.state.specialitiesSelectArray);
    let newSpecialitiesExtramuralRemote = this.excludingItems(specialitiesExtramuralRemote, this.state.specialitiesSelectArray);
    // console.log('newSpecialitiesDaytime ', newSpecialitiesDaytime);
    if (loading) return <>
      <LoadingSlider open={loading}/>
    </>;
    else
      return (
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <CardHeader title="Факультеты"/>
              <Divider/>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Typography variant="body1" component="p">
                      Выберите факультет, а затем специальности по формам обучения, на которые Вы
                      заинтересованы в
                      поступлении
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="profile-faculty-form-label">Факультет</InputLabel>
                      <Select
                        fullWidth
                        inputProps={{
                          id: 'profile-faculty-form-label',
                          name: 'facultySelect'
                        }}
                        name="facultySelect"
                        label="Факультет"
                        id="profile-faculty-form"
                        value={facultySelect}
                        onChange={this.handleChangeFaculty}
                      >
                        <MenuItem aria-label="None" value="">Не выбирать</MenuItem>
                        {
                          facultiesUser && facultiesUser.length > 0
                            ? facultiesUser.map((item) => {
                              return <MenuItem key={item.id}
                                               value={item}>{item.faculty?.data?.nameFull}</MenuItem>
                            })
                            : null
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  {
                    specialitiesDaytime && Array.isArray(specialitiesDaytime) && specialitiesDaytime.length !== 0 ?
                      <Grid item xs={12} md={12}>
                        <MySelectMulti
                          value={specialitiesDaytimeSelect}
                          arrayOptions={newSpecialitiesDaytime}
                          label={"Специальности дневной формы обучения"}
                          labelId="profile-specialities-daytime-checkbox-label"
                          id="profile-specialities-daytime-checkbox"
                          name='specialitiesDaytimeSelect'
                          onChange={this.handleChangeMultiSelect}
                        />
                      </Grid>
                      : null
                  }
                  {
                    specialitiesExtramural && Array.isArray(specialitiesExtramural) && specialitiesExtramural.length !== 0 ?
                      <Grid item xs={12} md={12}>
                        <MySelectMulti
                          value={specialitiesExtramuralSelect}
                          arrayOptions={newSpecialitiesExtramural}
                          label={"Специальности заочной формы обучения"}
                          labelId="profile-specialities-extramural-checkbox-label"
                          id="profile-specialities-extramural-checkbox"
                          name='specialitiesExtramuralSelect'
                          onChange={this.handleChangeMultiSelect}
                        />
                      </Grid>
                      : null
                  }
                  {
                    specialitiesEvening && Array.isArray(specialitiesEvening) && specialitiesEvening.length !== 0 ?
                      <Grid item xs={12} md={12}>
                        <MySelectMulti
                          value={specialitiesEveningSelect}
                          arrayOptions={newSpecialitiesEvening}
                          label={"Специальности вечерней формы обучения"}
                          labelId="profile-specialities-evening-checkbox-label"
                          id="profile-specialities-evening-checkbox"
                          name='specialitiesEveningSelect'
                          onChange={this.handleChangeMultiSelect}
                        />
                      </Grid>
                      : null
                  }
                  {
                    specialitiesExtramuralRemote && Array.isArray(specialitiesExtramuralRemote) && specialitiesExtramuralRemote.length !== 0 ?
                      <Grid item xs={12} md={12}>
                        <MySelectMulti
                          value={specialitiesExtramuralRemoteSelect}
                          arrayOptions={newSpecialitiesExtramuralRemote}
                          label={"Специальности заочной (дистанционной) формы обучения"}
                          labelId="profile-specialities-extramural-remote-checkbox-label"
                          id="profile-specialities-extramural-remote-checkbox"
                          name='specialitiesExtramuralRemoteSelect'
                          onChange={this.handleChangeMultiSelect}
                        />
                      </Grid>
                      : null
                  }
                  <Grid item xs={12} md={12}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={this.buttonClickHandler}
                      disabled={
                        facultySelect === '' ||
                        (specialitiesDaytimeSelect.length === 0 &&
                          specialitiesExtramuralSelect.length === 0 &&
                          specialitiesEveningSelect.length === 0 &&
                          specialitiesExtramuralRemoteSelect.length === 0)
                      }
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <CardHeader
                title={"Выбранные специальности"}
              />
              <Divider/>
              <Grid container spacing={3}>

                <Grid item xs={12} md={12}>
                  {/*{console.log('selectedSpecialitiesFaculties ', selectedSpecialitiesFaculties)}*/}
                  {
                    selectedSpecialitiesFaculties && selectedSpecialitiesFaculties.length !== 0 && facultiesUser.length !== 0
                      ?
                      selectedSpecialitiesFaculties.map((faculty) => {
                        if (faculty.specialitiesSelect && faculty.specialitiesSelect.length !== 0) {
                          // console.log('selectedSpecialitiesFaculties faculty ', faculty);
                          return (
                            <CardContent key={`faculty_${faculty?.id}`}>
                              <Typography variant="h5" component="h4">
                                {faculty?.faculty?.data?.nameFull}
                              </Typography>
                              {
                                faculty.specialitiesSelect.map((speciality) => {
                                  return (
                                    <Typography key={`speciality_${speciality?.id}`}
                                                variant="body1" component="p">
                                      {speciality?.value?.speciality?.data?.name + (speciality?.value?.data?.version === "ABBREVIATED" ? " (Сокращенный срок)" : '') } - {this.replaceEducationForm(speciality?.value?.data?.educationForm)}
                                      <IconButton
                                        id={speciality?.id}
                                        aria-label="delete"
                                        style={{
                                          color: "#f44336",
                                          marginLeft: '4px'
                                        }}
                                        onClick={async () => {
                                          if (speciality.id && speciality.id !== '' && speciality.id !== undefined) {
                                            if (window.confirm('Вы уверены, что хотите удалить эту запись?')) {
                                              await this.props.delCampaignApplicationData(speciality.id);
                                              await this.props.getFacultySpecialtiesClient();
                                            }
                                          }
                                        }}
                                      >
                                        <DeleteIcon/>
                                      </IconButton>
                                    </Typography>
                                  )
                                })
                              }
                            </CardContent>
                          )
                        } else {

                          // console.log('selectedSpecialitiesFaculties faculty - specialitiesSelect empty ', faculty);
                          return null
                        }
                      })
                      :
                      <Typography variant="body1" style={{margin: '10px'}}>
                        Выберите специальности, на которые хотели бы поступить
                      </Typography>
                  }
                </Grid>
              </Grid>
            </Card>
          </Grid>

        </Grid>
      )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getFaculties: () => dispatch(GET_FACULTIES_CLIENT()),
    getFacultySpecialtiesAll: (facultySelectId) => dispatch(GET_FACULTY_SPECIALTIES_ALL(facultySelectId)),
    getCampaignApplicationData: (appId, param) => dispatch(GET_CAMPAIGN_APPLICATION_DATA(appId, param)),
    setCampaignApplicationData: (appId, data) => dispatch(SET_CAMPAIGN_APPLICATION_DATA(appId, data)),
    getFacultySpecialtiesClient: () => dispatch(GET_FACULTIES_SPECIALTIES_CLIENT()),
    delCampaignApplicationData: (appId) => dispatch(DELETE_CAMPAIGN_APPLICATION_DATA(appId)),
  }
}

function mapStateToProps(state) {
  return {
    facultiesUser: state.compaignReducer.faculties,
    selectedSpecialitiesFaculties: state.compaignReducer.selectedSpecialitiesFaculties,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Specialties);
