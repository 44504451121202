import classes from "../ChatMessages.module.css";
import React, {useEffect, useRef} from "react";
import {format} from "date-fns";
import PropTypes from "prop-types";
import {Button} from "@material-ui/core";

export default function Messages(props) {
  const {messages} = props;
  const refMessages = useRef(null);

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      ScrollToBottom();
    }
    return () => {
      isMount = false;
    }
  }, [refMessages, messages]);
  messages.sort((a, b) => {
    return new Date(a.timestamp) - new Date(b.timestamp);
  });

  function ScrollToBottom() {
    if (refMessages?.current)
      refMessages.current.scrollBy(0, refMessages.current.scrollHeight);
  }

  return (
    <div className={classes['message__box__message']}>
      {messages?.length ? (
        <div className={classes['message__box__messages']} {...{ref: refMessages}}>
          {messages?.map(
            (message, index) => {
              if (message?.type !== 'SYSTEM_LAST_READ_MESSAGE') {
                let classesItem = classes['message__box__messages__item'];
                if (message?.employeeId === null) {
                  classesItem += ` ${classes['message__box__messages__item__system']}`
                } else {
                  classesItem += ` ${classes['message__box__messages__item__client']}`
                }
                return (
                  <div
                    key={`${message?.id} - ${index}`}
                    className={classesItem}>
                    <div
                      className={classes['message__box__messages__item__content']}>
                      {message?.data?.only_text || message?.data?.text ? (
                        <div
                          className={classes['message__box__messages__item__text']}>
                          {message?.data?.only_text || message?.data?.text}
                        </div>) : null}
                      {message?.data?.img ? (
                        <div
                          className={classes['message__box__messages__item__image']}>
                          <a href={message?.data?.img}>
                            <img src={message?.data?.img} alt={`image`}/>
                          </a>
                        </div>) : null}
                      {message?.data?.href ? (
                        <div className={classes['message__box__messages__item__href']}>
                          <div>Прикрепленные ссылки:</div>
                          {message?.data?.href?.map(
                            (link, index) =>
                              <a href={link} key={index} target="_blank" rel="noopener noreferrer">{link}</a>
                          )
                          }
                        </div>) : null}
                      {message?.data?.file ? (
                        <div className={classes['message__box__messages__item__file']}>
                          <div>Прикрепленный файл:</div>
                          <Button variant="outlined" href={message?.data?.file} component="a" target="_blank"
                                  rel="noopener noreferrer">Скачать</Button>
                        </div>) : null}

                    </div>
                    <div className={classes['message__box__messages__item__time']}>
                      {format(new Date(message?.timestamp), 'dd/MM/yyyy в HH:mm')}
                    </div>
                  </div>

                )
              }


            }
          )}
        </div>
      ) : (
        <div className={classes['message__box__form__message_not_found_chat']} {...{ref: refMessages}}>
          Этот чат пуст.
        </div>
      )}

    </div>
  )
}
Messages.propTypes = {
  messages: PropTypes.array,
  current: PropTypes.any
};
