import React, {useEffect, useState} from 'react';
import VerticalLinearStepper from "../../../../components/ListFormsApplicationForAdmission";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Container, Grid, MenuItem, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import {getFileStatement} from "../../../../store/FileLoading/actionFileLoading";
import fileDownload from 'js-file-download'
import {
  ANKETA_PDF_CREATE, ANKETA_STATUS,
  ANKETA_USER_TYPE_COMPETITION, USER_GENDER, USER_TERMS
} from "../../../../services/tagTypes";
import {
  getDataStatement, setContractStatement, setTypeCompetitionStatement
} from "../../../../store/Statement/actionStatement";
import {useHistory} from "react-router";
import LoadingSlider from "../../../../components/LoadingSlider/LoadingSlider";
import {Link as RouterLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  information: {
    marginTop: theme.spacing(2.5),
  }
}));

const ApplicationForAdmission = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const contractFile = useSelector(state => state.statementReducer.contractFile);
  const typeCompetition = useSelector(state => state.statementReducer.typeCompetition);
  const statementStatus = useSelector(state => state.statementReducer.statementStatus);
  const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  const profileInfo = useSelector(state => state.profileReducer.profileInfo);

  const [isLoading, setLoading] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])

  const onReloadInformationAnketa = (item) => {

    const typeCompetition = item.find(data => data.typeTitle === ANKETA_USER_TYPE_COMPETITION);
    if (typeCompetition !== undefined) dispatch(setTypeCompetitionStatement(typeCompetition));
    const document = item.find(data => data.typeTitle === ANKETA_PDF_CREATE);
    if (document !== undefined) dispatch(setContractStatement(document));

    setRender(true);
  }

  useEffect(() => {
    setLoading(true);
    const getDataStatusForms = async () => {
      const arrayParams = [
        `type[]=${ANKETA_USER_TYPE_COMPETITION}`,
        `type[]=${ANKETA_PDF_CREATE}`,
      ];
      const params = arrayParams.join('&');
      const result = await dispatch(getDataStatement(params))
      onReloadInformationAnketa(result)
    }
    const terms = profileInfo.find(data => data.typeTitle === USER_TERMS);
    if (terms?.value?.value !== true) {
      history.push("/terms");
    } else {
      getDataStatusForms();
      setLoading(false);
    }
    return () => {
      setRender(false);
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDownloadDocument = () => {
    if (contractFile?.value?.file_hash !== undefined) {
      dispatch(getFileStatement(contractFile.value.file_hash))
        .then(response => {
          fileDownload(response, 'Statement_GRSU.pdf');
        }, error => {
          console.warn('error from download_contract')
          console.log(error)
          // Manage errors
        })
    }

  }
  return (

    (isLoading) ?
      <LoadingSlider open={isLoading}/>
      :
      <Container maxWidth="md">
        <Alert variant="outlined" icon={false} severity="info" className={classes.information}>
          <ul>
            {/*<li>*/}
            {/*  После заполнения всех обязательных полей (отмечены звездочкой) и прикрепления необходимых документов в*/}
            {/*  разделах 1 (Общие сведения) и 2 (Выбор специальности) Вам станет доступна <strong>Электронная*/}
            {/*  очередь</strong> для личной подачи документов НА ВЫБРАННЫЙ ВАМИ ФАКУЛЬТЕТ.*/}
            {/*</li>*/}
            <li>
              После того, как Вы заполните ВСЕ разделы и отправите заполненное заявление на проверку в
              Приемную комиссию, Ваши документы будут проверены членом Приемной комиссии, и <strong>Вы сможете
              распечатать Заявление</strong> (в 1 экз.) <strong>и Договоры</strong> (в 2-х экз.), которые Вам
              <strong style={{textTransform: 'uppercase'}}> необходимо</strong> подписать и вместе с оригиналами
              документов лично принести в Приемную комиссию в выбранное Вами время.
            </li>
            <li>
              <strong style={{fontSize: "x-large"}}>!</strong> Если Вы выберите обучение за счет средств бюджета и на
              платной основе, то Вам необходимо распечатать 2 заявления и 2 договора (каждый договор в 2-х
              экземплярах). Договоры необходимо <strong>печатать с двух сторон листа.</strong>
            </li>
            <li>
              <strong style={{fontSize: "x-large"}}>!</strong> Если <strong>Вы
              несовершеннолетний</strong>, то договоры кроме Вас должен подписать один из Ваших родителей (законных
              представителей).
            </li>
            <li>
              <strong style={{fontSize: "x-large"}}>!</strong> <strong>Дата заполнения</strong> договора и заявления
              заполняется после личной подачи документов!
            </li>
          </ul>
          <Grid container spacing={2} justifyContent="center" className={classes.grid_container}>
            <Grid item md={12} xs={12}>
              {typeCompetition?.id !== undefined && contractFile?.value?.file_hash !== undefined
                ? statementStatus !== 'IS_ASU'
                  ?
                  <Button variant="outlined" color="primary" onClick={onClickDownloadDocument}>Скачать
                    документы</Button>
                  :
                  <>
                    <Typography align="justify" variant="body1" component="p">
                      Документы поданы в Приемную Комиссию
                    </Typography>
                    <Button variant="outlined" color="primary" onClick={() => {
                    }} disabled={true}>Скачать документы</Button>
                  </>
                :
                <>
                  <Typography align="justify" variant="body1" component="p">
                    Документы будут доступны после подтверждения членом Приемной комиссии
                  </Typography>
                  <Button variant="outlined" color="primary" onClick={() => {
                  }} disabled={true}>Скачать документы</Button>
                </>
              }
            </Grid>
            <Grid item md={12} xs={12}>
              {/* {(statementStatusFormsApplication[1].status !== "NOT_CONFIRMED" && statementStatusFormsApplication[2].status !== "NOT_CONFIRMED") ? */}
              {statementStatus === 'IS_ASU' || statementStatus === 'IS_CONFIRMED' || statementStatus === 'IS_FULL' || statementStatus === 'IS_CHECKED' ?
                <Button variant="outlined" color="primary" component={RouterLink} to={"/queue"}>Электронная
                  очередь</Button>
                :
                <>
                  <Typography align="justify" variant="body1" component="p">
                    {/* Электронная очередь будет доступна после заполнения данных в разделах 1 (Общие сведения) и 2 (Выбор специальности) */}
                    Электронная очередь будет доступна после заполнения данных во всех разделах
                  </Typography>
                  <Button variant="outlined" color="primary" component={RouterLink} to={"/queue"} disabled={true}>Электронная
                    очередь</Button>
                </>
              }
            </Grid>
          </Grid>
        </Alert>
        <VerticalLinearStepper/>
      </Container>

  );
};

export default ApplicationForAdmission;