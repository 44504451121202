import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";

import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {USER_TERMS} from "../../services/tagTypes";
import {TermsMessage} from "./TermsMessage";
import {chatAction_createNotification} from "../../store/Chat/ChatActions";
import {Form, useForm} from "../Customs/useForm";
import Controls from "../Customs/Controls/Controls";
import Valid from "../../constants/Valid";
import {PROFILE_USER_DATA_GET, PROFILE_USER_MANYDATA_POST} from "../../store/actions/profile";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const initialFValues = {
  terms: false,
};

function TermsForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  const profileInfo = useSelector(state => state.profileReducer.profileInfo);

  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])
  const terms = profileInfo.find(data => data.typeTitle === USER_TERMS);

  // useEffect(() => {
  //   async function onLoadingData() {
  //     try {
  //       await dispatch(PROFILE_USER_DATA_GET());
  //     } catch (e) {
  //       console.error(`Critical error: ${JSON.stringify(e.response)}`);
  //     }
  //   }
  //   onLoadingData();
  // }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (validate()) {
      await onSaver(values)
      setIsLoading(false);
      // history.push("/statement")
      history.push("/home")
    } else {
      setIsLoading(false);
    }
  };

  const onSaver = async (data) => {
    const sendObject = {
      USER_TERMS: {value: data.terms},
    }
    try {
      await dispatch(PROFILE_USER_MANYDATA_POST(sendObject));
      dispatch(chatAction_createNotification({
        message: 'Данные формы сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      if (error.data.detail === "lock_application_in_asu") {
        dispatch(chatAction_createNotification({
          message: 'Форма заблокирована для изменений.',
          variant: 'error'
        }));
      } else {
        console.log(error);
        dispatch(chatAction_createNotification({
          message: 'Ошибка сохранения данных формы.',
          variant: 'error'
        }));
      }
    }
  }

  const validate = (fieldValues = values) => {
    let temp = {...errors};

    if ('terms' in fieldValues) {
      temp.terms = Valid.isIsset(fieldValues.terms)
        ? ''
        : 'Это поле обязательное для заполнения'
    }
    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFValues, true, validate);

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} justifyContent="center" className={classes.grid_container}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardContent>

              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TermsMessage/>
                  </Grid>
                  {
                    (isAuthenticated === true && (terms?.value?.value === undefined || terms?.value?.value !== true)) ?
                      <>
                        <Grid item md={12} xs={12}>
                          <Controls.Checkbox
                            name="terms"
                            label="Даю согласие учреждению образования «Гродненский государственный университет имени Янки Купалы» на обработку моих персональных данных в соответствии с Политикой обработки персональных данных в информационной системе «Цифровой кабинет абитуриента ГрГУ им. Янки Купалы». С условиями Политики ознакомлен (ознакомлена) и согласен (согласна)."
                            value={values.terms}
                            onChange={handleInputChange}
                            error={errors.terms}
                          />
                        </Grid>
                        <Grid item container xs={12} justifyContent={"space-between"}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isLoading}
                          >
                            Сохранить
                          </Button>
                        </Grid>
                      </> :
                      null
                  }
                </Grid>
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default TermsForm;