import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid, Typography
} from "@material-ui/core";

import FileLoadingForm from "../FileLoading";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {
  OLYMPIADS_SELECTED, USER_EMAIL, USER_FIRST_NAME, USER_PHONE_MOBILE, USER_SURNAME,

} from "../../services/tagTypes";


import {makeStyles} from "@material-ui/core/styles";
import {USER_DATA_GET} from "../../store/actions/profile";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {Alert} from "@material-ui/lab";
import {getOlympiadsReferenceBooks} from "../../store/ReferenceBooks/actionReferenceBooks";
import LoadingSlider from "../LoadingSlider/LoadingSlider";
import {useChatForm} from "../../containers/Chat";
import OlympiadItem from "./Item";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
  list_item_divider: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  information: {
    marginTop: theme.spacing(2.5),
    width: "100%",
  },
}));

function Olympiad() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const arrayOlympiads = useSelector(state => state.referenceBooksReducer.olympiadsBooksReducer);

  const [render, setRender] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [secondYes, setSecondYes] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [selected, setSelected] = React.useState([]);
  const [savedTagsId, setSavedTagsId] = React.useState({});
  const [statusFiles, setStatusFiles] = React.useState([]);
  const [userData, setUserData] = React.useState({
    surName: "",
    firstName: "",
    email: "",
    phone: "",
  });
  const [filteredArrayOlympiads, setFilteredArrayOlympiads] = React.useState([]);

  const TIME_RELOAD = 30;

  const reload = async () => {
    await getData();
  }
  useEffect(() => {
    const timerStatus = setTimeout(reload, TIME_RELOAD * 1000);
    return () => clearTimeout(timerStatus);
  })

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])

  const setDisableAllButton = (bool) => {
    setDisableButton(bool);
  }

  const onReloadInformationOlympiads = (item) => {
    let array = [];
    let newArrayOlympiads = [...arrayOlympiads];
    let arrayOlympiadsId = [];
    let arrayTagsId = {};
    let arrayStatusFiles = [];
    let userData = {
      surName: "",
      firstName: "",
      email: "",
      phone: "",
    };
    let secondYes = false;
    for (let i = 0; i < item.length; i++) {
      if (item[i]?.typeTitle === OLYMPIADS_SELECTED) {
        if (!arrayOlympiadsId.includes(item[i]?.value?.value?.id)) {
          array.push(item[i]);
        }
        arrayOlympiadsId.push(item[i]?.value?.value?.id);
        arrayTagsId[item[i]?.value?.value?.id] = item[i]?.id;
        if (item[i]?.value?.second_yes) {
          secondYes = true;
        }
      }
      if (item[i]?.typeTitle === USER_SURNAME) {
        userData.surName = item[i]?.value?.value
      }
      if (item[i]?.typeTitle === USER_FIRST_NAME) {
        userData.firstName = item[i]?.value?.value
      }
      if (item[i]?.typeTitle === USER_EMAIL) {
        userData.email = item[i]?.value?.value
      }
      if (item[i]?.typeTitle === USER_PHONE_MOBILE) {
        userData.phone = item[i]?.value?.value
      }
      if (item[i]?.typeTitle === "EDUCATION_FILE_CLASSROOM") {
        arrayStatusFiles.push(item[i]?.value?.status);
      }
    }

    newArrayOlympiads = arrayOlympiads.filter((entry) => {
        if (!arrayOlympiadsId.includes(entry.id)) return entry;
      }
    )

    array.sort(function (a, b) {
      if (a?.value?.value?.data.title > b?.value?.value?.data.title) {
        return 1;
      }
      if (a?.value?.value?.data.title < b?.value?.value?.data.title) {
        return -1;
      }
      // a должно быть равным b
      return 0;
    })
    setFilteredArrayOlympiads(newArrayOlympiads);
    setStatusFiles(arrayStatusFiles);
    setUserData(userData);

    setSavedTagsId(arrayTagsId);
    setSelected(array);

    setSecondYes(secondYes);
    setRender(!render)
  }

  const getData = async () => {
    dispatch(getOlympiadsReferenceBooks());
    const arrayParams = [
      `type[]=${OLYMPIADS_SELECTED}`,
      `type[]=${USER_SURNAME}`,
      `type[]=${USER_FIRST_NAME}`,
      `type[]=${USER_EMAIL}`,
      `type[]=${USER_PHONE_MOBILE}`,
      `type[]=EDUCATION_FILE_CLASSROOM`,
    ];
    const params = arrayParams.join('&');
    const result = await dispatch(USER_DATA_GET(params))
    onReloadInformationOlympiads(result)
  }

  useEffect(() => {
    getData();
    setIsLoadingData(false);
  }, []);

  const showFiles = () => {
    return !statusFiles.includes('IS_CONFIRMED');
  }
  const showOlympiads = () => {
    return statusFiles.includes('IS_CONFIRMED') && userData.email && userData.phone && userData.surName && userData.firstName;
  }
  const isOlympiadsOver = () => {
    return !selected.length > 0;
  }
  const showProfile = () => {
    return !(userData.email && userData.phone && userData.surName && userData.firstName);
  }

  const showFinalStage = () => {
    if (selected.length > 0) return Date.now() >= new Date(selected[0]?.value?.value?.data?.dateAdmissionFinalStage);
    return false
  }

  const currentYear = new Date().getFullYear();
  const nextYear = new Date(new Date().setFullYear(currentYear + 1)).getFullYear();

  const {onVisibleChat: chatFormOpen} = useChatForm();

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} justifyContent="center" className={classes.grid_container}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader title={`Многопрофильная олимпиада ГрГУ имени Янки Купалы ${currentYear}/${nextYear} года`}/>
            <Divider/>
            <CardContent>
              {
                isLoadingData ?
                  <LoadingSlider open={isLoadingData}/>
                  :
                  <Grid container spacing={3}>
                    <Alert variant="outlined" icon={false} severity="info" className={classes.information}>
                      {
                        !showOlympiads() ?
                          <>
                            <Typography align="justify" variant="body1" component="p">
                              Для участия (регистрации) в Многопрофильной университетской олимпиаде ГрГУ имени Янки
                              Купалы {currentYear}/{nextYear}, Вам необходимо:
                            </Typography>
                            <ul>
                              {showProfile() ?
                                <li>
                                  Внести персональные данные в раздел меню <Link component={RouterLink} to="/profile"
                                                                                 variant="body2">«Профиль»</Link>
                                </li>
                                : null
                              }
                              {showFiles() ?
                                <li>
                                  Прикрепить фотографию справки, подтверждающей статус учащегося 11 класса в текущем
                                  учебном году (справка установленной формы выдается учреждением образование, в котором
                                  обучается абитуриент)
                                </li>
                                : null
                              }
                              <li>
                                Получить подтверждение регистрации (смс на указанный при регистрации номер телефона)
                              </li>
                              <li>
                                Выбрать профильную(ые) олимпиаду(ы), в которой(ых) Вы будете принимать участие (перечень
                                профильных олимпиад будет доступен после подтверждения регистрации)
                              </li>
                            </ul>
                            <Typography align="justify" variant="body1" component="p">
                              По вопросам регистрации и порядка проведения олимпиады необходимо обращаться в <Link
                              onClick={chatFormOpen} variant="body2">«Чат»</Link>.
                            </Typography>
                            <Typography align="justify" variant="body1" component="p">
                              Обращаем Ваше внимание, что проверка документов осуществляется в рабочие дни с 8.30 до
                              17.00.
                            </Typography>
                          </>
                          :
                          showFinalStage() ?
                            <>
                              <Typography align="justify" variant="body1" component="p">
                                Обращаем внимание, что принять участие в заключительном этапе многопрофильной олимпиады
                                Вы можете <strong>только в одной</strong> профильной олимпиаде.
                              </Typography>
                              <Typography align="justify" variant="body1" component="p">
                                Выберите олимпиаду и подтвердите свое участие в ее заключительном этапе.
                              </Typography>
                            </>
                            :
                            isOlympiadsOver() ?
                              <>
                                <Typography align="justify" variant="body1" component="p">
                                  Регистрация для участия в многопрофильной университетской олимпиаде ГрГУ имени Янки
                                  Купалы в 2023/2024 учебном году завершилась.
                                </Typography>
                                <Typography align="justify" variant="body1" component="p">
                                  Информацию о порядке приема в университет в 2024 году на иных условиях Вы можете
                                  получить, обратившись в <Link
                                  onClick={chatFormOpen} variant="body1">«Чат»</Link> Цифрового кабинета абитуриента.
                                </Typography>
                                <Typography align="justify" variant="body1" component="p">
                                  Желаем успехов!
                                </Typography>
                              </>
                              :
                              <Typography align="justify" variant="body1" component="p">
                                Выберите Олимпиады, в которых Вы будете участвовать
                              </Typography>
                      }
                    </Alert>

                    {showFiles() ?
                      <Grid item xs={12}>
                        <FileLoadingForm
                          saveType={'user'}
                          tag={'EDUCATION_FILE_CLASSROOM'}
                          labelText={'Загрузка файлов соответствующих документов подтверждающих право на участие в олимпиадах'}
                          labelForm={'Перетащите или <span class="filepond--label-action" tabindex="0">прикрепите</span> сканы (фото) соответствующих документов'}
                          size={5}
                          textSuccess={'Файл проверен и принят'}
                          textError={'Файл проверен и не принят'}
                        />
                      </Grid>
                      : null
                    }
                    {
                      showOlympiads() ?
                        <>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              {selected.map((val) => {
                                return (
                                  <OlympiadItem key={val.id} val={val?.value?.value} isSelected={true}
                                                savedTagsId={savedTagsId} getData={getData} tagData={val}
                                                secondYes={secondYes} setDisableAllButton={setDisableAllButton}
                                                disableButton={disableButton}
                                  />
                                );
                              })}
                              {filteredArrayOlympiads.map((val) => {
                                return (
                                  <OlympiadItem key={val.id} val={val} isSelected={false} savedTagsId={savedTagsId}
                                                getData={getData} tagData={null} secondYes={secondYes}
                                                setDisableAllButton={setDisableAllButton}
                                                disableButton={disableButton}/>
                                );
                              })}
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} justifyContent={"space-between"}>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                              onClick={() => {
                                history.push("/home")
                              }}
                            >
                              Назад
                            </Button>
                          </Grid>
                        </>
                        :
                        null
                    }
                  </Grid>
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Olympiad;