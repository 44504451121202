import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

export default function DialogDelete(props) {
    const {
        open: PropsVisible,
        onClose: PropsOnClose,
        onApprove: PropsOnApprove
    } = props;

    function onClose() {
        PropsOnClose && PropsOnClose();
    }

    function onApprove() {
        PropsOnApprove && PropsOnApprove();
    }

    return (
        <Dialog open={PropsVisible} onClose={onClose}>
            <DialogContent>
                <DialogContent>
                    <DialogContentText>Уверены, что хотите удалить данный файл?</DialogContentText>
                </DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={onApprove} color="secondary">Да</Button>
                <Button onClick={onClose} color="primary">Нет</Button>
            </DialogActions>
        </Dialog>
    )
}
DialogDelete.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onApprove: PropTypes.func.isRequired,
};
