export const CHAT_GET_CHAT_LIST_SUCCESSFUL = "CHAT_GET_CHAT_LIST_SUCCESSFUL";
export const CHAT_GET_CHAT_LIST_FAILED = "CHAT_GET_CHAT_LIST_FAILED";
export const CHAT_GET_CHAT_LIST_LOADING = "CHAT_GET_CHAT_LIST_LOADING";
export const CHAT_GET_CHAT_LIST_RELOADING = "CHAT_GET_CHAT_LIST_RELOADING";

export const CHAT_GET_MESSAGE_LIST_SUCCESSFUL = "CHAT_GET_MESSAGE_LIST_SUCCESSFUL";
export const CHAT_GET_MESSAGE_LIST_FAILED = "CHAT_GET_MESSAGE_LIST_FAILED";
export const CHAT_GET_MESSAGE_LIST_LOADING = "CHAT_GET_MESSAGE_LIST_LOADING";
export const CHAT_GET_MESSAGE_LIST_RELOADING = "CHAT_GET_MESSAGE_LIST_RELOADING";

export const CHAT_SET_NEW_MESSAGE_SENDING = "CHAT_SET_NEW_MESSAGE_SENDING";


export const CHAT_STATUS_VISIBLE = "CHAT_STATUS_VISIBLE";
export const CHAT_STATUS_LAST_EVENT = "CHAT_STATUS_LAST_EVENT";


