import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";
import Valid from "../../../../../constants/Valid";
import {Form, useForm} from "../../../../../components/Customs/useForm";
import Controls from "../../../../../components/Customs/Controls/Controls";
import {Button, Grid,} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {
  deleteContractFileStatement,
  deleteTypeCompetitionStatement,
  getDataStatement,
  getFacultyStatement,
  getSpecialitiesBudgetStatement,
  getSpecialitiesPaidStatement,
  getStudyFormStatement,
  getStudyPayStatement, getTypeTrainingStatement,
  setDataStatement
} from "../../../../../store/Statement/actionStatement";
import {
  TRAINING_ALLOW_FOR_PAID,
  TRAINING_FACULTY,
  TRAINING_SPECIALITIES_GROUP,
  TRAINING_SPECIALITY_BUDGET,
  TRAINING_SPECIALITY_PAID,
  TRAINING_STUDY_FORM,
  TRAINING_STUDY_PAY,
  TRAINING_TARGET_RECRUITMENT,
  TRAINING_TARGET_RECRUITMENT_SPEC,
  TRAINING_TYPE,
} from "../../../../../services/tagTypes";
import instance from "../../../../../customAxios";
import FileLoadingForm from "../../../../../components/FileLoading";
import {GET_SELECTED_FACULTY_STATEMENT,} from "../../../../../store/actions/actionTypes";
import {chatAction_createNotification} from "../../../../../store/Chat/ChatActions";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const initialFValues = {
  studyForm: "1/F",
  studyPay: "budget",
  faculty: "",
  specialityGroup: "",
  allowForPaid: '',
  targetRecruitmentSpeciality: "",
};

const Olympiad = ({typeTraining, dataFromApi}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [itemsFaculty, setItemsFaculty] = useState([]);
  const [itemsTargetRecruitmentSpecialities, setItemsTargetRecruitmentSpecialities] = useState([]);
  const [render, setRender] = useState(false);
  const [dataUnloadApi, setDataUnloadApi] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!render) return;
    setRender(false)
  }, [render])

  const itemsStudyForm = [
    {title: 'Дневная', id: '1/F', disabled: false},
  ];
  const itemsStudyPay = [
    {title: 'За счет средств бюджета', id: "budget", disabled: false},
    {title: 'На платной основе', id: "paid", disabled: false},
  ];

  const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  const statementTypeCompetition = useSelector(state => state.statementReducer.typeCompetition);
  const statementContractFile = useSelector(state => state.statementReducer.contractFile);

  const getTypeTraining = (item) => {
    const typeTraining = item.find(data => data.typeTitle === TRAINING_TYPE);
    return (typeTraining !== undefined ? typeTraining.value.value : '');
  }

  const getStudyForm = (item) => {
    const studyForm = item.find(data => data.typeTitle === TRAINING_STUDY_FORM);
    return (studyForm !== undefined ? studyForm.value : '');
  }
  const getStudyPay = (item) => {
    const studyPay = item.find(data => data.typeTitle === TRAINING_STUDY_PAY);
    return (studyPay !== undefined ? studyPay.value.value : initialFValues.studyPay);
  }

  const getFacultyFromApi = (item) => {
    return item.find(data => data.typeTitle === TRAINING_FACULTY);
  }
  const getFaculty = (item, entryFaculties) => {
    const faculty = getFacultyFromApi(item);
    return (faculty !== undefined ? entryFaculties.find(data => data.id === faculty.value.id) : '');
  }
  const getFacultyFromRecruitmentSpeciality = (item, entryFaculties) => {
    const faculty = item?.facultyGroup?.faculty;
    return (faculty !== undefined ? entryFaculties.find(data => data.id === faculty.id) : '');
  }
  const getTargetRecruitmentSpecialityFromApi = (item) => {
    return item.find(data => data.typeTitle === TRAINING_TARGET_RECRUITMENT_SPEC);
  }
  const getTargetRecruitmentSpeciality = (item, entryTargetRecruitmentSpeciality) => {
    const targetRecruitmentSpeciality = getTargetRecruitmentSpecialityFromApi(item);
    if (targetRecruitmentSpeciality !== undefined) {
      let selectedTargetRecruitmentSpeciality = (targetRecruitmentSpeciality.value.value ?
        targetRecruitmentSpeciality.value.value : targetRecruitmentSpeciality.value);

      const findEntryTargetRecruitmentSpeciality = entryTargetRecruitmentSpeciality.find(data => data?.id === selectedTargetRecruitmentSpeciality?.id)

      if (findEntryTargetRecruitmentSpeciality !== undefined) {
        return findEntryTargetRecruitmentSpeciality;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  const onSaver = async (data) => {
    let sendObject = {
      TRAINING_TYPE: {value: data.typeTraining},
      TRAINING_STUDY_FORM: data.studyForm,
      TRAINING_STUDY_PAY: {value: data.studyPay},
      TRAINING_FACULTY: data.selectedFaculty,
      TRAINING_SPECIALITIES_GROUP: data.selectedSpecialityGroup,
      TRAINING_ALLOW_FOR_PAID: {value: data.selectedAllowForPaid},
      TRAINING_SPECIALITY_BUDGET: data.itemsSpecialitiesBudget,
      TRAINING_SPECIALITY_PAID: data.itemsSpecialitiesPaid,
      TRAINING_TARGET_RECRUITMENT_SPEC: data.selectedTargetRecruitmentSpeciality,
      EXAMS_IS_EMPTY_EXAMS: {value: true},
      ANKETA_STATUS_FORMS_APPLICATION: {
        ...statementStatusFormsApplication,
        2: {"status": "IS_FULL", "coment": ""},
        6: {"status": "IS_FULL", "coment": ""}
      },
      ANKETA_STATUS: {"value": "IS_WORK"},
    }
    let isNeedClearExamsForm = clearExamsForm(dataFromApi);
    let isNeedClearPrivilegesForm = getTypeTraining(dataFromApi) !== typeTraining;
    if (isNeedClearExamsForm) {
      sendObject = {
        ...sendObject,
        EXAMS_RESULT_CT_1_CERTIFICATE: {value: ''}, // из ЦПК
        EXAMS_RESULT_CT_2_CERTIFICATE: {value: ''}, // из ЦПК
        EXAMS_RESULT_CT_LANG_CERTIFICATE: {value: ''}, // из ЦПК
        EXAMS_RESULT_CT_1: {value: ''},
        EXAMS_RESULT_CT_2: {value: ''},
        EXAMS_LANGUAGE_CT_LANG: {value: ''},
        EXAMS_LANGUAGE_EXAMS_LANG: {value: ''},
        EXAMS_RESULT_CT_LANG: {value: ''},
        EXAMS_NEED_AHOSTEL: {value: false},
        ANKETA_STATUS_FORMS_APPLICATION: {
          ...statementStatusFormsApplication,
          2: {"status": "IS_FULL", "coment": ""},
          6: {"status": "IS_FULL", "coment": ""},
          9: {"action": "2"}
        },
      }
    }
    if (isNeedClearPrivilegesForm) {
      sendObject = {
        ...sendObject,
        PRIVILEGES_EMPTY: {value: false},
        PRIVILEGES_STUDY: {value: []},
        PRIVILEGES_SPORT: {value: []},
        PRIVILEGES_SOCIAL: {value: []},
        ANKETA_STATUS_FORMS_APPLICATION: {
          ...statementStatusFormsApplication,
          2: {"status": "IS_FULL", "coment": ""},
          7: {"status": "NOT_CONFIRMED", "coment": ""}
        },
      }
    }
    if (isNeedClearExamsForm && isNeedClearPrivilegesForm) {
      sendObject = {
        ...sendObject,
        ANKETA_STATUS_FORMS_APPLICATION: {
          ...statementStatusFormsApplication,
          2: {"status": "IS_FULL", "coment": ""},
          6: {"status": "IS_FULL", "coment": ""},
          7: {"status": "NOT_CONFIRMED", "coment": ""}
        },
      }
    }
    try {
      await dispatch(setDataStatement(sendObject));
      dispatch(chatAction_createNotification({
        message: 'Данные формы сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      if (error.data.detail === "lock_application_in_asu") {
        dispatch(chatAction_createNotification({
          message: 'Форма заблокирована для изменений.',
          variant: 'error'
        }));
      } else {
        console.log(error);
        dispatch(chatAction_createNotification({
          message: 'Ошибка сохранения данных формы.',
          variant: 'error'
        }));
      }
    }
    await dispatch(getTypeTrainingStatement(data.typeTraining));
    await dispatch(getStudyFormStatement(data.studyForm));
    await dispatch(getStudyPayStatement(data.studyPay));
    await dispatch(getFacultyStatement(data.selectedFaculty));
    await dispatch(getSpecialitiesBudgetStatement(data.itemsSpecialitiesBudget));
    await dispatch(getSpecialitiesPaidStatement(data.itemsSpecialitiesPaid));
    if (statementTypeCompetition?.id) await dispatch(deleteTypeCompetitionStatement());
    if (statementContractFile?.id) await dispatch(deleteContractFileStatement());
  }

  const studyFormSubmit = () => {
    let first = values.studyForm[0];
    let last = values.studyForm[values.studyForm.length - 1];
    if (first && last) return {studyForm: +first, trainingVolume: last};
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const dataStudyForm = studyFormSubmit();

    let dataSubmit = {
      typeTraining: typeTraining,
      studyForm: dataStudyForm,
      studyPay: values.studyPay,
      selectedFaculty: values.faculty,
      selectedTargetRecruitmentSpeciality: values.targetRecruitmentSpeciality,
      itemsSpecialitiesPaid: [],
      itemsSpecialitiesBudget: [],
      selectedSpecialityGroup: values.specialityGroup,
      selectedAllowForPaid: '',
      selectedTargetRecruitment: true,

    }

    if (dataStudyForm === '') {
      dataSubmit.studyForm = {value: ''};
    }
    if (values.targetRecruitmentSpeciality === '') {
      dataSubmit.selectedTargetRecruitmentSpeciality = {value: ''};
    }
    if (values.studyPay === 'budget' && (values.allowForPaid === 'false' || values.allowForPaid === false)) {
      dataSubmit.itemsSpecialitiesPaid = [];
    }
    if (values.studyPay === 'paid') {
      dataSubmit.itemsSpecialitiesBudget = [];
      dataSubmit.selectedAllowForPaid = '';
    }

    if (validate()) {
      await onSaver(dataSubmit);
      await dispatch({
        type: GET_SELECTED_FACULTY_STATEMENT,
        payload: values.faculty
      });
      setIsLoading(false);
      history.push("/statement");
    } else {
      setIsLoading(false);
    }
  };

  const validate = (fieldValues = values) => {
    let temp = {...errors};
    if ('studyForm' in fieldValues) {
      temp.studyForm = Valid.isIsset(fieldValues.studyForm)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('studyPay' in fieldValues) {
      temp.studyPay = Valid.isIsset(fieldValues.studyPay)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('faculty' in fieldValues) {
      temp.faculty = Valid.isIsset(fieldValues.faculty)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('targetRecruitmentSpeciality' in fieldValues) {
      temp.targetRecruitmentSpeciality = Valid.isIsset(fieldValues.targetRecruitmentSpeciality)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }

    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const clearExamsForm = (item) => {
    const oldTypeTraining = getTypeTraining(item);
    const newTypeTraining = typeTraining;

    const oldObjectStudyForm = getStudyForm(item);
    const oldStudyForm = oldObjectStudyForm?.studyForm;
    const oldTrainingVolume = oldObjectStudyForm?.trainingVolume;
    const newObjectStudyForm = studyFormSubmit();
    const newStudyForm = newObjectStudyForm?.studyForm;
    const newTrainingVolume = newObjectStudyForm?.trainingVolume;

    const oldStudyPay = getStudyPay(item);
    const newStudyPay = values.studyPay;

    let oldFaculty = getFacultyFromApi(item);
    if (oldFaculty !== undefined) oldFaculty = oldFaculty.value;
    const newFaculty = values.faculty;

    let oldTargetRecruitmentSpeciality = getTargetRecruitmentSpecialityFromApi(item);
    if (oldTargetRecruitmentSpeciality !== undefined) oldTargetRecruitmentSpeciality = oldTargetRecruitmentSpeciality.value;
    const newTargetRecruitmentSpeciality = values.targetRecruitmentSpeciality;

    return oldTypeTraining !== newTypeTraining || oldStudyForm !== newStudyForm || oldTrainingVolume !== newTrainingVolume || oldStudyPay !== newStudyPay || oldFaculty?.id !== newFaculty?.id || oldTargetRecruitmentSpeciality?.id !== newTargetRecruitmentSpeciality?.id;

  }

  const clearTrainingForm = () => {
    // values.studyPay = '';
    clearSpecialitiesTarget();
  }
  const clearSpecialitiesTarget = () => {
    values.targetRecruitmentSpeciality = '';
    clearFaculty();
  }
  const clearFaculty = () => {
    values.faculty = '';
    setRender(!render);
  }

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFValues, true, validate);

  const onReloadInformationToFacultyTraining = (item, entryFaculties) => {
    // console.log('onReloadInformationToFacultyTraining', item);
    const fac = getFacultyFromRecruitmentSpeciality(item, entryFaculties);
    values.faculty = fac;
    values.specialityGroup = item?.facultyGroup;
    setRender(!render);
  }

  const onReloadInformationToTargetRecruitmentSpecialityTraining = (item, entryTargetRecruitmentSpeciality) => {
    // console.log('onReloadInformationToTargetRecruitmentSpecialityTraining');
    // values.targetRecruitmentSpeciality = getTargetRecruitmentSpeciality(item, entryTargetRecruitmentSpeciality);
    const tar = getTargetRecruitmentSpeciality(item, entryTargetRecruitmentSpeciality);
    // setValues({...values, 'targetRecruitmentSpeciality': tar});
    values.targetRecruitmentSpeciality = tar;
    setRender(true);
    // console.log(tar);
    return tar;
  }

  useEffect(() => {
    const studyPay = getStudyPay(dataFromApi);
    setValues({...values, studyPay})
    const fetchDataFaculties = async () => {
      const response = await instance.get(
        `/api/pub/faculties`,
      );
      await setItemsFaculty(response.data);
    };
    fetchDataFaculties();
    return () => {
      setDataUnloadApi(true);
      clearTrainingForm();
      clearSpecialitiesTarget();
    };
  }, []);


  useEffect(() => {
    const fetchDataSpeciality = async (studyForm, trainingVolume, studyPay, isOlympiad) => {
      const response = await instance.get(
        `/api/pub/group_speciality?${isOlympiad && studyPay === 'budget' ? `&isOlimpBudget=1` : ``}${isOlympiad && studyPay === 'paid' ? `&isOlimpPaid=1` : ``}`,
      );
      await setItemsTargetRecruitmentSpecialities(response.data);
      return response.data
    };
    if (dataUnloadApi === false) {
      clearSpecialitiesTarget();
    }
    if  (values.studyPay === 'budget' || values.studyPay === 'paid'){
      fetchDataSpeciality(+values.studyForm[0], values.studyForm[values.studyForm.length - 1], values.studyPay, true);
    }

    return () => {
      clearSpecialitiesTarget();
    };
  }, [values.studyPay]);

  useEffect(() => {
    if (itemsTargetRecruitmentSpecialities.length > 0) {
      const targetRecruitmentSpeciality = onReloadInformationToTargetRecruitmentSpecialityTraining(dataFromApi, itemsTargetRecruitmentSpecialities);
      if (itemsFaculty.length > 0) {
        onReloadInformationToFacultyTraining(targetRecruitmentSpeciality, itemsFaculty);
        setDataUnloadApi(false);
      }
    }
  }, [itemsTargetRecruitmentSpecialities]);

  useEffect(() => {
    if (dataUnloadApi === false && itemsFaculty.length > 0) {
      // console.log('useEffect values.targetRecruitmentSpeciality');
      onReloadInformationToFacultyTraining(values.targetRecruitmentSpeciality, itemsFaculty);
    }
  }, [values.targetRecruitmentSpeciality])

  const showStudyField = () => {
    return typeTraining;
  }
  const showOlympiadSpeciality = () => {
    return values.studyPay && values.studyForm && typeTraining === 'olympiad';
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={3}>
        {showStudyField() ?
          <>
            <Grid item md={6} xs={12}>
              <Controls.Select
                fullWidth
                name='studyForm'
                label={"Форма обучения"}
                value={values.studyForm}
                options={itemsStudyForm}
                onChange={handleInputChange}
                error={errors.studyForm}
                disabled={true}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controls.Select
                fullWidth
                name='studyPay'
                label={"Вид обучения*"}
                value={values.studyPay}
                options={itemsStudyPay}
                onChange={handleInputChange}
                error={errors.studyPay}
                disabled={true}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Controls.SelectFaculty
                fullWidth
                name='faculty'
                label={"Факультет"}
                value={values.faculty}
                options={itemsFaculty}
                onChange={handleInputChange}
                error={errors.faculty}
                disabled={true}
              />
            </Grid>
          </>
          : null
        }
        {showOlympiadSpeciality() ?
          <>
            <Grid item md={12} xs={12}>
              <Controls.SelectTargetRecruitmentSpecialities
                fullWidth
                name='targetRecruitmentSpeciality'
                label={"Специальность*"}
                value={values.targetRecruitmentSpeciality}
                options={itemsTargetRecruitmentSpecialities}
                onChange={handleInputChange}
                error={errors.targetRecruitmentSpeciality}
              />
            </Grid>
          </>
          : null
        }

        <Grid item container xs={12} justifyContent={"space-between"}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              history.push("/statement")
            }}
            disabled={isLoading}
          >
            Назад
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
          >
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
};

export default Olympiad;