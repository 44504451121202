import React, {useEffect} from 'react';
import {resolver, actions} from '../../services/statusMessages/index';
import {useDispatch, useSelector} from "react-redux";
import { getDataStatusMessagesASUStatement} from "../../store/Statement/actionStatement";
import {Grid, Typography} from "@material-ui/core";
import {MessageDates} from "../MessageDates";


function OrganizationalMeeting() {
    const dispatch = useDispatch();
  const selectedTypeTrainingStatement = useSelector(state => state.statementReducer.selectedTypeTrainingStatement);
  const idApplicationStatementActive = useSelector(state => state.statementReducer.applicationStatementActive.id);
  const statusMessagesASU = useSelector(state => state.statementReducer.dataStatusMessagesASU);
  const templateStatusMessagesASU = useSelector(state => state.statementReducer.templateStatusMessagesASU);
 
  const title = () => {
    if (template() !== null) {
      return resolver({
        messages: [template().title],
        actions: actions(),
      }).join(); 
    }
    return ''  
  }

  const messages = () => {
    if (template() !== null) {
      return resolver({
        messages: template().messages,
        actions: actions(),
        vars: template().vars,
        status: statusMessagesASU,
      }, {typeTraining: selectedTypeTrainingStatement});
    }
    return null;
  }

  const template = () => {
    let tmp = selectTemplateMessage();
    if (tmp !== undefined && tmp !== null) {
      return tmp
    }
    return null
  }

  const selectTemplateMessage = () => {
    const templateStatusMessages = templateStatusMessagesASU;
    if (statusMessagesASU._SHOW_PROFORG_FORT_ === true && templateStatusMessages["PROF_ORG"]) {
      return templateStatusMessages["PROF_ORG"];
    }
    return null;
  }

  useEffect(() => {
    if (idApplicationStatementActive) dispatch(getDataStatusMessagesASUStatement());
  }, [idApplicationStatementActive]);

  return (
    idApplicationStatementActive && templateStatusMessagesASU?.QUEUE
      ?

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Typography align="center" variant="body1" component="p" dangerouslySetInnerHTML={{__html: title()}}/>
        </Grid>
        <Grid item xs={12}>
          {
            messages() !== null
              ? messages().map((message, index) => <Typography align="justify" variant="body1" component="p" key={index}
                                                               dangerouslySetInnerHTML={{__html: message}}/>)
              : <MessageDates/>
            // : null
          }
        </Grid>
      </Grid>
      : null
  );
}

export default OrganizationalMeeting;