import React from 'react';
import {useSelector} from 'react-redux'
import {format} from 'date-fns';
import {Typography} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import './Queue.css';

// const WaitSpinner = () => {
//   return <div className="WaitSpinner">
//     <CircularProgress/>
//   </div>
// }

const UserMessage = () => {

  const queueState = useSelector(state => state.queueReducer);
  const selectedTypeTrainingStatement = useSelector(state => state.statementReducer.selectedTypeTrainingStatement);

  const currentQueue = queueState.currentQueue;

  const facultyName = currentQueue !== null && Array.isArray(currentQueue) && currentQueue.length > 0 ? currentQueue[0]?.faculty?.data?.nameFull : '';

  let link = <a
    href="https://abit.grsu.by/view/18-vysshee/kak-postupit/1041-dokumenty-predstavlyaemye-v-priemnuyu-komissiyu.html"
    target="_blank" rel="noreferrer">по ссылке</a>;
  if (selectedTypeTrainingStatement === 'targetTraining') {
    link = <a
      href="https://abit.grsu.by/kak-postupit/18-kategorii-ru/vysshee/kak-postupit/1659-perechen-dokumentov-dlya-tselevikov.html"
      target="_blank" rel="noreferrer">по ссылке</a>;
  }
  if (selectedTypeTrainingStatement === 'olympiad') {
    link = <a
      href="https://abit.grsu.by/kak-postupit/18-kategorii-ru/vysshee/kak-postupit/1658-perechen-dokumentov-dlya-olimpiadnikov.html"
      target="_blank" rel="noreferrer">по ссылке</a>;
  }

  const text1 = <><span>Выберите удобные для Вас дату и время из свободных для личной подачи оригиналов документов.
    С перечнем документов, необходимых для подачи в приемную комиссию, можно ознакомиться </span>{link}.</>;

  const vocative = <p className="CenterAligned">Уважаемый абитуриент!</p>;

  const queueTime = currentQueue !== null ? currentQueue.map((item, i) => {
    const comma = currentQueue.length > 1 && i > 0 ? ', ' : '';
    return <span key={i}>{comma}{format(new Date(item.item.time), 'dd.MM.yyyy')} в {item.item.text}</span>
  }) : '';

  const text2 =
    <>
      <p className="CenterAligned">Вы зарегистрировались на подачу документов для получения общего высшего и
        специального
        высшего образования в учреждении
        образования «Гродненский государственный университет имени Янки Купалы».</p>
      <p className="CenterAligned">
        Для подачи документов
        на <strong>{facultyName}</strong> Вам необходимо
        явиться <strong>{queueTime}</strong> по адресу
        {
          // (selectedTypeTrainingStatement !== 'generalBasis') ? ' г.Гродно, ул. Ожешко, 22, ауд. 218а' : ' г.Гродно, ул. Ленина, 32'
          ' г.Гродно, ул. Ленина, 32'
        }
      </p>
      <p className="CenterAligned">Желаем успехов!</p>
    </>;

  const output = currentQueue !== null ? <>{vocative}{text2}</> : text1;

  return (
    <div style={{paddingLeft: 35, paddingRight: 35}}>
      <div style={{textAlign: 'justify'}}>{output}</div>
    </div>
  );
};

export default UserMessage;