import {Box, CircularProgress, Grid} from "@material-ui/core";

export default function Loading(){
    return (
        <Grid container spacing={1} alignItems="center" justifyContent="center" alignContent="center">
            <Grid item>
                <Box p={1}>
                    <CircularProgress/>
                </Box>
            </Grid>
        </Grid>
    )
}
