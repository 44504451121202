import {
  CHANNELS_GET_USER_CHANNEL_LIST_SUCCESS,
  CHANNELS_GET_USER_CHANNEL_LIST_FAILED,
  CHANNELS_GET_USER_CHANNEL_LIST_LOADING,
  CHANNELS_GET_MESSAGES_LIST_SUCCESS,
  CHANNELS_GET_MESSAGES_LIST_FAILED,
  CHANNELS_GET_MESSAGES_LIST_LOADING,
  CHANNELS_GET_MESSAGES_LIST_INTERNAT_SUCCESS,
  CHANNELS_GET_MESSAGES_LIST_INTERNAT_FAILED,
} from "./typesChannels";
import instance from "../../customAxios";

const API_URL = "/";

export function channelsGetUserChannelList () {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + `api/ext/channels/custom/posts`,
      );
      dispatch(channelsGetUserChannelListSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(channelsGetUserChannelListFail());
      console.error('error channelsGetUserChannelList: ', error.response);
      return Promise.reject(error.response);
    }
  };
}

export function channelsGetUserChannelListSuccess (payload) {
  return {
    type: CHANNELS_GET_USER_CHANNEL_LIST_SUCCESS,
    payload
  }
}

export function channelsGetUserChannelListFail () {
  return {
    type: CHANNELS_GET_USER_CHANNEL_LIST_FAILED
  }
}

export function channelsGetMessagesList () {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + `api/ext/channels/custom/posts?limit=20&offset=0`,
      );
      dispatch(channelsGetMessagesListSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(channelsGetMessagesListFail());
      console.error('error channelsGetMessagesList: ', error.response);
      return Promise.reject(error.response);
    }
  };
}

export function channelsGetMessagesListSuccess (payload) {
  return {
    type: CHANNELS_GET_MESSAGES_LIST_SUCCESS,
    payload
  }
}

export function channelsGetMessagesListFail () {
  return {
    type: CHANNELS_GET_MESSAGES_LIST_FAILED
  }
}

export function channelsGetUserMessagesList () {

  return async (dispatch, getState) => {
    try {
      const {channelList} = getState().channelsReducer;
      let resultArray = [];
      for await (let channel of channelList) {
        let channelId = channel?.id;
        const response = await instance.get(
          API_URL + `api/channel/${channelId}?limit=20&offset=0`,
        );
        resultArray = [...resultArray, ...response.data];
      }

      resultArray.sort(function(a,b){
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      dispatch(channelsGetMessagesListSuccess(resultArray));
      return resultArray;
    } catch (error) {
      dispatch(channelsGetMessagesListFail());
      console.error('error channelsGetMessagesList: ', error.response);
      return Promise.reject(error.response);
    }
  };
}

export function channelsGetMessagesListInternat () {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + `api/ext/channels/posts?type[]=INTERNAT`,
      );
      dispatch(channelsGetMessagesListInternatSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(channelsGetMessagesListInternatFail());
      console.error('error channelsGetMessagesListInternat: ', error.response);
      return Promise.reject(error.response);
    }
  };
}

export function channelsGetMessagesListInternatSuccess (payload) {
  return {
    type: CHANNELS_GET_MESSAGES_LIST_INTERNAT_SUCCESS,
    payload
  }
}

export function channelsGetMessagesListInternatFail () {
  return {
    type: CHANNELS_GET_MESSAGES_LIST_INTERNAT_FAILED
  }
}