import React, {useRef, useEffect, useState} from "react";
import {Box, CircularProgress, Typography} from "@material-ui/core";

export default function Countdown(props) {
  const {initSec} = props;
  const [num, setNum] = useState(initSec);
  const normalise = value => (value * 100) / initSec;

  let intervalRef = useRef();

  const decreaseNum = () => setNum((prev) => prev - 1);

  useEffect(() => {
    intervalRef.current = setInterval(decreaseNum, 1000);

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (num <= 0) {
      clearInterval(intervalRef.current);
      props.handleTimeout()
    }
  }, [num, props]);

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={normalise(num)}/>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >
          {`${Math.round(num)} c`}
        </Typography>
      </Box>
    </Box>
  );
}