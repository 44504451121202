import {
    Button,
    Grid,
    InputAdornment,
    TextField
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {useForm} from "../../../../components/Customs/useForm";
import Valid from "../../../../constants/Valid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import REGULAR_EXPRESSION from "../../../../constants/RegExp";

const initialFValues = {
    login: "375",
};

function useFormResetPassword(propsOnSubmit, validate, values) {
    const [isEmail, setIsEmail] = useState(false);


    function onSubmit(e) {
        e.preventDefault();
        if (validate()) {
            const obj = {
                ...(isEmail && {type: "EMAIL"}),
                ...(isEmail && {email: values?.login}),
                ...(!isEmail && {phone: values?.login})
            };
            propsOnSubmit && propsOnSubmit(obj);
        }
    }


    useEffect(() => {
        if (REGULAR_EXPRESSION.EMAIL.test(values?.login) === true) {
            if (!isEmail) setIsEmail(true);
        } else {
            if (isEmail) setIsEmail(false);
        }
    }, [values.login])


    return {onSubmit};
}

export default function Form(props) {
    const classes = useStyles();
    const {onSubmit: propsOnSubmit, sending: propsSending} = props;
    const validate = (fieldValues = values) => {
        let temp = {...errors};
        if ('login' in fieldValues) {
            temp.login = "";
            if (temp?.login === "" && !Valid.isIsset(fieldValues?.login)) {
                temp.login = "Обязательно для заполнения.";
            }
            if (temp?.login === "" && REGULAR_EXPRESSION.EMAIL.test(fieldValues?.login) === false && REGULAR_EXPRESSION.MOBILE_PHONE.test(fieldValues?.login) === false) {
                temp.login = "Неподходящий формат логина";
            }
        }

        setErrors({...temp});
        if (fieldValues === values) {
            return Object.values(temp).every(x => x === '')
        }
    };
    const {
        values,
        errors,
        setErrors,
        handleInputChange: onChange
    } = useForm(initialFValues, false, validate);
    const {onSubmit} = useFormResetPassword(propsOnSubmit, validate, values);

    return (
        <form className={classes.form} onSubmit={onSubmit} noValidate>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        required
                        variant="outlined"
                        fullWidth
                        name="login"
                        label="Логин"
                        id="login"
                        placeholder="Введите логин"
                        value={values.login}
                        onChange={onChange}
                        error={Boolean(errors.login)}
                        helperText={errors.login && errors.login}
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <AccountCircleIcon color="disabled"/>
                                </InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.submit}
                        disabled={propsSending}
                    >
                        Отправить
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
    },
    formControl: {},
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

Form.propTypes = {
    onSubmit: PropTypes.func,
    sending: PropTypes.bool
};
