import React, {memo} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const LogoSVG = memo((props) => {
    return (
      <SvgIcon {...props} viewBox="0 0 550 474">
        <path d="M0,0L145,231l-21.5,35L253,474,550,0H288.5L275,23,260.5,0H0Z"
              fill="#273895"
        />
        <path d="M29,15L164,231l-22.5,35L253,444,522,15H296.5L275,52,253,15H29Z"
              fill="#ffffff"
        />
        <path
          d="M178.56,165.8V133.882L186.8,165.8h8.1l8.27-31.914V165.8h7.816V125.253H198.368l-7.474,27.656-7.56-27.656h-12.59V165.8h7.816Zm63.972-8.379a8.566,8.566,0,0,1-5.769,2.074,9.336,9.336,0,0,1-7.389-3.264q-2.842-3.263-2.842-10.951,0-7.245,2.885-10.482a9.594,9.594,0,0,1,7.517-3.235,8.995,8.995,0,0,1,5.7,1.825,8.556,8.556,0,0,1,3.083,4.978l8.327-1.936a15.88,15.88,0,0,0-4.263-7.467q-4.774-4.4-12.419-4.4-8.754,0-14.125,5.6t-5.371,15.722q0,9.57,5.343,15.086a20.042,20.042,0,0,0,24.711,2.3q4.362-3.222,6.238-9.86L246,150.89A11.956,11.956,0,0,1,242.532,157.417Zm26.544,8.379V133.882l8.242,31.914h8.1l8.27-31.914V165.8H301.5V125.253H288.885l-7.474,27.656-7.56-27.656h-12.59V165.8h7.815Zm46.637,0,9.265-14.021,9.236,14.021H344.36l-14.238-20.852,12.959-19.691h-9.748l-8.184,13.026-8.356-13.026H306.96l12.9,19.387L305.624,165.8h10.089Zm62.524,0v-6.831H357.32v-33.38h-8.413V165.8h29.33Z"
          fill="#273895"
        />
        <path d="M275,72.98l108.08,38H166.92Z"
              fill="#273895"
        />
        <rect x="171" y="181" width="206" height="16"
              fill="#273895"
        />
        <rect x="171" y="203" width="206" height="16"
              fill="#273895"
        />
      </SvgIcon>
    );
  }
)