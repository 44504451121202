import {
  LOGOUT,
} from "../actions/actionTypes";

const initialState = {
};

export default function systemReducer(state = initialState, action) {
  // const {type, payload} = action;
  const {type} = action;
  switch (type) {
    case LOGOUT:
      document.location.reload();
      return {
        ...state,
      };
    default:
      return state;
  }
}
