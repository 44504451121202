const actionFinalResult = ({ status }) => [
  {
    type: "%_FINISH_RESULT_NUMERIC_%",
    action: ({ message }) => {
      const star =
        status._COMMENT_ !== null && status._COMMENT_ !== "" ? ` *` : "";
      return message.replace(
        "%_FINISH_RESULT_NUMERIC_%",
        status._FINAL_RESULT_ + star
      );
    },
  },
];
export default actionFinalResult;
