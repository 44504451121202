import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@material-ui/core';
import CheckIcon from "@material-ui/icons/Check";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    marginBottom: theme.spacing(1),
  },
  title__text: { textAlign: 'center', flexGrow: 2, fontWeight: 'bold' },
  container: { padding: theme.spacing(0) },
  text__bold: { fontWeight: 'bold' },
  text__blue: { color: '#3f51b5', fontWeight: 'bold' },
  text__red: { color: '#8b0000', fontWeight: 'bold' },
  list: { listStyleType: 'none' },
  list_margin: { margin: '0.5em 0 0.5em 0' },
}));

export const MessageDates = memo(() => {
  const classes = useStyles();

  // const text3 = <><span>Прием документов на обучение </span><span style={{color: '#3f51b5', fontWeight: 'bold'}}>за счет средств республиканского бюджета</span><span className="RedSpan"> ЗАВЕРШЕН 24 июля 2022 г</span>.</>;

  return (
    <>
      <div className={classes.title}>
        <Typography className={classes.title__text}>ПРИЕМ ДОКУМЕНТОВ</Typography>
      </div>
      <div className={classes.container}>

        <span className={classes.text__red}>27 ИЮНЯ – 1 ИЮЛЯ</span>
        <ul className={classes.list}>
          <li className={classes.list_margin}>
            на дневную форму получения высшего образования <span className={classes.text__blue}>за счет средств республиканского бюджета</span> для абитуриентов из числа <span className={classes.text__blue}>победителей</span> (диплом I, II, III степени) <span className={classes.text__blue}>многопрофильной университетской олимпиады</span> ГрГУ имени Янки Купалы в 2023/2024 учебного года.
          </li>
        </ul>

        <span className={classes.text__red}>27 ИЮНЯ – 2 ИЮЛЯ</span>
        <ul className={classes.list}>
          <li className={classes.list_margin}>
            для абитуриентов, поступающих для получения высшего образования на условиях целевой подготовки (кроме абитуриентов, поступающих по результатам собеседования).
          </li>
        </ul>

        <span className={classes.text__red}>27 ИЮНЯ – 28 ИЮНЯ</span>
        <ul className={classes.list}>
          <li className={classes.list_margin}>
            для абитуриентов, поступающих по результатам собеседования для получения высшего образования на условиях целевой подготовки.
          </li>
        </ul>

        <span className={classes.text__red}>12 ИЮЛЯ – 17 ИЮЛЯ</span>
        <ul className={classes.list}>
          <li className={classes.list_margin}>
            на все формы получения высшего образования <span className={classes.text__blue}>за счет средств бюджета.</span>
          </li>
          <li className={classes.list_margin}>
            на все формы получения высшего образования <span className={classes.text__blue}>за счет средств бюджета и на платной основе</span> при поступлении <span className={classes.text__red}>на специальности, на которые сдаются вступительные испытания в ГрГУ имени Янки Купалы.</span>
          </li>
        </ul>

        <span className={classes.text__red}>12 ИЮЛЯ – 14 ИЮЛЯ</span>
        <ul className={classes.list}>
          <li className={classes.list_margin}>
            на дневную и заочную формы получения образования <span className={classes.text__red}>для абитуриентов, прошедших обучение в профильных классах (группах) педагогической и спортивно-педагогической направленности</span> учреждений общего среднего образования и поступающих по собеседованию.
          </li>
          <li className={classes.list_margin}>
            на дневную и заочную форму получения образования для абитуриентов, <span className={classes.text__red}>освоивших содержание образовательной программы дополнительного образования одаренных детей и молодежи в период пребывания в учреждении образования «Национальный детский технопарк».</span>
          </li>
        </ul>
        <span className={classes.text__red}>12 ИЮЛЯ – 1 АВГУСТА</span>
        <ul className={classes.list}>
          <li className={classes.list_margin}>
            на все формы получения высшего образования <span className={classes.text__blue}>на платной основе</span>, в том числе для получения второго высшего образования <span className={classes.text__red}>(кроме специальностей, на которые сдаются вступительные испытания в ГрГУ имени Янки Купалы).</span>
          </li>
        </ul>
      </div>
    </>

    // <Grid container spacing={2} justifyContent="center">
    //   <Grid item xs={12}>
    //     {/*<Typography align="center" variant="h6" component="p">УВАЖАЕМЫЙ АБИТУРИЕНТ!</Typography>*/}
    //     <List>
    //       <ListItem>
    //         <ListItemText>
    //           <Typography align="justify" variant="body1" component="p">
    //             <strong>ДЛЯ РЕГИСТРАЦИИ НА УЧАСТИЕ</strong> в Многопрофильной университетской олимпиаде ГрГУ имени Янки
    //             Купалы 2023/2024 года Вам необходимо выбрать <strong>пункт меню «Многопрофильная олимпиада».</strong>
    //           </Typography>
    //         </ListItemText>
    //       </ListItem>
    //       <ListItem>
    //         <ListItemText>
    //           <Typography align="justify" variant="body1" component="p">
    //             <strong>СРОКИ ПРИЕМА ДОКУМЕНТОВ, ПРОВЕДЕНИЯ ВСТУПИТЕЛЬНЫХ ИСПЫТАНИЙ И ЗАЧИСЛЕНИЯ АБИТУРИЕНТОВ в
    //               2024 году</strong>
    //           </Typography>
    //         </ListItemText>
    //       </ListItem>
    //       <ListItem>
    //         <ListItemText>
    //           <Typography align="justify" variant="body1" component="p">
    //             В соответствии с пунктами 15, 16, 19, 28 и 35 Правил приема лиц для получения общего высшего и
    //             специального
    //             высшего образования сроки приема документов, проведения вступительных испытаний и зачисления
    //             абитуриентов
    //             устанавливаются Министерством образования Республики Беларусь ежегодно в год приема.
    //           </Typography>
    //         </ListItemText>
    //       </ListItem>
    //     </List>
    //   </Grid>
    // </Grid>
  );
});