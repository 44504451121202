import React from 'react'
import {DatePicker as MuiDatePicker} from "@material-ui/pickers";

export default function DatePicker(props) {

  const {name, label, value, onChange, error = null, format = "dd.MM.yyyy", ...rest} = props

  const convertToDefEventPara = (name, value) => ({
    target: {
      name, value
    }
  })
  return (
    <MuiDatePicker
      {...rest}
      inputVariant="outlined"
      format={format}
      label={label}
      name={name}
      value={value}
      onChange={date => onChange(convertToDefEventPara(name, date))}
      error={!!error}
      helperText={error}
      cancelLabel={"Отмена"}
      okLabel={"Подтвердить"}
    />
  )
}
