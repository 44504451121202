import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_CONFIRM_SUCCESS,
  REGISTER_CONFIRM_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_TOKENS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_CONFIRM_SUCCESS,
  RESET_PASSWORD_CONFIRM_FAIL,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAIL,
  REFRESH_TOKENS_SUCCESS,
  REFRESH_TOKENS_FAIL,
  SET_TOKENS,
} from "../actions/actionTypes";

const initialState = {isAuthenticated: false, user: null, accessToken: null, refreshToken: null};

export default function authReducer (state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isAuthenticated: false,
      };
    case REGISTER_CONFIRM_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
      };
    case REGISTER_CONFIRM_FAIL:
      return {
        ...state,
        isAuthenticated: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        accessToken: payload.access,
        refreshToken: payload.refresh,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        accessToken: null,
        refreshToken: null,
      };
    case GET_TOKENS:
      return {
        ...state,
        accessToken: payload.access,
        refreshToken: payload.refresh,
      };
    case SET_TOKENS:
      return {
        ...state,
        accessToken: payload.access,
        refreshToken: payload.refresh,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
      };
    case RESET_PASSWORD_CONFIRM_SUCCESS:
      return {
        ...state,
      };
    case RESET_PASSWORD_CONFIRM_FAIL:
      return {
        ...state,
      };
    case GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case GET_USER_ROLES_FAIL:
      return {
        ...state,
      };
    case REFRESH_TOKENS_SUCCESS:
      return {
        ...state,
        accessToken: payload.access,
        refreshToken: payload.refresh,
      };
    case REFRESH_TOKENS_FAIL:
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
      };
    default:
      return state;
  }
}
