import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Container, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography} from '@material-ui/core';
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(1),
  }
}));

function Home(props) {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <Paper className={classes.paper}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <List>
                  <ListItem>
                    <ListItemText>
                      <Typography align="center" variant="h6" component="p">Уважаемый
                        абитуриент!</Typography>
                      <Typography align="justify" variant="body1" component="p">
                        Гродненский государственный университет рад приветствовать вас в числе потенциальных абитуриентов нашего университета и просит пройти регистрацию в <strong>Цифровом кабинете абитуриента</strong>.
                      </Typography>
                      <Typography align="justify" variant="body1" component="p">
                        После регистрации Вы сможете:
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {/* <ListItem>
                    <ListItemText>
                      <ListItem component={"div"}>
                        <ListItemIcon>
                          <CheckIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body1" component="p">
                            <strong>ПРИНЯТЬ УЧАСТИЕ В МНОГОПРОФИЛЬНОЙ УНИВЕРСИТЕТСКОЙ ОЛИМПИАДЕ</strong>
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <Typography align="justify" variant="body1" component="p">
                        Победители (дипломы I, II, III степени) многопрофильной университетской олимпиады ГрГУ имени Янки Купалы в соответствии с п. 23 Правил приема лиц для получения общего высшего и специального высшего образования имеют право на зачисление в ГрГУ имени Янки Купалы в 2024 году без вступительных испытаний при поступлении на специальность(и), соответствующие учебному предмету профильной олимпиады, согласно квоты по данной специальности, устанавливаемой Министерством образования Республики Беларусь.
                      </Typography>
                    </ListItemText>
                  </ListItem> */}
                  <ListItem>
                    <ListItemText>
                      <ListItem component={"div"}>
                        <ListItemIcon>
                          <CheckIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body1" component="p">
                            <strong>ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ ПО
                              ВОПРОСАМ ПОСТУПЛЕНИЯ</strong>
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <Typography align="justify" variant="body1" component="p">
                        В режиме реального времени можно получить ответы на все интересующие
                        вопросы о порядке и условиях поступления на избранную специальность, об
                        организации учебного процесса на факультетах университета, о
                        студенческой жизни, о возможностях самореализации в науке, спорте,
                        творчестве и многое другое.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <ListItem component={"div"}>
                        <ListItemIcon>
                          <CheckIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body1" component="p">
                            <strong>ОТПРАВИТЬ ДОКУМЕНТЫ СО СВОЕГО
                              СМАРТФОНА ИЛИ КОМПЬЮТЕРА</strong>
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <Typography align="justify" variant="body1" component="p">
                        В заявлении на поступление можно заранее внести все личные сведения,
                        сведения о полученном образовании, результаты централизованного экзамена и централизованного
                        тестирования
                        по соответствующим учебным предметам, сведения об имеющихся льготах, а
                        также
                        прикрепить скан- или фотокопии подтверждающих документов. Это позволит приемной комиссии университета провести
                        предварительную проверку документов, а затем Вы лично представите в
                        приемную
                        комиссию весь необходимый для участия в конкурсе пакет документов.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <ListItem component={"div"}>
                        <ListItemIcon>
                          <CheckIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body1" component="p">
                            <strong>ПОЛУЧИТЬ ИНФОРМАЦИЮ О ХОДЕ
                              ОБРАБОТКИ
                              ДОКУМЕНТОВ ПРИЕМНОЙ КОМИССИЕЙ</strong>
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <Typography align="justify" variant="body1" component="p">
                        В режиме реального времени можно получить информацию о ходе проверки
                        прикрепленных документов приемной комиссией университета и при наличии
                        замечаний привести их в полное соответствие с Правилами приема лиц для
                        получения общего высшего и специального высшего образования.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <ListItem component={"div"}>
                        <ListItemIcon>
                          <CheckIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body1" component="p">
                            <strong>РАСПЕЧАТАТЬ ЗАЯВЛЕНИЕ НА УЧАСТИЕ
                              В
                              КОНКУРСЕ И ДОГОВОР НА ОБУЧЕНИЕ</strong>
                          </Typography>
                        </ListItemText>
                      </ListItem>

                      <Typography align="justify" variant="body1" component="p">
                        После обработки документов приемной комиссией можно самостоятельно
                        распечатать заявление на участие в конкурсе по избранной специальности
                        (группе специальностей) и договоры на подготовку специалиста с высшим
                        образованием как за счет средств республиканского бюджета, так и на
                        платной основе.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>

                      <ListItem component={"div"}>
                        <ListItemIcon>
                          <CheckIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body1" component="p">
                            <strong>ЗАРЕГИСТРИРОВАТЬСЯ В ЭЛЕКТРОННОЙ
                              ОЧЕРЕДИ ДЛЯ ЛИЧНОЙ ПОДАЧИ ДОКУМЕНТОВ</strong>
                          </Typography>
                        </ListItemText>
                      </ListItem>

                      <Typography align="justify" variant="body1" component="p">
                        Выбрать удобное время для личной подачи документов в приемную комиссию
                        университета.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>

                      <ListItem component={"div"}>
                        <ListItemIcon>
                          <CheckIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body1" component="p">
                            <strong>ПОЛУЧИТЬ ИНФОРМАЦИЮ О РЕЗУЛЬТАТАХ
                              СДАЧИ ВСТУПИТЕЛЬНЫХ ИСПЫТАНИЙ И ЗАЧИСЛЕНИЯ</strong>
                          </Typography>
                        </ListItemText>
                      </ListItem>

                      <Typography align="justify" variant="body1" component="p">
                        Сразу после внесения отметок в сводную ведомость можно узнать результаты
                        внутривузовских вступительных испытаний, а также получить информацию о
                        решении приемной комиссии по зачислению в число студентов Купаловского
                        университета.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>

            </Grid>

          </Grid>
          {!props.isAuthenticated
            ?
            <>
              <Grid item>
                <Link component={RouterLink} to="/sign-up" variant="body2"
                      style={{textAlign: 'center'}}>
                  {/*<PersonAddIcon/>*/}
                  <Typography
                    variant="body1"
                  >
                    Нет учетной записи? Зарегистрироваться
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link component={RouterLink} to="/sign-in" variant="body2"
                      style={{textAlign: 'center'}}>
                  {/*<ExitToAppIcon/>*/}
                  <Typography
                    variant="body1"
                  >
                    Уже есть аккаунт? Войти в систему
                  </Typography>
                </Link>
              </Grid>
            </>
            : null
          }
        </Grid>
      </Paper>
    </Container>
  );
}

export default Home;
