const actionSpecPriority = ({ spec, arraySpec }) => [
  {
    type: "%_SPEC_PRIORITY_ANY_%",
    action: ({ message }) => {
      return message.replace(
        "%_SPEC_PRIORITY_ANY_%",
        `<ol class="mb-2">` +
          arraySpec.map((e) => `<li>${e}</li>`).join("") +
          `</ol>`
      );
    },
  },
  {
    type: "%_SPEC_PRIORITY_SINGLE_%",
    action: ({ message }) => {
      return message.replace("%_SPEC_PRIORITY_SINGLE_%", spec);
    },
  },
];
export default actionSpecPriority;
