const resolver = ({ messages, actions, status, vars }, store = {}) => {
  return messages.map((message) => {
    let ret = Object.assign(message);

    actions.forEach((action) => {
      try {
        if (message.indexOf(action.type) > -1) {
          ret = action.action(
            {
              message: ret,
              vars: vars,
              status: status,
            },
            store
          );
        }
      } catch (e) {
        console.log("resolver catch");
        console.log(e);
      }
    });

    return ret;
  });
};

export default resolver;
