import React from 'react';
import {useSelector} from 'react-redux';
import {Typography, Button, CircularProgress} from '@material-ui/core';
import './Queue.css';
import {format} from 'date-fns';

const WaitSpinner = () => {
  return (
    <div className="WaitSpinner">
      <CircularProgress/>
    </div>
  )
}

const QueueFrameComponent = ({date, chooseQueue, deleteQueue}) => {

  const queueState = useSelector(state => state.queueReducer);

  return <div className="QueueFrame">
    <div className="QueueFrameItems">
      {
        queueState.currentQueue !== null ?
          <>
            <Typography variant="h6" style={{textAlign: 'center'}}>Ваша очередь</Typography>
            <div className="TimeFrame">
              {
                queueState.loading ? <WaitSpinner/>
                  :
                  queueState.error
                    ?
                    <Typography variant="h6" style={{textAlign: 'center'}}>{queueState.errorText}</Typography>
                    :
                    queueState.currentQueue.map(item => {
                      return (
                        <div
                          className="QueueElement"
                          key={item.item.id}>
                          {item?.faculty?.data?.nameFull} {format(new Date(item.item.time), 'dd.MM.yyyy')} в {item.item.text} {'\u2002'}
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => deleteQueue(item.item.id)}
                          >
                            удалить
                          </Button>
                        </div>)
                    })
              }
            </div>
          </>
          :
          typeof (date.enteredDate) === 'undefined'
            ?
            <Typography variant="h6" style={{textAlign: 'center', color: '#8b0000', fontWeight: "bold"}}>Выберите
              дату!</Typography>
            :
            queueState.loading
              ?
              <WaitSpinner/>
              :
              queueState.error
                ?
                <Typography variant="h6" style={{textAlign: 'center'}}>{queueState.errorText}</Typography>
                :
                queueState.availableQueues.length === 0
                  ?
                  <Typography variant="h6" style={{textAlign: 'center'}}>К сожалению, в этот день доступного времени
                    нет</Typography>
                  :
                  <>
                    <Typography variant="h6" style={{textAlign: 'center'}}>Доступное время
                      на {date.dateOut}</Typography>
                    <div className="TimeFrame">
                      {
                        queueState.availableQueues.length > 0
                          ?
                          queueState.availableQueues.map((item) => {
                            const buttonColor = queueState.currentQueue !== null && queueState.currentQueue.some(queue => {
                              return queue.item.id === item.item.id;
                            })
                              ?
                              'secundary'
                              :
                              'primary';
                            return <div className="QueueElement" key={item.id}>
                              {item.text} {'\u2002'}
                              <Button
                                variant="contained"
                                size="small"
                                color={buttonColor}
                                onClick={() => chooseQueue(item)}>
                                запись
                              </Button></div>
                          })
                          : ''
                      }
                    </div>
                  </>
      }
    </div>
  </div>
};

export default QueueFrameComponent;