import { Grid, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    }
});

function FacultetSubscribesSkeletons(props){
    const {classes} = props;
    return (
        <Grid item lg={12} md={12} xs={12}>
            <List className={classes.root}>
                {[0, 1, 2, 3, 4].map((value) => (
                    <ListItem key={value}>
                        <ListItemIcon>
                            <Skeleton width={20} height={30}/>
                        </ListItemIcon>
                        <ListItemText>
                            <Skeleton width="70%" height={50}/>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <Skeleton width={100} height={70}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Grid>
    )

}
export default withStyles(styles)(FacultetSubscribesSkeletons);