import resolver from "./resolver";
import actionConsultation from "./actionConsultation";
import formatDate from "../datetime/formatDate";

const actionTesting = ({ test }) => [
  // {
  //   type: "%_CONSULTATION_%",
  //   action: ({vars}) => {
  //     const consultationVar = vars.find(item => item.name === "CONSULTATION")
  //     const consultationAction = actionConsultation({test: test})
  //     return resolver({
  //       messages: consultationVar.messages,
  //       actions: consultationAction
  //     })
  //   }
  // },
  // {
  //   type: "%_CONSULTATION_%",
  //   action: ({message}) => {
  //     const consultationAction = actionConsultation({test: test})
  //     return resolver({
  //       messages:  message.replace("%_CONSULTATION_%", test._CONSULTATION_),
  //       actions: consultationAction
  //     })
  //
  //   }
  // },
  {
    type: "%_CONSULTATION_%",
    action: ({ message }) => {
      const link = `<a href="${test._CONSULTATION_LINK_}" target="_blank" class=" pointer">по ссылке</a>`;
      return message
        .replace("%_CONSULTATION_%", test._CONSULTATION_)
        .replace("%_CONSULTATION_LINK_%", link);
    },
  },
  {
    type: "%_CONSULTATION_LINK_%",
    action: ({ message }) => {
      return message.replace("%_CONSULTATION_LINK_%", test._CONSULTATION_LINK_);
    },
  },
  {
    type: "%_DISCIPLINE_TITLE_%",
    action: ({ message }) => {
      return message.replace("%_DISCIPLINE_TITLE_%", test._TEST_TITLE_);
    },
  },
  {
    type: "%_TEST_TYPE_%",
    action: ({ message }) => {
      return message.replace("%_TEST_TYPE_%", test._TEST_TYPE_);
    },
  },
  {
    type: "%_TEST_DATETIME_%",
    action: ({ message }) => {
      return message.replace(
        "%_TEST_DATETIME_%",
        "<strong>" +
          formatDate(test._DATE_TEST_) +
          " в " +
          test._TIME_TEST_PERSONAL_ +
          "</strong>"
      );
    },
  },
  {
    type: "%_TEST_ADDRESS_%",
    action: ({ message }) => {
      return message.replace(
        "%_TEST_ADDRESS_%",
        "<strong>" + test._PLACE_TEST_ + ", " + test._TEST_ROOM_ + "</strong>"
      );
    },
  },
];
export default actionTesting;
