import React from 'react'
import {TextField, Tooltip} from '@material-ui/core';

export default function Input(props) {

  const {name, label, value, error = null, onChange,tooltipTitle = '', placeholder = '', ...rest} = props;
  return (
    <Tooltip title={tooltipTitle} arrow>
      <TextField
        {...rest}
        variant="outlined"
        label={label}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        {...(error && {error: true, helperText: error})}
      />
    </Tooltip>
  )
}
