import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import Valid from "../../../../../constants/Valid";
import {Form, useForm} from "../../../../../components/Customs/useForm";
import Controls from "../../../../../components/Customs/Controls/Controls";
import {Button, Card, CardContent, CardHeader, Container, Divider, Grid, Typography} from "@material-ui/core";

import {useDispatch, useSelector} from "react-redux";
import {
  EDUCATION_INSTITUTIONS_TYPE,
  EDUCATION_INSTITUTIONS_NAME,
  EDUCATION_INSTITUTIONS_BALL_TYPE,
  EDUCATION_INSTITUTIONS_POINT,
  EDUCATION_INSTITUTIONS_DATE,
  EDUCATION_INSTITUTIONS_LANG,
  EDUCATION_INSTITUTIONS_TRAINING, TRAINING_STUDY_FORM, EDUCATION_INSTITUTIONS_TYPE_DOCUMENT,
} from "../../../../../services/tagTypes";
import {
  deleteContractFileStatement,
  deleteTypeCompetitionStatement,
  getDataStatement,
  setDataStatement
} from "../../../../../store/Statement/actionStatement";
import FileLoadingForm from "../../../../../components/FileLoading";
import {chatAction_createNotification} from "../../../../../store/Chat/ChatActions";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': {transform: "scale(1)"},
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
}));

const initialFValues = {
  typeInstitutionEducation: "",
  nameInstitutionEducation: "",
  gradingScaleInstitutionEducation: "10",
  pointInstitutionEducation: "",
  dateInstitutionEducation: null,
  languageInstitutionEducation: "",
  documentTypeInstitutionEducation: "none",
  profileClassInstitutionEducation: "false",

};

function Education() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const arrayGradingScale = [
    {title: '5 балльная шкала оценки', id: '5'},
    {title: '10 балльная шкала оценки', id: '10'},
    {title: '12 балльная шкала оценки', id: '12'},
  ];

  const arrayInstitutionEducation = [
    {title: 'Средняя школа', id: "HIGH_SCHOOL"},
    {title: 'Лицей', id: "LYCEUM"},
    {title: 'Гимназия', id: "GYMNASIUM"},
    {title: 'Колледж (УССО)', id: "COLLEGE_USSO"},
    {title: 'Колледж (УПТО) на базе 9 классов', id: "COLLEGE_UPTO_9"},
    {title: 'Колледж (УПТО) на базе 11 классов', id: "COLLEGE_UPTO_11"},
    {title: 'Учреждение высшего образования', id: "HIGHER_EDUCATION_INSTITUTION"},
    {title: 'Учреждение высшего образования (незаконченное)', id: "HIGHER_EDUCATION_INSTITUTION_NULL"},
  ];

  const arrayLanguageInstitutionEducation = useSelector(state => state.referenceBooksReducer.languageBooksReducer);
  const statementStatusFormsApplication = useSelector(state => state.statementReducer.statementStatusFormsApplication);
  const statementTypeCompetition = useSelector(state => state.statementReducer.typeCompetition);
  const statementContractFile = useSelector(state => state.statementReducer.contractFile);
  const selectedTypeTrainingStatement = useSelector(state => state.statementReducer.selectedTypeTrainingStatement);

  const [arrayDocumentTypeInstitutionEducation, setArrayDocumentTypeInstitutionEducation] = useState([{
    title: 'не имею',
    id: "none"
  }]);
  const [arrayProfileClassInstitutionEducation, setArrayProfileClassInstitutionEducation] = useState([{
    title: 'нет',
    id: "false"
  }]);
  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [firstStart, setFirstStart] = useState(true);

  useEffect(() => {
    if (!render) return;
    setRender(false);
  }, [render]);

  const onReloadInformationEducation = (item) => {

    let typeInstitutionEducation = item.find(data => data.typeTitle === EDUCATION_INSTITUTIONS_TYPE);
    values.typeInstitutionEducation = (typeInstitutionEducation !== undefined ? typeInstitutionEducation.value.value : '');

    let nameInstitutionEducation = item.find(data => data.typeTitle === EDUCATION_INSTITUTIONS_NAME);
    values.nameInstitutionEducation = (nameInstitutionEducation !== undefined ? nameInstitutionEducation.value.value : '');

    let gradingScaleInstitutionEducation = item.find(data => data.typeTitle === EDUCATION_INSTITUTIONS_BALL_TYPE);
    if (gradingScaleInstitutionEducation !== undefined) values.gradingScaleInstitutionEducation = gradingScaleInstitutionEducation.value.value;

    let pointInstitutionEducation = item.find(data => data.typeTitle === EDUCATION_INSTITUTIONS_POINT);
    if (pointInstitutionEducation !== undefined) values.pointInstitutionEducation = pointInstitutionEducation.value.value;

    let dateInstitutionEducation = item.find(data => data.typeTitle === EDUCATION_INSTITUTIONS_DATE);
    if (dateInstitutionEducation !== undefined) values.dateInstitutionEducation = dateInstitutionEducation.value.value;

    let languageInstitutionEducation = item.find(data => data.typeTitle === EDUCATION_INSTITUTIONS_LANG);
    values.languageInstitutionEducation = (languageInstitutionEducation !== undefined && arrayLanguageInstitutionEducation.length > 0 ? arrayLanguageInstitutionEducation.find(data => data.id === languageInstitutionEducation.value.id) : '');

    let profileClassInstitutionEducation = item.find(data => data.typeTitle === EDUCATION_INSTITUTIONS_TRAINING);
    if (profileClassInstitutionEducation !== undefined) values.profileClassInstitutionEducation = profileClassInstitutionEducation.value.value;

    let documentTypeInstitutionEducation = item.find(data => data.typeTitle === EDUCATION_INSTITUTIONS_TYPE_DOCUMENT);
    if (documentTypeInstitutionEducation !== undefined) values.documentTypeInstitutionEducation = documentTypeInstitutionEducation.value.value;


    // if (documentType !== undefined) setFirstStart(false);
    setRender(!render);
  }

  useEffect(() => {
    const getDataEducation = async () => {
      const arrayEducationParams = [
        `type[]=${EDUCATION_INSTITUTIONS_TYPE}`,
        `type[]=${EDUCATION_INSTITUTIONS_NAME}`,
        `type[]=${EDUCATION_INSTITUTIONS_BALL_TYPE}`,
        `type[]=${EDUCATION_INSTITUTIONS_POINT}`,
        `type[]=${EDUCATION_INSTITUTIONS_DATE}`,
        `type[]=${EDUCATION_INSTITUTIONS_LANG}`,
        `type[]=${EDUCATION_INSTITUTIONS_TRAINING}`,
        `type[]=${EDUCATION_INSTITUTIONS_TYPE_DOCUMENT}`,
        `type[]=${TRAINING_STUDY_FORM}`,
      ];
      const educationParams = arrayEducationParams.join('&');
      const result = await dispatch(getDataStatement(educationParams));
      onReloadInformationEducation(result);
    };
    getDataEducation();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (validate()) {
      await onSaver(values)
      setIsLoading(false);
      history.push("/statement")
    } else {
      setIsLoading(false);
    }
  };

  const onSaver = async (data) => {
    const sendObject = {
      EDUCATION_INSTITUTIONS_TYPE: {value: data.typeInstitutionEducation},
      EDUCATION_INSTITUTIONS_NAME: {value: data.nameInstitutionEducation},
      EDUCATION_INSTITUTIONS_BALL_TYPE: {value: data.gradingScaleInstitutionEducation},
      EDUCATION_INSTITUTIONS_POINT: {value: data.pointInstitutionEducation},
      EDUCATION_INSTITUTIONS_DATE: {value: data.dateInstitutionEducation},
      EDUCATION_INSTITUTIONS_LANG: data.languageInstitutionEducation,
      EDUCATION_INSTITUTIONS_TRAINING: {value: data.profileClassInstitutionEducation},
      EDUCATION_INSTITUTIONS_TYPE_DOCUMENT: {value: data.documentTypeInstitutionEducation},
      ANKETA_STATUS_FORMS_APPLICATION: {
        ...statementStatusFormsApplication,
        3: {"status": "IS_FULL", "coment": ""},
        9: {"action": "3"}
      },
      ANKETA_STATUS: {"value": "IS_WORK"}
    }
    try {
      await dispatch(setDataStatement(sendObject));
      dispatch(chatAction_createNotification({
        message: 'Данные формы сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      if (error.data.detail === "lock_application_in_asu") {
        dispatch(chatAction_createNotification({
          message: 'Форма заблокирована для изменений.',
          variant: 'error'
        }));
      } else {
        console.log(error);
        dispatch(chatAction_createNotification({
          message: 'Ошибка сохранения данных формы.',
          variant: 'error'
        }));
      }
    }
    if (statementTypeCompetition?.id) await dispatch(deleteTypeCompetitionStatement());
    if (statementContractFile?.id) await dispatch(deleteContractFileStatement());
  }

  const validate = (fieldValues = values) => {
    let temp = {...errors};
    if ('typeInstitutionEducation' in fieldValues) {
      temp.typeInstitutionEducation = Valid.isIsset(fieldValues.typeInstitutionEducation)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('nameInstitutionEducation' in fieldValues) {
      temp.nameInstitutionEducation = Valid.isIsset(fieldValues.nameInstitutionEducation)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('gradingScaleInstitutionEducation' in fieldValues) {
      temp.gradingScaleInstitutionEducation = Valid.isIsset(fieldValues.gradingScaleInstitutionEducation)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if (!isTargetTraining()) {
      if ('pointInstitutionEducation' in fieldValues) {
        temp.pointInstitutionEducation = Valid.isIsset(fieldValues.pointInstitutionEducation)
          ? Valid.regexAveragePoint(fieldValues.pointInstitutionEducation)
            ? ''
            : 'Доступны целые числа и числа с одним десятичным знаком!'
          : 'Это поле обязательное для заполнения!';
      }
    }
    if ('dateInstitutionEducation' in fieldValues) {
      temp.dateInstitutionEducation = Valid.isIsset(fieldValues.dateInstitutionEducation)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('languageInstitutionEducation' in fieldValues) {
      temp.languageInstitutionEducation = Valid.isIsset(fieldValues.languageInstitutionEducation)
        ? ''
        : 'Это поле обязательное для заполнения!';
    }
    if ('profileClassInstitutionEducation' in fieldValues) {
      if (!hideProfileClassInstitutionEducation()) {
        temp.profileClassInstitutionEducation = Valid.isIsset(String(fieldValues.profileClassInstitutionEducation))
          ? ''
          : 'Это поле обязательное для заполнения!';
      }
    }
    if ('documentTypeInstitutionEducation' in fieldValues) {
      if (!hideDocumentTypeInstitutionEducation()) {
        temp.documentTypeInstitutionEducation = Valid.isIsset(String(fieldValues.documentTypeInstitutionEducation))
          ? ''
          : 'Это поле обязательное для заполнения!';
      }
    }

    setErrors({...temp});
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '')
    }
  };

  const hideProfileClassInstitutionEducation = () => {
    return !isPTUInstitutionEducation();
  }
  const hideDocumentTypeInstitutionEducation = () => {
    return !isPTUInstitutionEducation() && !isCollegeInstitutionEducation();
  }

  const isPTUInstitutionEducation = () => {
    return (values.typeInstitutionEducation === 'HIGH_SCHOOL' || values.typeInstitutionEducation === 'LYCEUM' || values.typeInstitutionEducation === 'GYMNASIUM');
  }

  const isCollegeInstitutionEducation = () => {
    return (values.typeInstitutionEducation === 'COLLEGE_USSO' || values.typeInstitutionEducation === 'COLLEGE_UPTO_9' || values.typeInstitutionEducation === 'COLLEGE_UPTO_11');
  }

  const isTargetTraining = () => {
    return (selectedTypeTrainingStatement === 'targetTraining');
  }

  const clearProfileClassInstitutionEducation = () => {
    values.profileClassInstitutionEducation = "false";
  }
  const clearDocumentTypeInstitutionEducation = () => {
    values.documentTypeInstitutionEducation = "none";
    setRender(!render);
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFValues, true, validate);

  useEffect(() => {
    if (hideProfileClassInstitutionEducation()) {
      clearProfileClassInstitutionEducation();
    }
    if (hideDocumentTypeInstitutionEducation()) {
      clearDocumentTypeInstitutionEducation();
    }
    if (isPTUInstitutionEducation()) {
      setArrayDocumentTypeInstitutionEducation([
        {title: 'не имею', id: "none"},
        {
          title: 'имею аттестат об общем среднем образовании особого образца с награждением золотой медалью',
          id: "gold"
        },
        {
          title: 'имею аттестат об общем среднем образовании особого образца с награждением серебряной медалью',
          id: "silver"
        },
      ]);
      setArrayProfileClassInstitutionEducation([
        {title: 'нет', id: "false"},
        {title: 'педагогической направленности', id: "pedagogical"},
        {title: 'аграрной направленности', id: "agricultural"},
        {title: 'спортивно-педагогической направленности', id: "sports"},
        {title: 'военно-патриотической направленности', id: "military"},
        {title: 'инженерной направленности', id: "engineer"},
      ]);
    }
    if (isCollegeInstitutionEducation()) {
      setArrayDocumentTypeInstitutionEducation([
        {title: 'не имею', id: "none"},
        {title: 'имею диплом о среднем специальном/профессионально-техническом образовании с отличием', id: "red"},
      ]);
    }

  }, [values.typeInstitutionEducation])

  useEffect(() => {
    const findDocType =  arrayDocumentTypeInstitutionEducation.find(obj => {
      return obj.id === values.documentTypeInstitutionEducation
    })
    if (findDocType === undefined) {
      clearDocumentTypeInstitutionEducation();
    }
  }, [arrayDocumentTypeInstitutionEducation])

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} justifyContent="center" className={classes.grid_container}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader title="Образование"/>
            <Divider/>
            <CardContent>
              <Form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Controls.Select
                      fullWidth
                      name="typeInstitutionEducation"
                      label="Оконченное учреждение образования*"
                      value={values.typeInstitutionEducation}
                      options={arrayInstitutionEducation}
                      onChange={handleInputChange}
                      error={errors.typeInstitutionEducation}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.Input
                      fullWidth
                      name="nameInstitutionEducation"
                      label="Полное название учреждения образования (как в документе)*"
                      value={values.nameInstitutionEducation}
                      onChange={handleInputChange}
                      error={errors.nameInstitutionEducation}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.Select
                      fullWidth
                      name="gradingScaleInstitutionEducation"
                      label="Балльная шкала оценок*"
                      value={values.gradingScaleInstitutionEducation}
                      options={arrayGradingScale}
                      onChange={handleInputChange}
                      error={errors.gradingScaleInstitutionEducation}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.Input
                      fullWidth
                      type={'number'}
                      name="pointInstitutionEducation"
                      label={isTargetTraining() ? "Средний балл документа об образовании" :"Средний балл документа об образовании*"}
                      InputProps={{inputProps: {min: "0", max: "12", step: "0.1"}}}
                      value={values.pointInstitutionEducation}
                      onChange={handleInputChange}
                      error={errors.pointInstitutionEducation}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.DatePicker
                      fullWidth
                      format={"yyyy"}
                      name="dateInstitutionEducation"
                      label="Год окончания УО*"
                      value={values.dateInstitutionEducation}
                      onChange={handleInputChange}
                      error={errors.dateInstitutionEducation}
                      disableFuture
                      openTo="year"
                      views={["year"]}
                      minDate={new Date('1950-01-01')}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controls.SelectCitizenship
                      fullWidth
                      name="languageInstitutionEducation"
                      label="Изучал(а) иностранный язык*"
                      value={values.languageInstitutionEducation}
                      options={arrayLanguageInstitutionEducation}
                      onChange={handleInputChange}
                      error={errors.languageInstitutionEducation}
                    />
                  </Grid>
                  {
                    hideProfileClassInstitutionEducation()
                      ? null
                      :
                      <Grid item md={6} xs={12}>
                        <Controls.Select
                          fullWidth
                          name="profileClassInstitutionEducation"
                          label="прошел обучение в профильном классе (группе)*"
                          value={values.profileClassInstitutionEducation}
                          onChange={handleInputChange}
                          error={errors.profileClassInstitutionEducation}
                          options={arrayProfileClassInstitutionEducation}
                        />
                      </Grid>

                  }
                  {
                    hideDocumentTypeInstitutionEducation()
                      ? null
                      :
                      <Grid item md={6} xs={12}>
                        <Controls.Select
                          fullWidth
                          name="documentTypeInstitutionEducation"
                          label="Особый образец документа об образовании*"
                          value={values.documentTypeInstitutionEducation}
                          onChange={handleInputChange}
                          error={errors.documentTypeInstitutionEducation}
                          options={arrayDocumentTypeInstitutionEducation}
                        />
                      </Grid>

                  }
                  <Grid item md={12} xs={12}>
                    <FileLoadingForm
                      tag={'EDUCATION_FILE_EDUCATIONS'}
                      labelText={'Загрузка файлов документа об образовании с выпиской (при наличии)'}
                      labelForm={'Перетащите или <span class="filepond--label-action" tabindex="0">прикрепите</span> сканы (фото) соответствующих документов'}
                      size={5}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FileLoadingForm
                      tag={'EDUCATION_FILE_CHARACTERISTICS'}
                      labelText={
                        <>
                          <Typography variant="h6" component="h6" align="center">
                            Загрузка файлов характеристики для выпускников 2024 года
                          </Typography>
                          <Typography variant="subtitle1" component="h6" align="center">
                            (может быть предоставлена лично в приемную комиссию)
                          </Typography>
                        </>
                        }
                      labelForm={'Перетащите или <span class="filepond--label-action" tabindex="0">прикрепите</span> сканы (фото) соответствующих документов'}
                      size={5}
                    />
                  </Grid>
                  {isTargetTraining() ?
                    <Grid item md={12} xs={12}>
                      <FileLoadingForm
                        tag={'EDUCATION_FILE_RECOMMENDATION'}
                        labelText={'Загрузка файлов рекомендации педагогического совета'}
                        labelForm={'Перетащите или <span class="filepond--label-action" tabindex="0">прикрепите</span> сканы (фото) соответствующих документов'}
                        size={5}
                      />
                    </Grid>
                    : null
                  }
                  <Grid item container xs={12} justifyContent={"space-between"}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => {
                        history.push("/statement")
                      }}
                      disabled={isLoading}
                    >
                      Назад
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isLoading}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Education;