import React from 'react'
import {FormControl, FormControlLabel, Checkbox as MuiCheckbox, FormHelperText} from '@material-ui/core';

export default function Checkbox(props) {

  const {name, label, value, onChange, disabled, error = null} = props;


  const convertToDefEventPara = (name, value) => ({
    target: {
      name, value
    }
  })

  return (
    <FormControl disabled={disabled} {...(error && {error: true})}>
      <FormControlLabel
        control={<MuiCheckbox
          name={name}
          color="primary"
          checked={value}
          onChange={e => onChange(convertToDefEventPara(name, e.target.checked))}
        />}
        label={label}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
