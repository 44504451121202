import {
  CREATE_QUEUE_REQUEST,
  CREATE_QUEUE_SUCCESS,
  CREATE_QUEUE_FAIL,
  GET_CURRENT_QUEUE_REQUEST,
  GET_CURRENT_QUEUE_SUCCESS,
  GET_CURRENT_QUEUE_FAIL,
  DELETE_CURRENT_QUEUE_REQUEST,
  DELETE_CURRENT_QUEUE_SUCCESS,
  DELETE_CURRENT_QUEUE_FAIL,
  LOGOUT,
  FETCH_AVAILABLE_QUEUES_REQUEST,
  FETCH_AVAILABLE_QUEUES_SUCCESS,
  FETCH_AVAILABLE_QUEUES_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: false,
  errorText: 'Произошла ошибка, попробуйте еще раз',
  availableQueues: [],
  currentQueue: null
};

const queueReducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case FETCH_AVAILABLE_QUEUES_REQUEST:
      return {...state, loading: payload, availableQueues: [], error: false};
    case FETCH_AVAILABLE_QUEUES_SUCCESS:
      return {...state, loading: false, availableQueues: payload, error: false};
    case FETCH_AVAILABLE_QUEUES_FAILURE:
      return {...state, loading: false, availableQueues: [], error: payload};
    case CREATE_QUEUE_REQUEST:
      return {...state, currentQueue: null, loading: payload, error: false};
    case CREATE_QUEUE_SUCCESS:
      return {...state, currentQueue: payload, loading: false, error: false};
    case CREATE_QUEUE_FAIL:
      return {...state, currentQueue: null, loading: false, error: payload};
    case GET_CURRENT_QUEUE_REQUEST:
      return {...state, loading: payload, currentQueue: null, error: false};
    case GET_CURRENT_QUEUE_SUCCESS:
      return {...state, currentQueue: payload, loading: false, error: false};
    case GET_CURRENT_QUEUE_FAIL:
      return {...state, loading: false, currentQueue: null, error: payload};
    case DELETE_CURRENT_QUEUE_REQUEST:
      return {...state, loading: true, error: false};
    case DELETE_CURRENT_QUEUE_SUCCESS:
      return {...state, loading: false, error: false, currentQueue: payload};
    case DELETE_CURRENT_QUEUE_FAIL:
      return {...state, loading: false, error: payload, currentQueue: null};
    case LOGOUT:
      return {
        ...state,
        currentQueue: null,
        availableQueues: [],
      };
    default:
      return state;
  }
}

export default queueReducer;