import React from 'react'
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  Chip,
  Checkbox, ListItemText, Grid
} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

export default function SelectPrivileges(props) {

  const {name, label, value, error = null, onChange, options, disabled, ...rest} = props;

  return (
    <FormControl
      variant="outlined"
      disabled={disabled}
      fullWidth={rest.fullWidth ? rest.fullWidth : false}
      {...(error && {error: true})}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        autoWidth={true}
        label={label}
        name={name}
        value={value}
        onChange={(e) => onChange(e, options)}
        multiple
        renderValue={(selected) => (
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}>
            {selected.map((value, index) => (
              // console.log('SelectPrivileges value: ', value)
              <Chip key={value.id + ' ' + index} label={value?.data?.title} style={{
                margin: 2,
              }}/>
            ))}
          </div>
        )}
      >
        {
          options && options.length > 0
            ?
            options.map((item) => {
                return <MenuItem key={item.id + (new Date().getUTCDate())} value={item} style={{maxWidth: '80vw',whiteSpace: "normal"}}>
                  {/*<Checkbox checked={value.indexOf(item) > -1}/>*/}
                  <Checkbox checked={value.find((entry) => entry.id === item.id) !== undefined}/>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid item xs={12}>
                      <Typography style={{whiteSpace: "normal"}} align="justify" variant="body1"
                                  component="p">{item.data.title}</Typography>
                    </Grid>

                    {item.data?.privilege ?
                      item.data?.privilege.map((priv, key) => {
                        let title = priv?.span;
                        // console.log(key)
                        return title ?
                          <Grid item xs={12} key={key + title + (new Date().getUTCDate())}>

                            <Typography align="justify" variant="subtitle2" component="p"
                                        key={key + title + (new Date().getUTCDate())}>{title}</Typography>
                            <Typography align="justify" variant="subtitle2" component="div"
                                        key={title + (new Date().getUTCDate())}>
                              {
                                priv?.other.map((point, index) =>
                                  <ListItemText
                                    key={index + point.message + (new Date().getUTCDate())}
                                    secondary={point.message}
                                    style={{whiteSpace: "normal"}}/>
                                )
                              }
                            </Typography>
                          </Grid>
                          : null
                      })
                      : null
                    }
                  </Grid>

                </MenuItem>
              }
            )
            :
            <MenuItem disabled value={''}>
              <ListItemText
                primary={'Данные отсутствуют'}
                style={{whiteSpace: "normal"}}
              />
            </MenuItem>
        }
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}