import React from 'react'
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@material-ui/core';

export default function RadioGroup(props) {

  const {name, label, value, onChange, error = null, items, fullWidth, ...rest} = props;

  return (
    <FormControl {...(error && {error: true})} fullWidth={fullWidth}>
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup
        {...rest}
        name={name}
        value={String(value)}
        onChange={onChange}
      >
        {
          Array.isArray(items) && items.length > 0 ?
            items.map((entry) => (
              <FormControlLabel key={entry.id} value={entry.id} control={<Radio/>} label={entry.title}/>
            )) :
            <FormControlLabel disabled={true} value={''} control={<Radio/>} label='Данные отсутствуют'/>
        }
      </MuiRadioGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
