import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  ListItemText, Typography
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Link from "@material-ui/core/Link";
import { PROFILE_USER_DATA_DELETE, PROFILE_USER_MANYDATA_POST } from "../../../store/actions/profile";
import { chatAction_createNotification } from "../../../store/Chat/ChatActions";
import { useDispatch, useSelector } from "react-redux";
import { GetOlympiadAuthURL } from "../../../store/Olympiads/actionOlympiads";

const useStyles = makeStyles((theme) => ({
  submit: {
    transition: '1s all',
    '&:hover': { transform: "scale(1)" },
  },
  grid_container: {
    marginTop: theme.spacing(1.5),
  },
  list_item_divider: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  information: {
    marginTop: theme.spacing(2.5),
    width: "100%",
  },
}));

function OlympiadItem(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { val, tagData, isSelected, savedTagsId, getData, secondYes, setDisableAllButton, disableButton } = props;
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.authReducer.user);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const onDelete = async (data) => {
    try {
      setIsLoading(true);
      // console.log(data);
      // console.log(savedTagsId[data.id]);
      await dispatch(PROFILE_USER_DATA_DELETE(savedTagsId[data.id]));
      await getData();
      setIsLoading(false);
      dispatch(chatAction_createNotification({
        message: 'Данные удалены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      console.log(error);
      dispatch(chatAction_createNotification({
        message: 'Ошибка удалены данных.',
        variant: 'error'
      }));
    }
  }

  const onSaver = async (data) => {
    try {
      setIsLoading(true);
      // console.log(data);
      const sendObject = {
        OLYMPIADS_SELECTED: { value: data },
      }
      await dispatch(PROFILE_USER_MANYDATA_POST(sendObject));
      await getData();
      setIsLoading(false);
      dispatch(chatAction_createNotification({
        message: 'Данные сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      console.log(error);
      dispatch(chatAction_createNotification({
        message: 'Ошибка сохранения данных.',
        variant: 'error'
      }));
    }
  }

  const onSaverFinalStage = async (data, cancel = false) => {
    try {
      setDisableAllButton(true);
      setIsLoading(true);
      // console.log(data);
      let sendObject = {
        OLYMPIADS_SELECTED: { value: data?.value?.value, firstResult: data?.value?.firstResult, second_yes: true },
      };
      if (cancel) sendObject = {
        OLYMPIADS_SELECTED: { value: data?.value?.value, firstResult: data?.value?.firstResult, second_yes: false },
      }
      // console.log(sendObject);
      await dispatch(PROFILE_USER_MANYDATA_POST(sendObject));
      await dispatch(PROFILE_USER_DATA_DELETE(data.id));
      await getData();
      setIsLoading(false);
      setDisableAllButton(false);
      dispatch(chatAction_createNotification({
        message: 'Данные сохранены успешно.',
        variant: 'success'
      }));
    } catch (error) {
      console.log(error);
      dispatch(chatAction_createNotification({
        message: 'Ошибка сохранения данных.',
        variant: 'error'
      }));
    }
  }
  const onClickLink = async (data) => {
    try {
      setIsLoading(true);
      const result = await dispatch(GetOlympiadAuthURL(data.id));
      window.open(result.url);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      dispatch(chatAction_createNotification({
        message: 'Ошибка перенаправления.',
        variant: 'error'
      }));
    }
  }
  const dateString = (string, isJustTime = false, isTimeDate = false) => {
    if (string === undefined || string === null) return '...';
    let date = new Date(`${string}`);
    let formatter = new Intl.DateTimeFormat("ru", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    let result = formatter.formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
    if (isJustTime) return `${result.hour}:${result.minute}`;
    if (isTimeDate) return `${result.hour}:${result.minute} ${result.day}.${result.month}.${result.year}`;
    return `${result.day}.${result.month}.${result.year}`;
  }

  const isAdmitted = () => {
    return (val?.data?.checkpoint > 0 && val?.data?.checkpoint <= tagData?.value?.firstResult);
  }
  const isFinalStageSelected = () => {
    return (tagData?.value?.second_yes);
  }

  //todo -1 day set
  const showLink = () => {
    let date = new Date(val?.dateOfTheEvent);
    date.setDate(date.getDate() - 1);
    return Date.now() >= date || (user.roles.includes('ROLE_ADMIN'));
  }

  const hideCancelButton = () => {
    return Date.now() <= new Date(val?.disabledAt);
  }
  const isEventEnd = () => {
    // console.log("check is_result_event_end")
    return Date.now() > new Date(val?.endOfTheEvent);
  }
  const isResultTimeEnd = () => {
    // console.log("check is_result_time_end")
    return Date.now() > new Date(val?.data?.dateResultsViewing);
  }

  const showResult = () => {
    // console.log(tagData)
    if (!isResultTimeEnd()) {
      return null;
    }
    return tagData?.value?.firstResult;
  }

  const showFinalStage = () => {
    return ((Date.now() >= new Date(val?.data?.dateAdmissionFinalStage)) && val?.data?.checkpoint > 0);
  }

  const isDisabledAtTheFinalEvent = () => {
    return Date.now() < new Date(val?.data?.disabledAtTheFinalEvent);
  }

  const isDateResultsTheFinalEvent = () => {
    return Date.now() > new Date(val?.data?.dateResultsTheFinalEvent);
  }
  const isDatePrize = () => {
    return Date.now() > new Date(val?.data?.datePrize);
  }
  const showCongratulation = () => {
    return tagData?.value?.secondResult && val?.data?.secondCheckpoint3 && tagData?.value?.secondResult >= val?.data?.secondCheckpoint3;
  }

  const prizeResult = () => {
    if (tagData?.value?.secondResult >= val?.data?.secondCheckpoint1) return 1;
    if (tagData?.value?.secondResult < val?.data?.secondCheckpoint1 && tagData?.value?.secondResult >= val?.data?.secondCheckpoint2) return 2;
    if (tagData?.value?.secondResult < val?.data?.secondCheckpoint2 && tagData?.value?.secondResult >= val?.data?.secondCheckpoint3) return 3;
  }

  return (
    <Grid item xs={12} key={val.id} className={classes.list_item_divider}>
      <Grid container spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={12} sm={9}>
          <Typography component="span" variant="body1" color="textPrimary">
            <strong>{val?.data?.title}</strong>
          </Typography>

          <List dense>
            <Typography component="span" variant="body2" color="textPrimary">
              Специальности:
            </Typography>
            {val?.facultySpecialities.map((value) =>
              <ListItem key={value.id}>
                <ListItemText style={{ margin: 0 }} primary={
                  <Link color="primary" target="_blank" rel="noopener" href={value?.speciality?.data?.link}>
                    {value?.speciality?.data?.name}
                  </Link>}
                />
              </ListItem>
            )}

            {
              showFinalStage() ?
                isAdmitted() ?
                  <>
                    {!isDateResultsTheFinalEvent() &&
                      <>
                        <Typography component="p" variant="body2" color="textPrimary">
                          <strong>Вы допущены к участию в заключительном этапе</strong>
                        </Typography>
                        <Typography component="p" variant="body2" color="textPrimary">
                          В предварительном этапе Вы набрали баллов: {tagData?.value?.firstResult ?? 0}
                        </Typography>
                        <Typography component="p" variant="body2" color="textPrimary">
                          Проходной балл: {val?.data?.checkpoint}
                        </Typography>
                        <Typography component="p" variant="body2" color="textPrimary">
                          Вам необходимо подтвердить свое участие в заключительном этапе одной профильной олимпиады
                          до {dateString(val?.data?.disabledAtTheFinalEvent, false, true)}
                        </Typography>
                      </>
                    }
                    {tagData?.value?.second_yes === true ?
                      !isDateResultsTheFinalEvent() ?
                        <>
                          <Typography component="p" variant="body2" color="textPrimary">
                            Дата проведения заключительного этапа:  {dateString(val?.data?.dateTheFinalEvent)}
                          </Typography>
                          <Typography component="p" variant="body2" color="textPrimary">
                            Время регистрации участников: с {dateString(val?.data?.startRegistrationTheFinalEvent, true)}
                          </Typography>
                          <Typography component="p" variant="body2" color="textPrimary">
                            Место проведения в соответствии с <Link color="primary" target="_blank" rel="noopener" href="https://abit.grsu.by/images/documents/%D0%9C%D0%BD%D0%BE%D0%B3%D0%BE%D0%BF%D1%80%D0%BE%D1%84%D0%B8%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F_%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%B0/2024/%D0%A0%D0%B0%D1%81%D0%BF%D0%B8%D1%81%D0%B0%D0%BD%D0%B8%D0%B5_2024_%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%B0.pdf">расписанием</Link>
                          </Typography>
                          <Typography component="p" variant="body2" color="textPrimary">
                            Начало заключительного этапа олимпиады: {dateString(val?.data?.dateTheFinalEvent, true)}
                          </Typography>
                          <Typography component="p" variant="body2" color="textPrimary">
                            С результатами выполнения олимпиадных заданий Вы можете ознакомиться с {dateString(val?.data?.dateResultsTheFinalEvent, false, true)}
                          </Typography>
                        </>
                        :
                        !isDatePrize() ?
                          <>
                            <Typography component="p" variant="body2" color="textPrimary">
                              В заключительном этапе Вы набрали {tagData?.value?.secondResult ?? 0} баллов из {val?.data?.secondMax ?? 0}
                            </Typography>
                            <Typography component="p" variant="body2" color="textPrimary">
                              В случае несогласия с выставленными баллами Вы можете обратиться с письменным заявлением (<Link color="primary" target="_blank" rel="noopener" href="https://abit.grsu.by/images/documents/%D0%9C%D0%BD%D0%BE%D0%B3%D0%BE%D0%BF%D1%80%D0%BE%D1%84%D0%B8%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F_%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%B0/2024/2024_%D0%97%D0%B0%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BD%D0%B0_%D0%B0%D0%BF%D0%B5%D0%BB%D0%BB%D1%8F%D1%86%D0%B8%D1%8E_%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%B5%D1%86.docx">апелляцией</Link>) с 11:00 {dateString(val?.data?.dateResultsTheFinalEvent)} лично (ул. Ожешко, 22, каб. 101) или по электронной почте организационного комитета олимпиады <Link color="primary" target="_blank" rel="noopener" href="mailto:school@grsu.by">school@grsu.by</Link>.
                            </Typography>
                            <Typography component="p" variant="body2" color="textPrimary">
                              {val?.data?.textPrizeStage}
                              {/* Информация о победителях заключительного этапа будет опубликована с {dateString(val?.data?.datePrize, false, true)} */}
                            </Typography>
                          </>
                          :
                          <>
                            {showCongratulation() &&
                              <>
                                <Typography component="p" variant="body2" color="textPrimary">
                                  Уважаемый участник олимпиады!
                                </Typography>
                                <Typography component="p" variant="body2" color="textPrimary">
                                  Вы стали победителем (диплом {prizeResult()} степени) многопрофильной университетской олимпиады ГрГУ имени Янки Купалы в 2024 году, набрав в заключительном этапа {tagData?.value?.secondResult ?? 0} баллов из {val?.data?.secondMax ?? 0}.
                                </Typography>
                                <Typography component="p" variant="body2" color="textPrimary">
                                  Торжественное награждение победителей состоится 16.03.2024. Подробная информация о награждении будет отправлена Вам дополнительно.
                                </Typography>
                                <Typography component="p" variant="body2" color="textPrimary">
                                  Поздравляем!
                                </Typography>
                              </>
                            }
                            {!showCongratulation() &&
                              <>
                                <Typography component="p" variant="body2" color="textPrimary">
                                  Уважаемый участник олимпиады!
                                </Typography>
                                <Typography component="p" variant="body2" color="textPrimary">
                                  В заключительном этапе Вы набрали {tagData?.value?.secondResult ?? 0} баллов из {val?.data?.secondMax ?? 0}.
                                </Typography>
                                <Typography component="p" variant="body2" color="textPrimary">
                                  Проходной балл в число победителей многопрофильной университетской олимпиады в  2024 году составил {val?.data?.secondCheckpoint3 ?? 0} баллов.
                                </Typography>
                                <Typography component="p" variant="body2" color="textPrimary">
                                  Будем рады видеть Вас в числе абитуриентов нашего университета при проведение основного этапа вступительной кампании 2024 года!
                                </Typography>
                                <Typography component="p" variant="body2" color="textPrimary">
                                  Желаем дальнейших успехов!
                                </Typography>
                              </>
                            }
                          </>
                      :
                      <>
                        <Typography component="p" variant="body2" color="textPrimary">
                          Будем рады видеть Вас в числе абитуриентов нашего университета при проведение основного этапа вступительной кампании 2024 года!
                        </Typography>
                        <Typography component="p" variant="body2" color="textPrimary">
                          Желаем дальнейших успехов!
                        </Typography>
                      </>
                    }

                  </>
                  :
                  <>
                    <Typography component="p" variant="body2" color="textPrimary">
                      В предварительном этапе Вы набрали баллов: {tagData?.value?.firstResult ?? 0}
                    </Typography>
                    <Typography component="p" variant="body2" color="textPrimary">
                      Проходной балл: {val?.data?.checkpoint}
                    </Typography>
                    <Typography component="p" variant="body2" color="textPrimary">
                      <strong>Вы не допущены к участию в заключительном этапе</strong>
                    </Typography>
                    <Typography component="p" variant="body2" color="textPrimary">
                      Будем рады видеть Вас в числе абитуриентов нашего университета при проведение основного этапа вступительной кампании 2024 года!
                    </Typography>
                    <Typography component="p" variant="body2" color="textPrimary">
                      Желаем дальнейших успехов!
                    </Typography>
                  </>
                :
                <>
                  <Typography component="p" variant="body2" color="textPrimary">
                    Дата проведения предварительного этапа: {dateString(val?.dateOfTheEvent)}
                  </Typography>
                  <Typography component="p" variant="body2" color="textPrimary">
                    Время окончания регистрации для участия: {dateString(val?.disabledAt, true)}
                  </Typography>
                  <Typography component="p" variant="body2" color="textPrimary">
                    Время выполнения олимпиадных
                    заданий: {dateString(val?.dateOfTheEvent, true)} – {dateString(val?.endOfTheEvent, true)}
                  </Typography>
                  {
                    isSelected ?
                      <>
                        { // todo варианты текста: Просмотр результатов выполнения  с 17:00 29.11.2023 ; Просмотр результатов выполнения
                          showLink() ?
                            <Typography component="p" variant="body2" color="textPrimary">
                              <Link color="primary" rel="noopener" style={{ cursor: "pointer" }}
                                onClick={() => onClickLink(val)}>
                                <strong>
                                  {isEventEnd() ?
                                    isResultTimeEnd() ?
                                      `Просмотр результатов выполнения`
                                      :
                                      `Просмотр результатов выполнения с ${dateString(val?.data?.dateResultsViewing, false, true)} `
                                    :
                                    `Перейти для выполнения задания по олимпиаде`
                                  }
                                </strong>
                              </Link>
                            </Typography>
                            : null
                        }
                        {
                          showResult() ?
                            <Typography component="p" variant="body2" color="textPrimary">
                              Результаты предварительного этапа: {tagData?.value?.firstResult}
                            </Typography>
                            : null
                        }

                        { //todo проверка и дата
                          isEventEnd() ?
                            <Typography component="p" variant="body2" color="textPrimary">
                              {`Информация о допуске к заключительному этапу будет опубликована не позднее ${dateString(val?.data?.dateAdmissionFinalStage)}`}
                            </Typography>
                            : null
                        }
                      </>
                      : null
                  }
                </>
            }

          </List>
        </Grid>
        <Grid item>
          {
            showFinalStage() ?
              isAdmitted() ?
                isFinalStageSelected() ?
                  isDisabledAtTheFinalEvent() &&
                  <Button variant="outlined"
                    onClick={() => onSaverFinalStage(tagData, true)}
                    color="secondary"
                    disabled={isLoading}>
                    Отмена
                  </Button>
                  :
                  isDisabledAtTheFinalEvent() &&
                  <Button variant="outlined"
                    onClick={() => onSaverFinalStage(tagData)}
                    color="primary"
                    disabled={isLoading || secondYes || disableButton}>
                    Участвовать
                  </Button>
                : null
              :
              isSelected
                ? (
                  hideCancelButton() ?
                    <Button variant="outlined"
                      onClick={() => onDelete(val)}
                      color="secondary"
                      disabled={isLoading}>
                      Отмена
                    </Button>
                    : null
                )
                : (
                  <Button variant="outlined"
                    onClick={() => onSaver(val)}
                    color="primary"
                    disabled={isLoading}>
                    Участвовать
                  </Button>
                )
          }
        </Grid>
      </Grid>
    </Grid >
  );
}

export default OlympiadItem;