import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckingIntoDormitory from '../CheckingIntoDormitory';
import OrganizationalMeeting from '../OrganizationalMeeting';

const useStyles = makeStyles((theme) => ({
    message: {
      marginTop: theme.spacing(1),
    }
  }));

function FirstYearStudents() {
    const classes = useStyles();
  return (
    <Grid
    container
    spacing={2}
    justifyContent="center"
    className={classes.message}
  >
    <Grid item xs={12}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{"Первокурснику"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <OrganizationalMeeting/>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
    <Grid item xs={12}>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography id='internal'>{"Заселение в общежитие"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <CheckingIntoDormitory/>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  </Grid>
  );
}

export default FirstYearStudents;
