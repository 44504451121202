import {
  GET_FACULTIES_REFERENCE_BOOKS_FAIL,
  GET_FACULTIES_REFERENCE_BOOKS_SUCCESS,
  GET_CITIZENSHIP_REFERENCE_BOOKS_SUCCESS,
  GET_CITIZENSHIP_REFERENCE_BOOKS_FAIL,
  GET_PRIVILEGES_REFERENCE_BOOKS_SUCCESS,
  GET_PRIVILEGES_REFERENCE_BOOKS_FAIL,
  GET_LANGUAGE_REFERENCE_BOOKS_SUCCESS,
  GET_LANGUAGE_REFERENCE_BOOKS_FAIL,
  GET_PPY_REFERENCE_BOOKS_SUCCESS,
  GET_PPY_REFERENCE_BOOKS_FAIL,
  GET_OLYMPIADS_REFERENCE_BOOKS_SUCCESS, GET_OLYMPIADS_REFERENCE_BOOKS_FAIL,
} from "../actions/actionTypes";
import instance from "../../customAxios";

const API_URL = "/";

export function getFacultiesReferenceBooks () {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + "api/pub/faculties",
      );

      response.data.sort(function (a, b) {
        if (a.data.nameFull > b.data.nameFull) {
          return 1;
        }
        if (a.data.nameFull < b.data.nameFull) {
          return -1;
        }
        return 0;
      });

      dispatch(getFacultiesReferenceBooksSuccess(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(getFacultiesReferenceBooksFail());
      console.error('☠️error getFacultiesReferenceBooks actions ReferenceBooks: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}

export function getFacultiesReferenceBooksSuccess (faculties) {
  return {
    type: GET_FACULTIES_REFERENCE_BOOKS_SUCCESS,
    payload: faculties
  }
}

export function getFacultiesReferenceBooksFail () {
  return {
    type: GET_FACULTIES_REFERENCE_BOOKS_FAIL
  }
}

export function getCitizenshipReferenceBooks () {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + "api/pub/search-directories/types=citizenship",
      );
      dispatch(getCitizenshipReferenceBooksSuccess(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(getCitizenshipReferenceBooksFail());
      console.error('☠️error getCitizenshipReferenceBooks actions ReferenceBooks: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}

export function getCitizenshipReferenceBooksSuccess (citizenship) {
  return {
    type: GET_CITIZENSHIP_REFERENCE_BOOKS_SUCCESS,
    payload: citizenship
  }
}

export function getCitizenshipReferenceBooksFail () {
  return {
    type: GET_CITIZENSHIP_REFERENCE_BOOKS_FAIL
  }
}

export function getPrivilegesReferenceBooks () {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + "api/pub/search-directories/types=privileges",
      );
      dispatch(getPrivilegesReferenceBooksSuccess(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(getPrivilegesReferenceBooksFail());
      console.error('☠️error getPrivilegesReferenceBooks actions ReferenceBooks: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}

export function getPrivilegesReferenceBooksSuccess (privileges) {
  return {
    type: GET_PRIVILEGES_REFERENCE_BOOKS_SUCCESS,
    payload: privileges
  }
}

export function getPrivilegesReferenceBooksFail () {
  return {
    type: GET_PRIVILEGES_REFERENCE_BOOKS_FAIL
  }
}

export function getLanguageReferenceBooks () {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + "api/pub/search-directories/types=language",
      );
      dispatch(getLanguageReferenceBooksSuccess(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(getLanguageReferenceBooksBooksFail());
      console.error('☠️error getLanguageReferenceBooks actions ReferenceBooks: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}

export function getLanguageReferenceBooksSuccess (language) {
  return {
    type: GET_LANGUAGE_REFERENCE_BOOKS_SUCCESS,
    payload: language
  }
}

export function getLanguageReferenceBooksBooksFail () {
  return {
    type: GET_LANGUAGE_REFERENCE_BOOKS_FAIL
  }
}

export function getPPYReferenceBooks () {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + "api/pub/search-directories/types=ppy",
      );
      dispatch(getPPYReferenceBooksSuccess(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(getPPYReferenceBooksBooksFail());
      console.error('☠️error getPPYReferenceBooks actions ReferenceBooks: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}

export function getPPYReferenceBooksSuccess (payload) {
  return {
    type: GET_PPY_REFERENCE_BOOKS_SUCCESS,
    payload: payload
  }
}

export function getPPYReferenceBooksBooksFail () {
  return {
    type: GET_PPY_REFERENCE_BOOKS_FAIL
  }
}
export function getOlympiadsReferenceBooks () {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        API_URL + "api/v2/pub/olympics.json",
      );
      dispatch(getOlympiadsReferenceBooksSuccess(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(getOlympiadsReferenceBooksBooksFail());
      console.error('☠️error getOlympiadsReferenceBooks actions ReferenceBooks: ', error.response ? error.response : error);
      return Promise.reject(error.response);
    }
  };
}

export function getOlympiadsReferenceBooksSuccess (payload) {
  return {
    type: GET_OLYMPIADS_REFERENCE_BOOKS_SUCCESS,
    payload: payload
  }
}

export function getOlympiadsReferenceBooksBooksFail () {
  return {
    type: GET_OLYMPIADS_REFERENCE_BOOKS_FAIL
  }
}
