import {Box, Grid, Typography} from "@material-ui/core";
import React from "react";
import {useSelector} from "react-redux";
import Loading from "../Loading";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";

export default function ViewFiles(props) {
    let {files, files_loading, files_delete_loading} = useSelector(state => state.fileLoadingReducer);
    const {onClick: PropsMenuClick, tag: TagName, textSuccess, textError} = props;
    const classes = useStyles();
    let currentFiles = [];
    Object.keys(files).filter(item => {
        if(item === TagName){
            currentFiles = files[item] ?? [];
        }
    });

    function onClick(element, archorEl) {
        PropsMenuClick && PropsMenuClick(element, archorEl);
    }

    return (
        <Grid container>
            {files_loading || files_delete_loading ? (
                <Grid item xs={12}>
                    <Loading/>
                </Grid>
            ) : null}
            {!files_loading && !files_delete_loading ? (
                <Grid item xs={12}>
                    <Grid container alignItems="center" alignContent="center">
                        {currentFiles.length ? currentFiles.map((fileItem, fileIndex) => (
                            <React.Fragment key={`${fileItem.value.value}-${fileIndex}`}>
                                <Grid item xs={6} sm={3} md={2} lg={2}>
                                    <Box p={1}>
                                        <div className={classes.fileComponentItem} aria-controls="simple-menu"
                                             aria-haspopup="true"
                                             onClick={(event) => onClick(fileItem.id, event.currentTarget)}>
                                            <img className={classes.fileComponentItemImg}
                                                 src={`/api/ext/files/${fileItem['value']['value']}`} alt="file"/>
                                        </div>
                                        {fileItem?.value?.status === 'IS_CONFIRMED' || fileItem?.value?.status === 'IS_REJECTED' ?
                                          <Typography component="span" variant="body2"
                                                      // className={classes.statusText}
                                            style={fileItem?.value?.status === 'IS_CONFIRMED' ? {'color': '#4caf50'} : {"color": '#f44336'}}
                                          >
                                              {fileItem?.value?.status === 'IS_CONFIRMED' ? textSuccess
                                                : fileItem?.value?.status === 'IS_REJECTED' ? textError: null}
                                          </Typography>
                                        : null
                                        }
                                    </Box>
                                </Grid>
                            </React.Fragment>
                        ))  : null}
                    </Grid>
                </Grid>
            ) : null}
        </Grid>
    )
}
ViewFiles.propTypes = {
    onClick: PropTypes.func.isRequired,
    tag: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
    fileComponentItem: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: 'auto',
        background: 'rgba(23,23,23, 0.1)',
        padding: '0.1em',
        borderRadius: '0.5em',
        cursor: 'pointer',
        opacity: '0.8',
        transition: 'all 0.3s ease',
        maxHeight: '100px',
        '&:hover': {
            opacity: '1'
        }
    },
    fileComponentItemImg: {
        display: 'flex',
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100px - 0.2em)',
        objectFit: 'cover',
        borderRadius: '0.5em'
    },
    statusText: {
        color: theme.palette.success.main,
    }
}));
