import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import Layout from "./hoc/Layout/Layout";
import {SignUp as SignUpNew} from "./containers/Auth/SignUp";
import {SignIn as SignInNew} from "./containers/Auth/SignIn";
import {ResetPassword as ResetPasswordNew} from "./containers/Auth/ResetPassword";
import Logout from "./components/Logout/Logout";
import Profile from "./containers/User/Profile";
import Home from './containers/Home';
import Contacts from "./components/Contacts";
import General from "./containers/User/Statement/Forms/General";
import Training from "./containers/User/Statement/Forms/Training";
import ApplicationForAdmission from "./containers/User/Statement/ApplicationForAdmission";
import Education from "./containers/User/Statement/Forms/Education";
import Parents from "./containers/User/Statement/Forms/Parents";
import Job from "./containers/User/Statement/Forms/Job";
import Exams from "./containers/User/Statement/Forms/Exams";
import Privileges from "./containers/User/Statement/Forms/Privileges";
import AdditionalInformation from "./containers/User/Statement/Forms/AdditionalInformation";
import Queue from "./components/Queue/Queue";
import {getTokens} from "./store/actions/auth";
import {authorizationInit, clearCache} from "./store/System/actionSystem";
import {Info} from "./containers/Info";
import {RemoteUserControl} from "./containers/Admin/Remote_User_Control";
import Channel from "./containers/Channel";
import TermsForm from "./components/Terms/TermsForm";
import {ANKETA_STATUS} from "./services/tagTypes";
import {getDataStatement} from "./store/Statement/actionStatement";
import Olympiad from "./components/Olympiad";

class App extends Component {
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.props.clearCache();
    if (this._isMounted) {
      try {
        await this.props.getTokens();
        if (this.props.accessToken) {
          await this.props.authorizationInit();
          const statementStatusData = await this.props.statementStatusData();
          const {history} = this.props;
          if (history) {
            const findAnketaStatus = statementStatusData.find(data => data.typeTitle === ANKETA_STATUS);
            if (findAnketaStatus !== undefined && findAnketaStatus.value.value === 'IS_ASU') {
              history.push('/info');
            } else {
              // history.push('/statement');
              history.push("/home")
            }
          }

        }
      } catch (error) {
        console.error("APP authorizationInit error: ", error);
      }
    }
  }

  render() {
    const home = () => <Home isAuthenticated={this.props.isAuthenticated}/>;

    let routes = (
      <Switch>
        <Route path="/sign-up" component={SignUpNew}/>
        <Route path="/sign-in" component={SignInNew}/>
        <Route path="/reset-password" component={ResetPasswordNew}/>
        <Route path="/terms" component={TermsForm}/>
        <Route path="/" exact component={home}/>
        <Redirect to="/"/>
      </Switch>
    );

    // console.log("isAuthenticated in app: ", this.props.isAuthenticated);
    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path="/logout" component={Logout}/>
          <Route path="/profile" component={Profile}/>
          <Route path="/reset-password" component={ResetPasswordNew}/>
          <Route path="/contacts" component={Contacts}/>
          <Route path="/statement" component={ApplicationForAdmission}/>
          <Route path="/general" component={General}/>
          <Route path="/training" component={Training}/>
          <Route path="/education" component={Education}/>
          <Route path="/parents" component={Parents}/>
          <Route path="/job" component={Job}/>
          <Route path="/exams" component={Exams}/>
          <Route path="/privileges" component={Privileges}/>
          <Route path="/additionalInformation" component={AdditionalInformation}/>
          <Route path="/queue" component={Queue}/>
          <Route path="/info" component={Info}/>
          <Route path="/channel" component={Channel}/>
          <Route path="/terms" component={TermsForm}/>
          <Route path="/olympiads" component={Olympiad}/>
          {this.props.user?.roles !== undefined && this.props.user.roles.includes('ROLE_ADMIN') ?
            <Route path="/remote_user_control" component={RemoteUserControl}/> : null}
          <Route path="/" exact component={Channel}/>
          <Redirect to="/"/>
        </Switch>
      );
    }


    return (
      <Layout
        isAuthenticated={this.props.isAuthenticated}
      >
        {routes}
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
    accessToken: state.authReducer.accessToken,
    user: state.authReducer.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getTokens: () => dispatch(getTokens()),
    authorizationInit: () => dispatch(authorizationInit()),
    clearCache: () => dispatch(clearCache()),
    statementStatusData: () => dispatch(getDataStatement(`type[]=${ANKETA_STATUS}`)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
