import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress} from "@material-ui/core";

export default function Loading() {
    const classes = useStyles();
    return (
        <div className={classes.box}>
            <CircularProgress color="primary" />
        </div>
    );
}
const useStyles = makeStyles(
    () => ({
        box: {
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }
    })
);
