import React, {memo, useCallback, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Toolbar, IconButton, Typography, Badge, MenuItem, Menu, Link, ListItemIcon} from '@material-ui/core';
import {Link as RouterLink} from "react-router-dom";
import {useSelector} from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import AssignmentIcon from '@material-ui/icons/Assignment';
import Reloader from "../../containers/Chat/Reloader";
import {useChatForm} from "../../containers/Chat";
import {LogoSVG} from "../Logo";


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0.5),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  sectionDesktop: {
    display: 'flex',
    alignItems: 'center',
  },
  logoLink: {
    color: 'inherit',
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: "center"
  },
  logoImage: {
    fontSize: 50,
    marginRight: theme.spacing(0.5),
  },
  welcome: {
    marginRight: theme.spacing(0.5),
    // display: 'none',
    // [theme.breakpoints.up('sm')]: {
    //   display: 'block',
    // },
  },
}));

export const ToolbarNav = memo(
  (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userName, setUserName] = React.useState('');
    const {onVisibleChat: chatFormOpen} = useChatForm();
    const {newMessages: newMessage} = useSelector(state => state.chatReducer);
    const profileInfo = useSelector(state => state.profileReducer.profileInfo)

    useEffect(() => {
      getUserName();
    }, [profileInfo]);

    const getUserName = () => {
      if (profileInfo && Object.entries(profileInfo).length !== 0) {
        let surName = profileInfo?.find(data => data.typeTitle === "USER_SURNAME");
        surName = (surName !== undefined ? surName.value.value : '');

        let firstName = profileInfo?.find(data => data.typeTitle === "USER_FIRST_NAME");
        firstName = (firstName !== undefined ? firstName.value.value : '');

        setUserName(`${firstName}`);
      }
    };


    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    let links = [
      {
        path: "/sign-up",
        text: "Зарегистрироваться",
        icon: <PersonAddIcon/>
      },
      {
        path: "/sign-in",
        text: "Войти",
        icon: <ExitToAppIcon/>
      },
    ];
    if (props.isAuthenticated) {
      links = [
        {
          path: "/profile",
          text: "Профиль",
          icon: <AccountCircleIcon/>
        },
        // {
        //   path: "/statement",
        //   text: "Заявление на поступление",
        //   icon: <AssignmentIcon/>
        // },
        {
          path: "/logout",
          text: "Выйти",
          icon: <InboxIcon/>
        },
      ]
    }


    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        id={menuId}
        keepMounted
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {links.map((link, index) => (
          <MenuItem
            onClick={handleMenuClose}
            key={index}
            component={RouterLink}
            to={link.path}
            variant="body2"
          >
            <ListItemIcon>{link.icon}</ListItemIcon>
            <Typography variant="inherit">{link.text}</Typography>
          </MenuItem>
        ))}
      </Menu>
    );

    return (
      <>
        {props.isAuthenticated ? <Reloader isAuthenticated={props.isAuthenticated}/> : null}
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
            >
              <MenuIcon/>
            </IconButton>
            <Link
              underline={'none'}
              className={classes.logoLink}
              component={RouterLink}
              to={'/'}
            >
              {/* logo */}
              <LogoSVG className={classes.logoImage}/>
              <Typography
                className={classes.title}
                variant="h6"
                component="h1"
                noWrap
              >
                Цифровой кабинет абитуриента
              </Typography>
            </Link>
            <div className={classes.grow}/>
            <div className={classes.sectionDesktop}>
              {props.isAuthenticated
                ?
                <>
                  <Typography
                    className={classes.welcome}
                    variant="body1"
                    // component={'h6'}
                    noWrap
                  >
                    {userName}
                  </Typography>
                  <IconButton
                    aria-label="notifications"
                    color="inherit"
                    onClick={chatFormOpen}
                  >
                    <Badge
                      badgeContent={newMessage}
                      color="secondary"
                    >
                      <NotificationsIcon/>
                    </Badge>
                  </IconButton>
                </>
                : null
              }
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle/>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
      </>
    );
  }
)