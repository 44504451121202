import instance from "../../customAxios";
import axios from "axios";
import {
  GET_FACULTIES_SUCCESS,
  GET_FACULTIES_FAIL,
  GET_FACULTY_SPECIALITIES_SUCCESS,
  GET_FACULTY_SPECIALITIES_FAIL,
  GET_FACULTIES_SPECIALITIES_CLIENT_SUCCESS,
  GET_FACULTIES_SPECIALITIES_CLIENT_FAIL,
  // SET_SELECTED_SPECIALITIES_FACULTIES_SUCCESS,
} from "./actionTypes";

const API_URL = "/";

export function GET_FACULTIES_ALL() {
  return async () => {
    try {
      const response = await axios.get(`${API_URL}api/pub/faculties`);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  };
}

export function GET_FACULTIES_CLIENT() {
  return async (dispatch) => {
    try {
      const response = await instance.get(`${API_URL}api/ext/campaigns/active/applications/potential`);
      dispatch(getFacultiesSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getFacultiesFail());
      return Promise.reject(error.response);
    }
  };
}

export function GET_COMBINE_FACULTIES() {
  return async (dispatch) => {
    try {
      const faculties_client = await instance.get(`${API_URL}api/ext/campaigns/active/applications/potential`);
      dispatch(getFacultiesSuccess(faculties_client.data));
      const faculties_all = await instance.get(`${API_URL}api/pub/faculties`);
      return {
        all: faculties_all.data,
        client: faculties_client.data
      }
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}

export function GET_FACULTY_SPECIALTIES_ALL(faculty) {
  return async (dispatch) => {
    try {
      const response = await instance.get(`${API_URL}api/pub/faculties/${faculty}/specialities`);
      dispatch(getFacultySpecialitiesSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getFacultySpecialitiesFail());
      return Promise.reject(error.response);
    }
  };
}

export function GET_FACULTIES_SPECIALTIES_CLIENT() {
  return async (dispatch) => {
    try {
      const clientFaculties = await dispatch(GET_FACULTIES_CLIENT());

      if (clientFaculties && clientFaculties.length > 0) {
        let selectedSpecialitiesFaculties = [];

        const promises = clientFaculties.map(async faculty => {
          const selectedSpecialitiesFaculty = await dispatch(GET_CAMPAIGN_APPLICATION_DATA(faculty.id, '?type[]=USER_PREFERENCE'));

          if (selectedSpecialitiesFaculty && selectedSpecialitiesFaculty.length > 0) {
            selectedSpecialitiesFaculties.push({
              ...faculty,
              specialitiesSelect: selectedSpecialitiesFaculty
            });
          }

        });
        await Promise.all(promises);

        // for (const faculty of clientFaculties) {
        //   const selectedSpecialitiesFaculties = await dispatch(GET_CAMPAIGN_APPLICATION_DATA(faculty.applicationId,'?type[]=USER_PREFERENCE'));
        // }

        dispatch(getFacultiesSpecialtiesClientSuccess(selectedSpecialitiesFaculties))
      }
      return false;
    } catch (error) {
      dispatch(getFacultiesSpecialtiesClientFail())
      return Promise.reject(error.response);
    }
  };
}

export function GET_CAMPAIGN_APPLICATION_DATA(appId, param = '') {
  return async () => {
    try {
      const response = await instance.get(`${API_URL}api/ext/application/${appId}/data${param}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}

export function SET_CAMPAIGN_APPLICATION_DATA(appId, data) {
  return async () => {
    try {
      const response = await instance.post(`${API_URL}api/ext/application/${appId}/data`, data);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}

export function DELETE_CAMPAIGN_APPLICATION_DATA(appId) {
  return async () => {
    try {
      const response = await instance.delete(`${API_URL}api/ext/application/data/${appId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}

export function SET_CAMPAIGN_APPLICATION(facultyId) {
  return async () => {
    try {
      const response = await instance.put(`${API_URL}api/ext/campaigns/active/applications/potential/faculty/${facultyId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}

export function DEL_CAMPAIGN_APPLICATION(applicationId) {
  return async () => {
    try {
      const response = await instance.delete(`${API_URL}api/ext/campaigns/active/applications/potential/${applicationId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}

export function getFacultySpecialitiesSuccess(payload) {
  return {type: GET_FACULTY_SPECIALITIES_SUCCESS, payload}
}

export function getFacultySpecialitiesFail() {
  return {type: GET_FACULTY_SPECIALITIES_FAIL}
}

export function getFacultiesSuccess(payload) {
  return {type: GET_FACULTIES_SUCCESS, payload}
}

export function getFacultiesFail() {
  return {type: GET_FACULTIES_FAIL}
}

export function getFacultiesSpecialtiesClientSuccess(payload) {
  return {type: GET_FACULTIES_SPECIALITIES_CLIENT_SUCCESS, payload}
}

export function getFacultiesSpecialtiesClientFail() {
  return {type: GET_FACULTIES_SPECIALITIES_CLIENT_FAIL}
}

// export function setSelectedSpecialitiesFacultiesSuccess(payload) {
//   return {type: SET_SELECTED_SPECIALITIES_FACULTIES_SUCCESS, payload}
// }