import {
    LOGOUT
} from "../actions/actionTypes";
import {FILE_LOADING_DELETE_LOADING, FILE_LOADING_GET_FILES, FILE_LOADING_GET_FILES_LOADING} from "./typesFileLoading";

const initialState = {
    files: {},
    files_loading: false,
    files_delete_loading: false,
};

export default function fileLoadingReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case FILE_LOADING_GET_FILES: return {...state, files: Object.assign(state.files, payload)};
        case FILE_LOADING_GET_FILES_LOADING: return {...state, files_loading: !state.files_loading};
        case FILE_LOADING_DELETE_LOADING: return {...state, files_delete_loading: !state.files_delete_loading};
        case LOGOUT:
            return {
                ...state,
                files: {},
                files_loading: false,
                files_delete_loading: false,
            };
        default:
            return state;
    }
}
