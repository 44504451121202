const FileTypes = {};
FileTypes.ALL_IMAGE = 'image/*';
FileTypes.JPG = 'image/jpeg';
FileTypes.JPEG = FileTypes.JPG;
FileTypes.JFIF = FileTypes.JPG;
FileTypes.PJPEG = FileTypes.JPG;
FileTypes.PJP = FileTypes.JPG;
FileTypes.GIF = 'image/gif';
FileTypes.APNG = 'image/apng';
FileTypes.PNG = 'image/png';
FileTypes.WEBP = 'image/webp';
FileTypes.BMP = 'image/bmp';

export default FileTypes;
