import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Container, Drawer, CssBaseline, Divider, IconButton, Box} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {DrawerMenu} from "../../components/DrawerMenu/DrawerMenu";
import {ToolbarNav} from "../../components/ToolbarNav/ToolbarNav";
import Copyright from "../../components/Copyright/Copyright";
import Notification from "../../components/Notification";
import ChatForm from "../../containers/Chat";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0 )
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0

  },
  appBar: {
    marginLeft: drawerWidth,

  },
  menuButton: {
    marginRight: theme.spacing(2),

  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    },
  }
}));

export default function Layout (props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Container className={classes.root}>
      <CssBaseline/>
      <ToolbarNav
        isAuthenticated={props.isAuthenticated}
        handleDrawerToggle={handleDrawerToggle}
      />

      <Drawer
        variant="temporary"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={open}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerToggle}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
          </IconButton>
        </div>
        <Divider/>
        <DrawerMenu isAuthenticated={props.isAuthenticated} handleDrawerToggle={handleDrawerToggle}/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar}/>
        {props.children}
        <Notification />
        {props.isAuthenticated ? (<ChatForm />) : null}
        <Box mt={8}>
          <Copyright/>
        </Box>
      </main>
    </Container>
  );
}
