
 const actionResultTesting = ({result}) => [
  {
    type: "%_DISCIPLINE_TITLE_%",
    action: ({message}) => {
      return message.replace("%_DISCIPLINE_TITLE_%", result._TEST_TITLE_)
    }
  },
  {
    type: "%_DISCIPLINE_RESULT_%",
    action: ({message}) => {
      return message.replace("%_DISCIPLINE_RESULT_%", Number.parseFloat(result._RESULT_))
    }
  },
]

export default actionResultTesting;