import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Link from "@material-ui/core/Link";
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    minWidth: 300,
    // alignItems: "stretch",
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  lineBreak: {
    whiteSpace: 'pre-line',
  },
}));

function CardNews(props) {
  const classes = useStyles();

  const {id, body, channelId, channelTitle, timestamp, status, asuId, ...rest} = props.message;

  const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false
  };
  let datetime = new Intl.DateTimeFormat('ru-RU', options).format(new Date(Date.parse(timestamp?.created)));

  return (
    <Card className={classes.root} {...rest}>
      <CardHeader
        action={
          <Tooltip title={'Подробнее...' || ''} arrow>
            <Link variant="body1" color="inherit" target="_blank" rel="noopener" href={body?.source}>
              <IconButton aria-label="settings">
                <MoreHorizIcon/>
              </IconButton>
            </Link>
          </Tooltip>
        }
        title={channelTitle?.value}
        subheader={datetime}
      />
      <CardContent>
        <Typography variant="subtitle1" paragraph>
          <Link color="inherit" target="_blank" rel="noopener" href={body?.source}>
            {body?.title}
          </Link>
        </Typography>
        <Typography variant="body2" component="p" className={classes.lineBreak} dangerouslySetInnerHTML={ { __html: body?.text } }>
          {/*{body?.text}*/}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CardNews;
