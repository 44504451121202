import React, {useEffect} from 'react';
import {
    Box, Button,
    Grid,Typography
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {FilePond, registerPlugin} from 'react-filepond';
import {useRef, useState} from "react";
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import PropTypes from "prop-types";
import {
    FileLoadingDeleteFiles,
    FileLoadingGetFiles,
    FileLoadingSetAndDeleteFiles
} from "../../store/FileLoading/actionFileLoading";
import CloseIcon from "@material-ui/icons/Close";
import {closeSnackbar, enqueueSnackbar} from "../../store/actions/message";
import FileTypes from "./fileTypes";
import TargetMenu from "./TargetMenu";
import ViewFiles from "./ViewFiles";
import DialogView from "./DialogView";
import DialogDelete from "./DialogDelete";
import DialogChange from "./DialogChange";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const defaultValues = {
    labelForm: 'Перетащите или <span class="filepond--label-action" tabindex="0">прикрепите</span> скан (фото) соответствующих документов',
    labelTitle: 'Загрузка файлов',
    type: 'image/*',
    size: 10,
    convert1MB: 1024 * 1024
}
export default function FileLoadingForm(props) {
    let {
        saveType: SaveType = undefined,
        tag: TagName = undefined,
        labelForm: TitleLabelForm = undefined,
        labelText: TitleLabel = undefined,
        size: FileSize = undefined,
        maxFiles: MaximumFiles = undefined,
        types: ValidTypes = undefined,
        textSuccess: TextSuccess = undefined,
        textError: TextError = undefined
    } = props;

    let newValidTypes = [];
    const CurrentValidTypes = ValidTypes;
    (ValidTypes ?? ['ALL_IMAGE']).map((item) => {
        if (FileTypes[item.toUpperCase()] !== undefined){
            newValidTypes.push(FileTypes[item]);
        }
        return null;
    });
    ValidTypes = newValidTypes;
    if (ValidTypes.length === 0) ValidTypes = [defaultValues.type];
    FileSize = (FileSize ?? defaultValues.size) * defaultValues.convert1MB;
    TitleLabelForm = TitleLabelForm ?? defaultValues.labelForm;
    TitleLabel = TitleLabel ?? defaultValues.labelTitle;


    let pond = useRef();
    const dispatch = useDispatch();
    const [localeFile, setFile] = useState([]);
    const [tokenFile, setTokenFile] = useState(null);
    let {files} = useSelector(state => state.fileLoadingReducer);
    const {accessToken} = useSelector(state => state.authReducer);

    const [ChangeWindowItem, setChangeWindowItem] = useState(null);
    const [DeleteWindowItem, setDeleteWindowItem] = useState(null);
    const [ViewWindowItem, setViewWindowItem] = useState(null);
    const [MenuAnchorEl, setMenuAnchorEl] = useState(null);
    const [MenuItem, setMenuItem] = useState(null);
    let currentFiles = [];
    Object.keys(files).filter(item => {
        if(item === TagName){
            currentFiles = files[item] ?? [];
        }
    });

    function InitValues() {
        setChangeWindowItem(null);
        setDeleteWindowItem(null);
        setViewWindowItem(null);
        setMenuAnchorEl(null);
        setMenuItem(null);
    }

    useEffect(() => {
        InitValues();
    }, []);

    const HandlerChangeWindowOpen = (item = null) => {
        setChangeWindowItem(item);
    }
    const HandlerChangeWindowClose = () => {
        HandlerCloseAllWindow();
    }

    const HandlerDeleteWindowOpen = (item = null) => {
        setDeleteWindowItem(item);
    }
    const HandlerDeleteWindowClose = () => {
        HandlerCloseAllWindow();
    }
    const HandlerDeleteWindowApprove = async () => {
        const result = await dispatch(FileLoadingDeleteFiles(DeleteWindowItem, TagName, SaveType));
        dispatch(enqueueSnackbar({
            ...({message: result?.id !== undefined ? 'Файл успешно удалён.' : 'Ошибка удаления файла. Попробуйте позже.'}),
            options: {
                key: new Date().getTime() + Math.random(),
                ...({variant: result?.id !== undefined ? 'success' : 'error'}),
                action: key => (
                    <Button style={{color: '#fff'}}
                            onClick={() => dispatch(closeSnackbar(key))}><CloseIcon/></Button>
                ),
            },
        }));
        setDeleteWindowItem(null);
    }

    const HandlerViewWindowOpen = (item = null) => {
        setViewWindowItem(item);
    }
    const HandlerViewWindowClose = () => {
        HandlerCloseAllWindow();
    }

    const HandlerMenuOpen = (item = null, anchorEl = null) => {
        setMenuItem(item);
        setMenuAnchorEl(anchorEl);
    }
    const HandlerMenuClose = () => {
        setMenuItem(null);
        setMenuAnchorEl(null);
    }


    function HandlerOpenWindow(window = undefined, item = undefined) {
        switch (window) {
            case 'view': {
                HandlerViewWindowOpen(item);
                if (ChangeWindowItem !== null) setChangeWindowItem(null);
                if (DeleteWindowItem !== null) setDeleteWindowItem(null);
                if (MenuItem !== null) setMenuItem(null);
                if (MenuAnchorEl !== null) setMenuAnchorEl(null);
                break;
            }
            case 'change': {
                HandlerChangeWindowOpen(item);
                if (DeleteWindowItem !== null) setDeleteWindowItem(null);
                if (ViewWindowItem !== null) setViewWindowItem(null);
                if (MenuItem !== null) setMenuItem(null);
                if (MenuAnchorEl !== null) setMenuAnchorEl(null);
                break;
            }
            case 'delete': {
                HandlerDeleteWindowOpen(item);
                if (ChangeWindowItem !== null) setChangeWindowItem(null);
                if (ViewWindowItem !== null) setViewWindowItem(null);
                if (MenuItem !== null) setMenuItem(null);
                if (MenuAnchorEl !== null) setMenuAnchorEl(null);
                break;
            }
            default: {
                console.error(`OpenWindow ${window} is not found.`)
            }
        }
    }

    function HandlerCloseAllWindow() {
        if (ChangeWindowItem !== null) setChangeWindowItem(null);
        if (DeleteWindowItem !== null) setDeleteWindowItem(null);
        if (ViewWindowItem !== null) setViewWindowItem(null);
        if (MenuItem !== null) setMenuItem(null);
        if (MenuAnchorEl !== null) setMenuAnchorEl(null);
    }

    useEffect(() => {
        let isMount = true;

        async function getData() {
            if (TagName && typeof TagName === 'string') {
                await dispatch(FileLoadingGetFiles(TagName, SaveType));
            }
        }

        if (isMount) {
            getData();
        }
        return () => {
            isMount = false;
        }
    }, [dispatch, TagName]);


    function handleAddFile(error, fileItem) {
        let errorText = undefined;
        if (!ValidTypes.includes(defaultValues.type)) {
            if (!ValidTypes.includes(fileItem.file.type)) {
                fileItem.abortProcessing();
                setTimeout(() => fileItem.abortLoad(), 1000);
                errorText = `Формат файла ${fileItem.file.name} не поддерживается.`;
            }
        } else if (ValidTypes.includes(defaultValues.type)) {
            if (fileItem.file.type.split('/')[0] !== 'image') {
                fileItem.abortProcessing();
                setTimeout(() => fileItem.abortLoad(), 1000);
                errorText = `Формат файла ${fileItem.file.name} не поддерживается.`;
            }
        }
        if(!errorText) {
            if (fileItem.file.size > FileSize) {
                fileItem.abortProcessing();
                setTimeout(() => fileItem.abortLoad(), 1000);
                errorText = `Допустимый размер файла ${fileItem.file.name} превышен.`;
            }
        }
        if(errorText){
            dispatch(enqueueSnackbar({
                message: errorText,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    action: key => (
                        <Button style={{color: '#fff'}}
                                onClick={() => dispatch(closeSnackbar(key))}><CloseIcon/></Button>
                    ),
                },
            }));
        }
    }

    function handleUpdate(fileItems) {
        setFile(fileItems.map((fileItem) => fileItem.file));
    }


    async function handleProcess(error, file) {
        const item = ChangeWindowItem;
        setFile(localeFile.filter(fileItem => fileItem.name !== file.source.name));
        if (tokenFile) {
            await dispatch(FileLoadingSetAndDeleteFiles(
              {
                  [TagName]: {value: tokenFile, status: 'NOT_CONFIRMED'}
                  // [TagName]: {value: tokenFile, status: 'IS_CONFIRMED'}
              },
              (MaximumFiles === undefined || (MaximumFiles > 1) ? item : undefined),
              TagName,
              SaveType)
            );
            setTokenFile(null);

            dispatch(enqueueSnackbar({
                message: 'Файл успешно загружен.',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    action: key => (
                        <Button style={{color: '#fff'}}
                                onClick={() => dispatch(closeSnackbar(key))}><CloseIcon/></Button>
                    ),
                },
            }));
            setTimeout(() => HandlerCloseAllWindow(), 500);
        }
    }

    async function handlerOnLoad(response) {
        setTokenFile(JSON.parse(response)['filepond']);
    }

    function handleOnError() {
        HandlerCloseAllWindow();
        dispatch(enqueueSnackbar({
            message: 'Произошла ошибка при добавлении файла.',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
                action: key => (
                    <Button style={{color: '#fff'}} onClick={() => dispatch(closeSnackbar(key))}><CloseIcon/></Button>
                ),
            },
        }));
    }


    const FilePondOption = {
        ref: (ref) => (pond = ref),
        files: localeFile,
        // ...({allowMultiple: (!MaximumFiles && MaximumFiles !== 1)}),
        ...(MaximumFiles ? {maxFiles: MaximumFiles} : {}),
        labelIdle: TitleLabelForm,
        allowRemove: false,
        allowRevert: false,
        allowProcess: false,
        allowReorder: false,
        acceptedFileTypes: [ValidTypes.join(',')],
        onupdatefiles: handleUpdate,
        onaddfile: handleAddFile,
        onprocessfile: handleProcess,
        credits: false,
        server: {
            process: {
                url: '/api/ext/files',
                method: 'POST',
                headers: {
                    Authorization: accessToken
                },
                onload: handlerOnLoad,
                onerror: handleOnError
            }
        }
    }
    const FilePondComponent = <FilePond {...FilePondOption}/>;
    return (
        <Grid container alignItems="center">
            <Grid item xs={12}>
                {typeof TitleLabel === 'string' ?
                    <Typography variant="h6" component="h6" align="center">{TitleLabel}</Typography> : null}
                {typeof TitleLabel === 'object' ? TitleLabel : null}
            </Grid>
            {FileSize || CurrentValidTypes
                ? <Grid item xs={12}>
                    {FileSize ?
                        <Box align="center">
                            <Typography variant="caption" color="error" align="center">* Ограничение по размеру файла: не более {FileSize / (1024 * 1024)} MB.</Typography>
                        </Box> : null}
                    <Box align="center">
                        {CurrentValidTypes ? <Typography variant="caption" color="error" align="center">* Ограничение по формату файла: допустимые {CurrentValidTypes.join(', ')}.</Typography>: null}
                        {!CurrentValidTypes ? <Typography variant="caption" color="error" align="center">* Ограничение по формату файла: только форматы изображений.</Typography>: null}
                    </Box>
                </Grid>
                : null}
            {/*<Grid item xs={12}>*/}
            {/*    {(!MaximumFiles)*/}
            {/*      ? <Typography variant="caption" component="h6" align="center">Файлы добавляются по одному</Typography>*/}
            {/*      : null}*/}
            {/*</Grid>*/}
            {(currentFiles.length === 0 || (MaximumFiles && MaximumFiles > currentFiles.length) || !MaximumFiles) && ChangeWindowItem === null
                ? <Grid item xs={12}>
                    <Box p={1}>{FilePondComponent}</Box>
                </Grid>
                : null}


            <ViewFiles onClick={HandlerMenuOpen} tag={TagName} textSuccess={TextSuccess} textError={TextError}/>
            {MenuItem !== null ?
                <TargetMenu onClose={HandlerMenuClose} onClick={HandlerOpenWindow} maxFiles={MaximumFiles}
                            element={MenuAnchorEl} item={MenuItem}/> : null}
            {ViewWindowItem !== null ?
                <DialogView tag={TagName} open={true} onClose={HandlerViewWindowClose} item={ViewWindowItem}/> : null}
            {DeleteWindowItem !== null ? <DialogDelete open={true} onClose={HandlerDeleteWindowClose}
                                                       onApprove={HandlerDeleteWindowApprove}/> : null}
            {ChangeWindowItem !== null ?
                <DialogChange open={true} onClose={HandlerChangeWindowClose} filePond={FilePondComponent}
                              files={localeFile}/> : null}

        </Grid>
    )
}

FileLoadingForm.propTypes = {
    tag: PropTypes.string.isRequired,
    labelForm: PropTypes.string,
    labelText: PropTypes.any,
    size: PropTypes.number,
    maxFile: PropTypes.number,
    types: PropTypes.array
};
