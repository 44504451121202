const actionSchoolResult = ({ status }) => [
  {
    type: "%_FINISH_RESULT_NUMERIC_%",
    action: ({ message }) => {
      return message.replace(
        "%_FINISH_RESULT_NUMERIC_%",
        Number.parseFloat(status._SCHOOL_RESULT_)
      );
    },
  },
];
export default actionSchoolResult;
