
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

export default function Undefined() {
    const classes = useStyles();
    return (
        <div className={classes.box}>
            <div className={classes.boxUndefined}>
                Выберите чат
            </div>
        </div>
    );
}
const useStyles = makeStyles(
    () => ({
        box: {
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        boxUndefined: {
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            background: 'rgba(23,23,23,0.1)',
            opacity: 0.5
        }
    })
);
